import { bind } from '@react-rxjs/core';
import { combineLatest, map } from 'rxjs';
import { ZERO } from '../datastructures';
import { assets$ } from './useAssets';
import { tokenRates$ } from './useTokenRates';

const DEFAULT_VALUE = null;

const totalAssets$ = combineLatest([assets$, tokenRates$]).pipe(
  map(([assets, tokenRates]) => {
    if (assets.length === 0) {
      return null;
    }

    return assets
      .map(asset => {
        const {
          address,
          nostraInterestBearingCollateralSupply,
          nostraInterestBearingSupply,
          nostraCollateralSupply,
          nostraSupply,
        } = asset;

        if (
          !nostraInterestBearingCollateralSupply ||
          !nostraInterestBearingSupply ||
          !nostraCollateralSupply ||
          !nostraSupply
        ) {
          return null;
        }

        const totalSupplyAvailable = ZERO.add(nostraInterestBearingCollateralSupply)
          .add(nostraInterestBearingSupply)
          .add(nostraCollateralSupply)
          .add(nostraSupply);

        const rate = tokenRates[address];
        if (!rate) {
          return null;
        }

        return totalSupplyAvailable.mul(rate);
      })
      .reduce((acc, totalSupply) => {
        if (!acc || !totalSupply) {
          return null;
        }

        return acc.add(totalSupply);
      }, ZERO);
  }),
);

const totalNostraInterestBearingCollateralSupply$ = combineLatest([assets$, tokenRates$]).pipe(
  map(([assets, tokenRates]) => {
    if (assets.length === 0) {
      return null;
    }

    return assets
      .map(asset => {
        const { address, nostraInterestBearingCollateralSupply } = asset;

        if (!nostraInterestBearingCollateralSupply) {
          return null;
        }

        const rate = tokenRates[address];
        if (!rate) {
          return null;
        }

        return nostraInterestBearingCollateralSupply.mul(rate);
      })
      .reduce((acc, totalSupply) => {
        if (!acc || !totalSupply) {
          return null;
        }

        return acc.add(totalSupply);
      }, ZERO);
  }),
);

const totalNostraInterestBearingSupply$ = combineLatest([assets$, tokenRates$]).pipe(
  map(([assets, tokenRates]) => {
    if (assets.length === 0) {
      return null;
    }

    return assets
      .map(asset => {
        const { address, nostraInterestBearingSupply } = asset;

        if (!nostraInterestBearingSupply) {
          return null;
        }

        const rate = tokenRates[address];
        if (!rate) {
          return null;
        }

        return nostraInterestBearingSupply.mul(rate);
      })
      .reduce((acc, totalSupply) => {
        if (!acc || !totalSupply) {
          return null;
        }

        return acc.add(totalSupply);
      }, ZERO);
  }),
);

const totalNostraCollateralSupply$ = combineLatest([assets$, tokenRates$]).pipe(
  map(([assets, tokenRates]) => {
    if (assets.length === 0) {
      return null;
    }

    return assets
      .map(asset => {
        const { address, nostraCollateralSupply } = asset;

        if (!nostraCollateralSupply) {
          return null;
        }

        const rate = tokenRates[address];
        if (!rate) {
          return null;
        }

        return nostraCollateralSupply.mul(rate);
      })
      .reduce((acc, totalSupply) => {
        if (!acc || !totalSupply) {
          return null;
        }

        return acc.add(totalSupply);
      }, ZERO);
  }),
);

const totalNostraSupply$ = combineLatest([assets$, tokenRates$]).pipe(
  map(([assets, tokenRates]) => {
    if (assets.length === 0) {
      return null;
    }

    return assets
      .map(asset => {
        const { address, nostraSupply } = asset;

        if (!nostraSupply) {
          return null;
        }

        const rate = tokenRates[address];
        if (!rate) {
          return null;
        }

        return nostraSupply.mul(rate);
      })
      .reduce((acc, totalSupply) => {
        if (!acc || !totalSupply) {
          return null;
        }

        return acc.add(totalSupply);
      }, ZERO);
  }),
);

/**
 * Total assets represents sum of all nostra tokens.
 */
export const [useTotalAssets] = bind(totalAssets$, DEFAULT_VALUE);
export const [useTotalNostraInterestBearingCollateralSupply] = bind(
  totalNostraInterestBearingCollateralSupply$,
  DEFAULT_VALUE,
);
export const [useTotalNostraInterestBearingSupply] = bind(totalNostraInterestBearingSupply$, DEFAULT_VALUE);
export const [useTotalNostraCollateralSupply] = bind(totalNostraCollateralSupply$, DEFAULT_VALUE);
export const [useTotalNostraSupply] = bind(totalNostraSupply$, DEFAULT_VALUE);
