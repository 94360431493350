import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AssetTier } from '../../../interfaces';
import Typography from '../Typography';

import './AssetTierPill.scss';

export interface AssetTierPillProps {
  tier: AssetTier;
}

const AssetTierPill: FC<AssetTierPillProps> = props => {
  const { tier } = props;
  const { t } = useTranslation();

  const tierName = useMemo(() => {
    switch (tier) {
      case 'nominal':
        return t('AssetTierPill.nominal');
      case 'isolated':
        return t('AssetTierPill.isolated');
      case 'cross':
        return t('AssetTierPill.cross');
      case 'shared':
        return t('AssetTierPill.shared');
    }
  }, [t, tier]);

  return (
    <div className={`nostra__asset-tier ${tier}`}>
      <Typography
        variant="body-tertiary"
        color={['shared', 'nominal'].includes(tier) ? 'text-primary-inverted' : 'text-primary'}
      >
        {tierName}
      </Typography>
    </div>
  );
};

export default AssetTierPill;
