import { Children, FC, memo, PropsWithChildren, useMemo } from 'react';
import { Typography } from '../shared';

import './Breadcrumb.scss';

interface BreadcrumbProps {
  path?: string[];
}

const Breadcrumb: FC<PropsWithChildren<BreadcrumbProps>> = ({ path, children }) => {
  const items = useMemo(() => {
    if (path) {
      return path.map((segment, index) => (
        <li key={`path-${index}`}>
          <Typography variant="title" color="breadcrumb">
            {segment}
          </Typography>
        </li>
      ));
    }

    if (children) {
      const childrenArray = Children.toArray(children);
      return childrenArray.map((segment, index) => <li key={`path-${index}`}>{segment}</li>);
    }

    return null;
  }, [path, children]);

  return (
    <div className="nostra__breadcrumb">
      <ul>{items}</ul>
    </div>
  );
};

export default memo(Breadcrumb);
