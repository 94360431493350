import { FC, PropsWithChildren } from 'react';
import { ShadowContainer, Typography } from '../../shared';

import './SummaryBox.scss';

interface SummaryBoxProps {
  title: string;
}

const SummaryBox: FC<PropsWithChildren<SummaryBoxProps>> = props => {
  const { title, children } = props;

  return (
    <ShadowContainer className="nostra__summary-box">
      <Typography className="nostra__summary-box__title" variant="subtitle" color="text-secondary" weight="bold">
        {title}
      </Typography>
      {children}
    </ShadowContainer>
  );
};

export default SummaryBox;
