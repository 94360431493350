import { FC, ReactNode, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonWrapper, TooltipWrapper, Typography } from '../shared';
import { TypographyColor, TypographyWeight } from '../shared/Typography';

export const ALL_ACTIONS = ['deposit', 'withdraw', 'borrow', 'repay', 'adjust'] as const;
export type Action = typeof ALL_ACTIONS[number];

interface ActionModalTabProps {
  action: Action;
  selected: boolean;
  tooltip?: ReactNode;
  disabled?: boolean;
  onClick: (action: Action) => void;
}

const ActionModalTab: FC<ActionModalTabProps> = props => {
  const { action, selected, disabled, tooltip, onClick } = props;
  const { t } = useTranslation();

  const label = useMemo(() => {
    switch (action) {
      case 'deposit':
        return t('ActionModalTab.labelDeposit');
      case 'withdraw':
        return t('ActionModalTab.labelWithdraw');
      case 'borrow':
        return t('ActionModalTab.labelBorrow');
      case 'repay':
        return t('ActionModalTab.labelRepay');
      case 'adjust':
        return t('ActionModalTab.labelAdjust');
    }
  }, [action, t]);

  const handleClick = useCallback(() => onClick(action), [onClick, action]);

  let textColor: TypographyColor = 'text-primary';
  let textWeight: TypographyWeight = 'regular';
  if (disabled) {
    textColor = 'text-disabled-tertiary';
  } else if (selected) {
    textColor = 'text-highlight';
    textWeight = 'bold';
  }

  return (
    <TooltipWrapper
      anchorClasses="nostra__action-modal__tooltip-wrapper"
      tooltipContent={tooltip}
      openEvent="mouseenter"
      placement="bottom"
      disabled={!Boolean(tooltip)}
    >
      <ButtonWrapper
        className="nostra__action-modal__tab"
        onClick={handleClick}
        selected={selected}
        disabled={disabled}
      >
        <Typography variant="body-secondary" color={textColor} weight={textWeight}>
          {label}
        </Typography>
      </ButtonWrapper>
    </TooltipWrapper>
  );
};

export default ActionModalTab;
