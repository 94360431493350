import { createRef, FC, memo, useCallback, useMemo, useState } from 'react';
import { Decimal, ZERO } from '../../../datastructures';
import { Asset, Nullable } from '../../../interfaces';
import { DecimalUtils } from '../../../utils';
import BaseInput from '../BaseInput';
import Typography from '../Typography';
import Logo from '../Logo';
import ButtonWrapper from '../ButtonWrapper';
import Icon from '../Icon';
import { colors } from '../Colors';
import LoadingPlaceholder from '../LoadingPlaceholder';
import LoadingButtonPlaceholder from '../LoadingButtonPlaceholder';
import PercentageButton from './PercentageButton';

import './CurrencyInput.scss';

const inputPercentages = [0.25, 0.5, 0.75, 1].map(percentage => new Decimal(percentage));

export interface CurrencyInputProps {
  asset: Nullable<Asset>;
  amount: string;
  showAmount?: boolean;
  maxAmount?: Decimal | null;
  maxAmountLabel?: string;
  tokenRate: Nullable<Decimal>;
  disabled?: boolean;
  error?: JSX.Element | string;
  warning?: string;
  autoFocus?: boolean;
  showWalletIcon?: boolean;
  onUpdate?: (value: string) => void;
  onDebounceUpdate?: (value: string) => void;
}

const CurrencyInput: FC<CurrencyInputProps> = props => {
  const {
    asset,
    maxAmount,
    amount,
    showAmount,
    tokenRate,
    disabled,
    error,
    warning,
    autoFocus,
    showWalletIcon = true,
    maxAmountLabel = '',
    onUpdate,
    onDebounceUpdate,
  } = props;
  const inputRef = createRef<HTMLInputElement>();

  const [usdAmount, setUsdAmount] = useState<string>(
    tokenRate
      ? DecimalUtils.format(Decimal.parse(amount, ZERO).mul(tokenRate), {
          style: 'currency',
          fractionDigits: 2,
          currency: '$',
        })
      : '$0.00',
  );
  const [focused, setFocused] = useState<boolean>(false);

  const percentageValue = useMemo(() => {
    if (!maxAmount || maxAmount.equals(ZERO) || !asset) {
      return null;
    }

    // We want to make sure `amount` that user enters and `max amount` have same precision
    const maxAmountTruncated = maxAmount.toTruncated(asset.tokenPrecision, false);

    return Decimal.parse(amount, ZERO).div(maxAmountTruncated);
  }, [amount, asset, maxAmount]);

  const percentageValueFormatted = useMemo(() => {
    if (!percentageValue) {
      return null;
    }

    return DecimalUtils.format(percentageValue, { style: 'percentage', fractionDigits: 2 });
  }, [percentageValue]);

  const formattedMaxAmount = useMemo(
    () =>
      maxAmount && asset?.uiTokenPrecision
        ? DecimalUtils.format(maxAmount, {
            style: 'currency',
            fractionDigits: asset.uiTokenPrecision,
            currency: asset.token,
            lessThanFormat: true,
          })
        : '',
    [asset?.token, asset?.uiTokenPrecision, maxAmount],
  );

  const updateUsdAmount = useCallback(
    (value: string) => {
      if (tokenRate) {
        const usdAmount = Decimal.parse(value, ZERO).mul(tokenRate);
        const formattedUsdAmount = DecimalUtils.format(usdAmount, {
          style: 'currency',
          fractionDigits: 2,
          currency: '$',
          lessThanFormat: true,
        });

        if (usdAmount.isZero() || formattedUsdAmount.startsWith('<')) {
          setUsdAmount(formattedUsdAmount);
        } else {
          setUsdAmount(`~${formattedUsdAmount}`);
        }
      }
    },
    [tokenRate],
  );

  const handleValueChange = useCallback(
    (value: string) => {
      updateUsdAmount(value);

      onUpdate?.(value);
    },
    [onUpdate, updateUsdAmount],
  );

  const handleDebounceValueChange = useCallback(
    (value: string) => {
      onDebounceUpdate?.(value);
    },
    [onDebounceUpdate],
  );

  const focusInput = useCallback(() => {
    if (!disabled) {
      inputRef.current?.focus();
    }
  }, [disabled, inputRef]);

  const handleInputFocus = useCallback(() => {
    if (!disabled) {
      setFocused(true);
    }
  }, [disabled]);

  const handleInputBlur = useCallback(() => setFocused(false), []);

  const handlePercentageClick = useCallback(
    (percentage: Decimal) => {
      if (!maxAmount || !asset) {
        return;
      }

      const value = percentage.mul(maxAmount);
      const formattedValue = value.toTruncated(asset.tokenPrecision, false);

      handleValueChange(formattedValue);
      handleDebounceValueChange(formattedValue);
    },
    [maxAmount, asset, handleValueChange, handleDebounceValueChange],
  );

  const handleMaxAmountLabelClick = useCallback(() => {
    handlePercentageClick(new Decimal(1));
  }, [handlePercentageClick]);

  const dataLoaded = Boolean(maxAmount) && Boolean(tokenRate) && Boolean(asset);
  const percentageButtonDisabled = disabled || !maxAmount || maxAmount.equals(ZERO);

  return (
    <div className="nostra__currency-input">
      <div className="nostra__currency-input__max-amount">
        {showAmount && !dataLoaded && <LoadingPlaceholder shape={{ width: 'large', height: 'small' }} />}
        {showAmount && dataLoaded && (
          <ButtonWrapper className="nostra__currency-input__max-amount-value" onClick={handleMaxAmountLabelClick}>
            {showWalletIcon && <Icon variant="wallet" size="tiny" color={colors.textDisabled} />}
            {maxAmountLabel && (
              <Typography variant="body-tertiary" color="text-disabled">
                {maxAmountLabel}
              </Typography>
            )}
            {formattedMaxAmount && asset?.token && (
              <Typography variant="body-tertiary">{formattedMaxAmount}</Typography>
            )}
          </ButtonWrapper>
        )}
      </div>
      <div
        className={`nostra__currency-input__field-container
          ${disabled ? ' nostra__currency-input__field-container-disabled' : ''}
          ${!disabled && focused ? ' nostra__currency-input__field-container-focused' : ''}
          ${error ? ' nostra__currency-input__field-container-error' : ''}
        `}
      >
        <div
          className={`nostra__currency-input__input-container
            ${disabled ? ' nostra__currency-input__input-container-disabled' : ''}
          `}
          onClick={focusInput}
        >
          <div className="nostra__currency-input__amount-container">
            {dataLoaded ? (
              <Typography
                className="nostra__currency-input__amount"
                variant="subtitle"
                color={disabled ? 'text-disabled' : 'text-primary'}
                weight="bold"
              >
                <BaseInput
                  ref={inputRef}
                  value={amount}
                  placeholder="0"
                  pattern={`[0-9]*[.]?[0-9]{0,${asset?.tokenPrecision}}`}
                  disabled={disabled}
                  debounce
                  autoFocus={dataLoaded && autoFocus}
                  onChange={handleValueChange}
                  onDebounceChange={handleDebounceValueChange}
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
                />
              </Typography>
            ) : (
              <LoadingPlaceholder shape={{ width: 'large', height: 'small' }} />
            )}
            {dataLoaded && usdAmount && (
              <span className="nostra__currency-input__fiat-amount">
                <Typography variant="body-tertiary" color={!disabled ? 'text-primary' : 'text-disabled'}>
                  {usdAmount}
                </Typography>
                {percentageValueFormatted && (
                  <Typography variant="body-tertiary" color={!disabled ? 'text-primary' : 'text-disabled'}>
                    &nbsp;({percentageValueFormatted})
                  </Typography>
                )}
              </span>
            )}
            {(!dataLoaded || !usdAmount) && <LoadingPlaceholder shape={{ width: 'small', height: 'small' }} />}
          </div>
          <div className="nostra__currency-input__token">
            {dataLoaded && asset?.token ? (
              <>
                <Typography className="nostra__currency-input__token-label" variant="subtitle">
                  {asset.token}
                </Typography>
                <Logo type={`token-${asset.token}`} size="small" />
              </>
            ) : (
              <LoadingPlaceholder shape={{ circle: 'medium' }} />
            )}
          </div>
        </div>
      </div>
      {error && (
        <Typography className="nostra__currency-input__error" variant="body-tertiary" color="text-error">
          {error}
        </Typography>
      )}
      {!error && warning && (
        <Typography className="nostra__currency-input__error" variant="body-tertiary" color="text-warning">
          {warning}
        </Typography>
      )}
      <div className="nostra__currency-input__percentage-buttons">
        {inputPercentages.map(percentage =>
          dataLoaded ? (
            <PercentageButton
              percentage={percentage}
              disabled={percentageButtonDisabled}
              onClick={handlePercentageClick}
              key={`percentage-button-${percentage}`}
            />
          ) : (
            <LoadingButtonPlaceholder key={`percentage-button-${percentage}`} width="tiny" height="small" />
          ),
        )}
      </div>
    </div>
  );
};

export default memo(CurrencyInput);
