import { FC } from 'react';
import ButtonWrapper from '../ButtonWrapper';
import Typography from '../Typography';

import './Button.scss';

interface ButtonProps {
  label: string;
  onClick: () => void;
}

const Button: FC<ButtonProps> = ({ label, onClick }) => {
  return (
    <ButtonWrapper className="nostra__button" onClick={onClick}>
      <Typography variant="body-primary" weight="bold">
        {label}
      </Typography>
    </ButtonWrapper>
  );
};
export default Button;
