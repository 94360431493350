import { AccountInterface } from 'starknet';
import { getConfigManager } from '../config/getConfigManager';
import { DEFAULT_DECIMAL_PRECISION } from '../datastructures';
import { ContractCache } from '../utils';
import { Erc20Contract } from './contracts';

const contractCache = new ContractCache((address, providerOrAccount) => {
  const asset = getConfigManager().getAssetByAddress(address);
  return new Erc20Contract(asset?.tokenPrecision ?? DEFAULT_DECIMAL_PRECISION, address, providerOrAccount);
});

export const getErc20Contract = (address: string, account?: AccountInterface) => {
  return contractCache.get(address, account);
};
