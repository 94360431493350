import { FC, useEffect } from 'react';
import {
  subscribeTokenBalances,
  subscribeAssets,
  subscribeDepositPositions,
  subscribeBorrowPositions,
  subscribeDepositStatus,
  subscribeWithdrawStatus,
  subscribeTokenRates,
  subscribeBorrowStatus,
  subscribeRepayStatus,
  subscribeBurnAndMintStatus,
  subscribeHealthFactorData,
  subscribeAssetVariants,
  subscribeAccounts,
  subscribeNostraTokenBalance,
  subscribeAccountTotalSupply,
  subscribeAccountTotalDebt,
  subscribeAccountSupplyApy,
  subscribeAccountBorrowApy,
  subscribeActiveAccountId,
  subscribeAccountTotalIdle,
  subscribeActiveAccountName,
  subscribeAccountTotalAssets,
  useActiveAccountId,
  subscribeDebtData,
  subscribeInterestRateConfig,
  subscribeCollateralData,
  subscribeInterestState,
  subscribeNostraTokenTotalSupply,
  subscribeNostraTokenTotalSupplyCap,
  subscribeWallet,
  subscribeTransactionHistory,
  subscribeStarknetDomain,
  subscribeStatisticsData,
  subscribeTotalAssetsChartData,
  useChartCurrency,
  subscribeTotalDebtChartData,
  subscribeCumulativeInterestEarnedChartData,
  useSelectedChartToken,
  subscribeApyChartData,
  subscribeTotalUtilizationRateChartData,
  subscribeTotalUniqueBorrowersChartData,
  subscribeTotalUniqueLendersChartData,
} from '../../hooks';

export const HookSubscriber: FC = () => {
  // to keep at least one subscriber of the stream insides the state hooks
  useActiveAccountId();
  useChartCurrency();
  useSelectedChartToken();

  // subscribe for the steam$ of the polling hooks
  useEffect(() => {
    subscribeAssets();
    subscribeAssetVariants();
    subscribeTokenBalances();
    subscribeDepositStatus();
    subscribeWithdrawStatus();
    subscribeBorrowStatus();
    subscribeBurnAndMintStatus();
    subscribeDepositPositions();
    subscribeBorrowPositions();
    subscribeRepayStatus();
    subscribeTokenRates();
    subscribeHealthFactorData();
    subscribeAccounts();
    subscribeActiveAccountId();
    subscribeNostraTokenBalance();
    subscribeAccountTotalSupply();
    subscribeAccountTotalDebt();
    subscribeAccountSupplyApy();
    subscribeAccountBorrowApy();
    subscribeAccountTotalIdle();
    subscribeActiveAccountName();
    subscribeAccountTotalAssets();
    subscribeDebtData();
    subscribeInterestRateConfig();
    subscribeCollateralData();
    subscribeInterestState();
    subscribeNostraTokenTotalSupply();
    subscribeNostraTokenTotalSupplyCap();
    subscribeTransactionHistory();
    subscribeWallet();
    subscribeStarknetDomain();
    subscribeStatisticsData();
    subscribeTotalAssetsChartData();
    subscribeTotalDebtChartData();
    subscribeCumulativeInterestEarnedChartData();
    subscribeApyChartData();
    subscribeTotalUtilizationRateChartData();
    subscribeTotalUniqueBorrowersChartData();
    subscribeTotalUniqueLendersChartData();
  }, []);

  return null;
};
