import { bind } from '@react-rxjs/core';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  filter,
  interval,
  merge,
  mergeMap,
  of,
  Subscription,
  tap,
  withLatestFrom,
} from 'rxjs';
import { AccountInterface } from 'starknet';
import { MULTICALL_CONTRACT_ADDRESS } from '../constants';
import { Decimal } from '../datastructures';
import { NostraToken } from '../interfaces';
import { getMultiCallContract } from '../services';
import { walletAccount$ } from './useWalletAccount';
import { AppEvent, appEvent$ } from './useAppEvent';
import { logGlobalError } from './useGlobalError';
import { nostraTokenList$ } from './useNostraTokenList';

const DEFAULT_VALUE = null;

export type NostraTokenTotalSupplyMap = { [nostraTokenAddress: string]: Decimal };

export const nostraTokenTotalSupply$ = new BehaviorSubject<NostraTokenTotalSupplyMap | null>(DEFAULT_VALUE);

const POLLING_INTERVAL_IN_MS = 300000; // 5 minutes
const RESPONSE_CHUNK_SIZE = 3;

const fetchData = (nostraTokens: NostraToken[], account?: AccountInterface) => {
  try {
    const calldata = nostraTokens.map(nostraToken => {
      return {
        contractAddress: nostraToken.address,
        entrypoint: 'totalSupply',
      };
    });

    const multiCallContract = getMultiCallContract(MULTICALL_CONTRACT_ADDRESS, account);

    return multiCallContract.aggregate(calldata).pipe(
      mergeMap(result => {
        if (!result) {
          return of(null);
        }

        const nostraTokenTotalSupplyMap: NostraTokenTotalSupplyMap = {};

        for (let i = 0; i < result.length; i += RESPONSE_CHUNK_SIZE) {
          const nostraToken = nostraTokens[i / RESPONSE_CHUNK_SIZE];

          // Index 1,2 is for totalSupply
          const totalSupply = new Decimal({ low: result[i + 1], high: result[i + 2] }, nostraToken.tokenPrecision);

          nostraTokenTotalSupplyMap[nostraToken.address] = totalSupply;
        }

        return of(nostraTokenTotalSupplyMap);
      }),
      catchError(error => {
        console.error(`useNostraTokenTotalSupply - Failed to load nostra token total supply!`, error);
        logGlobalError(error);
        return of(null);
      }),
    );
  } catch (error) {
    console.error(`useNostraTokenTotalSupply - Failed to load nostra token total supply!`, error);
    logGlobalError(error);
    return of(null);
  }
};

/**
 * Load total supply for nostra tokens on app load.
 */
const initialLoadStream$ = combineLatest([nostraTokenList$]).pipe(
  mergeMap(([nostraTokenList]) => {
    return fetchData(nostraTokenList);
  }),
);

/**
 * Fetch total supply for nostra tokens every [POLLING_INTERVAL_IN_MS] milliseconds
 */
const intervalBeat$ = interval(POLLING_INTERVAL_IN_MS);

const intervalLoadStream$ = combineLatest([nostraTokenList$, intervalBeat$]).pipe(
  mergeMap(([nostraTokenList]) => {
    return fetchData(nostraTokenList);
  }),
);

/**
 * Fetch total supply for nostra tokens on every app event
 */
const appEventStream$ = combineLatest([nostraTokenList$, appEvent$]).pipe(
  filter((value): value is [NostraToken[], AppEvent] => {
    const [, appEvent] = value;

    // Only fetch token total supply after these app events.
    const FETCH_ON_EVENTS = ['deposit', 'borrow', 'withdraw', 'repay', 'burnAndMint'];

    return FETCH_ON_EVENTS.indexOf(appEvent.eventType) > -1;
  }),
  withLatestFrom(walletAccount$),
  filter((value): value is [[NostraToken[], AppEvent], AccountInterface] => {
    const [, walletAccount] = value;

    return Boolean(walletAccount);
  }),
  mergeMap(([[nostraTokenList], walletAccount]) => {
    return fetchData(nostraTokenList, walletAccount);
  }),
);

/**
 * Combine all streams into one, and update BehaviorSubject every time one of the streams emits new value
 */
const stream$ = merge(initialLoadStream$, intervalLoadStream$, appEventStream$).pipe(
  tap(nostraTokenTotalSupplyMap => {
    if (nostraTokenTotalSupplyMap) {
      nostraTokenTotalSupply$.next(nostraTokenTotalSupplyMap);
    }
  }),
);

export const [useNostraTokenTotalSupply] = bind(nostraTokenTotalSupply$, DEFAULT_VALUE);

let subscription: Subscription | null = null;

export const subscribeNostraTokenTotalSupply = (): void => {
  unsubscribeNostraTokenTotalSupply();
  subscription = stream$.subscribe();
};
export const unsubscribeNostraTokenTotalSupply = (): void => subscription?.unsubscribe();
export const resetNostraTokenTotalSupply = (): void => nostraTokenTotalSupply$.next(DEFAULT_VALUE);
