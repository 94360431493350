import { SupportedLocale } from '../i18nTypes';
import enGB from './en-GB.json';
import zz from './zz.json';

const NAMESPACE = 'translation';

// TODO: we should have 'es' and 'it' but right now we dont have translation yet
const translations: Record<SupportedLocale, { [NAMESPACE]: any }> = {
  'en-GB': {
    [NAMESPACE]: enGB,
  },
  zz: {
    [NAMESPACE]: zz,
  },
};

export default translations;
