import { FC, useCallback } from 'react';
import { ButtonWrapper, Typography } from '../shared';

interface TransactionHistoryDropdownItemProps {
  label: string;
  value: number;
  onSelect: (value: number) => void;
}

const TransactionHistoryDropdownItem: FC<TransactionHistoryDropdownItemProps> = props => {
  const { label, value, onSelect } = props;

  const handleClick = useCallback(() => onSelect(value), [onSelect, value]);

  return (
    <ButtonWrapper className="nostra__transaction-history__dropdown-item" onClick={handleClick}>
      <Typography variant="body-secondary">{label}</Typography>
    </ButtonWrapper>
  );
};
export default TransactionHistoryDropdownItem;
