import { catchError, from, map, Observable, of } from 'rxjs';
import { Abi, AccountInterface, Call, InvokeFunctionResponse, ProviderInterface } from 'starknet';
import { Decimal, DEFAULT_DECIMAL_PRECISION, Uint256 } from '../../datastructures';
import { isAccount, parseAndLogError } from '../../utils';
import ERC20_ABI from './abi/Erc20_abi.json';
import NostraInterestToken_ABI from './abi/NostraInterestToken_abi.json';
import { ContractBase } from './base';
import { NostraInterestToken } from './typechain';

export class NostraInterestTokenContract extends ContractBase<NostraInterestToken> {
  private precision: number;

  constructor(precision: number, address: string, providerOrAccount: ProviderInterface | AccountInterface) {
    super(NostraInterestToken_ABI as Abi, address, providerOrAccount);
    this.precision = precision;
  }

  balanceOf(address: string): Observable<Decimal | null> {
    if (!this.contract) {
      return of(null);
    }

    return from(this.contract.balanceOf(address)).pipe(
      map(([balance]) => new Decimal(balance as Uint256, this.precision)),
    );
  }

  totalSupply(): Observable<Decimal | null> {
    if (!this.contract) {
      return of(null);
    }

    return from(this.contract.totalSupply()).pipe(map(([supply]) => new Decimal(supply, this.precision)));
  }

  getTotalSupplyCap(): Observable<Decimal | null> {
    if (!this.contract) {
      return of(null);
    }

    return from(this.contract.getTotalSupplyCap()).pipe(
      map(([supply]) => new Decimal(supply as Uint256, this.precision)),
    );
  }

  mint(asset: string, amount: Decimal, to: string): Observable<InvokeFunctionResponse | null> {
    if (!this.contract) {
      return of(null);
    }

    const account = this.contract.providerOrAccount;
    if (!isAccount(account)) {
      return of(null);
    }

    const amountUint256 = amount.toUint256(this.precision);

    const calls = [
      {
        contractAddress: asset,
        entrypoint: 'approve',
        calldata: [this.contract.address, amountUint256.low, amountUint256.high],
      },
      {
        contractAddress: this.contract.address,
        entrypoint: 'mint',
        calldata: [to, amountUint256.low, amountUint256.high],
      },
    ] as Call[];
    const abis = [ERC20_ABI, NostraInterestToken_ABI] as Abi[];

    return from(account.execute(calls, abis)).pipe(catchError(parseAndLogError));
  }

  burn(amount: Decimal, inputAmount: Decimal, to: string, burnFrom: string): Observable<InvokeFunctionResponse | null> {
    if (!this.contract) {
      return of(null);
    }

    const account = this.contract.providerOrAccount;
    if (!isAccount(account)) {
      return of(null);
    }

    // If amount is Decimal.MAX_DECIMAL we don't want to reduce number of decimals to match token decimals, we want to keep amount to 18 decimals.
    // This way contract will properly recognize passed amount as Decimal.MAX_DECIMAL value.
    const amountUint256 = amount.toUint256(amount.equals(inputAmount) ? this.precision : DEFAULT_DECIMAL_PRECISION);

    const calls = [
      {
        contractAddress: this.contract.address,
        entrypoint: 'burn',
        calldata: [burnFrom, to, amountUint256.low, amountUint256.high],
      },
    ] as Call[];
    const abis = [NostraInterestToken_ABI] as Abi[];

    return from(account.execute(calls, abis)).pipe(catchError(parseAndLogError));
  }
}
