import { FC } from 'react';
import { InnerIconProps } from './IconProps';
import withIcon from './withIcon';

const Gitbook: FC<InnerIconProps> = ({ size, color }) => (
  <svg
    className="nostra__icon nostra__icon-gitbook"
    width={size}
    height={size}
    viewBox="0 0 13 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.50586 7.8875C5.69336 7.8875 5.86211 8.0375 5.86211 8.24375C5.86211 8.43125 5.71211 8.6 5.50586 8.6C5.31836 8.6 5.14961 8.45 5.14961 8.24375C5.14961 8.0375 5.31836 7.8875 5.50586 7.8875ZM11.0184 5.7125C10.8309 5.7125 10.6621 5.5625 10.6621 5.35625C10.6621 5.16875 10.8121 5 11.0184 5C11.2059 5 11.3746 5.15 11.3746 5.35625C11.3746 5.54375 11.2059 5.7125 11.0184 5.7125ZM11.0184 4.26875C10.4184 4.26875 9.93086 4.75625 9.93086 5.35625C9.93086 5.46875 9.94961 5.58125 9.98711 5.69375L6.40586 7.60625C6.19961 7.30625 5.86211 7.1375 5.50586 7.1375C5.09336 7.1375 4.71836 7.38125 4.53086 7.7375L1.30586 6.05C0.968361 5.8625 0.705861 5.31875 0.743361 4.79375C0.762111 4.53125 0.855861 4.325 0.987111 4.25C1.08086 4.19375 1.17461 4.2125 1.30586 4.26875L1.32461 4.2875C2.18711 4.7375 4.98086 6.2 5.09336 6.25625C5.28086 6.33125 5.37461 6.36875 5.69336 6.21875L11.4684 3.21875C11.5621 3.18125 11.6559 3.10625 11.6559 2.975C11.6559 2.80625 11.4871 2.73125 11.4871 2.73125C11.1496 2.58125 10.6434 2.3375 10.1559 2.1125C9.10586 1.625 7.90586 1.0625 7.38086 0.781251C6.93086 0.537501 6.55586 0.743751 6.49961 0.781251L6.36836 0.837501C3.98711 2.0375 0.837111 3.59375 0.649611 3.70625C0.330861 3.89375 0.124611 4.2875 0.105861 4.775C0.0683612 5.54375 0.462111 6.35 1.02461 6.63125L4.43711 8.39375C4.51211 8.91875 4.98086 9.33125 5.50586 9.33125C6.10586 9.33125 6.57461 8.8625 6.59336 8.2625L10.3434 6.2375C10.5309 6.3875 10.7746 6.4625 11.0184 6.4625C11.6184 6.4625 12.1059 5.975 12.1059 5.375C12.1059 4.75625 11.6184 4.26875 11.0184 4.26875Z"
      fill={color}
    />
  </svg>
);

export default withIcon(Gitbook);
