import { FC, MouseEvent, ReactNode, useCallback, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';

import './TooltipWrapper.scss';

export type OpenEvent = 'mouseenter'; // TODO: support click later?
export type TooltipPlacement = 'left' | 'right' | 'top' | 'bottom' | 'bottom-start' | 'bottom-end';

export interface TooltipWrapperProps {
  anchorClasses?: string;
  tooltipContent: ReactNode;
  placement: TooltipPlacement;
  openEvent?: OpenEvent;
  disabled?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}

const TooltipWrapper: FC<TooltipWrapperProps> = props => {
  const { anchorClasses = '', tooltipContent, placement, disabled = false, onOpen, onClose, children } = props;

  const [open, setOpen] = useState<boolean>(false);
  const [anchorClicked, setAnchorClicked] = useState<boolean>(false);

  const handleOpen = useCallback(() => {
    if (!anchorClicked) {
      setOpen(true);
      onOpen?.();
    }
  }, [onOpen, anchorClicked]);
  const handleClose = useCallback(() => {
    setOpen(false);
    onClose?.();
  }, [onClose]);
  // TODO: the UI logic needs to be changed if we support click to open tooltip
  const handleAnchorClicked = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      setAnchorClicked(true);
      handleClose();
    },
    [handleClose],
  );
  const handleAnchorMouseEnter = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      handleOpen();
    },
    [handleOpen],
  );
  const handleAnchorMouseOut = useCallback((event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setAnchorClicked(false);
  }, []);

  if (disabled) {
    return <>{children}</>;
  }

  const anchorClassName = `nostra__tooltip-wrapper-anchor ${anchorClasses}`;

  if (!tooltipContent) {
    return (
      <div tabIndex={0} className={anchorClassName} data-testid="tooltip-anchor">
        {children}
      </div>
    );
  }

  return (
    <Tooltip
      title={tooltipContent}
      placement={placement}
      arrow
      classes={{ tooltip: 'nostra__tooltip-content' }}
      open={open}
      onClose={handleClose}
      leaveDelay={100} // allows user to click on edge elements inside tooltip
    >
      <div
        tabIndex={0}
        className={anchorClassName}
        data-testid="tooltip-anchor"
        onClick={handleAnchorClicked}
        onMouseEnter={handleAnchorMouseEnter}
        onMouseOut={handleAnchorMouseOut}
      >
        {children}
      </div>
    </Tooltip>
  );
};

export default TooltipWrapper;
