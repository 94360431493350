import { FC } from 'react';
import { InnerLogoProps } from './LogoProps';
import withLogo from './withLogo';

const TokenUSDC: FC<InnerLogoProps> = ({ size }) => (
  <svg
    className="nostra__logo nostra__logo-token-USDC"
    width={size}
    height={size}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 40C31.0834 40 40 31.0834 40 20C40 8.9166 31.0834 0 20 0C8.9166 0 0 8.9166 0 20C0 31.0834 8.9166 40 20 40Z"
      fill="#2775CA"
    />
    <path
      d="M25.4999 23.1666C25.4999 20.25 23.7499 19.25 20.2499 18.8334C17.75 18.5 17.25 17.8334 17.25 16.6666C17.25 15.4998 18.0834 14.75 19.7499 14.75C21.2499 14.75 22.0833 15.25 22.4999 16.5C22.5833 16.75 22.8333 16.9166 23.0833 16.9166H24.4165C24.7499 16.9166 24.9999 16.6666 24.9999 16.3334V16.25C24.6665 14.4166 23.1665 13.0001 21.2499 12.8335V10.8335C21.2499 10.5001 20.9999 10.2501 20.5833 10.1667H19.3334C19 10.1667 18.75 10.4167 18.6666 10.8335V12.7501C16.1666 13.0835 14.5834 14.75 14.5834 16.8334C14.5834 19.5834 16.25 20.6666 19.7499 21.0834C22.0833 21.5 22.8333 22 22.8333 23.3334C22.8333 24.6668 21.6665 25.5834 20.0833 25.5834C17.9166 25.5834 17.1666 24.6666 16.9166 23.4166C16.8334 23.0834 16.5834 22.9166 16.3334 22.9166H14.9166C14.5834 22.9166 14.3334 23.1666 14.3334 23.5V23.5834C14.6666 25.6666 16 27.1666 18.75 27.5834V29.5834C18.75 29.9166 19 30.1666 19.4166 30.25H20.6665C20.9999 30.25 21.2499 30 21.3333 29.5834V27.5834C23.8333 27.1666 25.4999 25.4166 25.4999 23.1666Z"
      fill="white"
    />
    <path
      d="M15.75 31.9166C9.25 29.5834 5.9166 22.3334 8.3334 15.9166C9.5834 12.4166 12.3334 9.75001 15.75 8.5C16.0834 8.3334 16.25 8.0834 16.25 7.6666V6.5C16.25 6.1666 16.0834 5.9166 15.75 5.8334C15.6666 5.8334 15.5 5.8334 15.4166 5.9166C7.5 8.4166 3.1666 16.8334 5.6666 24.75C7.1666 29.4166 10.75 33 15.4166 34.5C15.75 34.6666 16.0834 34.5 16.1666 34.1666C16.25 34.0834 16.25 34 16.25 33.8334V32.6666C16.25 32.4166 16 32.0834 15.75 31.9166ZM24.5834 5.9166C24.25 5.75 23.9166 5.9166 23.8334 6.25C23.75 6.3334 23.75 6.4166 23.75 6.5834V7.75C23.75 8.0834 24 8.4166 24.25 8.58341C30.75 10.9166 34.0834 18.1666 31.6666 24.5834C30.4166 28.0834 27.6666 30.75 24.25 32C23.9166 32.1666 23.75 32.4166 23.75 32.8334V34C23.75 34.3334 23.9166 34.5834 24.25 34.6666C24.3334 34.6666 24.5 34.6666 24.5834 34.5834C32.5 32.0834 36.8334 23.6666 34.3334 15.75C32.8334 11 29.1666 7.4166 24.5834 5.9166Z"
      fill="white"
    />
  </svg>
);

export default withLogo(TokenUSDC);
