import { FC } from 'react';
import { InnerLogoProps } from './LogoProps';
import withLogo from './withLogo';

const TokenWBTCDark: FC<InnerLogoProps> = ({ size }) => (
  <svg
    className="nostra__logo nostra__logo-token-wBTC-dark"
    width={size}
    height={size}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="20" r="20" fill="#282138" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.0073 4.5444C16.1565 4.5444 12.4452 5.98608 9.60346 8.58579L8.48903 7.4963C11.6237 4.60514 15.7311 3 19.9946 3C24.2581 3 28.3655 4.60514 31.5001 7.4963L30.4111 8.58579C27.5694 5.98608 23.858 4.5444 20.0073 4.5444ZM31.4166 9.58101L32.5057 8.49152V8.46609C35.3956 11.6021 37 15.7112 37 19.9766C37 24.242 35.3956 28.3511 32.5057 31.4871L31.4166 30.3976C34.0152 27.5546 35.4563 23.8417 35.4563 19.9893C35.4563 16.1369 34.0152 12.424 31.4166 9.58101ZM8.58338 30.4085C5.98787 27.5662 4.54871 23.8555 4.54871 20.0057C4.54871 16.1558 5.98787 12.4451 8.58338 9.60281L7.49436 8.51331C4.60445 11.6493 3 15.7585 3 20.0238C3 24.2892 4.60445 28.3983 7.49436 31.5343L8.58338 30.4085ZM19.9928 35.4556C23.8435 35.4556 27.5549 34.0139 30.3966 31.4142L31.511 32.5037C28.3764 35.3949 24.269 37 20.0055 37C15.742 37 11.6346 35.3949 8.49995 32.5037L9.58897 31.4142C12.4307 34.0139 16.1421 35.4556 19.9928 35.4556Z"
      fill="white"
    />
    <path
      d="M26.945 16.1512C26.7421 13.9658 24.916 13.2327 22.6063 13.0093V10H20.8208V12.9534C20.3508 12.9534 19.8706 12.9534 19.3938 12.9534V10H17.6218V13.0302H14V15.0026C14 15.0026 15.3189 14.9782 15.2986 15.0026C15.5364 14.9756 15.775 15.045 15.9643 15.196C16.1535 15.3471 16.2786 15.5681 16.3131 15.8125V24.1072C16.308 24.1934 16.2862 24.2776 16.249 24.355C16.2119 24.4323 16.16 24.5012 16.0966 24.5575C16.0344 24.6149 15.9616 24.6587 15.8826 24.6863C15.8035 24.7139 15.7199 24.7247 15.6367 24.7181C15.6604 24.739 14.3382 24.7181 14.3382 24.7181L14 26.9209H17.588V30H19.3735V26.9663H20.8005V29.986H22.5894V26.9419C25.6059 26.7534 27.7093 25.9853 27.9731 23.0703C28.1861 20.7244 27.1175 19.6771 25.4098 19.2547C26.4479 18.7275 27.0904 17.75 26.945 16.1512ZM24.4426 22.7073C24.4426 24.9974 20.6416 24.7356 19.431 24.7356V20.672C20.6416 20.6755 24.4426 20.3159 24.4426 22.7073ZM23.6141 16.982C23.6141 19.0766 20.4421 18.8218 19.4343 18.8218V15.1283C20.4421 15.1283 23.6141 14.8001 23.6141 16.982Z"
      fill="#F09242"
    />
    <path
      d="M19.9963 40C16.041 39.9993 12.1747 38.8258 8.88632 36.6279C5.5979 34.43 3.03502 31.3064 1.52172 27.652C0.00841897 23.9976 -0.387339 19.9766 0.384482 16.0973C1.1563 12.218 3.06104 8.6547 5.85787 5.85787C8.6547 3.06104 12.218 1.1563 16.0973 0.384482C19.9766 -0.387339 23.9976 0.00841897 27.652 1.52172C31.3064 3.03502 34.43 5.5979 36.6279 8.88632C38.8258 12.1747 39.9993 16.041 40 19.9963C40.0005 22.6234 39.4834 25.2248 38.4783 27.652C37.4732 30.0792 35.9997 32.2845 34.1421 34.1421C32.2845 35.9997 30.0792 37.4732 27.652 38.4783C25.2248 39.4834 22.6234 40.0005 19.9963 40ZM19.9963 1.55959C16.3523 1.56249 12.7909 2.64563 9.76225 4.67212C6.73364 6.69861 4.37376 9.57747 2.98091 12.9448C1.58807 16.3122 1.22476 20.0169 1.93693 23.5907C2.64909 27.1645 4.40475 30.4469 6.982 33.0232C9.55925 35.5994 12.8424 37.3538 16.4165 38.0645C19.9906 38.7752 23.6951 38.4105 27.0619 37.0163C30.4287 35.6221 33.3067 33.2611 35.332 30.2317C37.3572 27.2022 38.439 23.6404 38.4404 19.9963C38.4414 17.5743 37.9649 15.1758 37.0383 12.9381C36.1116 10.7003 34.753 8.66715 33.04 6.95485C31.327 5.24255 29.2933 3.8847 27.0552 2.95894C24.817 2.03318 22.4184 1.55767 19.9963 1.55959Z"
      fill="#282138"
    />
  </svg>
);

export default withLogo(TokenWBTCDark);
