import { FC, memo, useCallback, useMemo } from 'react';
import { Decimal, ONE } from '../../../datastructures';
import { DecimalUtils } from '../../../utils';
import ButtonWrapper from '../ButtonWrapper';
import ShadowContainer from '../ShadowContainer';
import Typography, { TypographyColor } from '../Typography';

export interface PercentageButtonProps {
  percentage: Decimal;
  disabled?: boolean;
  onClick: (percentage: Decimal) => void;
}

const PercentageButton: FC<PercentageButtonProps> = props => {
  const { percentage, disabled, onClick } = props;

  const onButtonClick = useCallback(() => onClick(percentage), [percentage, onClick]);

  const label = useMemo(() => {
    if (percentage.equals(ONE)) {
      return 'Max';
    }
    return DecimalUtils.format(percentage, { style: 'percentage' });
  }, [percentage]);

  let textColor: TypographyColor = 'text-primary';
  if (disabled) {
    textColor = 'text-disabled-tertiary';
  }

  return (
    <ShadowContainer>
      <ButtonWrapper className="nostra__currency-input__percentage-button" onClick={onButtonClick} disabled={disabled}>
        <Typography variant="body-tertiary" color={textColor}>
          {label}
        </Typography>
      </ButtonWrapper>
    </ShadowContainer>
  );
};

export default memo(PercentageButton);
