import { FC, useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { StarknetChainId } from 'starknet/dist/constants';
import { useWalletNetwork } from '../../hooks';
import { Link, ModalWrapper, Typography } from '../shared';

import './UnsupportedNetworkPopup.scss';

const UnsupportedNetworkPopup: FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const handleModalClose = useCallback(() => {}, []);
  const walletNetwork = useWalletNetwork();
  const { t } = useTranslation();

  useEffect(() => {
    if (!walletNetwork) {
      return;
    }

    setOpen(walletNetwork !== StarknetChainId.MAINNET);
  }, [walletNetwork]);

  return (
    <ModalWrapper open={open} onClose={handleModalClose}>
      <div className="nostra__unsupported-network-popup">
        <Typography variant="title" weight="bold">
          {t('UnsupportedNetworkPopup.title')}
        </Typography>
        <div className="nostra__unsupported-network-popup__divider" />
        <Typography variant="body-primary">
          <Trans t={t} i18nKey="UnsupportedNetworkPopup.description">
            <Link href="https://discord.gg/nostrafinance" />
          </Trans>
        </Typography>
      </div>
    </ModalWrapper>
  );
};

export default UnsupportedNetworkPopup;
