import { FC } from 'react';
import { InnerLogoProps } from './LogoProps';
import withLogo from './withLogo';

const TokenYvUSDT: FC<InnerLogoProps> = ({ size }) => (
  <svg
    className="nostra__logo nostra__logo-token-yvUSDT"
    width={size}
    height={size}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="20" r="20" fill="white" />
    <path
      d="M21.6517 17.5257V15.5343H26.2056V12.5H13.8051V15.5343H18.3596V17.5241C14.6581 17.6941 11.875 18.4271 11.875 19.3053C11.875 20.1835 14.6595 20.9166 18.3596 21.0876V27.4665H21.6527V21.0871C25.3475 20.9166 28.125 20.184 28.125 19.3067C28.125 18.4293 25.3475 17.6968 21.6527 17.5262L21.6517 17.5257ZM21.6527 20.5468V20.5452C21.5598 20.5511 21.0825 20.5797 20.0195 20.5797C19.1698 20.5797 18.5719 20.5556 18.3612 20.5447V20.5473C15.0911 20.4025 12.6501 19.833 12.6501 19.1516C12.6501 18.4703 15.0913 17.9016 18.3612 17.7565V19.98C18.5754 19.9947 19.188 20.0309 20.0335 20.0309C21.049 20.0309 21.5596 19.9886 21.6533 19.98V17.7565C24.917 17.9018 27.3523 18.4719 27.3523 19.1508C27.3523 19.8298 24.9159 20.4001 21.6533 20.5455"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.6875 6.5625H5.3125V9.6875H9.6875L9.6875 13.4375H12.8125V9.6875V6.5625H9.6875Z"
      fill="#0657F9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0804 7.2425C17.411 6.80132 18.8339 6.5625 20.3125 6.5625C27.7338 6.5625 33.75 12.5787 33.75 20C33.75 27.4213 27.7338 33.4375 20.3125 33.4375C12.8912 33.4375 6.875 27.4213 6.875 20C6.875 16.0299 8.59669 12.462 11.3342 10.002L10.4479 6.69436C6.3836 9.71252 3.75 14.5487 3.75 20C3.75 29.1472 11.1653 36.5625 20.3125 36.5625C29.4597 36.5625 36.875 29.1472 36.875 20C36.875 10.8528 29.4597 3.4375 20.3125 3.4375C18.5543 3.4375 16.8601 3.71145 15.2703 4.219L16.0804 7.2425Z"
      fill="#0657F9"
    />
  </svg>
);

export default withLogo(TokenYvUSDT);
