import { FC, memo } from 'react';

const Logo: FC = () => (
  <svg width="213" height="52" viewBox="0 0 213 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 32.232L16.5338 22.7185L0 13.205V32.232ZM20.6491 25.087L0 36.969L20.6491 48.8494L41.2966 36.969V13.205L20.6491 25.087ZM20.388 9.15234L10.9187 14.7519L20.6491 20.3515L30.3779 14.7519L20.388 9.15234Z"
      fill="#FF4240"
    />
    <path
      d="M71.3348 26.5692C70.077 24.3931 68.3002 20.9617 67.1151 18.4907H67.0407C67.1515 21.2203 67.1879 24.6138 67.1879 26.9382V36.6409H63.1533V14.5059H69.3713L74.1827 23.1741C75.7 25.9037 77.4025 29.2247 78.5875 31.6595H78.6239C78.5875 29.1143 78.5496 26.3847 78.5496 24.2086V14.5059H82.5826V36.6393H77.0686L71.3316 26.5692H71.3348Z"
      fill="white"
    />
    <path
      d="M107.572 25.9416C107.572 21.6997 105.054 18.3062 100.392 18.3062C95.7289 18.3062 93.21 21.6997 93.21 25.9416C93.21 30.5903 95.6893 33.7993 100.392 33.7993C105.094 33.7993 107.572 30.5903 107.572 25.9416ZM89.0283 25.9779C89.0283 19.3738 93.4695 14.5059 100.392 14.5059C107.314 14.5059 111.791 19.3753 111.791 25.9779C111.791 32.5804 107.535 37.5981 100.392 37.5981C93.248 37.5981 89.0283 32.6182 89.0283 25.9779Z"
      fill="white"
    />
    <path
      d="M117.591 29.7782L121.662 28.9677C122.254 32.1026 124.401 33.9475 127.805 33.9475C130.655 33.9475 132.136 32.8406 132.136 31.0697C132.136 29.2988 130.84 28.3401 126.658 27.7125C120.515 26.7521 118.553 24.2811 118.553 20.9254C118.553 17.0147 121.44 14.5059 126.843 14.5059C132.693 14.5059 135.061 17.6408 135.913 21.6997L131.767 22.5481C131.065 19.634 129.805 18.0854 126.66 18.0854C124.181 18.0854 122.81 19.1924 122.81 20.8513C122.81 22.3273 123.625 23.3965 128.511 24.2086C134.765 25.2052 136.431 27.7866 136.431 30.923C136.431 34.9063 133.655 37.5997 127.548 37.5997C120.811 37.5634 118.108 34.0579 117.591 29.7798"
      fill="white"
    />
    <path d="M141.725 14.5059H159.64V18.4907H152.792V36.6393H148.572V18.4907H141.725V14.5059Z" fill="white" />
    <path
      d="M170.169 18.2305V24.1329H175.202C177.755 24.1329 179.2 23.0259 179.2 21.0343C179.2 19.2634 178.164 18.2305 175.425 18.2305H170.169ZM173.87 27.8591H170.169V36.6393H165.949V14.5059H175.61C180.83 14.5059 183.345 16.9043 183.345 20.9239C183.345 24.4656 181.532 26.7159 178.274 27.528L184.79 36.5668V36.6409H179.683L173.871 27.8607L173.87 27.8591Z"
      fill="white"
    />
    <path
      d="M198.193 27.7128H205.113L204.151 25.1314C203.338 23.0294 202.487 20.7413 201.783 18.6393H201.523C200.821 20.7413 199.968 23.0294 199.155 25.1314L198.193 27.7128ZM206.409 31.1442H196.86L194.788 36.6413H190.421L199.305 14.5078H204.116L213 36.6413H208.447L206.41 31.1442H206.409Z"
      fill="white"
    />
  </svg>
);

export default memo(Logo);
