import { getConfigManager } from '../config/getConfigManager';
import { CDPManagerContract } from './contracts';
import { ContractCache } from '../utils';
import { AccountInterface } from 'starknet';

const contractCache = new ContractCache(
  (address, providerOrAccount) => new CDPManagerContract(address, providerOrAccount),
);

export const getCDPManagerContract = (account?: AccountInterface) => {
  const cdpManagerAddress = getConfigManager().getConfig().cdpManager;
  return contractCache.get(cdpManagerAddress, account);
};
