import { FC, memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setFromApyChartDate, useApyChartData, useSelectedChartToken } from '../../../hooks';
import { Nullable } from '../../../interfaces';
import Graph from '../Graph';

const SupplyBorrowApy: FC = () => {
  const { t } = useTranslation();

  const apyChartData = useApyChartData();
  const [selectedChartToken] = useSelectedChartToken();

  const [fromTimestamp, setFromTimestamp] = useState<Nullable<number>>(-1);

  const handleDataFromChange = useCallback((fromTimestamp: Nullable<number>) => {
    setFromTimestamp(fromTimestamp);
  }, []);

  useEffect(() => {
    if (fromTimestamp === -1) {
      return;
    }

    setFromApyChartDate(fromTimestamp);
  }, [fromTimestamp]);

  return (
    <Graph
      title={t('Analytics.titleSupplyBorrowApy')}
      titleLogo={selectedChartToken ? `token-${selectedChartToken}` : undefined}
      parsedChartData1={apyChartData?.borrowApy ?? null}
      parsedChartData2={apyChartData?.supplyApy ?? null}
      yAxisLabelFormatting="percentage"
      tooltipValueFormatting="percentage"
      tooltipValueLabel1={t('Analytics.labelBorrowApy')}
      tooltipValueLabel2={t('Analytics.labelSupplyApy')}
      onDateFromChange={handleDataFromChange}
    />
  );
};

export default memo(SupplyBorrowApy);
