import { Abi, Contract, hash, Call, InvokeFunctionResponse, AccountInterface, ProviderInterface } from 'starknet';
import { ContractBase } from './base';
import DeferredBatchCallAdapter_ABI from './abi/DeferredBatchCallAdapter_abi.json';
import { catchError, from, Observable, of } from 'rxjs';
import { Asset, Nullable } from '../../interfaces';
import { Decimal } from '../../datastructures';
import { getNostraTokenFromFlags, getAccountAddressFromId, isAccount, parseAndLogError } from '../../utils';

export class DeferredBatchCallAdapterContract extends ContractBase<Contract> {
  constructor(address: string, providerOrAccount: ProviderInterface | AccountInterface) {
    super(DeferredBatchCallAdapter_ABI as Abi, address, providerOrAccount);
  }

  burnAndMint(
    asset: Asset,
    tokenPrecision: number,
    amount: Decimal,
    accountId: number,
    isFromInterestBearing: boolean,
    isFromCollateral: boolean,
    isToInterestBearing: boolean,
    isToCollateral: boolean,
  ): Observable<Nullable<InvokeFunctionResponse>> {
    if (!this.contract) {
      return of(null);
    }

    const sender = this.contract.providerOrAccount;
    if (!isAccount(sender)) {
      return of(null);
    }

    const accountAddress = getAccountAddressFromId(sender.address, accountId);

    const fromNostraToken = getNostraTokenFromFlags(asset, {
      interestBearing: isFromInterestBearing,
      collateral: isFromCollateral,
    });
    const toNostraToken = getNostraTokenFromFlags(asset, {
      interestBearing: isToInterestBearing,
      collateral: isToCollateral,
    });

    const amountUint256 = amount.toUint256(tokenPrecision);
    const burnAndMintAccountsToDefer = [sender.address, this.contract.address];
    const burnAndMintCalls = [
      // transferFrom
      fromNostraToken.address,
      hash.getSelectorFromName('transferFrom'),
      4,
      accountAddress,
      this.contract.address,
      amountUint256.low,
      amountUint256.high,
      // withdraw
      fromNostraToken.address,
      hash.getSelectorFromName('burn'),
      4,
      this.contract.address,
      this.contract.address,
      amountUint256.low,
      amountUint256.high,
      // approve
      asset.address,
      hash.getSelectorFromName('approve'),
      3,
      toNostraToken.address,
      amountUint256.low,
      amountUint256.high,
      // deposit
      toNostraToken.address,
      hash.getSelectorFromName('mint'),
      3,
      accountAddress,
      amountUint256.low,
      amountUint256.high,
    ];
    const calls = [
      {
        contractAddress: fromNostraToken.address,
        entrypoint: 'approveFromSubAccount',
        calldata: [accountAddress, this.contract.address, amountUint256.low, amountUint256.high],
      },
      {
        contractAddress: this.contract.address,
        entrypoint: 'batch',
        calldata: [
          burnAndMintAccountsToDefer.length,
          ...burnAndMintAccountsToDefer,
          burnAndMintCalls.length,
          ...burnAndMintCalls,
        ],
      },
    ] as Call[];

    return from(sender.execute(calls)).pipe(catchError(parseAndLogError));
  }
}
