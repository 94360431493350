import {
  map,
  combineLatest,
  BehaviorSubject,
  Subscription,
  catchError,
  of,
  merge,
  debounce,
  tap,
  interval,
  Observable,
  mergeMap,
  filter,
  concatMap,
  withLatestFrom,
  scan,
  distinctUntilChanged,
} from 'rxjs';
import { bind } from '@react-rxjs/core';
import { AccountInterface, Call } from 'starknet';
import { Decimal, DEFAULT_DECIMAL_PRECISION, ZERO } from '../datastructures';
import { TokenRateMap, tokenRatesInBaseAsset$ } from './useTokenRates';
import { nostraTokenBalance$, NostraTokenBalanceMap } from './useNostraTokenBalance';
import { assets$ } from './useAssets';
import { DEBOUNCE_IN_MS, HEALTH_FACTOR_INFINITY, MULTICALL_CONTRACT_ADDRESS } from '../constants';
import { Asset, Nullable } from '../interfaces';
import { getCDPManagerContract, getMultiCallContract } from '../services';
import { walletAccount$ } from './useWalletAccount';
import { AppEvent, appEvent$ } from './useAppEvent';
import { accountList$ } from './useAccounts';
import { getAccountAddressFromId } from '../utils';
import { logGlobalError } from './useGlobalError';

interface AccountHealthData {
  value: Decimal;
  totalCollateral: Decimal;
  totalDebt: Decimal;
  walletAddress: string;
  accountId: number;
}

export interface AccountHealthFactorMap {
  [walletAddressAccountId: string]: AccountHealthData;
}

const DEFAULT_VALUE: AccountHealthFactorMap = {};
const PRECISION_OFFSET = 0.0001;
const RESPONSE_CHUNK_SIZE = 8;

export const healthFactorData$ = new BehaviorSubject<AccountHealthFactorMap>(DEFAULT_VALUE);

const fetchData = (
  address: string,
  accountIds: number[],
  account?: AccountInterface,
): Observable<Nullable<AccountHealthFactorMap>> => {
  try {
    const cdpManagerContract = getCDPManagerContract();
    const calldata: Call[] = accountIds.map(accountId => ({
      contractAddress: cdpManagerContract.getAddress(),
      entrypoint: 'getUserAccountData',
      calldata: [getAccountAddressFromId(address, accountId)],
    }));

    const multiCallContract = getMultiCallContract(MULTICALL_CONTRACT_ADDRESS, account);

    return multiCallContract.aggregate(calldata).pipe(
      mergeMap(result => {
        if (!result) {
          return of(null);
        }

        const healthFactorMap: AccountHealthFactorMap = {};

        for (let i = 0; i < result.length; i += RESPONSE_CHUNK_SIZE) {
          // Index 1,2 is totalCollateral
          // Index 3,4 is totalDebt
          // Index 5 is isValidDebt
          // Index 6,7 is healthFactor
          const totalCollateral = new Decimal({ low: result[i + 1], high: result[i + 2] }, DEFAULT_DECIMAL_PRECISION);
          const totalDebt = new Decimal({ low: result[i + 3], high: result[i + 4] }, DEFAULT_DECIMAL_PRECISION);
          const healthFactor = new Decimal({ low: result[i + 6], high: result[i + 7] }, DEFAULT_DECIMAL_PRECISION);
          const accountId = accountIds[i / RESPONSE_CHUNK_SIZE];

          healthFactorMap[`${address}-${accountId}`] = {
            totalCollateral,
            totalDebt,
            value: healthFactor,
            walletAddress: address,
            accountId,
          };
        }

        return of(healthFactorMap);
      }),
      catchError(error => {
        console.error(`useAccountHealthFactor - failed to fetch health factors for '${address}'`, error);
        logGlobalError(error);
        return of(null);
      }),
    );
  } catch (error) {
    console.error(`useAccountHealthFactor - failed to fetch health factors for '${address}'`, error);
    return of(null);
  }
};

const calculateHealthFactor: (totalCollateral: Decimal, totalDebt: Decimal) => Decimal = (totalCollateral, totalDebt) =>
  totalDebt.isZero() ? new Decimal(HEALTH_FACTOR_INFINITY) : totalCollateral.div(totalDebt);

// we are using different math lib between frontend and smart contract, it's possible to have precision error between total collateral
// use this function only for displaying number on frontend
const calculateTotalCollateral = (
  walletAddress: string,
  accountId: number,
  assets: Asset[],
  nostraTokenBalances: NostraTokenBalanceMap,
  tokenRatesInBaseAsset: TokenRateMap,
) =>
  assets.reduce((sum, item) => {
    const nostraInterestBearingCollateralTokenDataKey = `${walletAddress}-${accountId}-${item.nostraInterestBearingCollateralTokenAddress}`;
    const nostraCollateralTokenDataKey = `${walletAddress}-${accountId}-${item.nostraCollateralTokenAddress}`;

    const nostraInterestBearingCollateralTokenBalance =
      nostraTokenBalances[nostraInterestBearingCollateralTokenDataKey];
    const nostraCollateralTokenBalance = nostraTokenBalances[nostraCollateralTokenDataKey];

    if (
      !sum ||
      !nostraInterestBearingCollateralTokenBalance ||
      !nostraCollateralTokenBalance ||
      !tokenRatesInBaseAsset[item.address] ||
      !item.collateralFactor
    ) {
      return null;
    }

    const totalNostraTokenBalance = nostraInterestBearingCollateralTokenBalance.add(nostraCollateralTokenBalance);
    const totalNostraTokenAmountInEth = totalNostraTokenBalance.mul(tokenRatesInBaseAsset[item.address]);

    return sum.add(totalNostraTokenAmountInEth.mul(item.collateralFactor));
  }, ZERO as Nullable<Decimal>);

// we are using different math lib between frontend and smart contract, it's possible to have precision error between total debt
// use this function only for displaying number on frontend
const calculateTotalDebt = (
  walletAddress: string,
  accountId: number,
  assets: Asset[],
  nostraTokenBalances: NostraTokenBalanceMap,
  tokenRatesInBaseAsset: TokenRateMap,
) =>
  assets.reduce((sum, item) => {
    const debtTokenDataKey = `${walletAddress}-${accountId}-${item.debtTokenAddress}`;
    const debtTokenBalance = nostraTokenBalances[debtTokenDataKey];

    if (!sum || !debtTokenBalance || !tokenRatesInBaseAsset[item.address] || !item.debtFactor) {
      return null;
    }

    const totalDebtTokenAmountInEth = debtTokenBalance.mul(tokenRatesInBaseAsset[item.address]);

    return sum.add(totalDebtTokenAmountInEth.div(item.debtFactor));
  }, ZERO as Nullable<Decimal>);

const calculateNewDepositHealthFactor$: Observable<
  (walletAddress: string, accountId: number, balance: Decimal, asset: Asset) => Decimal | null
> = combineLatest([nostraTokenBalance$, tokenRatesInBaseAsset$, assets$]).pipe(
  map(
    ([nostraTokenBalances, tokenRatesInBaseAsset, assets]) =>
      (walletAddress: string, accountId: number, balance: Decimal, asset: Asset) => {
        const totalCollateral = calculateTotalCollateral(
          walletAddress,
          accountId,
          assets,
          nostraTokenBalances,
          tokenRatesInBaseAsset,
        );
        const totalDebt = calculateTotalDebt(
          walletAddress,
          accountId,
          assets,
          nostraTokenBalances,
          tokenRatesInBaseAsset,
        );

        if (!totalCollateral || !totalDebt || !tokenRatesInBaseAsset[asset.address] || !asset.collateralFactor) {
          return null;
        }

        const newTotalCollateral = totalCollateral.add(
          balance.mul(tokenRatesInBaseAsset[asset.address]).mul(asset.collateralFactor),
        );
        return calculateHealthFactor(newTotalCollateral, totalDebt);
      },
  ),
);

const calculateNewWithdrawHealthFactor$: Observable<
  (walletAddress: string, accountId: number, balance: Decimal, asset: Asset) => Decimal | null
> = combineLatest([nostraTokenBalance$, tokenRatesInBaseAsset$, assets$]).pipe(
  map(
    ([nostraTokenBalances, tokenRatesInBaseAsset, assets]) =>
      (walletAddress: string, accountId: number, balance: Decimal, asset: Asset) => {
        const totalCollateral = calculateTotalCollateral(
          walletAddress,
          accountId,
          assets,
          nostraTokenBalances,
          tokenRatesInBaseAsset,
        );
        const totalDebt = calculateTotalDebt(
          walletAddress,
          accountId,
          assets,
          nostraTokenBalances,
          tokenRatesInBaseAsset,
        );

        if (!totalCollateral || !totalDebt || !tokenRatesInBaseAsset[asset.address] || !asset.collateralFactor) {
          return null;
        }

        const subtrahend = balance.mul(tokenRatesInBaseAsset[asset.address]).mul(asset.collateralFactor);
        const newTotalCollateral = totalCollateral.sub(subtrahend);

        // input balance shouldnt be larger than totalCollateral, but if somehow it happens, we dont want to show -ve HF
        // also avoid precision error for calculation
        if (totalCollateral.lt(subtrahend) || totalCollateral.approximatelyEquals(subtrahend, PRECISION_OFFSET)) {
          return new Decimal(HEALTH_FACTOR_INFINITY);
        }

        return calculateHealthFactor(newTotalCollateral, totalDebt);
      },
  ),
);

const calculateNewBorrowHealthFactor$: Observable<
  (walletAddress: string, accountId: number, balance: Decimal, asset: Asset) => Decimal | null
> = combineLatest([nostraTokenBalance$, tokenRatesInBaseAsset$, assets$]).pipe(
  map(
    ([nostraTokenBalances, tokenRatesInBaseAsset, assets]) =>
      (walletAddress: string, accountId: number, balance: Decimal, asset: Asset) => {
        const totalCollateral = calculateTotalCollateral(
          walletAddress,
          accountId,
          assets,
          nostraTokenBalances,
          tokenRatesInBaseAsset,
        );
        const totalDebt = calculateTotalDebt(
          walletAddress,
          accountId,
          assets,
          nostraTokenBalances,
          tokenRatesInBaseAsset,
        );

        if (!totalCollateral || !totalDebt || !tokenRatesInBaseAsset[asset.address] || !asset.debtFactor) {
          return null;
        }

        const newTotalDebt = totalDebt.add(balance.mul(tokenRatesInBaseAsset[asset.address]).div(asset.debtFactor));
        return calculateHealthFactor(totalCollateral, newTotalDebt);
      },
  ),
);

const calculateNewRepayHealthFactor$: Observable<
  (walletAddress: string, accountId: number, balance: Decimal, asset: Asset) => Decimal | null
> = combineLatest([nostraTokenBalance$, tokenRatesInBaseAsset$, assets$]).pipe(
  map(
    ([nostraTokenBalances, tokenRatesInBaseAsset, assets]) =>
      (walletAddress: string, accountId: number, balance: Decimal, asset: Asset) => {
        const totalCollateral = calculateTotalCollateral(
          walletAddress,
          accountId,
          assets,
          nostraTokenBalances,
          tokenRatesInBaseAsset,
        );
        const totalDebt = calculateTotalDebt(
          walletAddress,
          accountId,
          assets,
          nostraTokenBalances,
          tokenRatesInBaseAsset,
        );

        if (!totalCollateral || !totalDebt || !tokenRatesInBaseAsset[asset.address] || !asset.debtFactor) {
          return null;
        }

        const subtrahend = balance.mul(tokenRatesInBaseAsset[asset.address]).div(asset.debtFactor);

        // input balance shouldnt be larger than totalCollateral, but if somehow it happens, we dont want to show -ve HF
        // also avoid precision error for calculation
        if (totalDebt.lt(subtrahend) || totalDebt.approximatelyEquals(subtrahend, PRECISION_OFFSET)) {
          return new Decimal(HEALTH_FACTOR_INFINITY);
        }

        const newTotalDebt = totalDebt.sub(subtrahend);

        return calculateHealthFactor(totalCollateral, newTotalDebt);
      },
  ),
);

const calculateNewAdjustHealthFactor$: Observable<
  (
    walletAddress: string,
    accountId: number,
    balance: Decimal,
    asset: Asset,
    collateralEnabled: boolean,
  ) => Decimal | null
> = combineLatest([nostraTokenBalance$, tokenRatesInBaseAsset$, assets$]).pipe(
  map(
    ([nostraTokenBalances, tokenRatesInBaseAsset, assets]) =>
      (walletAddress: string, accountId: number, balance: Decimal, asset: Asset, collateralEnabled: boolean) => {
        const totalCollateral = calculateTotalCollateral(
          walletAddress,
          accountId,
          assets,
          nostraTokenBalances,
          tokenRatesInBaseAsset,
        );
        const totalDebt = calculateTotalDebt(
          walletAddress,
          accountId,
          assets,
          nostraTokenBalances,
          tokenRatesInBaseAsset,
        );

        if (!totalCollateral || !totalDebt || !tokenRatesInBaseAsset[asset.address] || !asset.collateralFactor) {
          return null;
        }

        let newTotalCollateral: Nullable<Decimal> = null;
        if (collateralEnabled) {
          newTotalCollateral = totalCollateral.add(
            balance.mul(tokenRatesInBaseAsset[asset.address]).mul(asset.collateralFactor),
          );
        } else if (!collateralEnabled) {
          newTotalCollateral = totalCollateral.sub(
            balance.mul(tokenRatesInBaseAsset[asset.address]).mul(asset.collateralFactor),
          );
        }

        if (!newTotalCollateral) {
          return null;
        }

        return calculateHealthFactor(newTotalCollateral, totalDebt);
      },
  ),
);

const calculateMaxWithdraw$: Observable<
  (walletAddress: string, accountId: number, asset: Asset, healthFactor: Decimal) => Decimal | null
> = combineLatest([healthFactorData$, tokenRatesInBaseAsset$]).pipe(
  map(
    ([healthFactorData, tokenRatesInBaseAsset]) =>
      (walletAddress: string, accountId: number, asset: Asset, healthFactor: Decimal) => {
        const dataKey = `${walletAddress}-${accountId}`;

        if (
          !healthFactorData[dataKey]?.totalCollateral ||
          !healthFactorData[dataKey]?.totalDebt ||
          !tokenRatesInBaseAsset[asset.address] ||
          !asset.collateralFactor
        ) {
          return null;
        }

        if (tokenRatesInBaseAsset[asset.address].isZero() || asset.collateralFactor.isZero()) {
          return Decimal.MAX_DECIMAL;
        }

        return healthFactorData[dataKey].totalCollateral
          .sub(healthFactor.mul(healthFactorData[dataKey].totalDebt))
          .div(tokenRatesInBaseAsset[asset.address])
          .div(asset.collateralFactor);
      },
  ),
);

const calculateMaxBorrow$: Observable<
  (walletAddress: string, accountId: number, asset: Asset, healthFactor: Decimal) => Decimal | null
> = combineLatest([healthFactorData$, tokenRatesInBaseAsset$]).pipe(
  map(
    ([healthFactorData, tokenRatesInBaseAsset]) =>
      (walletAddress: string, accountId: number, asset: Asset, healthFactor: Decimal) => {
        const dataKey = `${walletAddress}-${accountId}`;

        if (
          !healthFactorData[dataKey]?.totalCollateral ||
          !healthFactorData[dataKey]?.totalDebt ||
          !tokenRatesInBaseAsset[asset.address] ||
          !asset.debtFactor
        ) {
          return null;
        }

        if (tokenRatesInBaseAsset[asset.address].isZero()) {
          return Decimal.MAX_DECIMAL;
        }

        return healthFactorData[dataKey].totalCollateral
          .sub(healthFactor.mul(healthFactorData[dataKey].totalDebt))
          .div(tokenRatesInBaseAsset[asset.address])
          .mul(asset.debtFactor);
      },
  ),
);

// fetch when account change
const appLoadStream$ = combineLatest([walletAccount$, accountList$]).pipe(
  filter((value): value is [AccountInterface, string[]] => {
    const [walletAccount, accounts] = value;

    return Boolean(walletAccount) && Boolean(accounts?.length);
  }),
  concatMap(([walletAccount, accounts]) => {
    const accountIds = accounts
      .map((account, id) => [account, id] as const)
      .filter(([accountName]) => Boolean(accountName))
      .map(([, id]) => id);

    return fetchData(walletAccount.address, accountIds);
  }),
);

// fetch when app event fire
const appEventsStream$ = appEvent$.pipe(
  filter((appEvent): appEvent is AppEvent => {
    return Boolean(appEvent);
  }),
  withLatestFrom(walletAccount$),
  filter((value): value is [AppEvent, AccountInterface] => {
    const [, walletAccount] = value;

    return Boolean(walletAccount);
  }),
  mergeMap(([appEvent, walletAccount]) => fetchData(walletAccount.address, [appEvent.accountId], walletAccount)),
);

// fetch when token rate change
const priceUpdateStream$ = combineLatest([walletAccount$, accountList$, tokenRatesInBaseAsset$]).pipe(
  filter((value): value is [AccountInterface, string[], TokenRateMap] => {
    const [walletAccount, accounts, tokenRatesInBaseAsset] = value;

    return Boolean(walletAccount) && Boolean(accounts?.length) && Object.keys(tokenRatesInBaseAsset).length > 0;
  }),
  distinctUntilChanged(
    ([, , prevTokenRatesInBaseAsset], [, , currentTokenRatesInBaseAsset]) =>
      Object.keys(currentTokenRatesInBaseAsset).length === Object.keys(prevTokenRatesInBaseAsset).length &&
      Object.keys(currentTokenRatesInBaseAsset).every(token =>
        currentTokenRatesInBaseAsset[token].equals(prevTokenRatesInBaseAsset[token]),
      ),
  ),
  concatMap(([walletAccount, accounts]) => {
    const accountIds = accounts
      .map((account, id) => [account, id] as const)
      .filter(([accountName]) => Boolean(accountName))
      .map(([, id]) => id);

    return fetchData(walletAccount.address, accountIds);
  }),
);

// merge all stream$ into one
const stream$ = merge(appLoadStream$, appEventsStream$, priceUpdateStream$).pipe(
  filter((newHealthFactorData): newHealthFactorData is AccountHealthFactorMap => Boolean(newHealthFactorData)),
  scan((currentHealthFactorData, newHealthFactorData) => {
    if (!newHealthFactorData) {
      return currentHealthFactorData;
    }

    return {
      ...currentHealthFactorData,
      ...newHealthFactorData,
    };
  }, {} as AccountHealthFactorMap),
  debounce(() => interval(DEBOUNCE_IN_MS)),
  tap(healthFactorData => {
    healthFactorData$.next(healthFactorData);
  }),
);

export const [useAccountsHealthFactors] = bind(healthFactorData$, DEFAULT_VALUE);
export const [useNewDepositHealthFactor] = bind(calculateNewDepositHealthFactor$, null);
export const [useNewWithdrawHealthFactor] = bind(calculateNewWithdrawHealthFactor$, null);
export const [useNewBorrowHealthFactor] = bind(calculateNewBorrowHealthFactor$, null);
export const [useNewRepayHealthFactor] = bind(calculateNewRepayHealthFactor$, null);
export const [useNewAdjustHealthFactor] = bind(calculateNewAdjustHealthFactor$, null);
export const [useMaxWithdraw] = bind(calculateMaxWithdraw$, null);
export const [useMaxBorrow] = bind(calculateMaxBorrow$, null);

let subscription: Subscription;

export const subscribeHealthFactorData = (): void => {
  unsubscribeHealthFactorData();
  subscription = stream$.subscribe();
};
export const unsubscribeHealthFactorData = (): void => subscription?.unsubscribe?.();
export const resetHealthFactorData = (): void => healthFactorData$.next(DEFAULT_VALUE);
