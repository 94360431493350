import { Token } from '../interfaces';

const tokenTickerToTokenNameMap = new Map<Token, string>();
tokenTickerToTokenNameMap.set('WBTC', 'Wrapped BTC');
tokenTickerToTokenNameMap.set('ETH', 'Ethereum');
tokenTickerToTokenNameMap.set('DAI', 'DAI Stablecoin');
tokenTickerToTokenNameMap.set('USDC', 'USD Coin');
tokenTickerToTokenNameMap.set('USDT', 'Tether USD');

export function getTokenName(token: Token): string | undefined {
  return tokenTickerToTokenNameMap.get(token);
}
