import { FC, memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { AssetVariant, useWalletAccount, useActiveAccountId, useAssetVariant, useBurnAndMint } from '../../../hooks';
import { DepositPosition } from '../../../interfaces';
import { DecimalUtils } from '../../../utils';
import {
  ActionButton,
  AssetTierPill,
  Icon,
  Loading,
  LoadingButtonPlaceholder,
  LoadingPlaceholder,
  LoadingToggleSwitchPlaceholder,
  Logo,
  TableBodyCell,
  TableRow,
  Typography,
} from '../../shared';
import { LogoType } from '../../shared/Logo';

interface DepositPositionRowProps {
  position: DepositPosition;
}

const DepositPositionRow: FC<DepositPositionRowProps> = props => {
  const { position } = props;
  const { asset, balance, balanceUsd, apy } = position;
  const walletAccount = useWalletAccount();
  const activeAccountId = useActiveAccountId();
  const assetVariantMap = useAssetVariant();
  const { burnAndMintStatus } = useBurnAndMint();
  const { search } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isBurning = useMemo(
    () => burnAndMintStatus?.pending && burnAndMintStatus.request.asset.address === asset.address,
    [asset.address, burnAndMintStatus?.pending, burnAndMintStatus?.request.asset.address],
  );

  const formattedBalance = useMemo(
    () =>
      DecimalUtils.format(balance, {
        style: 'multiplier',
        fractionDigits: 2,
        noMultiplierFractionDigits: asset.uiTokenPrecision,
        currency: asset.token,
        lessThanFormat: true,
      }),
    [asset.token, asset.uiTokenPrecision, balance],
  );

  const formattedBalanceUsd = useMemo(
    () =>
      DecimalUtils.format(balanceUsd, {
        style: 'multiplier',
        fractionDigits: 2,
        currency: '$',
        lessThanFormat: true,
      }),
    [balanceUsd],
  );

  const assetVariant = useMemo((): AssetVariant | null => {
    return assetVariantMap[`${walletAccount?.address}-${activeAccountId}-${asset.address}`] || null;
  }, [walletAccount?.address, activeAccountId, asset.address, assetVariantMap]);

  const formattedApy = useMemo(
    () =>
      apy && assetVariant?.isLending ? DecimalUtils.format(apy, { style: 'percentage', fractionDigits: 2 }) : 'n/a',
    [apy, assetVariant?.isLending],
  );

  const handleManageAsset = useCallback(
    () => navigate(`/asset/${asset.token}/withdraw${search}`),
    [navigate, asset.token, search],
  );

  const canBeBorrowed = asset.assetTier === 'isolated' || asset.assetTier === 'cross' || asset.assetTier === 'shared';
  const canBeUsedAsCollateral = asset.assetTier === 'shared' || asset.assetTier === 'nominal';

  if (!assetVariant || !asset.assetTier || !formattedBalance || !formattedBalanceUsd) {
    return (
      <TableRow id={`deposit-pool-${asset.address}`}>
        <TableBodyCell>
          <div className="nostra__deposit-positions__loading-asset-cell">
            <LoadingPlaceholder shape={{ circle: 'medium' }} />
            <LoadingPlaceholder shape={{ width: 'medium', height: 'small' }} />
            <LoadingPlaceholder shape={{ width: 'small', height: 'small' }} />
          </div>
        </TableBodyCell>
        <TableBodyCell align="center">
          <LoadingToggleSwitchPlaceholder />
        </TableBodyCell>
        <TableBodyCell align="right">
          <LoadingPlaceholder shape={{ width: 'small', height: 'small' }} />
        </TableBodyCell>
        <TableBodyCell align="center">
          <LoadingToggleSwitchPlaceholder />
        </TableBodyCell>
        <TableBodyCell align="right">
          <div className="nostra__deposit-positions__multiple-values">
            <LoadingPlaceholder shape={{ width: 'medium', height: 'small' }} />
            <LoadingPlaceholder shape={{ width: 'small', height: 'small' }} />
          </div>
        </TableBodyCell>
        <TableBodyCell>
          <div className="nostra__deposit-positions__action-cell">
            <LoadingButtonPlaceholder width="tiny" height="small" />
          </div>
        </TableBodyCell>
      </TableRow>
    );
  }

  return (
    <TableRow id={`deposit-pool-${asset.address}`}>
      <TableBodyCell>
        <div className="nostra__deposit-positions__asset-cell">
          <Logo type={`token-${asset.token}` as LogoType} size="small" />
          <Typography className="nostra__token" variant="body-primary">
            {asset.token}
          </Typography>
          <AssetTierPill tier={asset.assetTier} />
        </div>
      </TableBodyCell>
      <TableBodyCell align="center">
        <div className="nostra__deposit-positions__lending">
          {isBurning && <Loading color="primary" />}
          {!isBurning && !canBeBorrowed && <Typography variant="body-primary">n/a</Typography>}
          {!isBurning && canBeBorrowed ? (
            <Icon variant={assetVariant.isLending ? 'checkmark-solid' : 'x-solid'} size={20} />
          ) : null}
        </div>
      </TableBodyCell>
      <TableBodyCell align="right">
        <Typography variant="body-primary">{canBeBorrowed && assetVariant.isLending ? formattedApy : 'n/a'}</Typography>
      </TableBodyCell>
      <TableBodyCell align="center">
        {isBurning && canBeUsedAsCollateral && <Loading color="primary" />}
        {!isBurning && !canBeUsedAsCollateral && <Typography variant="body-primary">n/a</Typography>}
        {!isBurning && canBeUsedAsCollateral ? (
          <Icon variant={assetVariant.isCollateral ? 'checkmark-solid' : 'x-solid'} size={20} />
        ) : null}
      </TableBodyCell>
      <TableBodyCell align="right">
        <div className="nostra__deposit-positions__multiple-values">
          <Typography variant="body-primary">{formattedBalanceUsd}</Typography>
          <Typography variant="body-tertiary" color="text-disabled">
            {formattedBalance}
          </Typography>
        </div>
      </TableBodyCell>
      <TableBodyCell>
        <div className="nostra__deposit-positions__action-cell">
          <ActionButton
            variant="tertiary"
            labels={{ default: t('DepositPositionRow.btnManage') }}
            onClick={handleManageAsset}
            size="large"
            state={isBurning ? 'disabled' : 'default'}
          />
        </div>
      </TableBodyCell>
    </TableRow>
  );
};

export default memo(DepositPositionRow);
