import { FC, PropsWithChildren, useCallback } from 'react';

import './TableRow.scss';

interface TableRowProps {
  id: string;
  onClick?: (id: string) => void;
}

const TableRow: FC<PropsWithChildren<TableRowProps>> = props => {
  const { id, onClick, children } = props;

  const onRowClick = useCallback(() => {
    onClick?.(id);
  }, [id, onClick]);

  return (
    <tr id={id} className="nostra__tableRow" onClick={onRowClick}>
      {children}
    </tr>
  );
};
export default TableRow;
