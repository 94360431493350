import { formatInTimeZone } from 'date-fns-tz';
import { FC, useEffect, useState } from 'react';
import { TimeResolution } from '../../../interfaces';

interface YAxisTickProps {
  x?: number;
  y?: number;
  payload?: {
    value: number;
  };
  timeScale: TimeResolution;
}

const XAxisTick: FC<YAxisTickProps> = props => {
  const { x, y, payload, timeScale } = props;

  const [value, setValue] = useState<string>('');

  useEffect(() => {
    if (!payload) {
      return;
    }

    let value = '';
    switch (timeScale) {
      case 'a':
      case '1y':
      case '6m':
        value = formatInTimeZone(payload.value, 'UTC', 'MMM y');
        break;
      case '3m':
      case '1m':
        value = formatInTimeZone(payload.value, 'UTC', 'd MMM');
        break;
      case '1d':
        value = formatInTimeZone(payload.value, 'UTC', 'HH:mm');
    }

    setValue(value);
  }, [payload, timeScale]);

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={20} dx={0} fill="#27334D" textAnchor="middle" fontFamily="Albert Sans, sans-serif">
        {value}
      </text>
    </g>
  );
};
export default XAxisTick;
