import { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useWalletAccount, useActiveAccountId, useDepositPositions, useAccountTotalAssets } from '../../../hooks';
import { DecimalUtils } from '../../../utils';
import {
  LoadingPlaceholder,
  ShadowContainer,
  Table,
  TableBody,
  TableHead,
  TableHeadCell,
  Typography,
} from '../../shared';
import DepositPositionRow from './DepositPositionRow';
import DepositPositionRowEmpty from './DepositPositionRowEmpty';
import DepositPositionRowSkeleton from './DepositPositionRowSkeleton';

import './DepositPositions.scss';

const DepositPositions: FC = () => {
  const walletAccount = useWalletAccount();
  const activeAccountId = useActiveAccountId();
  const depositPositions = useDepositPositions();
  const accountTotalAssets = useAccountTotalAssets();
  const { t } = useTranslation();

  const totalAssetsFormatted = useMemo(() => {
    const dataKey = `${walletAccount?.address}-${activeAccountId}`;

    if (!accountTotalAssets[dataKey]) {
      return null;
    }

    return DecimalUtils.format(accountTotalAssets[dataKey], {
      style: 'multiplier',
      fractionDigits: 2,
      currency: '$',
      lessThanFormat: true,
    });
  }, [walletAccount?.address, activeAccountId, accountTotalAssets]);

  return (
    <ShadowContainer className="nostra__positions__container nostra__deposit-positions">
      <div className="nostra__deposit-positions__header">
        <Typography variant="subheader" weight="bold">
          {t('DepositPositions.title')}
        </Typography>
        <div className="nostra__deposit-positions__header-total-assets">
          {totalAssetsFormatted && (
            <>
              <Typography variant="body-secondary" color="text-wallet">
                {t('DepositPositions.titleTotalAssets')}
              </Typography>
              <Typography variant="body-primary" weight="bold">
                {totalAssetsFormatted}
              </Typography>
            </>
          )}
          {!totalAssetsFormatted && <LoadingPlaceholder shape={{ width: 'medium', height: 'small' }} />}
        </div>
      </div>
      <Table>
        <TableHead>
          <tr className="nostra__tableRow">
            <TableHeadCell label={t('DepositPositions.headerMarket')} />
            <TableHeadCell label={t('DepositPositions.headerLending')} align="center" />
            <TableHeadCell label={t('DepositPositions.headerAPY')} align="right" />
            <TableHeadCell label={t('DepositPositions.headerCollateral')} align="center" />
            <TableHeadCell label={t('DepositPositions.headerBalance')} align="right" />
            <TableHeadCell label="" />
          </tr>
        </TableHead>
        <TableBody>
          {/* Show loading skeleton if deposit positions are still loading. */}
          {depositPositions === null && <DepositPositionRowSkeleton />}
          {/* Show table with data once deposit positions have loaded. */}
          {depositPositions &&
            depositPositions.length > 0 &&
            depositPositions.map(position => <DepositPositionRow key={position.asset.address} position={position} />)}
          {/* If user does not have any deposit positions, show empty table. */}
          {depositPositions && depositPositions.length === 0 && <DepositPositionRowEmpty />}
        </TableBody>
      </Table>
      {/* If user does not have any deposit positions, show explanation text. */}
      {depositPositions && depositPositions.length === 0 && (
        <div className="nostra__deposit-positions__empty">
          <Typography variant="body-primary">{t('DepositPositions.strNoDeposits')}</Typography>
        </div>
      )}
    </ShadowContainer>
  );
};

export default memo(DepositPositions);
