import { bind } from '@react-rxjs/core';
import { BehaviorSubject, combineLatest, filter, Subscription, tap } from 'rxjs';
import { AccountInterface } from 'starknet';
import { Decimal, ONE, ZERO } from '../datastructures';
import { Asset } from '../interfaces';
import { weightedAverage } from '../utils';
import { walletAccount$ } from './useWalletAccount';
import { assets$ } from './useAssets';
import { nostraTokenBalance$, NostraTokenBalanceMap } from './useNostraTokenBalance';
import { accountList$ } from './useAccounts';
import { accountTotalDebt$, AccountTotalDebtMap } from './useAccountTotalDebt';
import { TokenRateMap, tokenRates$ } from './useTokenRates';

interface AccountBorrowApyMap {
  [walletAddressAccountId: string]: Decimal;
}

const accountBorrowApy$ = new BehaviorSubject<AccountBorrowApyMap>({});

type FetchStreamNonNullInputs = [
  NostraTokenBalanceMap,
  string[],
  AccountInterface,
  TokenRateMap,
  Asset[],
  AccountTotalDebtMap,
];

const fetchStream$ = combineLatest([
  nostraTokenBalance$,
  accountList$,
  walletAccount$,
  tokenRates$,
  assets$,
  accountTotalDebt$,
]).pipe(
  filter((value): value is FetchStreamNonNullInputs => {
    const [, accounts, walletAccount] = value;
    return Boolean(accounts) && Boolean(walletAccount);
  }),
  tap(([nostraTokenBalance, accounts, account, tokenRates, assets, accountTotalDebt]) => {
    const result: AccountBorrowApyMap = {};
    accounts
      .filter(account => account !== '')
      .forEach((_, accountId) => {
        try {
          const totalDebt = accountTotalDebt[`${account.address}-${accountId}`];
          if (!totalDebt) {
            return;
          }

          const values = assets.map(asset => asset.borrowApy ?? ZERO);

          const weights = assets.map(asset => {
            const tokenRate = tokenRates[asset.address];

            const debtBalanceKey = `${account.address}-${accountId}-${asset.debtTokenAddress}`;
            const debtTokenBalance = (nostraTokenBalance[debtBalanceKey] ?? ZERO).mul(tokenRate ?? ONE);

            if (debtTokenBalance.equals(ZERO) || totalDebt.equals(ZERO)) {
              return ZERO;
            }

            return debtTokenBalance.div(totalDebt);
          });

          result[`${account.address}-${accountId}`] = weightedAverage(values, weights);
        } catch (error) {
          console.error(`useAccountBorrowApy - error calculating borrow apy for ID ${accountId}`, error);
        }
      });

    accountBorrowApy$.next(result);
  }),
);

export const [useAccountBorrowApy] = bind(accountBorrowApy$, {});

let subscription: Subscription;
export const subscribeAccountBorrowApy = (): void => {
  unsubscribeAccountBorrowApy();
  subscription = fetchStream$.subscribe();
};

export const unsubscribeAccountBorrowApy = (): void => subscription?.unsubscribe();
export const resetAccountBorrowApy = (): void => {
  accountBorrowApy$.next({});
};
