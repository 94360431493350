const prefix = 'NOSTRA_';

export class StorageService {
  static set(key: string, value: string | number | object) {
    if (!key || !value) {
      return;
    }

    let result;

    if (typeof value === 'object') {
      result = JSON.stringify(value);
    } else {
      result = value.toString();
    }

    const encodedValue = window.btoa(result);

    localStorage.setItem(`${prefix}${key}`, encodedValue);
  }

  static get(key: string): string | object | undefined {
    const value = localStorage.getItem(`${prefix}${key}`);
    if (!value) {
      return;
    }

    if (value === null) {
      return;
    }

    let result;
    const decodedValue = window.atob(value);

    if (decodedValue[0] === '{' || decodedValue[0] === '[') {
      result = JSON.parse(decodedValue);
    }

    return result || undefined;
  }

  static delete(key: string) {
    localStorage.removeItem(`${prefix}${key}`);
  }

  static clear() {
    localStorage.clear();
  }
}
