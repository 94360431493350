import { FC } from 'react';
import { InnerIconProps } from './IconProps';
import withIcon from './withIcon';

const Nostra: FC<InnerIconProps> = ({ size, color }) => (
  <svg
    className="nostra__icon nostra__icon-nostra"
    width={size}
    height={size}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 6.70649L4.80441 3.94206L0 1.17762V6.70649ZM6.00023 4.6303L0 8.08298L6.00023 11.5352L12 8.08298V1.17762L6.00023 4.6303ZM5.92437 0L3.17275 1.62714L6.00023 3.25427L8.82725 1.62714L5.92437 0Z"
      fill={color || 'white'}
    />
  </svg>
);

export default withIcon(Nostra);
