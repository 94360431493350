import { FC, memo } from 'react';
import { HealthFactorStatus } from '../../../utils/getHealthFactorStatus';

import './HealthFactorStatusDot.scss';

interface HealthFactorStatusDotProps {
  status: HealthFactorStatus;
}

const HealthFactorStatusDot: FC<HealthFactorStatusDotProps> = props => {
  const { status } = props;
  return <div className="nostra__health-factor-status-dot" data-status={status} />;
};

export default memo(HealthFactorStatusDot);
