import { FC } from 'react';
import { InnerIconProps } from './IconProps';
import withIcon from './withIcon';

const TermsConditions: FC<InnerIconProps> = ({ size, color }) => (
  <svg
    className="nostra__icon nostra__icon-terms-conditions"
    width={size}
    height={size}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.33333 6C8.33333 6.15913 8.27187 6.31174 8.16248 6.42426C8.05308 6.53679 7.90471 6.6 7.75 6.6H4.25C4.09529 6.6 3.94692 6.53679 3.83752 6.42426C3.72812 6.31174 3.66667 6.15913 3.66667 6C3.66667 5.84087 3.72812 5.68826 3.83752 5.57574C3.94692 5.46321 4.09529 5.4 4.25 5.4H7.75C7.90471 5.4 8.05308 5.46321 8.16248 5.57574C8.27187 5.68826 8.33333 5.84087 8.33333 6ZM6 7.8H4.25C4.09529 7.8 3.94692 7.86321 3.83752 7.97574C3.72812 8.08826 3.66667 8.24087 3.66667 8.4C3.66667 8.55913 3.72812 8.71174 3.83752 8.82426C3.94692 8.93679 4.09529 9 4.25 9H6C6.15471 9 6.30308 8.93679 6.41248 8.82426C6.52187 8.71174 6.58333 8.55913 6.58333 8.4C6.58333 8.24087 6.52187 8.08826 6.41248 7.97574C6.30308 7.86321 6.15471 7.8 6 7.8ZM11.25 1.8V10.2C11.25 10.6774 11.0656 11.1352 10.7374 11.4728C10.4092 11.8104 9.96413 12 9.5 12H2.5C2.03587 12 1.59075 11.8104 1.26256 11.4728C0.934375 11.1352 0.75 10.6774 0.75 10.2V1.8C0.75 1.32261 0.934375 0.864773 1.26256 0.527208C1.59075 0.189642 2.03587 0 2.5 0H9.5C9.96413 0 10.4092 0.189642 10.7374 0.527208C11.0656 0.864773 11.25 1.32261 11.25 1.8ZM7.16667 2.4C7.32138 2.4 7.46975 2.33679 7.57915 2.22426C7.68854 2.11174 7.75 1.95913 7.75 1.8V1.2H4.25V1.8C4.25 1.95913 4.31146 2.11174 4.42085 2.22426C4.53025 2.33679 4.67862 2.4 4.83333 2.4H7.16667ZM10.0833 1.8C10.0833 1.64087 10.0219 1.48826 9.91248 1.37574C9.80308 1.26321 9.65471 1.2 9.5 1.2H8.91667V1.8C8.91667 2.27739 8.73229 2.73523 8.4041 3.07279C8.07591 3.41036 7.6308 3.6 7.16667 3.6H4.83333C4.3692 3.6 3.92409 3.41036 3.5959 3.07279C3.26771 2.73523 3.08333 2.27739 3.08333 1.8V1.2H2.5C2.34529 1.2 2.19692 1.26321 2.08752 1.37574C1.97812 1.48826 1.91667 1.64087 1.91667 1.8V10.2C1.91667 10.3591 1.97812 10.5117 2.08752 10.6243C2.19692 10.7368 2.34529 10.8 2.5 10.8H9.5C9.65471 10.8 9.80308 10.7368 9.91248 10.6243C10.0219 10.5117 10.0833 10.3591 10.0833 10.2V1.8Z"
      fill={color}
    />
  </svg>
);

export default withIcon(TermsConditions);
