import { FC } from 'react';
import { InnerLogoProps } from './LogoProps';
import withLogo from './withLogo';

const TokenRARI: FC<InnerLogoProps> = ({ size }) => (
  <svg
    className="nostra__logo nostra__logo-token-RARI"
    width={size}
    height={size}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="20" r="20" fill="#050708" />
    <circle cx="20.0002" cy="20" r="16.75" fill="#050708" stroke="white" strokeWidth="0.5" />
    <rect
      x="26.5452"
      y="11.386"
      width="0.637043"
      height="21.4877"
      transform="rotate(38.0121 26.5452 11.386)"
      fill="white"
    />
    <mask id="path-4-inside-1_299_1739" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.74 23.2249L26.3382 29.0291L28.9412 23.2014L23.74 23.2249ZM24.5865 23.6425L26.3387 27.5295L28.0941 23.6268L24.5865 23.6425Z"
      />
    </mask>
    <path
      d="M26.3382 29.0291L25.8819 29.2333L26.3387 30.2539L26.7948 29.233L26.3382 29.0291ZM23.74 23.2249L23.7377 22.7249L22.9699 22.7283L23.2836 23.4291L23.74 23.2249ZM28.9412 23.2014L29.3977 23.4053L29.7137 22.6979L28.9389 22.7014L28.9412 23.2014ZM26.3387 27.5295L25.8829 27.735L26.3392 28.7473L26.7947 27.7346L26.3387 27.5295ZM24.5865 23.6425L24.5843 23.1425L23.8142 23.146L24.1307 23.848L24.5865 23.6425ZM28.0941 23.6268L28.5501 23.8319L28.8688 23.1233L28.0919 23.1268L28.0941 23.6268ZM26.7946 28.8248L24.1964 23.0206L23.2836 23.4291L25.8819 29.2333L26.7946 28.8248ZM28.4846 22.9975L25.8817 28.8251L26.7948 29.233L29.3977 23.4053L28.4846 22.9975ZM23.7422 23.7248L28.9434 23.7014L28.9389 22.7014L23.7377 22.7249L23.7422 23.7248ZM26.7945 27.3241L25.0423 23.437L24.1307 23.848L25.8829 27.735L26.7945 27.3241ZM27.6381 23.4217L25.8827 27.3244L26.7947 27.7346L28.5501 23.8319L27.6381 23.4217ZM24.5887 24.1425L28.0963 24.1268L28.0919 23.1268L24.5843 23.1425L24.5887 24.1425Z"
      fill="white"
      mask="url(#path-4-inside-1_299_1739)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.7356 23.2288L28.9397 23.2288L28.9397 22.9126C28.9397 21.4755 27.7747 20.3106 26.3377 20.3106C24.9006 20.3106 23.7356 21.4755 23.7356 22.9126L23.7356 23.2288ZM26.3373 20.9138C25.2135 20.9138 24.3007 21.8248 24.3007 22.9485L28.3738 22.9485C28.3738 21.8248 27.461 20.9138 26.3373 20.9138Z"
      fill="white"
    />
    <mask id="path-7-inside-2_299_1739" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6643 16.7756L14.0661 10.9714L11.4631 16.7991L16.6643 16.7756ZM15.8178 16.358L14.0656 12.4709L12.3102 16.3737L15.8178 16.358Z"
      />
    </mask>
    <path
      d="M14.0661 10.9714L14.5224 10.7671L14.0656 9.74654L13.6095 10.7675L14.0661 10.9714ZM16.6643 16.7756L16.6666 17.2756L17.4344 17.2722L17.1207 16.5713L16.6643 16.7756ZM11.4631 16.7991L11.0066 16.5952L10.6906 17.3026L11.4654 17.2991L11.4631 16.7991ZM14.0656 12.4709L14.5214 12.2655L14.0651 11.2532L13.6096 12.2658L14.0656 12.4709ZM15.8178 16.358L15.82 16.858L16.5901 16.8545L16.2736 16.1525L15.8178 16.358ZM12.3102 16.3737L11.8542 16.1686L11.5355 16.8772L12.3124 16.8737L12.3102 16.3737ZM13.6097 11.1757L16.2079 16.9799L17.1207 16.5713L14.5224 10.7671L13.6097 11.1757ZM11.9197 17.003L14.5226 11.1753L13.6095 10.7675L11.0066 16.5952L11.9197 17.003ZM16.6621 16.2756L11.4609 16.2991L11.4654 17.2991L16.6666 17.2756L16.6621 16.2756ZM13.6098 12.6764L15.362 16.5635L16.2736 16.1525L14.5214 12.2655L13.6098 12.6764ZM12.7662 16.5788L14.5216 12.676L13.6096 12.2658L11.8542 16.1686L12.7662 16.5788ZM15.8156 15.858L12.308 15.8737L12.3124 16.8737L15.82 16.858L15.8156 15.858Z"
      fill="white"
      mask="url(#path-7-inside-2_299_1739)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.6687 16.7717H11.4646V17.0879C11.4646 18.5249 12.6296 19.6899 14.0666 19.6899C15.5037 19.6899 16.6687 18.5249 16.6687 17.0879V16.7717ZM14.067 19.0867C15.1908 19.0867 16.1036 18.1757 16.1036 17.052H12.0305C12.0305 18.1757 12.9433 19.0867 14.067 19.0867Z"
      fill="white"
    />
  </svg>
);

export default withLogo(TokenRARI);
