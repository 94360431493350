import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { HEALTH_FACTOR_DANGER, HEALTH_FACTOR_INFINITY, HEALTH_FACTOR_WARNING } from '../../constants';
import { Decimal } from '../../datastructures';
import { colors, HealthFactor, Icon, TooltipWrapper, Typography } from '../shared';
import { HealthFactorTooltip } from '../Tooltips';

interface ActionModalHealthFactorDataProps {
  amount: Decimal;
  healthFactor: Decimal | null;
  newHealthFactor?: Decimal | null;
  showLabel?: boolean;
}

const ActionModalHealthFactorData: FC<ActionModalHealthFactorDataProps> = props => {
  const { amount, healthFactor, newHealthFactor, showLabel = true } = props;
  const { t } = useTranslation();

  const newHealthFactorIconColor = useMemo(() => {
    if (newHealthFactor) {
      if (newHealthFactor.lt(HEALTH_FACTOR_DANGER)) {
        return colors.textError;
      }

      if (newHealthFactor.lte(HEALTH_FACTOR_WARNING)) {
        return colors.textWarning;
      }
    }

    return null;
  }, [newHealthFactor]);

  const showHealthFactor = useMemo(() => {
    if (
      amount.isZero() ||
      (healthFactor && newHealthFactor && healthFactor.equals(newHealthFactor)) ||
      (healthFactor &&
        healthFactor.gte(HEALTH_FACTOR_INFINITY) &&
        newHealthFactor &&
        newHealthFactor.gte(HEALTH_FACTOR_INFINITY))
    ) {
      return false;
    }

    return true;
  }, [amount, healthFactor, newHealthFactor]);

  return (
    <div className="nostra__action-modal__data-row">
      {showLabel && (
        <Typography className="nostra__action-modal__health-factor-label" variant="body-tertiary">
          {newHealthFactorIconColor && (
            <TooltipWrapper tooltipContent={<HealthFactorTooltip />} openEvent="mouseenter" placement="left">
              <Icon variant="exclamation-error" size="small" color={newHealthFactorIconColor} />
            </TooltipWrapper>
          )}
          {t('ActionModalHealthFactorData.healthFactor')}
        </Typography>
      )}
      <div className="nostra__action-modal__health-factor">
        {healthFactor && newHealthFactor && showHealthFactor && (
          <>
            <TooltipWrapper tooltipContent={<HealthFactorTooltip />} openEvent="mouseenter" placement="right">
              <HealthFactor healthFactor={healthFactor} />
              <Icon variant="right-arrow-thin" size="tiny" />
              <HealthFactor healthFactor={newHealthFactor} />
            </TooltipWrapper>
          </>
        )}
        {!showHealthFactor && '---'}
      </div>
    </div>
  );
};

export default ActionModalHealthFactorData;
