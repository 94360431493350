import { combineLatest, concatMap, from, interval, map, startWith, Subscription, tap } from 'rxjs';
import { bind } from '@react-rxjs/core';
import { BehaviorSubject } from 'rxjs';
import { GraphqlQuery, Nullable, StatisticsData } from '../interfaces';
import { getGraphqlClient } from '../services';
import { ApolloQueryResult, gql } from '@apollo/client';

const DEFAULT_VALUE = null;
const POLLING_INTERVAL_IN_MS = 60000; // 1 minute

export const statisticsData$ = new BehaviorSubject<StatisticsData | null>(DEFAULT_VALUE);

const intervalBeat$ = interval(POLLING_INTERVAL_IN_MS).pipe(startWith(0));

const fetchStream$ = combineLatest([intervalBeat$]).pipe(
  concatMap(() => {
    const graphqlClient = getGraphqlClient();

    return from(
      graphqlClient.query<GraphqlQuery>({
        query: gql`
          query GetTotalLendersChartData {
            numberOfBorrowers
            numberOfLenders
          }
        `,
      }),
    );
  }),
  map<ApolloQueryResult<GraphqlQuery>, StatisticsData>(result => ({
    borrowersCount: result.data.numberOfBorrowers,
    lendersCount: result.data.numberOfLenders,
  })),
  tap(statisticsData => statisticsData$.next(statisticsData)),
);

export const [useStatisticsData] = bind(statisticsData$, DEFAULT_VALUE);

let subscription: Nullable<Subscription> = null;

export const subscribeStatisticsData = (): void => {
  unsubscribeStatisticsData();
  subscription = fetchStream$.subscribe();
};
export const unsubscribeStatisticsData = (): void => subscription?.unsubscribe();
