import { FC, useCallback, useEffect, useState } from 'react';
import { format, parse, add } from 'date-fns';
import { Nullable } from '../../../interfaces';
import BaseInput from '../BaseInput';
import Icon from '../Icon';
import Typography from '../Typography';
import ButtonWrapper from '../ButtonWrapper';
import MenuWrapper from '../MenuWrapper';
import Menu from '../Menu';
import { colors } from '../Colors';
import DateRangePickerPanel from './DateRangePickerPanel';

import './DateRangePicker.scss';

const DATE_REGEXP = '\\d{0,2}|\\d\\d\\/\\d{0,2}|\\d\\d\\/\\d\\d\\/\\d{0,4}';
const DATE_FORMAT = 'dd/MM/yyyy';

export interface DateRangePickerProps {
  from: Nullable<Date>;
  to: Nullable<Date>;
  onChange: (from: Nullable<Date>, to: Nullable<Date>) => void;
}

const getParsedDate = (dateStr: string) => {
  if (dateStr.length === DATE_FORMAT.length) {
    try {
      const parsedDate = parse(dateStr, DATE_FORMAT, new Date());
      if (!isNaN(parsedDate.getTime())) {
        return parsedDate;
      }
    } catch (error) {
      console.error('error for parsing input date', error);
    }
  }

  return null;
};

const DateRangePicker: FC<DateRangePickerProps> = props => {
  const { from, to, onChange } = props;

  const [fromDateString, setFromDateString] = useState('');
  const [toDateString, setToDateString] = useState('');
  const [fromDatePickerOpen, setFromDatePickerOpen] = useState(false);
  const [toDatePickerOpen, setToDatePickerOpen] = useState(false);

  useEffect(() => setFromDateString(from ? format(from, DATE_FORMAT) : ''), [from]);
  useEffect(() => setToDateString(to ? format(to, DATE_FORMAT) : ''), [to]);

  const openFromDatePicker = useCallback(() => {
    setFromDatePickerOpen(true);
    setToDatePickerOpen(false);
  }, []);
  const closeFromDatePicker = useCallback(() => setFromDatePickerOpen(false), []);
  const openToDatePicker = useCallback(() => {
    setFromDatePickerOpen(false);
    setToDatePickerOpen(true);
  }, []);
  const closeToDatePicker = useCallback(() => setToDatePickerOpen(false), []);

  const onFromDateSelect = useCallback((value: Nullable<Date>) => onChange(value, to), [to, onChange]);
  const onToDateSelect = useCallback(
    (value: Nullable<Date>) => onChange(from, value ? add(value, { days: 1, seconds: -1 }) : value),
    [from, onChange],
  );
  const onFromDateInputChange = useCallback(
    (value: string) => {
      const parsedDate = getParsedDate(value);
      if (parsedDate) {
        onChange(parsedDate, to);
      }
      setFromDateString(value);
    },
    [to, onChange],
  );
  const onToDateInputChange = useCallback(
    (value: string) => {
      const parsedDate = getParsedDate(value);
      if (parsedDate) {
        onChange(from, add(parsedDate, { days: 1, seconds: -1 }));
      }
      setToDateString(value);
    },
    [from, onChange],
  );

  return (
    <div className="nostra__date-range-picker-input">
      <MenuWrapper>
        <Typography
          className={`nostra__date-range-picker-input__input ${fromDatePickerOpen ? 'open' : ''}`}
          variant="body-secondary"
        >
          <BaseInput
            placeholder={DATE_FORMAT}
            pattern={DATE_REGEXP}
            value={fromDateString}
            onFocus={openFromDatePicker}
            onChange={onFromDateInputChange}
          />
          <ButtonWrapper onClick={openFromDatePicker}>
            <Icon
              variant="calendar"
              color={fromDatePickerOpen ? colors.dateRangePickerBorderHighlight : colors.dateRangePickerIcon}
              size="small"
            />
          </ButtonWrapper>
        </Typography>
        <Menu open={fromDatePickerOpen} onClose={closeFromDatePicker}>
          <DateRangePickerPanel selectedDate={from} onChange={onFromDateSelect} />
        </Menu>
      </MenuWrapper>
      <div className="nostra__date-range-picker-input__arrow">
        <Icon variant="right-arrow-thin" size="tiny" color={colors.dateRangePickerIcon} />
      </div>
      <MenuWrapper>
        <Typography
          className={`nostra__date-range-picker-input__input ${toDatePickerOpen ? 'open' : ''}`}
          variant="body-secondary"
        >
          <BaseInput
            placeholder={DATE_FORMAT}
            pattern={DATE_REGEXP}
            value={toDateString}
            onFocus={openToDatePicker}
            onChange={onToDateInputChange}
          />
          <ButtonWrapper onClick={openToDatePicker}>
            <Icon
              variant="calendar"
              color={toDatePickerOpen ? colors.dateRangePickerBorderHighlight : colors.dateRangePickerIcon}
              size="small"
            />
          </ButtonWrapper>
        </Typography>
        <Menu open={toDatePickerOpen} onClose={closeToDatePicker}>
          <DateRangePickerPanel selectedDate={to} onChange={onToDateSelect} />
        </Menu>
      </MenuWrapper>
    </div>
  );
};

export default DateRangePicker;
