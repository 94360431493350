import { bind } from '@react-rxjs/core';
import { combineLatest, map } from 'rxjs';
import { ZERO } from '../datastructures';
import { assets$ } from './useAssets';
import { tokenRates$ } from './useTokenRates';

const DEFAULT_VALUE = null;

const totalValueLocked$ = combineLatest([assets$, tokenRates$]).pipe(
  map(([assets, tokenRates]) => {
    if (assets.length === 0) {
      return null;
    }

    return assets
      .map(asset => {
        const {
          address,
          nostraInterestBearingCollateralSupply,
          nostraInterestBearingSupply,
          nostraCollateralSupply,
          nostraSupply,
          debtTokenSupply,
        } = asset;

        if (
          !nostraInterestBearingCollateralSupply ||
          !nostraInterestBearingSupply ||
          !nostraCollateralSupply ||
          !nostraSupply ||
          !debtTokenSupply
        ) {
          return null;
        }

        const assetTotalValueLocked = ZERO.add(nostraSupply)
          .add(nostraCollateralSupply)
          .add(nostraInterestBearingSupply)
          .add(nostraInterestBearingCollateralSupply)
          .sub(debtTokenSupply);

        const rate = tokenRates[address];
        if (!rate) {
          return null;
        }

        return assetTotalValueLocked.mul(rate);
      })
      .reduce((acc, totalValueLocked) => {
        if (!acc || !totalValueLocked) {
          return null;
        }

        return acc.add(totalValueLocked);
      }, ZERO);
  }),
);

/**
 * Total value locked is sum of all nostra tokens currently deposited, minus borrowed tokens.
 */
export const [useTotalValueLocked] = bind(totalValueLocked$, DEFAULT_VALUE);
