import { FC } from 'react';
import { InnerIconProps } from './IconProps';
import withIcon from './withIcon';

const Telegram: FC<InnerIconProps> = ({ size, color }) => (
  <svg
    className="nostra__icon nostra__icon-telegram"
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_63_440)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9224 8C15.9224 12.418 12.3404 16 7.92236 16C3.50436 16 -0.0776367 12.418 -0.0776367 8C-0.0776367 3.582 3.50436 0 7.92236 0C12.3404 0 15.9224 3.582 15.9224 8ZM8.20903 5.906C7.43103 6.22933 5.8757 6.89933 3.5437 7.91533C3.16503 8.066 2.96636 8.21333 2.94836 8.35733C2.9177 8.60133 3.22303 8.69733 3.6377 8.82733C3.69436 8.84533 3.75303 8.86333 3.81303 8.88333C4.2217 9.016 4.77103 9.17133 5.05636 9.17733C5.3157 9.18267 5.60503 9.076 5.92436 8.85733C8.10303 7.386 9.2277 6.64267 9.29836 6.62667C9.34836 6.61533 9.4177 6.60067 9.46436 6.64267C9.51103 6.684 9.50636 6.76267 9.5017 6.784C9.47103 6.91267 8.27503 8.02533 7.65503 8.60133C7.4617 8.78067 7.32503 8.908 7.29703 8.93733C7.23436 9.002 7.17036 9.064 7.10903 9.12333C6.72903 9.48867 6.44503 9.76333 7.12503 10.2113C7.4517 10.4267 7.71303 10.6047 7.9737 10.782C8.25836 10.976 8.54236 11.1693 8.91036 11.4107C9.0037 11.472 9.09303 11.5353 9.18036 11.5973C9.5117 11.834 9.8097 12.046 10.1777 12.0127C10.391 11.9927 10.6124 11.792 10.7244 11.1927C10.989 9.77533 11.5104 6.706 11.631 5.44067C11.6384 5.33561 11.6339 5.23006 11.6177 5.126C11.608 5.04195 11.5671 4.96462 11.503 4.90933C11.4077 4.83133 11.2597 4.81467 11.193 4.816C10.8924 4.82133 10.431 4.982 8.20903 5.906Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_63_440">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default withIcon(Telegram);
