import { FC } from 'react';
import { InnerIconProps } from './IconProps';
import withIcon from './withIcon';

const Back: FC<InnerIconProps> = ({ size, color }) => (
  <svg
    className="nostra__icon nostra__icon-back"
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" r="16" fill="#A8A8A8" fillOpacity="0.09" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.6953 11.2807C18.8824 11.468 18.9875 11.722 18.9875 11.9869C18.9875 12.2517 18.8824 12.5057 18.6953 12.693L15.4089 15.982L18.6953 19.2711C18.7906 19.3632 18.8667 19.4734 18.919 19.5953C18.9713 19.7171 18.9988 19.8482 19 19.9808C19.0011 20.1134 18.9759 20.2449 18.9257 20.3677C18.8755 20.4904 18.8014 20.602 18.7077 20.6957C18.614 20.7895 18.5026 20.8637 18.3799 20.9139C18.2573 20.9641 18.1258 20.9894 17.9933 20.9882C17.8608 20.9871 17.7299 20.9595 17.6081 20.9072C17.4863 20.8548 17.3762 20.7788 17.2842 20.6834L13.2922 16.6882C13.1051 16.5009 13 16.2469 13 15.982C13 15.7172 13.1051 15.4632 13.2922 15.2759L17.2842 11.2807C17.4713 11.0935 17.7251 10.9883 17.9897 10.9883C18.2544 10.9883 18.5082 11.0935 18.6953 11.2807Z"
      fill="#222222"
    />
  </svg>
);

export default withIcon(Back);
