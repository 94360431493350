import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AssetTier } from '../../interfaces';
import { colors, Icon, Link, Typography } from '../shared';

import './AssetTierTooltip.scss';

interface MarketsFilterTooltipProps {
  assetTier: AssetTier;
}

interface ContentData {
  name: string;
  title: string;
  description: string;
}

const content = {
  shared: {
    name: 'Markets.btnShared',
    // t('Markets.titleSharedTierTooltip')
    title: 'Markets.titleSharedTierTooltip',
    // t('Markets.descSharedTierTooltip')
    description: 'Markets.descSharedTierTooltip',
  },
  cross: {
    name: 'Markets.btnCross',
    // t('Markets.titleCrossTierTooltip')
    title: 'Markets.titleCrossTierTooltip',
    // t('Markets.descCrossTierTooltip')
    description: 'Markets.descCrossTierTooltip',
  },
  isolated: {
    name: 'Markets.btnIsolated',
    // t('Markets.titleIsolatedTierTooltip')
    title: 'Markets.titleIsolatedTierTooltip',
    // t('Markets.descIsolatedTierTooltip')
    description: 'Markets.descIsolatedTierTooltip',
  },
  nominal: {
    name: 'Markets.btnNominal',
    // t('Markets.titleNominalTierTooltip')
    title: 'Markets.titleNominalTierTooltip',
    // t('Markets.descNominalTierTooltip')
    description: 'Markets.descNominalTierTooltip',
  },
} as Record<AssetTier, ContentData>;

const AssetTierTooltip: FC<MarketsFilterTooltipProps> = props => {
  const { assetTier } = props;
  const { t } = useTranslation();

  return (
    <div className="nostra__asset-tier-tooltip">
      <div className="nostra__asset-tier-tooltip__title">
        <Icon variant="info-bordered-rounded" color={colors.textHighlight} size={20} />
        <Typography variant="body-primary" weight="bold">
          {t(content[assetTier].title)}
        </Typography>
      </div>
      <Typography variant="body-primary">
        {t('Markets.descTierTooltipCommon', { tier: t(content[assetTier].name) })}
      </Typography>
      <br />
      <Typography variant="body-primary">{t(content[assetTier].description)}</Typography>
      <Typography className="nostra__asset-tier-tooltip__link" variant="body-tertiary" color="text-highlight">
        <Link href="https://docs.nostra.finance/nostra-money-market/risk-management/asset-tiers">
          {t('Markets.labelFilterTooltipReadMore')}
        </Link>
      </Typography>
    </div>
  );
};
export default AssetTierTooltip;
