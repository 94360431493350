import { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Decimal, ZERO } from '../../../datastructures';
import { useAssets, useTotalAssets, useTotalBorrow, useTotalValueLocked, useUtilizationRate } from '../../../hooks';
import { DecimalUtils, weightedAverage } from '../../../utils';
import AnalyticsBox, { AnalyticsBoxMainValue } from '../AnalyticsBox';

import './AnalyticsStats.scss';

const AnalyticsStats: FC = () => {
  const { t } = useTranslation();
  const totalValueLocked = useTotalValueLocked();

  const assets = useAssets();
  const totalAssets = useTotalAssets();
  const totalBorrow = useTotalBorrow();
  const utilizationRate = useUtilizationRate();

  const formattedTVL = useMemo(
    () =>
      totalValueLocked !== null
        ? DecimalUtils.format(new Decimal(totalValueLocked), {
            style: 'multiplier',
            fractionDigits: 2,
            currency: '$',
            lessThanFormat: true,
          })
        : null,
    [totalValueLocked],
  );

  const formattedUtilizationRate = useMemo(
    () =>
      utilizationRate !== null
        ? DecimalUtils.format(utilizationRate, { style: 'percentage', fractionDigits: 2 })
        : null,
    [utilizationRate],
  );

  const markets = useMemo(() => (assets && assets.length > 0 ? `${assets.length}` : null), [assets]);

  const averageSupplyAPY = useMemo(() => {
    const values = assets.map(asset => asset.supplyApy);
    const weights = assets.map(asset => {
      if (!asset.totalSupply || !totalAssets) {
        return null;
      }

      if (asset.totalSupply.equals(ZERO) || totalAssets.equals(ZERO)) {
        return ZERO;
      }

      return asset.totalSupply.div(totalAssets);
    });

    if (values.some(value => value === null) || weights.some(weight => weight === null)) {
      return null;
    }

    const nonNullValues = values.filter((value): value is Decimal => value !== null);
    const nonNullWeights = weights.filter((weight): weight is Decimal => weight !== null);

    return DecimalUtils.format(weightedAverage(nonNullValues, nonNullWeights), {
      style: 'percentage',
      fractionDigits: 2,
    });
  }, [assets, totalAssets]);

  const averageBorrowAPY = useMemo(() => {
    const values = assets.map(asset => asset.borrowApy);
    const weights = assets.map(asset => {
      if (!asset.totalBorrow || !totalBorrow) {
        return null;
      }

      if (asset.totalBorrow.equals(ZERO) || totalBorrow.equals(ZERO)) {
        return ZERO;
      }

      return asset.totalBorrow.div(totalBorrow);
    });

    if (values.some(value => value === null) || weights.some(weight => weight === null)) {
      return null;
    }

    const nonNullValues = values.filter((value): value is Decimal => value !== null);
    const nonNullWeights = weights.filter((weight): weight is Decimal => weight !== null);

    return DecimalUtils.format(weightedAverage(nonNullValues, nonNullWeights), {
      style: 'percentage',
      fractionDigits: 2,
    });
  }, [assets, totalBorrow]);

  return (
    <AnalyticsBox>
      <div className="nostra__analytics__box">
        <AnalyticsBoxMainValue title={t('Analytics.labelTVL')} value={formattedTVL} />
      </div>
      <div className="nostra__analytics__box">
        <AnalyticsBoxMainValue title={t('Analytics.labelUtilizationRate')} value={formattedUtilizationRate} />
      </div>
      <div className="nostra__analytics__box">
        <AnalyticsBoxMainValue title={t('Analytics.labelMarkets')} value={markets} />
      </div>
      <div className="nostra__analytics__box">
        <AnalyticsBoxMainValue title={t('Analytics.labelAverageSupplyAPY')} value={averageSupplyAPY} />
      </div>
      <div className="nostra__analytics__box">
        <AnalyticsBoxMainValue title={t('Analytics.labelAverageBorrowAPY')} value={averageBorrowAPY} />
      </div>
    </AnalyticsBox>
  );
};

export default memo(AnalyticsStats);
