import { useCallback, useMemo } from 'react';
import { useNavigation } from 'react-day-picker';
import { format, addMonths } from 'date-fns';
import Icon from '../Icon';
import Typography from '../Typography';
import ButtonWrapper from '../ButtonWrapper';

const DateRangePickerCaption = () => {
  const { currentMonth, goToMonth } = useNavigation();

  const prevMonth = useMemo(() => addMonths(currentMonth, -1), [currentMonth]);
  const nextMonth = useMemo(() => addMonths(currentMonth, 1), [currentMonth]);
  const prevYear = useMemo(() => addMonths(currentMonth, -12), [currentMonth]);
  const nextYear = useMemo(() => addMonths(currentMonth, 12), [currentMonth]);
  const currentMonthStr = useMemo(() => format(currentMonth, 'MMMM yyyy'), [currentMonth]);

  const goToPrevMonth = useCallback(() => goToMonth(prevMonth), [prevMonth, goToMonth]);
  const goToNextMonth = useCallback(() => goToMonth(nextMonth), [nextMonth, goToMonth]);
  const goToPrevYear = useCallback(() => goToMonth(prevYear), [prevYear, goToMonth]);
  const goToNextYear = useCallback(() => goToMonth(nextYear), [nextYear, goToMonth]);

  return (
    <div className="rdp-caption">
      <div className="rdp-caption__bth-container">
        <ButtonWrapper onClick={goToPrevYear}>
          <Icon variant="left-double-chevron" size="tiny" />
        </ButtonWrapper>
        <ButtonWrapper onClick={goToPrevMonth}>
          <Icon variant="left-chevron" size="tiny" />
        </ButtonWrapper>
      </div>
      <Typography variant="body-secondary" weight="medium">
        {currentMonthStr}
      </Typography>
      <div className="rdp-caption__bth-container">
        <ButtonWrapper onClick={goToNextMonth}>
          <Icon variant="right-chevron" size="tiny" />
        </ButtonWrapper>
        <ButtonWrapper onClick={goToNextYear}>
          <Icon variant="right-double-chevron" size="tiny" />
        </ButtonWrapper>
      </div>
    </div>
  );
};

export default DateRangePickerCaption;
