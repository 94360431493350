import { FC } from 'react';
import { InnerIconProps } from './IconProps';
import withIcon from './withIcon';

const Copyright: FC<InnerIconProps> = ({ size, color }) => (
  <svg
    className="nostra__icon nostra__icon-copyright"
    width={size}
    height={size}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.90848 7.09622C8.02466 6.91469 8.266 6.86165 8.44761 6.97774C8.62919 7.09392 8.68222 7.33525 8.56609 7.51683C8.04487 8.33172 7.15682 8.8182 6.19058 8.8182C4.63664 8.8182 3.37244 7.55399 3.37244 6.00001C3.37244 4.44603 4.63664 3.18182 6.19058 3.18182C7.12816 3.18182 8.00172 3.64619 8.52723 4.42404C8.64792 4.60267 8.60095 4.8453 8.42237 4.96595C8.24366 5.08664 8.00112 5.03963 7.88047 4.86109C7.50024 4.29837 6.86852 3.96241 6.19058 3.96241C5.06705 3.96241 4.15303 4.87648 4.15303 6.00001C4.15303 7.12354 5.06705 8.03761 6.19058 8.03761C6.88925 8.03761 7.53146 7.68569 7.90848 7.09622Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 6C0 2.69156 2.69156 0 6 0C9.30844 0 12 2.69156 12 6C12 9.30844 9.30844 12 6 12C2.69156 12 0 9.30844 0 6ZM0.780589 6C0.780589 8.87803 3.12201 11.2195 6 11.2195C8.87803 11.2195 11.2194 8.87799 11.2194 6C11.2194 3.12201 8.87799 0.780589 6 0.780589C3.12201 0.780589 0.780589 3.12197 0.780589 6Z"
      fill={color}
    />
  </svg>
);

export default withIcon(Copyright);
