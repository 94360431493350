export const ADDRESS_CHAR_LENGTH = 66;

export const DEBOUNCE_IN_MS = 500;

export const POLLING_INTERVAL_IN_MS = 2 * 60 * 1000;

export const HEALTH_FACTOR_INFINITY = 9999;
export const HEALTH_FACTOR_WARNING = 1.5;
export const HEALTH_FACTOR_DANGER = 1;
export const HEALTH_FACTOR_BUFFER = 0.01;

export const GAS_FEE_UI_DECIMALS = 4;
export const GAS_FEE_PRECISION = 18;

export const MULTICALL_CONTRACT_ADDRESS = '0x05754af3760f3356da99aea5c3ec39ccac7783d925a19666ebbeca58ff0087f4';

export const NOSTRA_HOMEPAGE_URL = 'https://nostra.finance';

export const TRANSACTION_HISTORY_PAGE_SIZES = [7, 10, 15, 20, 30];
