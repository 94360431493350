import { FC } from 'react';
import { ShadowContainer } from '../../shared';

import './AnalyticsBox.scss';

const AnalyticsBox: FC = props => {
  const { children } = props;

  return <ShadowContainer className="nostra__analytics-box">{children}</ShadowContainer>;
};

export default AnalyticsBox;
