import { FC } from 'react';
import ButtonPrimary from '../ButtonPrimary';
import Icon from '../Icon';
import ModalWrapper from '../ModalWrapper';
import Typography from '../Typography';
import { colors } from '../Colors';

import './ModalInfo.scss';

interface ModalInfoProps {
  text: string;
  open: boolean;
  onClose: () => void;
}

const ModalInfo: FC<ModalInfoProps> = ({ text, open, onClose }) => {
  return (
    <ModalWrapper onClose={onClose} open={open}>
      <div className="nostra__modal-info">
        <div className="nostra__modal-info__icon">
          <Icon variant="info-bordered-rounded" size={20} color={colors.textHighlight} />
        </div>
        <div className="nostra__modal-info__text">
          <Typography variant="body-primary" weight="medium">
            {text}
          </Typography>
        </div>
        <div className="nostra__modal-info__actions">
          <ButtonPrimary label="Ok" onClick={onClose} />
        </div>
      </div>
    </ModalWrapper>
  );
};
export default ModalInfo;
