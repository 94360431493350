import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { truncateAddress } from '../../../helpers';
import {
  addAccount,
  useWalletAccount,
  useActiveAccountId,
  useAccounts,
  useWallet,
  useStarknetDomain,
} from '../../../hooks';
import { ButtonWrapper, Logo, SelectedAccountIndicator, Typography } from '../../shared';
import AccountCard from './AccountCard/AccountCard';

import './WalletPopup.scss';

interface WalletPopupProps {
  open: boolean;
  onSwitchWallet: () => void;
  onDisconnectWallet: () => void;
  onClose: () => void;
}

const WalletPopup: FC<WalletPopupProps> = props => {
  const { open, onSwitchWallet, onDisconnectWallet, onClose } = props;

  const navigate = useNavigate();
  const { search } = useLocation();
  const { t } = useTranslation();

  const wallet = useWallet();
  const walletAccount = useWalletAccount();
  const accounts = useAccounts();
  const activeAccountId = useActiveAccountId();
  const starknetDomain = useStarknetDomain();

  const shortAddress = useMemo(() => (walletAccount ? truncateAddress(walletAccount.address) : null), [walletAccount]);

  const onAccountManagementClick = useCallback(() => {
    navigate(`/accounts${search}`);

    onClose();
  }, [search, navigate, onClose]);

  const handleSwitchWallet = useCallback(() => {
    onSwitchWallet();

    onClose();
  }, [onSwitchWallet, onClose]);

  const handleDisconnectWallet = useCallback(() => {
    onDisconnectWallet();

    onClose();
  }, [onDisconnectWallet, onClose]);

  const onAddNewAccount = useCallback(() => {
    if (walletAccount) {
      addAccount(walletAccount.address);
    }
  }, [walletAccount]);

  const canCreateNewAccount = useMemo(
    () => accounts.filter(name => Boolean(name)).length < accounts.length,
    [accounts],
  );

  const walletLogo = useMemo(() => {
    if (!wallet) {
      return null;
    }

    switch (wallet?.id) {
      case 'argentX':
        return 'wallet-argent';
      case 'braavos':
        return 'wallet-braavos';
      default:
        return null;
    }
  }, [wallet]);

  return (
    <>
      <ButtonWrapper onClick={onClose}>
        <div className="nostra__walletPopup__backdrop" />
      </ButtonWrapper>
      <div className={`nostra__walletPopup ${open ? `nostra__walletPopup__open` : ''}`}>
        <div className="nostra__walletPopup__info">
          <div className="nostra__walletPopup__info-title">
            {walletLogo && <Logo type={walletLogo} size="small" />}
            <Typography variant="body-primary" weight="bold">
              {t('Wallet.popupTitle')}
            </Typography>
          </div>
          <Typography variant="body-primary" color="text-wallet">
            {starknetDomain ?? shortAddress}
          </Typography>
        </div>
        <div className="nostra__walletPopup__actions">
          <ButtonWrapper className="nostra__walletPopup__button" onClick={handleSwitchWallet}>
            <Typography variant="body-primary" weight="bold">
              {t('Wallet.btnSwitchWallet')}
            </Typography>
          </ButtonWrapper>
          <ButtonWrapper className="nostra__walletPopup__button" onClick={handleDisconnectWallet}>
            <Typography variant="body-primary" weight="bold">
              {t('Wallet.btnDisconnect')}
            </Typography>
          </ButtonWrapper>
        </div>
        <div className="nostra__walletPopup__divider" />
        <div className="nostra__walletPopup__accounts">
          <Typography variant="body-primary" weight="bold">
            {t('Wallet.labelAccounts')}
          </Typography>
        </div>
        <div className="nostra__walletPopup__account-actions">
          <ButtonWrapper className="nostra__walletPopup__button" onClick={onAccountManagementClick}>
            <Typography variant="body-primary" weight="bold">
              {t('Wallet.btnAccountManagement')}
            </Typography>
          </ButtonWrapper>
          <ButtonWrapper
            className="nostra__walletPopup__button-primary"
            onClick={onAddNewAccount}
            disabled={!canCreateNewAccount}
          >
            <Typography
              variant="body-primary"
              weight={canCreateNewAccount ? 'bold' : 'regular'}
              color="text-primary-inverted"
              opacity={canCreateNewAccount ? 1 : 0.5}
            >
              {t('Wallet.btnAddNewAccount')}
            </Typography>
          </ButtonWrapper>
        </div>

        {accounts.map((account, index) =>
          account !== '' ? (
            <div className="nostra__walletPopup__account-row" key={index}>
              {index === activeAccountId && (
                <div className="nostra__walletPopup__account-row-selected-indicator">
                  <SelectedAccountIndicator />
                </div>
              )}
              <AccountCard accountName={account} accountId={index} />
            </div>
          ) : null,
        )}
      </div>
    </>
  );
};
export default WalletPopup;
