import { bind } from '@react-rxjs/core';
import { AccountInterface } from 'starknet';
import {
  BehaviorSubject,
  combineLatest,
  debounce,
  filter,
  interval,
  map,
  merge,
  mergeMap,
  Observable,
  of,
  pairwise,
  scan,
  Subscription,
  tap,
} from 'rxjs';
import { Asset, DepositPosition, Nullable } from '../interfaces';
import { walletAccount$ } from './useWalletAccount';
import { Decimal } from '../datastructures';
import { DEBOUNCE_IN_MS } from '../constants';
import { getNostraTokenFromFlags } from '../utils';
import { getConfigManager } from '../config/getConfigManager';
import { assets$ } from './useAssets';
import { nostraTokenBalance$ } from './useNostraTokenBalance';
import { activeAccountId$ } from './useActiveAccountId';
import { TokenRateMap, tokenRates$ } from './useTokenRates';
import { AssetVariant, assetVariant$, AssetVariantMap } from './useAssetVariant';

type AssetAddressPositionMap = { [walletAddressAccountIdAssetAddress: string]: DepositPosition };

// If value is null we can assume positions are still loading and we should show skeleton view
const DEFAULT_VALUE: Nullable<DepositPosition[]> = null;

const fetchData = (
  walletAddress: string,
  accountId: number,
  asset: Asset,
  collateralFactor: Decimal,
  lendingRate: Decimal,
  tokenRate: Decimal,
  assetVariant: AssetVariant,
): Observable<DepositPosition | null> =>
  nostraTokenBalance$.pipe(
    map(nostraTokenBalances => {
      try {
        const nostraToken = getNostraTokenFromFlags(asset, {
          interestBearing: assetVariant.isLending,
          collateral: assetVariant.isCollateral,
        });

        const nostraTokenBalance = nostraTokenBalances[`${walletAddress}-${accountId}-${nostraToken.address}`];

        // Still loading the nostra token balance
        if (!nostraTokenBalance) {
          return null;
        }

        const balanceUsd = nostraTokenBalance.mul(tokenRate);

        return {
          asset,
          balance: nostraTokenBalance,
          balanceUsd,
          apy: lendingRate,
          collateralFactor,
        } as DepositPosition;
      } catch (error) {
        console.error(
          `useDepositPositions - Error fetching deposit position for token ${asset.token} and account ${walletAddress}`,
          error,
        );
        return null;
      }
    }),
  );

export const depositPositionMap$ = new BehaviorSubject<Nullable<AssetAddressPositionMap>>(DEFAULT_VALUE);
export const depositPositions$ = new BehaviorSubject<Nullable<DepositPosition[]>>(DEFAULT_VALUE);

type UpdateStreamInputs = [Nullable<AccountInterface>, Nullable<number>, Asset[], TokenRateMap, AssetVariantMap];
type UpdateStreamNonNullInputs = [AccountInterface, number, Asset[], TokenRateMap, AssetVariantMap];

const updateStream$ = combineLatest([walletAccount$, activeAccountId$, assets$, tokenRates$, assetVariant$]).pipe(
  pairwise(),
  tap<[UpdateStreamInputs, UpdateStreamInputs]>(value => {
    const previousWalletAccount = value[0][0];
    const walletAccount = value[1][0];

    /*
    In case user changes the wallet, we want to reset deposit positions map to null.
    (show loading skeleton while positions for new wallet are being loaded)
    */
    if (previousWalletAccount !== walletAccount) {
      depositPositionMap$.next(DEFAULT_VALUE);
    }
    return value;
  }),
  filter((value): value is [UpdateStreamNonNullInputs, UpdateStreamNonNullInputs] => {
    const [walletAccount, activeAccountId] = value[1];
    return Boolean(walletAccount) && activeAccountId !== null;
  }),
  mergeMap(([, [walletAccount, activeAccountId, assets, tokenRates, assetVariants]]) => {
    const lendTokenBalances$ = assets.map(asset => {
      if (!asset.supplyApy || !asset.collateralFactor) {
        return of(null);
      }

      const accountAssetVariant = assetVariants[`${walletAccount.address}-${activeAccountId}-${asset.address}`];
      if (!accountAssetVariant) {
        return of(null);
      }

      const tokenRate = tokenRates[asset.address];
      if (!tokenRate) {
        return of(null);
      }

      return fetchData(
        walletAccount.address,
        activeAccountId,
        asset,
        asset.collateralFactor,
        asset.supplyApy,
        tokenRate,
        accountAssetVariant,
      ).pipe(
        map(position =>
          // if position is null, nostra token is still loading, skip update
          position
            ? {
                ...position,
                walletAddress: walletAccount.address,
                accountId: activeAccountId,
              }
            : null,
        ),
      );
    });

    return merge(...lendTokenBalances$);
  }),
  scan((allPositions, position) => {
    if (position === null) {
      return allPositions;
    }

    return {
      ...(allPositions ?? {}),
      [`${position.walletAddress}-${position.accountId}-${position.asset.address}`]: position,
    };
  }, null as Nullable<AssetAddressPositionMap>),
  debounce(() => interval(DEBOUNCE_IN_MS)),
  filter((positions): positions is AssetAddressPositionMap => positions !== null),
  tap(positions => depositPositionMap$.next(positions)),
);

type PositionsStreamInputs = [Nullable<AccountInterface>, Nullable<number>, Nullable<AssetAddressPositionMap>];
type PositionsStreamNonNullInputs = [AccountInterface, number, AssetAddressPositionMap];

const positionsStream$ = combineLatest([walletAccount$, activeAccountId$, depositPositionMap$]).pipe(
  pairwise(),
  tap<[PositionsStreamInputs, PositionsStreamInputs]>(value => {
    const previousWalletAccount = value[0][0];
    const walletAccount = value[1][0];

    /*
    In case user changes the wallet, we want to reset deposit positions to null.
    (show loading skeleton while positions for new wallet are being loaded)
    */
    if (previousWalletAccount !== walletAccount) {
      depositPositions$.next(DEFAULT_VALUE);
    }
    return value;
  }),
  filter((value): value is [PositionsStreamNonNullInputs, PositionsStreamNonNullInputs] => {
    const [walletAccount, accountId, depositPositionMap] = value[1];

    return Boolean(walletAccount) && accountId !== undefined && accountId !== null && depositPositionMap !== null;
  }),
  map(([, [walletAccount, accountId, map]]) => {
    const positions = Object.keys(map)
      .filter(key => key.startsWith(`${walletAccount?.address}-${accountId}-`))
      .sort((a, b) => map[a].asset.token.localeCompare(map[b].asset.token))
      .map(key => map[key]);

    const config = getConfigManager().getConfig();

    // If not all positions are loaded, skip the UI update until all positions are loaded
    if (positions.length < Object.keys(config.assets).length) {
      return null;
    }

    // Return only non zero balance positions
    return positions.filter(position => !position.balance.isZero());
  }),
  debounce(() => interval(DEBOUNCE_IN_MS)),
  tap(positions => {
    if (positions !== null) {
      depositPositions$.next(positions);
    }
  }),
);

export const [useDepositPositions] = bind(depositPositions$, DEFAULT_VALUE);

let subscriptions: Subscription[];

export const subscribeDepositPositions = (): void => {
  unsubscribeDepositPositions();
  subscriptions = [updateStream$.subscribe(), positionsStream$.subscribe()];
};
export const unsubscribeDepositPositions = (): void =>
  subscriptions?.forEach(subscription => subscription?.unsubscribe());
export const resetDepositPositions = (): void => {
  depositPositionMap$.next(null);
  depositPositions$.next(DEFAULT_VALUE);
};
