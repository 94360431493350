import { FC, memo } from 'react';
import { useNotifications } from '../../hooks';
import NotificationItem from './NotificationItem';

import './NotificationCenter.scss';

const NotificationCenter: FC = () => {
  const [notifications] = useNotifications();

  return (
    <div className="nostra__notification-center">
      {notifications.map(notification => (
        <NotificationItem
          key={`${notification.userAddress}-${notification.action}-${notification.timestamp}`}
          notification={notification}
        />
      ))}
    </div>
  );
};

export default memo(NotificationCenter);
