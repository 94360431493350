import { catchError, from, map, Observable, of } from 'rxjs';
import { Abi, AccountInterface, Call, InvokeFunctionResponse, ProviderInterface } from 'starknet';
import { Decimal, DEFAULT_DECIMAL_PRECISION, Uint256 } from '../../datastructures';
import { Asset } from '../../interfaces';
import { isAccount, parseAndLogError } from '../../utils';
import LentDebtToken_ABI from './abi/LentDebtToken_abi.json';
import ERC20_ABI from './abi/Erc20_abi.json';
import { ContractBase } from './base';
import { LentDebtToken } from './typechain';

export class LentDebtTokenContract extends ContractBase<LentDebtToken> {
  private precision: number;

  constructor(precision: number, address: string, providerOrAccount: ProviderInterface | AccountInterface) {
    super(LentDebtToken_ABI as Abi, address, providerOrAccount);
    this.precision = precision;
  }

  balanceOf(address: string): Observable<Decimal | null> {
    if (!this.contract) {
      return of(null);
    }

    return from(this.contract.balanceOf(address)).pipe(
      map(([balance]) => new Decimal(balance as Uint256, this.precision)),
    );
  }

  totalSupply(): Observable<Decimal | null> {
    if (!this.contract) {
      return of(null);
    }

    return from(this.contract.totalSupply()).pipe(
      map(([supply]) => {
        return new Decimal(supply, this.precision);
      }),
      catchError(error => {
        console.error('LentDebtTokenContract -> totalSupply() -> ', error);

        return of(null);
      }),
    );
  }

  getTotalSupplyCap(): Observable<Decimal | null> {
    if (!this.contract) {
      return of(null);
    }

    return from(this.contract.getTotalSupplyCap()).pipe(
      map(([supply]) => new Decimal(supply as Uint256, this.precision)),
    );
  }

  mint(amount: Decimal, to: string): Observable<InvokeFunctionResponse | null> {
    if (!this.contract) {
      return of(null);
    }

    const account = this.contract.providerOrAccount;
    if (!isAccount(account)) {
      return of(null);
    }

    const amountUint256 = amount.toUint256(this.precision);

    const calls = [
      {
        contractAddress: this.contract.address,
        entrypoint: 'mint',
        calldata: [to, amountUint256.low, amountUint256.high],
      },
    ] as Call[];
    const abis = [LentDebtToken_ABI] as Abi[];

    return from(account.execute(calls, abis)).pipe(catchError(parseAndLogError));
  }

  burn(
    asset: Asset,
    amount: Decimal,
    inputAmount: Decimal,
    burnFrom: string,
  ): Observable<InvokeFunctionResponse | null> {
    if (!this.contract) {
      return of(null);
    }

    const account = this.contract.providerOrAccount;
    if (!isAccount(account)) {
      return of(null);
    }

    const approvalAmount = amount.equals(inputAmount) ? amount : inputAmount.add(inputAmount.div(100));
    const approvalAmountUint256 = approvalAmount.toUint256(asset.tokenPrecision);

    // If amount is Decimal.MAX_DECIMAL we don't want to reduce number of decimals to match token decimals, we want to keep amount to 18 decimals.
    // This way contract will properly recognize passed amount as Decimal.MAX_DECIMAL value.
    const amountUint256 = amount.equals(inputAmount)
      ? amount.toUint256(asset.tokenPrecision)
      : amount.toUint256(DEFAULT_DECIMAL_PRECISION);

    const calls = [
      {
        contractAddress: asset.address,
        entrypoint: 'approve',
        calldata: [this.contract.address, approvalAmountUint256.low, approvalAmountUint256.high],
      },
      {
        contractAddress: this.contract.address,
        entrypoint: 'burn',
        calldata: [burnFrom, amountUint256.low, amountUint256.high],
      },
    ] as Call[];
    const abis = [ERC20_ABI, LentDebtToken_ABI] as Abi[];

    return from(account.execute(calls, abis)).pipe(catchError(parseAndLogError));
  }
}
