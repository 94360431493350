import { FC, memo, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { datadogLogs } from '@datadog/browser-logs';
import { initServices } from '../../services';
import Summary from '../Summary';
import Markets from '../Markets';
import Positions from '../Positions';
import { useWalletConnected } from '../../hooks';

const Main: FC = () => {
  const [servicesLoaded, setServicesLoaded] = useState<boolean>(false);

  const walletConnected = useWalletConnected();

  // Init services and config
  useEffect(() => {
    datadogLogs.logger.info('App opened');

    setServicesLoaded(initServices());
  }, []);

  return (
    <>
      {servicesLoaded && walletConnected === false && <Summary />}
      {servicesLoaded && walletConnected === true && <Positions />}
      <Markets />
      <Outlet />
    </>
  );
};

export default memo(Main);
