import { AccountService } from './AccountService';

let accountService: AccountService;
export const getAccountService = () => {
  if (!accountService) {
    accountService = new AccountService();
  }

  return accountService;
};
