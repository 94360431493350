import { FC } from 'react';
import { InnerIconProps } from './IconProps';
import withIcon from './withIcon';

const External: FC<InnerIconProps> = ({ size, color }) => (
  <svg
    className="nostra__icon nostra__icon-external"
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_855_11670)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.16667 1C1.85725 1 1.5605 1.12292 1.34171 1.34171C1.12292 1.5605 1 1.85725 1 2.16667V13.8333C1 14.1428 1.12292 14.4395 1.34171 14.6583C1.5605 14.8771 1.85725 15 2.16667 15H13.8333C14.1428 15 14.4395 14.8771 14.6583 14.6583C14.8771 14.4395 15 14.1428 15 13.8333V9.66667C15 9.39052 15.2239 9.16667 15.5 9.16667C15.7761 9.16667 16 9.39052 16 9.66667V13.8333C16 14.408 15.7717 14.9591 15.3654 15.3654C14.9591 15.7717 14.408 16 13.8333 16H2.16667C1.59203 16 1.04093 15.7717 0.634602 15.3654C0.228273 14.9591 0 14.408 0 13.8333V2.16667C0 1.59203 0.228273 1.04093 0.634602 0.634602C1.04093 0.228273 1.59203 0 2.16667 0H6.33333C6.60948 0 6.83333 0.223858 6.83333 0.5C6.83333 0.776142 6.60948 1 6.33333 1H2.16667ZM10.834 0.5C10.834 0.223858 11.0578 0 11.334 0H15.501C15.6336 0 15.7608 0.0526838 15.8545 0.14646C15.9483 0.240237 16.001 0.367424 16.001 0.500039L16.0007 4.66671C16.0006 4.94285 15.7768 5.16669 15.5006 5.16667C15.2245 5.16665 15.0006 4.94277 15.0007 4.66663L15.0009 1.7072L9.60453 7.10355C9.40927 7.29882 9.09269 7.29882 8.89742 7.10355C8.70216 6.90829 8.70216 6.59171 8.89742 6.39645L14.2939 1H11.334C11.0578 1 10.834 0.776142 10.834 0.5Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_855_11670">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default withIcon(External);
