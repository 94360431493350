import { Observable, of } from 'rxjs';

export function parseAndLogError(error: unknown): Observable<null> {
  if (error instanceof Error) {
    // Do not log user rejection as an error.
    if (error.message === 'User abort') {
      console.info('User rejected the transaction.');
      return of(null);
    }
  }

  console.error(error);

  return of(null);
}
