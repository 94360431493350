import { bind } from '@react-rxjs/core';
import { BehaviorSubject, debounce, distinctUntilChanged, interval } from 'rxjs';
import { DEBOUNCE_IN_MS } from '../constants';
import { Nullable } from '../interfaces';

const DEFAULT_VALUE = null;

// If value is null it means app did not yet check if wallet is connected or not
const rawWalletConnected$ = new BehaviorSubject<Nullable<boolean>>(DEFAULT_VALUE);
const walletConnected$ = rawWalletConnected$.pipe(
  distinctUntilChanged(),
  debounce(() => interval(DEBOUNCE_IN_MS)),
);

export function setWalletConnected(connected: boolean) {
  rawWalletConnected$.next(connected);
}

export const [useWalletConnected] = bind(walletConnected$, DEFAULT_VALUE);
