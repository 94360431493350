import { FC, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Decimal } from '../../../datastructures';
import { Asset } from '../../../interfaces';
import { DecimalUtils, getTokenName } from '../../../utils';
import { AssetTierPill, Loading, LoadingPlaceholder, Logo, TooltipWrapper, Typography } from '../../shared';
import { LogoType } from '../../shared/Logo';
import { TableBodyCell, TableRow } from '../../shared/Table';
import MarketsAssetContractsTooltip from '../MarketsAssetContractsTooltip';

import './MarketsTableRow.scss';

interface MarketsTableRowProps {
  asset: Asset;
  price: Decimal | undefined;
}

const MarketsTableRow: FC<MarketsTableRowProps> = props => {
  const { asset, price } = props;
  const {
    assetTier,
    token,
    supplyApy,
    borrowApy,
    totalBorrow,
    availableForBorrowing,
    uiTokenPrecision,
    nostraInterestBearingCollateralSupply,
    nostraInterestBearingSupply,
  } = asset;

  const { search } = useLocation();
  const navigate = useNavigate();

  const tokenName = useMemo(() => getTokenName(token), [token]);

  const formattedPrice = useMemo(
    () =>
      price
        ? DecimalUtils.format(price, { style: 'currency', fractionDigits: asset.fiatTokenPrecision, currency: '$' })
        : '',
    [price, asset.fiatTokenPrecision],
  );

  const formattedSupplyApy = useMemo(
    () => (supplyApy ? DecimalUtils.format(supplyApy, { style: 'percentage', fractionDigits: 2, pad: true }) : null),
    [supplyApy],
  );

  const formattedBorrowApy = useMemo(
    () => (borrowApy ? DecimalUtils.format(borrowApy, { style: 'percentage', fractionDigits: 2, pad: true }) : null),
    [borrowApy],
  );

  const totalSupply = useMemo(() => {
    if (!nostraInterestBearingCollateralSupply || !nostraInterestBearingSupply) {
      return null;
    }

    return nostraInterestBearingCollateralSupply.add(nostraInterestBearingSupply);
  }, [nostraInterestBearingCollateralSupply, nostraInterestBearingSupply]);

  const formattedTotalSupply = useMemo(
    () =>
      totalSupply
        ? DecimalUtils.format(totalSupply, {
            style: 'multiplier',
            fractionDigits: 2,
            noMultiplierFractionDigits: uiTokenPrecision,
            currency: token,
            lessThanFormat: true,
          })
        : null,
    [totalSupply, uiTokenPrecision, token],
  );

  const formattedUsdTotalSupply = useMemo(
    () =>
      totalSupply && price
        ? DecimalUtils.format(totalSupply.mul(price), {
            style: 'multiplier',
            fractionDigits: 2,
            currency: '$',
            lessThanFormat: true,
          })
        : null,
    [price, totalSupply],
  );

  const formattedTotalBorrow = useMemo(
    () =>
      totalBorrow
        ? DecimalUtils.format(totalBorrow, {
            style: 'multiplier',
            fractionDigits: 2,
            noMultiplierFractionDigits: uiTokenPrecision,
            currency: token,
            lessThanFormat: true,
          })
        : null,
    [totalBorrow, uiTokenPrecision, token],
  );

  const formattedUsdTotalBorrow = useMemo(
    () =>
      totalBorrow && price
        ? DecimalUtils.format(totalBorrow.mul(price), {
            style: 'multiplier',
            fractionDigits: 2,
            currency: '$',
            lessThanFormat: true,
          })
        : null,
    [price, totalBorrow],
  );

  const formattedAvailableForBorrowing = useMemo(
    () =>
      availableForBorrowing
        ? DecimalUtils.format(availableForBorrowing, {
            style: 'multiplier',
            fractionDigits: 2,
            noMultiplierFractionDigits: uiTokenPrecision,
            currency: token,
            lessThanFormat: true,
          })
        : null,
    [availableForBorrowing, token, uiTokenPrecision],
  );

  const formattedUsdAvailableForBorrowing = useMemo(
    () =>
      availableForBorrowing && price
        ? DecimalUtils.format(availableForBorrowing.mul(price), {
            style: 'multiplier',
            fractionDigits: 2,
            currency: '$',
            lessThanFormat: true,
          })
        : null,
    [availableForBorrowing, price],
  );

  const onRowClick = useCallback(() => {
    navigate(`/asset/${token}${search}`);
  }, [token, search, navigate]);

  const dataNotLoaded = !price || !assetTier || !totalSupply || !totalBorrow || !availableForBorrowing;

  if (dataNotLoaded) {
    return (
      <TableRow id={`market-row-${token}`}>
        <TableBodyCell>
          <div className="nostra__market-table-row__cell nostra__market-table-row__market">
            <LoadingPlaceholder shape={{ circle: 'medium' }} />
            <div className="nostra__market-table-row__market-token">
              <LoadingPlaceholder shape={{ width: 'large', height: 'small' }} />
              <LoadingPlaceholder shape={{ width: 'small', height: 'small' }} />
            </div>
          </div>
        </TableBodyCell>
        <TableBodyCell align="center">
          <LoadingPlaceholder shape={{ width: 'medium', height: 'small' }} />
        </TableBodyCell>
        <TableBodyCell align="right">
          <LoadingPlaceholder shape={{ width: 'medium', height: 'small' }} />
        </TableBodyCell>
        <TableBodyCell align="right">
          <LoadingPlaceholder shape={{ width: 'medium', height: 'small' }} />
        </TableBodyCell>
        <TableBodyCell align="right">
          <LoadingPlaceholder shape={{ width: 'medium', height: 'small' }} />
        </TableBodyCell>
        <TableBodyCell align="right">
          <div className="nostra__market-table-row__multiple-values">
            <LoadingPlaceholder shape={{ width: 'large', height: 'small' }} />
            <LoadingPlaceholder shape={{ width: 'small', height: 'small' }} />
          </div>
        </TableBodyCell>
        <TableBodyCell align="right">
          <div className="nostra__market-table-row__multiple-values">
            <LoadingPlaceholder shape={{ width: 'large', height: 'small' }} />
            <LoadingPlaceholder shape={{ width: 'small', height: 'small' }} />
          </div>
        </TableBodyCell>
        <TableBodyCell align="right">
          <div className="nostra__market-table-row__multiple-values">
            <LoadingPlaceholder shape={{ width: 'large', height: 'small' }} />
            <LoadingPlaceholder shape={{ width: 'small', height: 'small' }} />
          </div>
        </TableBodyCell>
      </TableRow>
    );
  }

  return (
    <TableRow id={`market-row-${token}`} onClick={onRowClick}>
      <TableBodyCell>
        <div className="nostra__market-table-row__cell nostra__market-table-row__market">
          <Logo type={`token-${token}` as LogoType} />
          <TooltipWrapper
            tooltipContent={<MarketsAssetContractsTooltip asset={asset} />}
            openEvent="mouseenter"
            placement="bottom-start"
          >
            <div className="nostra__market-table-row__market-token">
              <Typography variant="body-primary">{token}</Typography>
              <Typography variant="body-tertiary" color="text-disabled">
                {tokenName}
              </Typography>
            </div>
          </TooltipWrapper>
        </div>
      </TableBodyCell>
      <TableBodyCell align="center">
        <AssetTierPill tier={assetTier} />
      </TableBodyCell>
      <TableBodyCell align="right">
        {formattedPrice ? (
          <Typography variant="body-primary">{formattedPrice}</Typography>
        ) : (
          <Loading color="primary" size={16} />
        )}
      </TableBodyCell>
      <TableBodyCell align="right">
        <Typography variant="body-primary" color="text-supply-apy">
          {formattedSupplyApy}
        </Typography>
      </TableBodyCell>
      <TableBodyCell align="right">
        <Typography variant="body-primary" color="text-borrow-apy">
          {formattedBorrowApy}
        </Typography>
      </TableBodyCell>
      <TableBodyCell align="right">
        {formattedUsdTotalSupply && formattedTotalSupply ? (
          <div className="nostra__market-table-row__multiple-values">
            <Typography variant="body-primary">{formattedUsdTotalSupply}</Typography>
            <Typography variant="body-tertiary" color="text-disabled">
              {formattedTotalSupply}
            </Typography>
          </div>
        ) : (
          <Loading color="primary" size={16} />
        )}
      </TableBodyCell>
      <TableBodyCell align="right">
        <div className="nostra__market-table-row__multiple-values">
          {formattedUsdTotalBorrow && formattedTotalBorrow ? (
            <>
              <Typography variant="body-primary">{formattedUsdTotalBorrow}</Typography>
              <Typography variant="body-tertiary" color="text-disabled">
                {formattedTotalBorrow}
              </Typography>
            </>
          ) : (
            <Loading color="primary" size={16} />
          )}
        </div>
      </TableBodyCell>
      <TableBodyCell align="right">
        <div className="nostra__market-table-row__multiple-values">
          {formattedUsdAvailableForBorrowing && formattedAvailableForBorrowing ? (
            <>
              <Typography variant="body-primary">{formattedUsdAvailableForBorrowing}</Typography>
              <Typography variant="body-tertiary" color="text-disabled">
                {formattedAvailableForBorrowing}
              </Typography>
            </>
          ) : (
            <Loading color="primary" size={16} />
          )}
        </div>
      </TableBodyCell>
    </TableRow>
  );
};

export default MarketsTableRow;
