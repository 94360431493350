import { FC, memo } from 'react';
import SummaryMarketOverview from './SummaryMarketOverview';
import SummaryMarketBorrow from './SummaryMarketBorrow';
import SummaryMarketSupply from './SummaryMarketSupply';

import './Summary.scss';

const Summary: FC = () => (
  <div className="nostra__summary">
    <SummaryMarketSupply />
    <SummaryMarketOverview />
    <SummaryMarketBorrow />
  </div>
);

export default memo(Summary);
