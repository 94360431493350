import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import isMobile from 'is-mobile';
import { datadogLogs } from '@datadog/browser-logs';
import Main from './components';
import reportWebVitals from './reportWebVitals';
import Analytics from './components/Analytics/Analytics';
import { ActionModalResolver } from './components/ActionModal/ActionModalResolver';
import AccountManagement from './components/AccountManagement/AccountManagement';
import Background from './components/Main/Background';
import Header from './components/Header';
import Footer from './components/Footer';
import { NotificationCenter } from './components/Notification';
import { HookSubscriber } from './components/HookSubscriber';
import Terms from './components/Terms';
import WideMessage from './components/WideMessage';
import AlphaPopup from './components/AlphaPopup';
import MobileBanner from './components/MobileBanner';
import UnsupportedNetworkPopup from './components/UnsupportedNetworkPopup';
import TransactionHistory from './components/TransactionHistory';

import './i18n';

import './components/shared/Colors';
import './components/shared/Shadow';

import './index.scss';

const mobile = isMobile();

const wideMessageLink = 'https://app.nostra.finance/';
const wideMessageLinkText = 'Nostra Money Market';

// Initialize DataDog log collection library
if (process.env.REACT_APP_DATADOG_CLIENT_TOKEN) {
  datadogLogs.init({
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.eu',
    forwardErrorsToLogs: true,
    sampleRate: 100,
  });
}

if (mobile) {
  ReactDOM.render(
    <React.StrictMode>
      <MobileBanner />
    </React.StrictMode>,
    document.getElementById('root'),
  );
} else {
  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
        <AlphaPopup />
        <UnsupportedNetworkPopup />
        <WideMessage icon="info-bordered" link={wideMessageLink} linkText={wideMessageLinkText} />
        <div className="nostra__app__root">
          <Background />
          <Header />
          <Routes>
            <Route path="/accounts/:accountId/transactions" element={<TransactionHistory />} />
            <Route path="/accounts" element={<AccountManagement />} />
            <Route path="/analytics/:token" element={<Analytics />} />
            <Route path="/analytics" element={<Analytics />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/" element={<Main />}>
              <Route path="asset/:ticker/:action" element={<ActionModalResolver />} />
              <Route path="asset/:ticker" element={<ActionModalResolver />} />
            </Route>
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          <Footer />
          <NotificationCenter />
          <HookSubscriber />
        </div>
      </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root'),
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
