import { createRef, forwardRef, KeyboardEvent, memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { renameAccount, useWalletAccount } from '../../../../hooks';
import { BaseInput, colors, Icon, Tooltip, Typography } from '../../../shared';

import './AccountNameInputField.scss';

interface AccountNameInputFieldProps {
  accountId: number;
  paddedAccountId: string;
  accountName: string;
  onNameChange: () => void;
}

const AccountNameInputField = forwardRef<HTMLInputElement, AccountNameInputFieldProps>((props, ref) => {
  const { accountId, paddedAccountId, accountName, onNameChange } = props;
  const walletAccount = useWalletAccount();
  const { t } = useTranslation();

  const nameInputRef = createRef<HTMLInputElement>();

  const [newName, setNewName] = useState<string>(accountName);
  const [errorShown, setErrorShown] = useState(false);

  const isValidName = useCallback((name: string) => Boolean(name.trim()), []);

  const saveNewName = useCallback(() => {
    if (!walletAccount) {
      return;
    }

    if (isValidName(newName)) {
      renameAccount(walletAccount.address, accountId, newName);
      onNameChange();
    } else {
      setErrorShown(true);
    }
  }, [walletAccount, isValidName, newName, accountId, onNameChange]);

  const handleKeyDown = useCallback(
    (ev: KeyboardEvent<HTMLInputElement>) => {
      switch (ev.key) {
        case 'Enter':
          saveNewName();
          break;

        case 'Escape':
          setErrorShown(false);
          onNameChange();
          break;
      }
    },
    [onNameChange, saveNewName],
  );

  const handleNameChange = useCallback(
    (name: string) => {
      if (!isValidName(name)) {
        setErrorShown(true);
      } else if (errorShown) {
        setErrorShown(false);
      }

      setNewName(name);
    },
    [errorShown, isValidName],
  );

  return (
    <div className="nostra__account-name-input-field__container">
      <Tooltip variant="error" open={errorShown}>
        <div className="nostra__account-name-input-field__error-tooltip">
          <Icon variant="exclamation-error" size="small" color={colors.textError} />
          <Typography variant="body-secondary" color="text-secondary">
            {t('AccountNameInputField.emptyNameError')}
          </Typography>
        </div>
      </Tooltip>
      <div
        className={`nostra__account-name-input-field ${errorShown ? 'nostra__account-name-input-field__error' : ''}`}
      >
        <Typography className="nostra__account-name-input-field__value" variant="body-primary" weight="bold">
          <BaseInput
            ref={nameInputRef}
            value={newName}
            autoFocus
            onChange={handleNameChange}
            onBlur={saveNewName}
            onKeyDown={handleKeyDown}
          />
        </Typography>
        <Typography className="nostra__account-name-input-field__id" variant="body-tertiary" color="text-wallet">
          ID: {paddedAccountId}
        </Typography>
      </div>
    </div>
  );
});

export default memo(AccountNameInputField);
