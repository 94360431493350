import { FC } from 'react';
import { Nullable } from '../../../interfaces';
import { LoadingPlaceholder, LoadingPlaceholderWidth, Typography } from '../../shared';

import './SummaryBoxSideValue.scss';

interface SummaryBoxSideValueProps {
  title: string;
  value: Nullable<string>;
  placeholderWidth: LoadingPlaceholderWidth;
}

const SummaryBoxSideValue: FC<SummaryBoxSideValueProps> = props => {
  const { title, value, placeholderWidth } = props;

  return (
    <div className="nostra__summary-box__side-value">
      <Typography variant="body-primary" color="text-secondary">
        {title}
      </Typography>
      {value ? (
        <Typography variant="body-primary" weight="bold" color="text-primary-accent">
          {value}
        </Typography>
      ) : (
        <LoadingPlaceholder shape={{ width: placeholderWidth, height: 'small' }} />
      )}
    </div>
  );
};

export default SummaryBoxSideValue;
