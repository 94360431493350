import { formatInTimeZone } from 'date-fns-tz';
import { FC, memo, useMemo } from 'react';
import { DecimalUtils } from '../../../utils';
import { DecimalUtilsCurrency } from '../../../utils/decimalUtils';
import Typography from '../Typography';
import { LabelFormat } from './DateChart';

interface Payload {
  payload: {
    x: number;
    y1?: number;
    y2?: number;
  };
}

interface TooltipProps {
  payload?: Payload[];
  valueFormat?: LabelFormat;
  timeFormat?: string;
  currencySymbol?: DecimalUtilsCurrency;
  valueLabel1?: string;
  valueLabel2?: string;
}

const formatValue = (value: number, valueFormat?: LabelFormat, currencySymbol?: DecimalUtilsCurrency) => {
  let formattedValue = '';
  switch (valueFormat) {
    case 'decimal':
      if (currencySymbol) {
        formattedValue = DecimalUtils.format(value, {
          style: 'currency',
          fractionDigits: 2,
          lessThanFormat: true,
          currency: currencySymbol,
        });
      } else {
        formattedValue = DecimalUtils.format(value, {
          style: 'decimal',
          fractionDigits: 0,
          lessThanFormat: true,
        });
      }
      break;
    case 'multiplier':
      formattedValue = DecimalUtils.format(value, {
        style: 'multiplier',
        fractionDigits: 2,
        noMultiplierFractionDigits: 2,
        lessThanFormat: true,
        currency: currencySymbol,
      });
      break;
    case 'percentage':
      formattedValue = DecimalUtils.format(value, {
        style: 'percentage',
        fractionDigits: 2,
        lessThanFormat: false,
      });
  }

  return formattedValue;
};

const ChartTooltip: FC<TooltipProps> = ({
  payload,
  valueFormat,
  timeFormat = 'd MMMM y',
  currencySymbol,
  valueLabel1,
  valueLabel2,
}) => {
  const timestampFormatted = useMemo(() => {
    if (!payload || !payload[0]) {
      return null;
    }

    const timestamp = payload[0].payload.x;

    return formatInTimeZone(timestamp, 'UTC', timeFormat);
  }, [payload, timeFormat]);

  const value1 = useMemo(() => payload?.[0]?.payload?.y1 ?? null, [payload]);
  const value2 = useMemo(() => payload?.[0]?.payload?.y2 ?? null, [payload]);
  const showStackedValues = useMemo(() => value1 !== null && value2 !== null, [value1, value2]);
  const value1Formatted = useMemo(
    () => (value1 ? formatValue(value1, valueFormat, currencySymbol) : null),
    [currencySymbol, value1, valueFormat],
  );
  const value2Formatted = useMemo(
    () => (value2 ? formatValue(value2, valueFormat, currencySymbol) : null),
    [currencySymbol, value2, valueFormat],
  );

  return (
    <div className="nostra__chart__tooltip">
      <Typography variant="body-tertiary" color="text-primary-inverted">
        {timestampFormatted}
      </Typography>
      {showStackedValues && (
        <>
          <Typography className="nostra__chart__tooltip__value1" variant="subtitle" color="text-primary-inverted">
            {valueLabel1 && `${valueLabel1}: `}
            {value1Formatted}
          </Typography>
          <Typography className="nostra__chart__tooltip__value2" variant="subtitle" color="text-primary-inverted">
            {valueLabel2 && `${valueLabel2}: `}
            {value2Formatted}
          </Typography>
        </>
      )}
      {!showStackedValues && (
        <Typography variant="subtitle" color="text-primary-inverted">
          {value1Formatted}
        </Typography>
      )}
    </div>
  );
};

export default memo(ChartTooltip);
