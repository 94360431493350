import { connect, ConnectOptions, disconnect } from 'get-starknet';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { updateWallet, useWalletAccount, useWallet } from '../../hooks';
import { ActionButtonVariant, WalletButton } from '../shared';
import WalletPopup from './WalletPopup/WalletPopup';
import { getLastWallet } from '../../utils';

import './Wallet.scss';

export interface WalletProps {
  connectWalletButtonLabel: string;
  connectWalletButtonVariant?: ActionButtonVariant;
  connectWalletButtonFullWidth?: boolean;
  onConnectWalletClick?: () => void;
  redirectTo?: string;
}

const Wallet: FC<WalletProps> = props => {
  const {
    connectWalletButtonVariant = 'primary',
    connectWalletButtonFullWidth,
    connectWalletButtonLabel,
    onConnectWalletClick,
    redirectTo,
  } = props;

  const navigate = useNavigate();
  const { search } = useLocation();

  const [popupOpen, setPopupOpen] = useState<boolean>(false);
  const [popupOpenForAnimation, setPopupOpenForAnimation] = useState(false);
  const wallet = useWallet();
  const walletAccount = useWalletAccount();

  const connectWallet = useCallback(async (disconnectFirst?: boolean, options?: ConnectOptions) => {
    if (disconnectFirst) {
      disconnect({
        clearLastWallet: true,
      });
    }

    const newWallet = await connect(options);

    if (newWallet) {
      updateWallet(newWallet);
    }

    return newWallet !== null;
  }, []);

  const handleAccountChangeEvent = useCallback(() => {
    setTimeout(async () => {
      connectWallet(false, { modalMode: 'neverAsk' });
    }, 0);
  }, [connectWallet]);

  /**
   * Checks connection on app load - if user already connected wallet in previous session,
   * wallet will be automatically reconnected
   */
  useEffect(() => {
    const checkConnection = async () => {
      const lastWallet = getLastWallet();

      if (lastWallet) {
        // Workaround to wait for starknet object to get injected in window object before asking get-starknet to read for it
        // See https://github.com/starknet-io/get-starknet/issues/166
        setTimeout(async () => {
          connectWallet(false, { modalMode: 'neverAsk' });
        }, 0);
      }
    };

    checkConnection();
  }, [connectWallet]);

  useEffect(() => {
    if (wallet) {
      wallet.on('accountsChanged', handleAccountChangeEvent);
      wallet.on('networkChanged', handleAccountChangeEvent);

      return () => {
        wallet.off('accountsChanged', handleAccountChangeEvent);
        wallet.off('networkChanged', handleAccountChangeEvent);
      };
    }
  }, [handleAccountChangeEvent, wallet]);

  /**
   * Called when user clicks on the Connect Wallet button
   */
  const handleConnect = useCallback(() => {
    onConnectWalletClick?.();

    setTimeout(async () => {
      const successfullyConnected = await connectWallet(true);

      if (successfullyConnected && redirectTo) {
        navigate(`${redirectTo}${search}`);
      }
    }, 200);
  }, [connectWallet, navigate, onConnectWalletClick, redirectTo, search]);

  const handleSwitchWallet = useCallback(async () => {
    await connectWallet(true);
  }, [connectWallet]);

  const handleWalletDisconnect = useCallback(() => {
    disconnect({
      clearLastWallet: true,
    });

    // Passing null will clear app wallet state
    updateWallet(null);
  }, []);

  const openWalletPopup = useCallback(async () => {
    setPopupOpen(true);
    await new Promise(resolve => setTimeout(resolve, 100));
    setPopupOpenForAnimation(true);
  }, []);

  const closeWalletPopup = useCallback(async () => {
    setPopupOpenForAnimation(false);
    await new Promise(resolve => setTimeout(resolve, 400));
    setPopupOpen(false);
  }, []);

  return (
    <>
      <WalletButton
        label={connectWalletButtonLabel}
        connected={Boolean(walletAccount)}
        onConnect={handleConnect}
        onWalletClick={openWalletPopup}
        walletPopupOpen={popupOpen}
        connectWalletButtonFullWidth={connectWalletButtonFullWidth}
        connectWalletButtonVariant={connectWalletButtonVariant}
      />
      {popupOpen && (
        <WalletPopup
          onSwitchWallet={handleSwitchWallet}
          onDisconnectWallet={handleWalletDisconnect}
          open={popupOpenForAnimation}
          onClose={closeWalletPopup}
        />
      )}
    </>
  );
};

export default memo(Wallet);
