import { FC, useCallback, useState } from 'react';
import { Menu, MenuWrapper, ButtonWrapper, Typography, Icon, colors } from '../shared';

interface TransactionHistoryDropdownProps {
  label: string;
  value: number;
}

const TransactionHistoryDropdown: FC<TransactionHistoryDropdownProps> = props => {
  const { label, value, children } = props;

  const [open, setOpen] = useState<boolean>(false);

  const onToggle = useCallback(() => setOpen(state => !state), []);

  return (
    <div className="nostra__transaction-history__dropdown">
      <MenuWrapper>
        <ButtonWrapper onClick={onToggle}>
          <Typography variant="body-secondary" color="text-light">
            {label}
          </Typography>
          <Typography className="nostra__transaction-history__dropdown-value" variant="body-secondary">
            {value}
          </Typography>
          <Icon variant={open ? 'up-chevron' : 'down-chevron'} size="tiny" color={colors.textLight} />
        </ButtonWrapper>
        <Menu open={open} onClose={onToggle}>
          {children}
        </Menu>
      </MenuWrapper>
    </div>
  );
};
export default TransactionHistoryDropdown;
