import { ApolloClient, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { Nullable } from '../interfaces';

let instance: Nullable<ApolloClient<NormalizedCacheObject>> = null;

export const getGraphqlClient = (): ApolloClient<NormalizedCacheObject> => {
  if (instance) {
    return instance;
  }

  instance = new ApolloClient({
    uri: process.env.REACT_APP_GRAPHQL_ENDPOINT_URL,
    cache: new InMemoryCache(),
  });

  return instance;
};
