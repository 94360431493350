import { FC } from 'react';
import { InnerIconProps } from './IconProps';
import withIcon from './withIcon';

const InfoBorderedRounded: FC<InnerIconProps> = ({ size, color }) => (
  <svg
    className="nostra__icon nostra__icon-info-bordered-rounded"
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 18.75C14.8325 18.75 18.75 14.8325 18.75 10C18.75 5.16751 14.8325 1.25 10 1.25C5.16751 1.25 1.25 5.16751 1.25 10C1.25 14.8325 5.16751 18.75 10 18.75ZM10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
      fill={color}
    />
    <path
      d="M11.2497 13.7461V10.0175C11.2497 9.32481 10.6902 8.76325 9.99987 8.76325C9.30949 8.76325 8.74986 9.32497 8.75 10.0178L8.75075 13.7463C8.75089 14.4252 9.28934 14.9806 9.96554 14.9995C10.6689 15.0191 11.2497 14.4522 11.2497 13.7461Z"
      fill={color}
    />
    <path
      d="M8.75037 6.25379C8.75003 6.94666 9.30963 7.50883 10.0001 7.50883C10.6902 7.50883 11.2497 6.94704 11.2497 6.25442C11.2497 5.5618 10.6902 5 10.0001 5C9.31012 5 8.75072 5.56141 8.75037 6.25379Z"
      fill={color}
    />
  </svg>
);

export default withIcon(InfoBorderedRounded);
