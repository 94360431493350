import { AssetConfig } from '../interfaces';

interface FlagsData {
  collateral: boolean;
  lend: boolean;
}

export function getFlagsFromNostraToken(asset: AssetConfig, nostraToken: string): FlagsData {
  if (nostraToken === asset.nostraTokenAddress) {
    return {
      collateral: false,
      lend: false,
    };
  }

  if (nostraToken === asset.nostraCollateralTokenAddress) {
    return {
      collateral: true,
      lend: false,
    };
  }

  if (nostraToken === asset.nostraInterestBearingTokenAddress) {
    return {
      collateral: false,
      lend: true,
    };
  }

  if (nostraToken === asset.nostraInterestBearingCollateralTokenAddress) {
    return {
      collateral: true,
      lend: true,
    };
  }

  throw new Error(`Failed to get flags from nostra token ${nostraToken}, for asset ${asset.address}!`);
}
