import { ApolloQueryResult, gql } from '@apollo/client';
import { bind } from '@react-rxjs/core';
import { createSignal } from '@react-rxjs/utils';
import { catchError, combineLatest, concatMap, from, map, of, Subscription, tap } from 'rxjs';
import { GraphqlQuery, Nullable, ParsedChartData } from '../interfaces';
import { getGraphqlClient } from '../services';
import { downsampleChartData } from '../utils';

const DEFAULT_VALUE = null;

const [fromTotalUniqueLendersChartDate$, setFromTotalUniqueLendersChartDate] = createSignal<Nullable<number>>();
const [rawChartData$, setRawChartData] = createSignal<Nullable<ParsedChartData[]>>();

const totalUniqueLendersChartData$ = combineLatest([fromTotalUniqueLendersChartDate$]).pipe(
  concatMap(([fromTotalUniqueLendersChartDate]) => {
    try {
      setRawChartData(null);

      const graphqlClient = getGraphqlClient();

      const searchCriteria = fromTotalUniqueLendersChartDate
        ? `(fromTimestamp: "${new Date(fromTotalUniqueLendersChartDate).toISOString()}")`
        : '';

      return from(
        graphqlClient.query<GraphqlQuery>({
          query: gql`
        query GetTotalUniqueLendersChartData {
            totalUniqueLenders ${searchCriteria} {
                timestamp
                totalUniqueLenders
          }
        }
        `,
        }),
      ).pipe(
        catchError(error => {
          console.error('Error while fetching total unique lenders chart data!', error);
          return of(null);
        }),
      );
    } catch (error) {
      console.error('Error while fetching total unique lenders chart data!', error);
      return of(null);
    }
  }),
  map<Nullable<ApolloQueryResult<GraphqlQuery>>, Nullable<ParsedChartData[]>>(result => {
    if (!result) {
      return null;
    }

    if (!result.data.totalUniqueLenders.length) {
      return [];
    }

    try {
      const downsampledData = downsampleChartData(
        result.data.totalUniqueLenders.map(dataEntry => {
          return {
            timestamp: new Date(dataEntry.timestamp).getTime(),
            value: dataEntry.totalUniqueLenders,
          };
        }),
      );

      return downsampledData.map(downSampledDataEntry => ({
        value: downSampledDataEntry.value,
        timestamp: downSampledDataEntry.timestamp,
      }));
    } catch (error) {
      console.error('Error while fetching total unique lenders chart data!', error);
      return DEFAULT_VALUE;
    }
  }),
  tap(chartData => setRawChartData(chartData)),
);

export const [useTotalUniqueLendersChartData] = bind(rawChartData$, DEFAULT_VALUE);

let subscription: Nullable<Subscription> = null;

export const subscribeTotalUniqueLendersChartData = (): void => {
  unsubscribeTotalUniqueLendersChartData();
  subscription = totalUniqueLendersChartData$.subscribe();
};
export const unsubscribeTotalUniqueLendersChartData = (): void => subscription?.unsubscribe();

export { setFromTotalUniqueLendersChartDate };
