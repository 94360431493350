import { FC, MouseEvent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Asset } from '../../../interfaces';
import { colors, Icon, Typography } from '../../shared';
import MarketsAssetContract from './MarketsAssetContract';

import './MarketsAssetContractsTooltip.scss';

interface MarketsAssetContractsTooltipProps {
  asset: Asset;
}

const MarketsAssetContractsTooltip: FC<MarketsAssetContractsTooltipProps> = props => {
  const { asset } = props;

  const { t } = useTranslation();

  const contracts = useMemo(
    () => [
      {
        address: asset.nostraTokenAddress,
        name: asset.nostraTokenSymbol,
      },
      {
        address: asset.nostraCollateralTokenAddress,
        name: asset.nostraCollateralTokenSymbol,
      },
      {
        address: asset.nostraInterestBearingTokenAddress,
        name: asset.nostraInterestBearingTokenSymbol,
      },
      {
        address: asset.nostraInterestBearingCollateralTokenAddress,
        name: asset.nostraInterestBearingCollateralTokenSymbol,
      },
      {
        address: asset.debtTokenAddress,
        name: asset.debtTokenSymbol,
      },
    ],
    [asset],
  );

  const handleTooltipClick = useCallback((event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  }, []);

  return (
    <div className="nostra__markets-asset-contracts-tooltip" onClick={handleTooltipClick}>
      <div className="nostra__markets-asset-contracts-tooltip__header">
        <Icon variant="info-bordered-rounded" color={colors.textHighlight} size={20} />
        <Typography variant="body-primary" weight="bold">
          {t('Markets.contractsTitle')}
        </Typography>
      </div>
      <div className="nostra__markets-asset-contracts-tooltip__contracts">
        {contracts.map(contract => (
          <MarketsAssetContract {...contract} key={contract.address} />
        ))}
      </div>
    </div>
  );
};

export default MarketsAssetContractsTooltip;
