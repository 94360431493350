import { FC, memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  setFromTotalAssetsChartDate,
  setFromTotalDebtChartDate,
  useChartCurrency,
  useSelectedChartToken,
  useTotalAssetsChartData,
  useTotalDebtChartData,
} from '../../../hooks';
import { Nullable } from '../../../interfaces';
import Graph from '../Graph';

const TotalAssetsAndDebt: FC = () => {
  const { t } = useTranslation();

  const totalAssetsChartData = useTotalAssetsChartData();
  const totalDebtChartData = useTotalDebtChartData();
  const [selectedChartToken] = useSelectedChartToken();
  const [chartCurrency] = useChartCurrency();

  const [fromTimestamp, setFromTimestamp] = useState<Nullable<number>>(-1);

  const handleDataFromChange = useCallback((fromTimestamp: Nullable<number>) => {
    setFromTimestamp(fromTimestamp);
  }, []);

  useEffect(() => {
    if (fromTimestamp === -1) {
      return;
    }

    setFromTotalAssetsChartDate(fromTimestamp);
    setFromTotalDebtChartDate(fromTimestamp);
  }, [fromTimestamp]);

  return (
    <Graph
      title={t('Analytics.titleTotalAssetsAndDebt')}
      titleLogo={selectedChartToken ? `token-${selectedChartToken}` : undefined}
      parsedChartData1={totalAssetsChartData}
      parsedChartData2={totalDebtChartData}
      yAxisLabel={chartCurrency}
      yAxisLabelFormatting="multiplier"
      currencySymbol="$"
      tooltipValueLabel1={t('Analytics.labelAssets')}
      tooltipValueLabel2={t('Analytics.labelDebt')}
      onDateFromChange={handleDataFromChange}
    />
  );
};

export default memo(TotalAssetsAndDebt);
