import { AccountInterface, Contract, ProviderInterface } from 'starknet';
import { ContractBase, getDefaultProvider } from '../services';

export class ContractCache<C extends Contract, T extends ContractBase<C>> {
  private cache: Map<string, T> = new Map();
  private contractBuilder: (address: string, providerOrAccount: ProviderInterface | AccountInterface) => T;

  public constructor(contractBuilder: (address: string, providerOrAccount: ProviderInterface | AccountInterface) => T) {
    this.contractBuilder = contractBuilder;
  }

  public get(address: string, account?: AccountInterface): T {
    const contractAddressAccountAddress = `${address}${account ? `-${account.address}` : ''}`;
    const contract = this.cache.get(contractAddressAccountAddress);

    if (contract) {
      return contract;
    }

    const providerOrAccount = account ?? getDefaultProvider();
    const newContract = this.contractBuilder(address, providerOrAccount);

    this.cache.set(contractAddressAccountAddress, newContract);

    return newContract;
  }
}
