import { FC, memo, ReactNode, useMemo } from 'react';
import { Decimal } from '../../../datastructures';
import { HEALTH_FACTOR_DANGER, HEALTH_FACTOR_INFINITY, HEALTH_FACTOR_WARNING } from '../../../constants';
import Icon from '../Icon';
import Typography, { TypographyColor } from '../Typography';
import { colors } from '../Colors';
import TooltipWrapper from '../TooltipWrapper';

import './HealthFactor.scss';

type HealthFactorSize = 'large' | 'small';

interface HealthFactorProps {
  healthFactor: Decimal;
  showStatus?: boolean;
  tooltip?: ReactNode | string;
  size?: HealthFactorSize;
}

const HealthFactor: FC<HealthFactorProps> = props => {
  const { healthFactor, showStatus = false, tooltip = '', size = 'small' } = props;

  const healthFactorTypographyVariant = useMemo(() => {
    switch (size) {
      case 'large':
        return 'body-primary';
      case 'small':
        return 'body-tertiary';
    }
  }, [size]);

  const healthFactorTypographyWeight = useMemo(() => {
    switch (size) {
      case 'large':
        return 'bold';
      case 'small':
        return 'regular';
    }
  }, [size]);

  const healthFactorColor: TypographyColor = useMemo(() => {
    if (showStatus) {
      return 'text-primary';
    }

    if (healthFactor.lt(HEALTH_FACTOR_DANGER)) {
      return 'text-error';
    }

    if (healthFactor.lte(HEALTH_FACTOR_WARNING)) {
      return 'text-caption';
    }

    return 'text-success';
  }, [healthFactor, showStatus]);

  const healthFactorStatus = useMemo(() => {
    if (healthFactor.lt(HEALTH_FACTOR_DANGER)) {
      return 'low';
    }

    if (healthFactor.lte(HEALTH_FACTOR_WARNING)) {
      return 'medium';
    }

    return 'high';
  }, [healthFactor]);

  const healthFactorContent = useMemo(() => {
    if (healthFactor.gte(HEALTH_FACTOR_INFINITY)) {
      const infinityColor = showStatus ? colors.textPrimary : colors.textSuccess;
      switch (size) {
        case 'large':
          return <Icon variant="infinity" color={infinityColor} size="medium" />;
        case 'small':
          return <Icon variant="infinity" color={infinityColor} size="small" />;
      }
    }
    return (
      <Typography
        variant={healthFactorTypographyVariant}
        weight={healthFactorTypographyWeight}
        color={healthFactorColor}
      >
        {healthFactor.toTruncated(2)}
      </Typography>
    );
  }, [showStatus, healthFactor, healthFactorColor, healthFactorTypographyVariant, healthFactorTypographyWeight, size]);

  return (
    <div className="nostra__health-factor">
      <TooltipWrapper tooltipContent={tooltip} openEvent="mouseenter" placement="bottom-start">
        {showStatus && <div className="nostra__health-factor__indicator" data-status={healthFactorStatus} />}
        {healthFactorContent}
      </TooltipWrapper>
    </div>
  );
};

export default memo(HealthFactor);
