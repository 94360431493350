import { AccountInterface } from 'starknet';
import { Asset } from '../interfaces';
import {
  getNostraInterestTokenContract,
  getNostraTokenContract,
  NostraInterestTokenContract,
  NostraTokenContract,
} from '../services';

export function getNostraContract(
  asset: Asset,
  nostraToken: string,
  account: AccountInterface,
): NostraInterestTokenContract | NostraTokenContract {
  if (
    nostraToken === asset.nostraInterestBearingTokenAddress ||
    nostraToken === asset.nostraInterestBearingCollateralTokenAddress
  ) {
    return getNostraInterestTokenContract(nostraToken, account);
  }

  return getNostraTokenContract(nostraToken, account);
}
