import { bind } from '@react-rxjs/core';
import { BehaviorSubject, combineLatest, filter, Subscription, tap } from 'rxjs';
import { AccountInterface } from 'starknet';
import { getConfigManager } from '../config/getConfigManager';
import { Decimal, ONE, ZERO } from '../datastructures';
import { NostraToken } from '../interfaces';
import { walletAccount$ } from './useWalletAccount';
import { nostraTokenBalance$, NostraTokenBalanceMap } from './useNostraTokenBalance';
import { nostraTokenList$ } from './useNostraTokenList';
import { accountList$ } from './useAccounts';
import { TokenRateMap, tokenRates$ } from './useTokenRates';

interface AccountTotalAssetsMap {
  [walletAccountIdKey: string]: Decimal;
}

export const accountTotalAssets$ = new BehaviorSubject<AccountTotalAssetsMap>({});

type FetchStreamNonNullInputs = [NostraTokenBalanceMap, string[], AccountInterface, NostraToken[], TokenRateMap];

const fetchStream$ = combineLatest([
  nostraTokenBalance$,
  accountList$,
  walletAccount$,
  nostraTokenList$,
  tokenRates$,
]).pipe(
  filter((value): value is FetchStreamNonNullInputs => {
    const [, accounts, walletAccount] = value;
    return Boolean(accounts) && Boolean(walletAccount);
  }),
  tap(([nostraTokenBalance, accounts, walletAccount, nostraTokens, tokenRates]) => {
    const configManager = getConfigManager();

    const result: AccountTotalAssetsMap = {};
    accounts
      .filter(account => account !== '')
      .forEach((_, accountId) => {
        nostraTokens.forEach(nostraToken => {
          try {
            // Do not count debt tokens when calculating total assets for a account
            if (nostraToken.variant === 'debt') {
              return;
            }

            const accountTotalAssetsDataKey = `${walletAccount.address}-${accountId}`;
            const tokenBalanceKey = `${walletAccount.address}-${accountId}-${nostraToken.address}`;
            const tokenRate = tokenRates[configManager.getNostraTokenAssetAddress(nostraToken.address)] ?? ONE;
            const tokenBalance = nostraTokenBalance[tokenBalanceKey];

            if (!tokenBalance) {
              return;
            }

            result[accountTotalAssetsDataKey] = (result[accountTotalAssetsDataKey] ?? ZERO).add(
              tokenBalance.mul(tokenRate),
            );
          } catch (error) {
            console.error(
              `useAccountTotalAssets - error calculating total assets for account with id: ${accountId}`,
              error,
            );
          }
        });
      });

    accountTotalAssets$.next(result);
  }),
);

export const [useAccountTotalAssets] = bind(accountTotalAssets$, {});

let subscription: Subscription;
export const subscribeAccountTotalAssets = (): void => {
  unsubscribeAccountTotalAssets();
  subscription = fetchStream$.subscribe();
};

export const unsubscribeAccountTotalAssets = (): void => subscription?.unsubscribe();
export const resetAccountTotalAssets = (): void => {
  accountTotalAssets$.next({});
};
