import { FC, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { CURRENCY_ETH, CURRENCY_USD, useChartCurrency } from '../../../hooks';
import { ButtonWrapper, Typography } from '../../shared';

import './CurrencySwitcher.scss';

const CurrencySwitcher: FC = () => {
  const { t } = useTranslation();
  const [chartCurrency, setChartCurrency] = useChartCurrency();

  const onEthSwitched = useCallback(() => setChartCurrency(CURRENCY_ETH), [setChartCurrency]);
  const onUsdSwitched = useCallback(() => setChartCurrency(CURRENCY_USD), [setChartCurrency]);

  return (
    <div className="nostra__analytics__currency-switcher">
      <Typography className="nostra__analytics__currency-switcher__title" variant="body-primary" color="breadcrumb">
        {t('CurrencySwitcher.title')}
      </Typography>
      <ButtonWrapper onClick={onEthSwitched} disabled={chartCurrency === CURRENCY_ETH}>
        <Typography
          variant="body-secondary"
          color={chartCurrency === CURRENCY_ETH ? 'switcher-button-disabled' : 'switcher-button'}
        >
          ETH
        </Typography>
      </ButtonWrapper>
      <ButtonWrapper onClick={onUsdSwitched} disabled={chartCurrency === CURRENCY_USD}>
        <Typography
          variant="body-secondary"
          color={chartCurrency === CURRENCY_USD ? 'switcher-button-disabled' : 'switcher-button'}
        >
          USD
        </Typography>
      </ButtonWrapper>
    </div>
  );
};

export default memo(CurrencySwitcher);
