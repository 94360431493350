import { FC, memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  setFromTotalUtilizationRateChartDate,
  useSelectedChartToken,
  useTotalUtilizationRateChartData,
} from '../../../hooks';
import { Nullable } from '../../../interfaces';
import Graph from '../Graph';

const TotalUtilizationRate: FC = () => {
  const { t } = useTranslation();

  const totalUtilizationRateChartData = useTotalUtilizationRateChartData();
  const [selectedChartToken] = useSelectedChartToken();

  const [fromTimestamp, setFromTimestamp] = useState<Nullable<number>>(-1);

  const handleDataFromChange = useCallback((fromTimestamp: Nullable<number>) => {
    setFromTimestamp(fromTimestamp);
  }, []);

  useEffect(() => {
    if (fromTimestamp === -1) {
      return;
    }

    setFromTotalUtilizationRateChartDate(fromTimestamp);
  }, [fromTimestamp]);

  return (
    <Graph
      title={t('Analytics.titleTotalUtilizationRate')}
      titleLogo={selectedChartToken ? `token-${selectedChartToken}` : undefined}
      parsedChartData1={totalUtilizationRateChartData}
      yAxisLabelFormatting="percentage"
      tooltipValueFormatting="percentage"
      onDateFromChange={handleDataFromChange}
    />
  );
};

export default memo(TotalUtilizationRate);
