import { config } from './config';
import ConfigManager from './ConfigManager';

let configManager: ConfigManager | null = null;
export const getConfigManager = (): ConfigManager => {
  if (!configManager) {
    configManager = new ConfigManager(config);
  }
  return configManager;
};
