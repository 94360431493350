import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Link, ModalWrapper, Typography, ButtonPrimary, Checkbox, TooltipWrapper, Icon, colors } from '../shared';
import AlphaPopupAssetRow from './AlphaPopupAssetRow';

import './AlphaPopup.scss';

const STORAGE_KEY = 'alpha-popup-shown';

const AlphaPopup = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    const alreadyAcceptedTerms = localStorage.getItem(STORAGE_KEY);
    const isTermsPage = window.location.pathname === '/terms';

    setOpen(!alreadyAcceptedTerms && !isTermsPage);
  }, [location.pathname]);

  const handleModalClose = useCallback(() => {}, []);

  const handleTermsAccepted = useCallback(() => setTermsAccepted(!termsAccepted), [termsAccepted]);

  const handleAppAccess = useCallback(() => {
    localStorage.setItem(STORAGE_KEY, 'true');
    setOpen(false);
  }, []);

  return (
    <ModalWrapper open={open} onClose={handleModalClose}>
      <div className="nostra__alpha-popup">
        <Typography variant="title" weight="bold">
          Nostra Alpha
        </Typography>
        <div className="nostra__alpha-popup-divider-large" />
        <div className="nostra__alpha-popup-description">
          <Typography variant="body-secondary" color="text-secondary">
            Welcome to Nostra! You are entering the alpha version of Nostra. Only withdrawals and repayments of existing
            positions are available. If you want to use the latest version of Nostra please go to{' '}
            <Link href={'https://app.nostra.finance'}>app.nostra.finance.</Link>
          </Typography>
        </div>
        <Typography variant="subtitle" weight="bold">
          Alpha deposit limits
        </Typography>
        <div className="nostra__alpha-popup-divider-small" />
        <div className="nostra__alpha-popup-info-row nostra__alpha-popup-info-row-header">
          <div className="nostra__alpha-popup-asset-column">
            <Typography variant="body-tertiary" color="text-disabled">
              Asset
            </Typography>
          </div>
          <div className="nostra__alpha-popup-asset-tier-column">
            <Typography variant="body-tertiary" color="text-disabled">
              Asset tier
            </Typography>
          </div>
          <div className="nostra__alpha-popup-limit-column">
            <Typography variant="body-tertiary" color="text-disabled">
              Deposit limit
            </Typography>
          </div>
        </div>

        <AlphaPopupAssetRow token="ETH" tier="shared" depositLimit={0.004} />
        <div className="nostra__alpha-popup-row-divider" />
        <AlphaPopupAssetRow token="USDC" tier="shared" depositLimit={5} />
        <div className="nostra__alpha-popup-row-divider" />
        <AlphaPopupAssetRow token="USDT" tier="shared" depositLimit={5} />
        <div className="nostra__alpha-popup-row-divider" />
        <AlphaPopupAssetRow token="DAI" tier="cross" depositLimit={5} />
        <div className="nostra__alpha-popup-row-divider" />
        <AlphaPopupAssetRow token="WBTC" tier="isolated" depositLimit={0.0003} />

        <div className="nostra__alpha-popup__terms">
          <TooltipWrapper
            tooltipContent={
              !termsAccepted && (
                <div className="nostra__alpha-popup__terms-tooltip">
                  <Icon variant="info-bordered-rounded" color={colors.textHighlight} size={20} />
                  <Typography variant="body-primary">
                    You must accept the Terms & Conditions and Disclaimer in order to use Nostra Money Market.
                  </Typography>
                </div>
              )
            }
            openEvent="mouseenter"
            placement="bottom-start"
          >
            <Checkbox checked={termsAccepted} onChange={handleTermsAccepted} />
          </TooltipWrapper>
          <Typography variant="body-secondary">
            I have read and agree to the <Link href="/terms">Terms & Conditions</Link> and{' '}
            <Link href="https://nostra.finance/disclaimer/">Disclaimer</Link>.
          </Typography>
        </div>

        <div className="nostra__alpha-popup-action">
          <ButtonPrimary label="Get started" disabled={!termsAccepted} onClick={handleAppAccess} />
        </div>
      </div>
    </ModalWrapper>
  );
};
export default AlphaPopup;
