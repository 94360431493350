import { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useWalletAccount, useActiveAccountId, useBorrowPositions, useAccountTotalDebt } from '../../../hooks';
import { DecimalUtils } from '../../../utils';
import {
  LoadingPlaceholder,
  ShadowContainer,
  Table,
  TableBody,
  TableHead,
  TableHeadCell,
  Typography,
} from '../../shared';
import BorrowPositionRow from './BorrowPositionRow';
import BorrowPositionRowEmpty from './BorrowPositionRowEmpty';
import BorrowPositionRowSkeleton from './BorrowPositionRowSkeleton';

import './BorrowPositions.scss';

const BorrowPositions: FC = () => {
  const walletAccount = useWalletAccount();
  const activeAccountId = useActiveAccountId();
  const borrowPositions = useBorrowPositions();
  const accountTotalDebt = useAccountTotalDebt();
  const { t } = useTranslation();

  const totalDebtFormatted = useMemo(() => {
    const dataKey = `${walletAccount?.address}-${activeAccountId}`;
    const totalDebt = accountTotalDebt[dataKey];

    if (!totalDebt) {
      return null;
    }

    return DecimalUtils.format(totalDebt, {
      style: 'multiplier',
      fractionDigits: 2,
      currency: '$',
      lessThanFormat: true,
    });
  }, [walletAccount?.address, activeAccountId, accountTotalDebt]);

  return (
    <ShadowContainer className="nostra__positions__container nostra__borrow-positions">
      <div className="nostra__borrow-positions__header">
        <Typography variant="subheader" weight="bold">
          {t('BorrowPositions.title')}
        </Typography>
        <div className="nostra__borrow-positions__header-total-debt">
          {totalDebtFormatted && (
            <>
              <Typography variant="body-secondary" color="text-wallet">
                {t('BorrowPositions.titleTotalDebt')}
              </Typography>
              <Typography variant="body-primary" weight="bold">
                {totalDebtFormatted}
              </Typography>
            </>
          )}
          {!totalDebtFormatted && <LoadingPlaceholder shape={{ width: 'medium', height: 'small' }} />}
        </div>
      </div>
      <Table>
        <TableHead>
          <tr className="nostra__tableRow">
            <TableHeadCell label={t('BorrowPositions.headerMarket')} />
            <TableHeadCell label={t('BorrowPositions.headerBorrowed')} align="right" />
            <TableHeadCell label={t('BorrowPositions.headerAPY')} align="right" />
            <TableHeadCell label="" />
          </tr>
        </TableHead>
        <TableBody>
          {/* Show loading skeleton if borrow positions are still loading. */}
          {borrowPositions === null && <BorrowPositionRowSkeleton />}
          {/* Show table with data once borrow positions have loaded and user has positions. */}
          {borrowPositions &&
            borrowPositions.length > 0 &&
            borrowPositions.map(position => <BorrowPositionRow key={position.asset.address} position={position} />)}
          {/* If user does not have any borrow positions, show empty table. */}
          {borrowPositions && borrowPositions.length === 0 && <BorrowPositionRowEmpty />}
        </TableBody>
      </Table>
      {/* If user does not have any borrow positions, show explanation text. */}
      {borrowPositions && borrowPositions.length === 0 && (
        <div className="nostra__borrow-positions__empty">
          <Typography variant="body-primary">{t('BorrowPositions.strNoBorrows')}</Typography>
        </div>
      )}
    </ShadowContainer>
  );
};

export default memo(BorrowPositions);
