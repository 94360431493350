import { bind } from '@react-rxjs/core';
import { BehaviorSubject, combineLatest, filter, map, Subscription, tap } from 'rxjs';
import { AccountInterface } from 'starknet';
import { getAccountService } from '../services';
import { walletAccount$ } from './useWalletAccount';
import { activeAccountId$ } from './useActiveAccountId';
import { accountList$ } from './useAccounts';

const DEFAULT_SUB_ACCOUNT_NAME = '';

export const activeAccountName$ = new BehaviorSubject<string>(DEFAULT_SUB_ACCOUNT_NAME);

// Fetch account list on app load
const fetchStream$ = combineLatest([walletAccount$, activeAccountId$, accountList$]).pipe(
  filter((value): value is [AccountInterface, number, string[]] => {
    const [walletAccount, activeAccountId] = value;
    return Boolean(walletAccount) && activeAccountId !== null;
  }),
  map(([walletAccount, activeAccountId]) => {
    try {
      const accountService = getAccountService();

      return accountService.getActiveAccountName(walletAccount.address, activeAccountId);
    } catch (error) {
      console.error(
        `useActiveAccountName - failed to get active account name for ${walletAccount.address} with id ${activeAccountId}`,
        error,
      );
      return null;
    }
  }),
  tap(activeAccountName => {
    if (activeAccountName !== null) {
      activeAccountName$.next(activeAccountName);
    }
  }),
);

export const [useActiveAccountName] = bind(activeAccountName$, DEFAULT_SUB_ACCOUNT_NAME);

let subscription: Subscription;
export const subscribeActiveAccountName = (): void => {
  unsubscribeActiveAccountName();
  subscription = fetchStream$.subscribe();
};

export const unsubscribeActiveAccountName = (): void => subscription?.unsubscribe?.();
export const resetActiveAccountName = (): void => activeAccountName$.next(DEFAULT_SUB_ACCOUNT_NAME);
