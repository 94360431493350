import { ApolloQueryResult, gql } from '@apollo/client';
import { bind } from '@react-rxjs/core';
import { createSignal } from '@react-rxjs/utils';
import { catchError, combineLatest, concatMap, from, map, of, Subscription, tap } from 'rxjs';
import { GraphqlQuery, Nullable, ParsedChartData, RawChartData } from '../interfaces';
import { getGraphqlClient } from '../services';
import { downsampleChartData } from '../utils';
import { chartCurrency$, ChartCurrencyType, CURRENCY_ETH, CURRENCY_USD } from './useChartCurrency';
import { selectedChartToken$ } from './useSelectedChartToken';

const DEFAULT_VALUE = null;

const [fromTotalAssetsChartDate$, setFromTotalAssetsChartDate] = createSignal<Nullable<number>>();
const [rawChartData$, setRawChartData] = createSignal<Nullable<RawChartData[]>>();

const totalAssetsChartData$ = combineLatest([fromTotalAssetsChartDate$, selectedChartToken$]).pipe(
  concatMap(([fromTotalAssetsChartDate, selectedChartToken]) => {
    try {
      setRawChartData(null);

      const graphqlClient = getGraphqlClient();

      const filters = [];
      if (fromTotalAssetsChartDate) {
        filters.push(`fromTimestamp: "${new Date(fromTotalAssetsChartDate).toISOString()}"`);
      }
      if (selectedChartToken) {
        filters.push(`token: "${selectedChartToken}"`);
      }

      const searchCriteria = filters.length > 0 ? `(${filters.join(', ')})` : '';

      return from(
        graphqlClient.query<GraphqlQuery>({
          query: gql`
        query GetTotalAssetsChartData {
          totalAssets ${searchCriteria} {
            timestamp
            totalAssetsEth
            totalAssetsUsd
          }
        }
        `,
        }),
      ).pipe(
        catchError(error => {
          console.error('Error while fetching total assets chart data!', error);
          return of(null);
        }),
      );
    } catch (error) {
      console.error('Error while fetching total assets chart data!', error);
      return of(null);
    }
  }),
  map<Nullable<ApolloQueryResult<GraphqlQuery>>, Nullable<RawChartData[]>>(result => {
    if (!result) {
      return null;
    }

    if (!result.data.totalAssets.length) {
      return [];
    }

    return result.data.totalAssets.map(dataEntry => {
      return {
        timestamp: new Date(dataEntry.timestamp).getTime(),
        valueInEth: dataEntry.totalAssetsEth,
        valueInUsd: dataEntry.totalAssetsUsd,
      };
    });
  }),
  tap(chartData => setRawChartData(chartData)),
);

const chartData$ = combineLatest([rawChartData$, chartCurrency$]).pipe(
  map<[Nullable<RawChartData[]>, ChartCurrencyType], Nullable<ParsedChartData[]>>(([rawChartData, chartCurrency]) => {
    if (!rawChartData) {
      return DEFAULT_VALUE;
    }

    if (!rawChartData.length) {
      return [];
    }

    try {
      const chartDataInCurrency = rawChartData.map(data => {
        switch (chartCurrency) {
          case CURRENCY_ETH:
            return {
              timestamp: data.timestamp,
              value: data.valueInEth,
            };
          case CURRENCY_USD:
          default:
            return {
              timestamp: data.timestamp,
              value: data.valueInUsd,
            };
        }
      });

      const downsampledData = downsampleChartData(chartDataInCurrency).map(downSampledDataEntry => ({
        value: downSampledDataEntry.value,
        timestamp: downSampledDataEntry.timestamp,
      }));

      return downsampledData;
    } catch (error) {
      console.error('Error while fetching total assets chart data!', error);
      return DEFAULT_VALUE;
    }
  }),
);

export const [useTotalAssetsChartData] = bind(chartData$, DEFAULT_VALUE);

let subscriptions: Subscription[] = [];

export const subscribeTotalAssetsChartData = (): void => {
  unsubscribeTotalAssetsChartData();
  subscriptions = [totalAssetsChartData$.subscribe(), chartData$.subscribe()];
};
export const unsubscribeTotalAssetsChartData = (): void =>
  subscriptions.forEach(subscription => subscription.unsubscribe());

export { setFromTotalAssetsChartDate };
