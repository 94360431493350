import { ContractBase } from './base';
import { MultiCall } from './typechain';
import MultiCall_ABI from './abi/MultiCall_abi.json';
import { Abi, AccountInterface, Call, ProviderInterface, transaction } from 'starknet';
import { from, map, Observable, of } from 'rxjs';
import BN from 'bn.js';
import { Nullable } from '../../interfaces';
import { CallArray } from './typechain/MultiCall';
import { Uint256 } from '../../datastructures';

export class MultiCallContract extends ContractBase<MultiCall> {
  constructor(address: string, providerOrAccount: ProviderInterface | AccountInterface) {
    super(MultiCall_ABI as Abi, address, providerOrAccount);
  }

  aggregate(calls: Call[]): Observable<Nullable<BN[]>> {
    if (!this.contract) {
      return of(null);
    }

    const { callArray, calldata } = transaction.transformCallsToMulticallArrays(calls);

    return from(this.contract.aggregate(callArray as CallArray[], calldata)).pipe(map(([_, retdata]) => retdata));
  }

  aggregateUint256Array(calls: Call[]): Observable<Nullable<Uint256[]>> {
    return from(this.aggregate(calls)).pipe(
      map(result => {
        if (!result) {
          return null;
        }

        const resultData = result.filter((_, index) => index % 3 !== 0); // remove uint256 length indicator
        const lowUint256 = resultData.filter((_, index) => index % 2 === 0);
        const highUint256 = resultData.filter((_, index) => index % 2 === 1);

        return lowUint256.map((low, index) => {
          const high = highUint256[index];
          return { low, high } as Uint256;
        });
      }),
    );
  }
}
