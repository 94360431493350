import { bind } from '@react-rxjs/core';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  concatMap,
  debounce,
  filter,
  from,
  interval,
  map,
  merge,
  mergeMap,
  of,
  scan,
  Subscription,
  tap,
  withLatestFrom,
} from 'rxjs';
import { AccountInterface, Call } from 'starknet';
import { getConfigManager } from '../config/getConfigManager';
import { DEBOUNCE_IN_MS, MULTICALL_CONTRACT_ADDRESS } from '../constants';
import { Decimal } from '../datastructures';
import { NostraToken, Nullable } from '../interfaces';
import { getMultiCallContract } from '../services';
import { getAccountAddressFromId } from '../utils';
import { walletAccount$ } from './useWalletAccount';
import { activeAccountId$ } from './useActiveAccountId';
import { AppEvent, appEvent$ } from './useAppEvent';
import { nostraTokenList$ } from './useNostraTokenList';
import { accountList$ } from './useAccounts';

const DEFAULT_VALUE: NostraTokenBalanceMap = {};

export interface NostraTokenBalanceMap {
  [accountTokenAddressId: string]: Nullable<Decimal>;
}

export const nostraTokenBalance$ = new BehaviorSubject<NostraTokenBalanceMap>(DEFAULT_VALUE);

const fetchBalances = (
  accountIds: number[],
  nostraTokens: NostraToken[],
  walletAddress: string,
  account?: AccountInterface,
) => {
  try {
    const accountIdNostraTokenPairs = accountIds.flatMap(accountId =>
      nostraTokens.map(nostraToken => [accountId, nostraToken] as const),
    );

    const calldata = accountIdNostraTokenPairs.map(
      ([accountId, nostraToken]) =>
        ({
          contractAddress: nostraToken.address,
          entrypoint: 'balanceOf',
          calldata: [getAccountAddressFromId(walletAddress, accountId)],
        } as Call),
    );

    return from(
      getMultiCallContract(MULTICALL_CONTRACT_ADDRESS, account)
        .aggregateUint256Array(calldata)
        .pipe(
          map(result => {
            if (!result) {
              return null;
            }

            return result
              .map((uint256Value, index) => {
                const [accountId, nostraToken] = accountIdNostraTokenPairs[index];
                const tokenPrecision = getConfigManager().getTokenPrecision(nostraToken.address);

                const balance = new Decimal(uint256Value, tokenPrecision);
                return {
                  [`${walletAddress}-${accountId}-${nostraToken.address}`]: balance,
                } as NostraTokenBalanceMap;
              })
              .reduce((acc, cur) => ({ ...acc, ...cur }), {});
          }),
          catchError(error => {
            console.error(`useNostraTokenBalance - Failed to load nostra token balance!`, error);
            return of(null);
          }),
        ),
    );
  } catch (error) {
    console.error(`useNostraTokenBalance - Failed to load nostra token balance!`, error);
    return of(null);
  }
};

type FetchStreamNonNullInputs = [string[], NostraToken[], AccountInterface];

// Initial fetching stream when wallet address is changed or accounts are updated
const fetchStream$ = combineLatest([accountList$, nostraTokenList$, walletAccount$]).pipe(
  filter((value): value is FetchStreamNonNullInputs => {
    const [accounts, , walletAccount] = value;
    return Boolean(accounts) && Boolean(walletAccount);
  }),
  debounce(() => interval(DEBOUNCE_IN_MS)),
  concatMap(([accounts, nostraTokenList, walletAccount]) => {
    const activeAccountIds = accounts
      .map((account, accountId) => [account, accountId] as const)
      .filter(([account]) => account !== '')
      .map(([, accountId]) => accountId);

    return fetchBalances(activeAccountIds, nostraTokenList, walletAccount.address);
  }),
);

type AppEventStreamNonNullInputs = [[AppEvent, NostraToken[]], AccountInterface, number];

const appEventStream$ = combineLatest([appEvent$, nostraTokenList$]).pipe(
  filter(([appEvent]) => Boolean(appEvent.asset)),
  withLatestFrom(walletAccount$, activeAccountId$),
  filter((value): value is AppEventStreamNonNullInputs => {
    const [, walletAccount, activeAccountId] = value;
    return Boolean(walletAccount) && activeAccountId !== null;
  }),
  mergeMap(([[appEvent, nostraTokenList], walletAccount, activeAccountId]) => {
    const relatedTokenList = nostraTokenList.filter(nostraToken => {
      if (nostraToken.underlyingToken !== appEvent.asset?.token) {
        return false;
      }

      switch (appEvent.eventType) {
        case 'deposit':
        case 'withdraw':
        case 'burnAndMint':
          return nostraToken.variant !== 'debt';

        case 'borrow':
        case 'repay':
          return nostraToken.variant === 'debt';

        default:
          return true;
      }
    });

    const balanceFetchList$ = fetchBalances([activeAccountId], relatedTokenList, walletAccount.address, walletAccount);

    // set the balance map to null first when doing fetching
    const nullList$ = relatedTokenList.map(nostraToken =>
      of({
        [`${walletAccount.address}-${activeAccountId}-${nostraToken.address}`]: null,
      } as NostraTokenBalanceMap),
    );

    return merge(...nullList$, balanceFetchList$);
  }),
);

const stream$ = merge(fetchStream$, appEventStream$).pipe(
  filter(balanceMap => Boolean(balanceMap)),
  scan((accumulator, balance) => {
    if (!balance) {
      return accumulator;
    }

    return { ...accumulator, ...balance } as NostraTokenBalanceMap;
  }, {} as NostraTokenBalanceMap),
  debounce(() => interval(DEBOUNCE_IN_MS)),
  tap(balanceMap => {
    nostraTokenBalance$.next(balanceMap);
  }),
);

export const [useNostraTokenBalance] = bind(nostraTokenBalance$, DEFAULT_VALUE);

let subscription: Subscription;

export const subscribeNostraTokenBalance = (): void => {
  unsubscribeNostraTokenBalance();
  subscription = stream$.subscribe();
};

export const unsubscribeNostraTokenBalance = (): void => subscription?.unsubscribe();
