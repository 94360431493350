import { ApolloQueryResult, gql } from '@apollo/client';
import { bind } from '@react-rxjs/core';
import { createSignal } from '@react-rxjs/utils';
import { catchError, combineLatest, concatMap, from, map, of, Subscription } from 'rxjs';
import { GraphqlQuery, Nullable, ParsedChartData } from '../interfaces';
import { getGraphqlClient } from '../services';
import { downsampleChartData } from '../utils';

const DEFAULT_VALUE = null;

const [fromTotalLendersChartDate$, setFromTotalLendersChartDate] = createSignal<Nullable<number>>();

const totalLendersChartData$ = combineLatest([fromTotalLendersChartDate$]).pipe(
  concatMap(([fromTotalLendersChartDate]) => {
    try {
      const graphqlClient = getGraphqlClient();

      const searchCriteria = fromTotalLendersChartDate
        ? `(fromTimestamp: "${new Date(fromTotalLendersChartDate).toISOString()}")`
        : '';

      return from(
        graphqlClient.query<GraphqlQuery>({
          query: gql`
        query GetTotalLendersChartData {
            totalLenders ${searchCriteria} {
                timestamp
                totalLenders
          }
        }
        `,
        }),
      ).pipe(
        catchError(error => {
          console.error('Error while fetching total lenders chart data!', error);
          return of(null);
        }),
      );
    } catch (error) {
      console.error('Error while fetching total lenders chart data!', error);
      return of(null);
    }
  }),
  map<Nullable<ApolloQueryResult<GraphqlQuery>>, Nullable<ParsedChartData[]>>(result => {
    if (!result?.data.totalLenders.length) {
      return [];
    }

    try {
      const downsampledData = downsampleChartData(
        result.data.totalLenders.map(dataEntry => {
          return {
            timestamp: new Date(dataEntry.timestamp).getTime(),
            value: dataEntry.totalLenders,
          };
        }),
      );

      return downsampledData.map(downSampledDataEntry => ({
        value: downSampledDataEntry.value,
        timestamp: downSampledDataEntry.timestamp,
      }));
    } catch (error) {
      console.error('Error while fetching total lenders chart data!', error);
      return DEFAULT_VALUE;
    }
  }),
);

export const [useTotalLendersChartData] = bind(totalLendersChartData$, DEFAULT_VALUE);

let subscription: Nullable<Subscription> = null;

export const subscribeTotalLendersChartData = (): void => {
  unsubscribeTotalLendersChartData();
  subscription = totalLendersChartData$.subscribe();
};
export const unsubscribeTotalLendersChartData = (): void => subscription?.unsubscribe();

export { setFromTotalLendersChartDate };
