import BN from 'bn.js';
import { uint256 } from 'starknet';

export const parseUint256ToBn = (input: uint256.Uint256) => {
  return uint256.uint256ToBN(input);
};

export const parseBnToHexString = (input: BN, forcedLength?: number) => {
  const inputAsString = input.toString('hex');
  const remainingLength = forcedLength ? Math.max(0, forcedLength - inputAsString.length) : 0;

  if (forcedLength !== undefined && forcedLength - inputAsString.length < 0) {
    console.warn('parseBnToHexString -> requested length smaller than provided address length!');
  }

  return `0x${'0'.repeat(remainingLength)}${inputAsString}`;
};
