import { FC, forwardRef, HTMLProps, useMemo } from 'react';
import { Link as InternalLink } from 'react-router-dom';

import './Link.scss';

export interface LinkProps {
  className?: string;
  title?: string;
  href?: string;
  disabled?: boolean;
  download?: boolean;
  target?: string;
  onClick?: () => void;
}

const Link = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
  const { children, className = '', disabled, title, href = '', download, target = '_blank', onClick } = props;

  const isExternal = useMemo(() => href.includes('://') && new URL(href).origin !== window.location.origin, [href]);

  return (
    <>
      {isExternal ? (
        <a
          ref={ref}
          rel="external noreferrer nofollow"
          target={target}
          className={`nostra__link ${className} ${disabled ? 'disabled' : ''}`}
          title={title}
          href={href}
          download={download}
          onClick={onClick}
        >
          {children}
        </a>
      ) : (
        <InternalLink
          ref={ref}
          className={`nostra__link ${className} `}
          title={title}
          to={disabled ? '#' : href}
          onClick={onClick}
        >
          {children}
        </InternalLink>
      )}
    </>
  );
});

export default Link as FC<LinkProps & HTMLProps<HTMLAnchorElement>>;
