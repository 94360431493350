import { bind } from '@react-rxjs/core';
import { BehaviorSubject, combineLatest, filter, Subscription, tap } from 'rxjs';
import { AccountInterface } from 'starknet';
import { getConfigManager } from '../config/getConfigManager';
import { Decimal, ONE, ZERO } from '../datastructures';
import { NostraToken, Nullable, Token } from '../interfaces';
import { walletAccount$ } from './useWalletAccount';
import { nostraTokenBalance$, NostraTokenBalanceMap } from './useNostraTokenBalance';
import { nostraTokenList$ } from './useNostraTokenList';
import { accountList$ } from './useAccounts';
import { TokenRateMap, tokenRates$ } from './useTokenRates';

type PerTokenTotalIdle = {
  [tokenAddress in Token]: Nullable<Decimal>;
};

interface PerTokenAccountTotalIdle {
  [walletAddress: string]: PerTokenTotalIdle;
}

interface AccountTotalIdleMap {
  [walletAddressAccountId: string]: Decimal;
}

export const perTokenAccountTotalIdle$ = new BehaviorSubject<PerTokenAccountTotalIdle>({});
export const accountTotalIdle$ = new BehaviorSubject<AccountTotalIdleMap>({});

type FetchStreamNonNullInputs = [NostraTokenBalanceMap, string[], AccountInterface, NostraToken[], TokenRateMap];

const fetchStream$ = combineLatest([
  nostraTokenBalance$,
  accountList$,
  walletAccount$,
  nostraTokenList$,
  tokenRates$,
]).pipe(
  filter((value): value is FetchStreamNonNullInputs => {
    const [, accounts, walletAccount] = value;
    return Boolean(accounts) && Boolean(walletAccount);
  }),
  tap(([nostraTokenBalance, accounts, walletAccount, nostraTokens, tokenRates]) => {
    const configManager = getConfigManager();

    const perTokenAccountTotalIdleMap: PerTokenAccountTotalIdle = {};
    const accountTotalIdleMap: AccountTotalIdleMap = {};

    accounts
      .filter(account => account !== '')
      .forEach((_, accountId) => {
        nostraTokens.forEach(nostraToken => {
          try {
            // Do not count non idle tokens when calculating total idle tokens for a account
            if (nostraToken.variant === 'debt' || nostraToken.variant.interestBearing) {
              return;
            }

            const accountTotalIdleKey = `${walletAccount.address}-${accountId}`;
            const tokenBalanceKey = `${walletAccount.address}-${accountId}-${nostraToken.address}`;
            const tokenRate = tokenRates[configManager.getNostraTokenAssetAddress(nostraToken.address)] ?? ONE;
            const tokenBalance = nostraTokenBalance[tokenBalanceKey];

            if (!perTokenAccountTotalIdleMap[walletAccount.address]) {
              perTokenAccountTotalIdleMap[walletAccount.address] = {
                WBTC: null,
                ETH: null,
                USDT: null,
                USDC: null,
                DAI: null,
              };
            }

            if (!tokenBalance) {
              return;
            }

            perTokenAccountTotalIdleMap[walletAccount.address][nostraToken.underlyingToken] = (
              perTokenAccountTotalIdleMap[walletAccount.address][nostraToken.underlyingToken] ?? ZERO
            ).add(tokenBalance);

            accountTotalIdleMap[accountTotalIdleKey] = (accountTotalIdleMap[accountTotalIdleKey] ?? ZERO).add(
              tokenBalance.mul(tokenRate),
            );
          } catch (error) {
            console.error(`useAccountTotalIdle - error calculating total idle for ID ${accountId}`, error);
          }
        });
      });

    perTokenAccountTotalIdle$.next(perTokenAccountTotalIdleMap);
    accountTotalIdle$.next(accountTotalIdleMap);
  }),
);

export const [usePerTokenAccountTotalIdle] = bind(perTokenAccountTotalIdle$, {});
export const [useAccountTotalIdle] = bind(accountTotalIdle$, {});

let subscription: Subscription;
export const subscribeAccountTotalIdle = (): void => {
  unsubscribeAccountTotalIdle();
  subscription = fetchStream$.subscribe();
};

export const unsubscribeAccountTotalIdle = (): void => subscription?.unsubscribe();
export const resetAccountTotalIdle = (): void => {
  accountTotalIdle$.next({});
};
