import { FC } from 'react';
import { InnerIconProps } from './IconProps';
import withIcon from './withIcon';

const Governance: FC<InnerIconProps> = ({ size, color }) => (
  <svg
    className="nostra__icon nostra__icon-governance"
    width={size}
    height={size}
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_6785_54778)">
      <path
        d="M1.73688 3.90421H2.29031V9.67993C2.29031 10.5491 3.01847 11.25 3.92136 11.25H10.4748C11.3777 11.25 12.1058 10.5491 12.1058 9.67993C12.1058 8.81079 11.3777 8.10986 10.4748 8.10986H9.92136V2.33414C9.92136 1.46501 9.1932 0.764069 8.29031 0.75H1.73688C0.833993 0.75 0.105835 1.45094 0.105835 2.33414C0.105835 3.21735 0.833993 3.90421 1.73688 3.90421ZM10.4748 9.16124C10.7807 9.16124 11.0282 9.39954 11.0282 9.69397C11.0282 9.9884 10.7807 10.2267 10.4748 10.2267H5.46505C5.5961 9.89023 5.5961 9.51178 5.46505 9.1753H10.4748V9.16124ZM3.36796 1.8014H8.29031C8.59619 1.8014 8.82912 2.03971 8.84374 2.33414V8.10986H3.36799L3.36796 1.8014ZM3.92139 9.16124C4.22726 9.16124 4.47481 9.39954 4.47481 9.69397C4.47481 9.9884 4.22725 10.2267 3.92139 10.2267C3.61552 10.2267 3.36796 9.9884 3.36796 9.69397C3.36796 9.39954 3.61552 9.16124 3.92139 9.16124ZM1.73691 1.8014H2.29034V2.33414C2.29034 2.62858 2.04278 2.86688 1.73691 2.86688C1.43104 2.86688 1.18348 2.62857 1.18348 2.33414C1.18348 2.03971 1.43104 1.8014 1.73691 1.8014Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_6785_54778">
        <rect width="12" height="12" fill={color} transform="translate(0.105835)" />
      </clipPath>
    </defs>
  </svg>
);

export default withIcon(Governance);
