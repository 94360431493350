import { FC, memo } from 'react';

const SelectedAccountIndicator: FC = () => (
  <svg width="6" height="52" viewBox="0 0 6 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 0L3.46028 1.95581C5.02963 2.84283 6 4.50595 6 6.30863V45.6914C6 47.494 5.02963 49.1572 3.46029 50.0442L0 52V0Z"
      fill="#FF6B00"
    />
  </svg>
);

export default memo(SelectedAccountIndicator);
