import { ApolloQueryResult, gql } from '@apollo/client';
import { bind } from '@react-rxjs/core';
import { createSignal } from '@react-rxjs/utils';
import { catchError, combineLatest, concatMap, from, map, of, Subscription, tap } from 'rxjs';
import { GraphqlQuery, Nullable, ParsedChartData, RawChartData } from '../interfaces';
import { getGraphqlClient } from '../services';
import { downsampleChartData } from '../utils';
import { chartCurrency$, ChartCurrencyType, CURRENCY_ETH, CURRENCY_USD } from './useChartCurrency';
import { selectedChartToken$ } from './useSelectedChartToken';

const DEFAULT_VALUE = null;

const [fromTotalDebtChartDate$, setFromTotalDebtChartDate] = createSignal<Nullable<number>>();
const [rawChartData$, setRawChartData] = createSignal<Nullable<RawChartData[]>>();

const totalDebtChartData$ = combineLatest([fromTotalDebtChartDate$, selectedChartToken$]).pipe(
  concatMap(([fromTotalDebtChartDate, selectedChartToken]) => {
    try {
      setRawChartData(null);

      const graphqlClient = getGraphqlClient();

      const filters = [];
      if (fromTotalDebtChartDate) {
        filters.push(`fromTimestamp: "${new Date(fromTotalDebtChartDate).toISOString()}"`);
      }
      if (selectedChartToken) {
        filters.push(`token: "${selectedChartToken}"`);
      }

      const searchCriteria = filters.length > 0 ? `(${filters.join(', ')})` : '';

      return from(
        graphqlClient.query<GraphqlQuery>({
          query: gql`
        query GetTotalDebtsChartData {
            totalDebt ${searchCriteria} {
            timestamp
            totalDebtEth
            totalDebtUsd
          }
        }
        `,
        }),
      ).pipe(
        catchError(error => {
          console.error('Error while fetching total debt chart data!', error);
          return of(null);
        }),
      );
    } catch (error) {
      console.error('Error while fetching total debt chart data!', error);
      return of(null);
    }
  }),
  map<Nullable<ApolloQueryResult<GraphqlQuery>>, Nullable<RawChartData[]>>(result => {
    if (!result) {
      return null;
    }

    if (!result.data.totalDebt.length) {
      return [];
    }

    return result.data.totalDebt.map(dataEntry => {
      return {
        timestamp: new Date(dataEntry.timestamp).getTime(),
        valueInEth: dataEntry.totalDebtEth,
        valueInUsd: dataEntry.totalDebtUsd,
      };
    });
  }),
  tap(chartData => setRawChartData(chartData)),
);

const chartData$ = combineLatest([rawChartData$, chartCurrency$]).pipe(
  map<[Nullable<RawChartData[]>, ChartCurrencyType], Nullable<ParsedChartData[]>>(([rawChartData, chartCurrency]) => {
    if (!rawChartData) {
      return DEFAULT_VALUE;
    }

    if (!rawChartData.length) {
      return [];
    }

    try {
      const chartDataInCurrency = rawChartData.map(data => {
        switch (chartCurrency) {
          case CURRENCY_ETH:
            return {
              timestamp: data.timestamp,
              value: data.valueInEth,
            };
          case CURRENCY_USD:
          default:
            return {
              timestamp: data.timestamp,
              value: data.valueInUsd,
            };
        }
      });

      const downsampledData = downsampleChartData(chartDataInCurrency).map(downSampledDataEntry => ({
        value: downSampledDataEntry.value,
        timestamp: downSampledDataEntry.timestamp,
      }));

      return downsampledData;
    } catch (error) {
      console.error('Error while fetching total debt chart data!', error);
      return DEFAULT_VALUE;
    }
  }),
);

export const [useTotalDebtChartData] = bind(chartData$, DEFAULT_VALUE);

let subscriptions: Subscription[] = [];

export const subscribeTotalDebtChartData = (): void => {
  unsubscribeTotalDebtChartData();
  subscriptions = [totalDebtChartData$.subscribe(), chartData$.subscribe()];
};
export const unsubscribeTotalDebtChartData = (): void =>
  subscriptions.forEach(subscription => subscription.unsubscribe());

export { setFromTotalDebtChartDate };
