import { useDayPicker } from 'react-day-picker';
import Typography from '../Typography';

const WEEKDAY_NAMES = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

const DateRangePickerHeadRow = () => {
  const { weekStartsOn } = useDayPicker();

  const weekdayNames = [...WEEKDAY_NAMES, ...WEEKDAY_NAMES].slice(weekStartsOn, (weekStartsOn ?? 0) + 7);

  return (
    <tr className="rdp-head_row">
      {weekdayNames.map((weekday, i) => (
        <th key={i} scope="col" className="rdp-head_cell">
          <Typography variant="body-tertiary">{weekday}</Typography>
        </th>
      ))}
    </tr>
  );
};

export default DateRangePickerHeadRow;
