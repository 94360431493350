export type GraphqlQuery = {
  __typename?: 'Query';
  transactions: Transaction[];
  totalAssets: TotalAssetsChartData[];
  totalDebt: TotalDebtChartData[];
  totalLenders: TotalLendersChartData[];
  totalBorrowers: TotalBorrowersChartData[];
  totalUniqueLenders: TotalUniqueLendersChartData[];
  totalUniqueBorrowers: TotalUniqueBorrowersChartData[];
  totalUtilizationRate: TotalUtilizationRateChartData[];
  cumulativeInterestEarned: CumulativeInterestEarnedChartData[];
  transactionCount: number;
  numberOfLenders: number;
  numberOfBorrowers: number;
  apy: ApyChartData[];
};

export enum TransactionType {
  Deposit = 'DEPOSIT',
  Withdraw = 'WITHDRAW',
  Borrow = 'BORROW',
  Repay = 'REPAY',
  Adjust = 'ADJUST',
}

export type TransactionToken = {
  __typename?: 'TransactionToken';
  tokenAddress: string;
};

export type AdjustTransactionTokens = {
  __typename?: 'AdjustTransactionTokens';
  fromTokenAddress: string;
  toTokenAddress: string;
};

export type CommonTransactionData = {
  __typename?: 'Transaction';
  transactionHash: string;
  timestamp: string;
  amount: string;
};

export type Transaction = CommonTransactionData &
  (
    | {
        type: TransactionType.Deposit | TransactionType.Withdraw | TransactionType.Borrow | TransactionType.Repay;
        tokens: TransactionToken;
      }
    | {
        type: TransactionType.Adjust;
        tokens: AdjustTransactionTokens;
      }
  );

export type TotalAssetsChartData = {
  timestamp: string;
  totalAssetsEth: number;
  totalAssetsUsd: number;
};

export type TotalDebtChartData = {
  timestamp: string;
  totalDebtEth: number;
  totalDebtUsd: number;
};

export type TotalLendersChartData = {
  timestamp: string;
  totalLenders: number;
};

export type TotalBorrowersChartData = {
  timestamp: string;
  totalBorrowers: number;
};

export type TotalUniqueLendersChartData = {
  timestamp: string;
  totalUniqueLenders: number;
};

export type TotalUniqueBorrowersChartData = {
  timestamp: string;
  totalUniqueBorrowers: number;
};

export type TotalUtilizationRateChartData = {
  timestamp: string;
  totalUtilizationRate: number;
};

export type CumulativeInterestEarnedChartData = {
  timestamp: string;
  cumulativeInterestEarnedEth: number;
  cumulativeInterestEarnedUsd: number;
};

export type ApyChartData = {
  timestamp: string;
  supplyApy: number;
  borrowApy: number;
};
