import { ethers } from 'ethers';
import { AccountInterface, GetTransactionReceiptResponse, GetTransactionResponse } from 'starknet';
import { bind } from '@react-rxjs/core';
import { createSignal } from '@react-rxjs/utils';
import {
  concatMap,
  map,
  of,
  from,
  tap,
  BehaviorSubject,
  Subscription,
  catchError,
  combineLatest,
  switchMap,
  Observable,
} from 'rxjs';
import {
  getNostraContract,
  getNostraTokenFromFlags,
  getAccountAddressFromId,
  getTokenAmountTransferredInReceipt,
  waitForTransactionReceipt,
} from '../utils';
import { GAS_FEE_PRECISION } from '../constants';
import { Decimal } from '../datastructures';
import { walletAccount$ } from './useWalletAccount';
import { emitAppEvent } from './useAppEvent';
import { Asset } from '../interfaces';

interface DepositRequest {
  asset: Asset;
  amount: Decimal;
  onBehalfOf: string;
  accountId: number;
  isInterestBearing: boolean;
  isCollateral: boolean;
  txnId: string;
}

interface DepositStatus {
  pending: boolean;
  request: DepositRequest;
  success?: boolean;
  error?: Error;
  getTransactionResponse?: GetTransactionResponse;
  transactionData?: {
    depositedAmount: Decimal;
    gasFee: Decimal;
  };
  txnId: string;
}

interface EstimateStatus {
  pending: boolean;
  request?: DepositRequest;
  success?: boolean;
  error?: Error;
  gasFee?: Decimal | null;
}

interface DepositResponse {
  request: DepositRequest;
  getTransactionResponse?: GetTransactionResponse | void;
  getTransactionReceipt?: GetTransactionReceiptResponse;
  transactionData?: {
    depositedAmount: Decimal;
    gasFee: Decimal;
  };
  error?: Error;
  txnId: string;
}

const [deposit$, deposit] = createSignal<DepositRequest>();
const depositStatus$ = new BehaviorSubject<DepositStatus | null>(null);
const estimateStatus$ = new BehaviorSubject<EstimateStatus | null>(null);

const stream$ = combineLatest([deposit$, walletAccount$]).pipe(
  concatMap<[DepositRequest, AccountInterface | null], Observable<DepositResponse>>(([request, walletAccount]) => {
    const { asset, amount, onBehalfOf, accountId, isInterestBearing, isCollateral, txnId } = request;

    try {
      if (!walletAccount) {
        return of({
          request,
          error: new Error('Account not defined!'),
          txnId,
        });
      }

      const nostraToken = getNostraTokenFromFlags(asset, {
        interestBearing: isInterestBearing,
        collateral: isCollateral,
      });

      const nostraTokenContract = getNostraContract(asset, nostraToken.address, walletAccount);
      if (!nostraTokenContract) {
        return of({
          request,
          error: new Error('Failed to get nostra token contract!'),
          txnId,
        });
      }

      depositStatus$.next({ pending: true, txnId, request });

      const accountAddress = getAccountAddressFromId(onBehalfOf, accountId);

      const result$ = nostraTokenContract.mint(asset.address, amount, accountAddress);
      const waitForTxReceipt$ = result$.pipe(
        switchMap(result => {
          if (result?.transaction_hash) {
            return from(waitForTransactionReceipt(result.transaction_hash, walletAccount));
          }
          return of(null);
        }),
      );

      return combineLatest([result$, waitForTxReceipt$]).pipe(
        switchMap(async ([getTransactionResponse, getTransactionReceipt]) => {
          if (getTransactionResponse?.transaction_hash) {
            return {
              getTransactionReceipt,
              getTransactionResponse,
            };
          }
        }),
        map(
          result =>
            ({
              getTransactionResponse: result?.getTransactionResponse,
              getTransactionReceipt: result?.getTransactionReceipt,
              request,
              txnId,
            } as DepositResponse),
        ),
        catchError(error => {
          console.error('useDeposit - Failed to execute deposit!', error);
          return of({
            request,
            error,
            txnId,
          });
        }),
      );
    } catch (error) {
      console.error('useDeposit - Failed to execute deposit!', error);
      return of({
        request,
        error,
        txnId,
      });
    }
  }),
  map<DepositResponse, DepositStatus>(response => {
    const { getTransactionResponse, getTransactionReceipt, request, error, txnId } = response;

    if (!getTransactionResponse) {
      const userRejectError = new Error('Rejected by user.');
      return { pending: false, success: false, error: error ?? userRejectError, request, txnId } as DepositStatus;
    }

    if (!getTransactionReceipt) {
      const receiptFetchFailed = new Error('Failed to fetch deposit transaction receipt!');
      return { pending: false, success: false, error: error ?? receiptFetchFailed, request, txnId } as DepositStatus;
    }

    try {
      const depositedAmount = getTokenAmountTransferredInReceipt(getTransactionReceipt, request.asset);
      const gasFee = new Decimal(
        ethers.utils.formatUnits(getTransactionReceipt.actual_fee || '0x0', GAS_FEE_PRECISION),
      );

      return {
        pending: false,
        success: true,
        request,
        getTransactionResponse,
        transactionData: {
          depositedAmount,
          gasFee,
        },
        txnId,
      };
    } catch (error) {
      console.error('useDeposit - Failed to parse transaction receipt!', error);
      return { pending: false, success: false, error, request, txnId } as DepositStatus;
    }
  }),
  tap(status => {
    const { asset, accountId } = status.request as DepositRequest;

    depositStatus$.next(status);

    if (status.success) {
      emitAppEvent({
        eventType: 'deposit',
        asset,
        accountId,
        txnHash: status.getTransactionResponse?.transaction_hash as string,
        timestamp: Date.now(),
      });
    }
  }),
);

const [depositStatus] = bind<DepositStatus | null>(depositStatus$, null);
const [estimateStatus] = bind<EstimateStatus | null>(estimateStatus$, null);

export const useDeposit = (): {
  depositStatus: DepositStatus | null;
  estimateStatus: EstimateStatus | null;
  deposit: (payload: DepositRequest) => void;
} => ({
  depositStatus: depositStatus(),
  estimateStatus: estimateStatus(),
  deposit,
});

let subscriptions: Subscription[];

export const subscribeDepositStatus = (): void => {
  unsubscribeDepositStatus();
  subscriptions = [stream$.subscribe()];
};
export const unsubscribeDepositStatus = (): void => subscriptions?.forEach(subscription => subscription.unsubscribe());
export const resetDepositStatus = (): void => {
  depositStatus$.next(null);
  estimateStatus$.next(null);
};
