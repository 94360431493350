import { ethers } from 'ethers';
import { GetTransactionReceiptResponse, GetTransactionResponse } from 'starknet';
import { bind } from '@react-rxjs/core';
import { createSignal } from '@react-rxjs/utils';
import {
  concatMap,
  map,
  of,
  from,
  tap,
  BehaviorSubject,
  catchError,
  combineLatest,
  switchMap,
  Subscription,
} from 'rxjs';
import { Decimal } from '../datastructures';
import { getConfigManager } from '../config/getConfigManager';
import { walletAccount$ } from './useWalletAccount';
import { emitAppEvent } from './useAppEvent';
import { Asset, Nullable } from '../interfaces';
import { getDeferredBatchCallAdapterContract } from '../services';
import { GAS_FEE_PRECISION } from '../constants';
import { waitForTransactionReceipt } from '../utils';

interface BurnAndMintRequest {
  asset: Asset;
  amount: Decimal;
  accountId: number;
  isFromInterestBearing: boolean;
  isFromCollateral: boolean;
  isToInterestBearing: boolean;
  isToCollateral: boolean;
  txnId: string;
}

interface BurnAndMintStatus {
  pending: boolean;
  request: BurnAndMintRequest;
  success?: boolean;
  error?: Error;
  getTransactionResponse?: GetTransactionResponse;
  transactionData?: {
    burnAndMintedAmount: Decimal;
    gasFee: Decimal;
  };
  txnId: string;
}

interface BurnAndMintResponse {
  request: BurnAndMintRequest;
  getTransactionResponse?: GetTransactionResponse | void;
  getTransactionReceipt?: GetTransactionReceiptResponse;
  transactionData?: {
    burnAndMintedAmount: Decimal;
    gasFee: Decimal;
  };
  error?: Error;
  txnId: string;
}

const [burnAndMint$, burnAndMint] = createSignal<BurnAndMintRequest>();
const burnAndMintStatus$ = new BehaviorSubject<Nullable<BurnAndMintStatus>>(null);

const stream$ = combineLatest([burnAndMint$, walletAccount$]).pipe(
  concatMap(([request, walletAccount]) => {
    const {
      asset,
      amount,
      accountId,
      isFromInterestBearing,
      isFromCollateral,
      isToInterestBearing,
      isToCollateral,
      txnId,
    } = request;

    try {
      if (!walletAccount) {
        return of({
          request,
          error: new Error('Account not defined!'),
          txnId,
        });
      }

      const deferredBatchCallAdapterContract = getDeferredBatchCallAdapterContract(walletAccount);

      burnAndMintStatus$.next({ pending: true, txnId, request });

      const tokenPrecision = getConfigManager().getTokenPrecision(asset.address);

      const result$ = deferredBatchCallAdapterContract.burnAndMint(
        asset,
        tokenPrecision,
        amount,
        accountId,
        isFromInterestBearing,
        isFromCollateral,
        isToInterestBearing,
        isToCollateral,
      );
      const waitForTxReceipt$ = result$.pipe(
        switchMap(result => {
          if (result?.transaction_hash) {
            return from(waitForTransactionReceipt(result.transaction_hash, walletAccount));
          }
          return of(null);
        }),
      );

      return combineLatest([result$, waitForTxReceipt$]).pipe(
        switchMap(async ([getTransactionResponse, getTransactionReceipt]) => {
          if (getTransactionResponse?.transaction_hash) {
            return {
              getTransactionReceipt,
              getTransactionResponse,
            };
          }
        }),
        map(
          result =>
            ({
              getTransactionResponse: result?.getTransactionResponse,
              getTransactionReceipt: result?.getTransactionReceipt,
              transactionData: {
                burnAndMintedAmount: amount,
              },
              request,
              txnId,
            } as BurnAndMintResponse),
        ),
        catchError(error => {
          console.error('useBurnAndMint - Failed to execute burnAndMint!', error);
          return of({
            request,
            error,
            txnId,
          });
        }),
      );
    } catch (error) {
      console.error('useBurnAndMint - Failed to execute burnAndMint!', error);
      return of({
        request,
        error,
        txnId,
      });
    }
  }),
  map<BurnAndMintResponse, BurnAndMintStatus>(response => {
    const { getTransactionResponse, getTransactionReceipt, request, error, txnId } = response;

    if (!getTransactionResponse) {
      const userRejectError = new Error('Rejected by user.');
      return { pending: false, success: false, error: error ?? userRejectError, request, txnId } as BurnAndMintStatus;
    }

    if (!getTransactionReceipt) {
      const receiptFetchFailed = new Error('Failed to fetch burn and mint transaction receipt!');
      return {
        pending: false,
        success: false,
        error: error ?? receiptFetchFailed,
        request,
        txnId,
      } as BurnAndMintStatus;
    }

    try {
      const gasFee = new Decimal(
        ethers.utils.formatUnits(getTransactionReceipt.actual_fee || '0x0', GAS_FEE_PRECISION),
      );

      return {
        pending: false,
        success: true,
        request,
        getTransactionResponse,
        transactionData: {
          burnAndMintedAmount: request.amount, // TODO - Read amount from receipt
          gasFee,
        },
        txnId,
      };
    } catch (error) {
      console.error('useBurnAndMint - Failed to parse transaction receipt!', error);
      return { pending: false, success: false, error, request, txnId } as BurnAndMintStatus;
    }
  }),
  tap(status => {
    const { asset, accountId } = status.request as BurnAndMintRequest;

    burnAndMintStatus$.next(status);

    if (status.success) {
      emitAppEvent({
        eventType: 'burnAndMint',
        asset,
        accountId,
        txnHash: status.getTransactionResponse?.transaction_hash as string,
        timestamp: Date.now(),
      });
    }
  }),
);

const [burnAndMintStatus] = bind<BurnAndMintStatus | null>(burnAndMintStatus$, null);

export const useBurnAndMint = (): {
  burnAndMintStatus: BurnAndMintStatus | null;
  burnAndMint: (payload: BurnAndMintRequest) => void;
} => ({
  burnAndMintStatus: burnAndMintStatus(),
  burnAndMint,
});

let subscription: Subscription;

export const subscribeBurnAndMintStatus = (): void => {
  unsubscribeBurnAndMintStatus();
  subscription = stream$.subscribe();
};
export const unsubscribeBurnAndMintStatus = (): void => subscription?.unsubscribe();
export const resetBurnAndMintStatus = (): void => {
  burnAndMintStatus$.next(null);
};
