import { state, useStateObservable } from '@react-rxjs/core';
import { BehaviorSubject } from 'rxjs';

export type ChartCurrencyType = 'ETH' | 'USD';
export const CURRENCY_ETH: ChartCurrencyType = 'ETH';
export const CURRENCY_USD: ChartCurrencyType = 'USD';

const chartCurrency$ = new BehaviorSubject<ChartCurrencyType>(CURRENCY_USD);
const stateChartCurrency$ = state(chartCurrency$, CURRENCY_USD);
const setChartCurrency = (chartCurrency: ChartCurrencyType) => chartCurrency$.next(chartCurrency);

export function useChartCurrency(): [ChartCurrencyType, (value: ChartCurrencyType) => void] {
  const chartCurrency = useStateObservable(stateChartCurrency$);
  return [chartCurrency, setChartCurrency];
}

export { chartCurrency$ };
