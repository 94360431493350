import { FC, memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { BorrowPosition } from '../../../interfaces';
import { DecimalUtils } from '../../../utils';
import {
  ActionButton,
  AssetTierPill,
  Loading,
  LoadingButtonPlaceholder,
  LoadingPlaceholder,
  Logo,
  TableBodyCell,
  TableRow,
  Typography,
} from '../../shared';
import { LogoType } from '../../shared/Logo';

interface BorrowPositionRowProps {
  position: BorrowPosition;
}

const BorrowPositionRow: FC<BorrowPositionRowProps> = props => {
  const { position } = props;
  const { asset, balance, balanceUsd, apy } = position;
  const { search } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const formattedBalance = useMemo(
    () =>
      DecimalUtils.format(balance, {
        style: 'multiplier',
        fractionDigits: 2,
        noMultiplierFractionDigits: asset.uiTokenPrecision,
        currency: asset.token,
        lessThanFormat: true,
      }),
    [asset.token, asset.uiTokenPrecision, balance],
  );

  const formattedBalanceUsd = useMemo(
    () =>
      DecimalUtils.format(balanceUsd, {
        style: 'multiplier',
        fractionDigits: 2,
        currency: '$',
        lessThanFormat: true,
      }),
    [balanceUsd],
  );

  const formattedApy = useMemo(
    () => (apy ? DecimalUtils.format(apy, { style: 'percentage', fractionDigits: 2 }) : '---'),
    [apy],
  );

  const handleManageAsset = useCallback(
    () => navigate(`/asset/${asset.token}/repay${search}`),
    [navigate, asset.token, search],
  );

  if (!asset.assetTier || !formattedBalance || !formattedBalanceUsd) {
    return (
      <TableRow id={`borrow-position-${asset.address}`}>
        <TableBodyCell>
          <div className="nostra__borrow-positions__loading-asset-cell">
            <LoadingPlaceholder shape={{ circle: 'medium' }} />
            <LoadingPlaceholder shape={{ width: 'medium', height: 'small' }} />
            <LoadingPlaceholder shape={{ width: 'small', height: 'small' }} />
          </div>
        </TableBodyCell>
        <TableBodyCell align="right">
          <LoadingPlaceholder shape={{ width: 'small', height: 'small' }} />
        </TableBodyCell>
        <TableBodyCell align="right">
          <LoadingPlaceholder shape={{ width: 'small', height: 'small' }} />
        </TableBodyCell>
        <TableBodyCell>
          <div className="nostra__borrow-positions__action-cell">
            <LoadingButtonPlaceholder width="tiny" height="small" />
          </div>
        </TableBodyCell>
      </TableRow>
    );
  }

  return (
    <TableRow id={`borrow-position-${asset.address}`}>
      <TableBodyCell>
        <div className="nostra__borrow-positions__asset-cell">
          <Logo type={`token-${asset.token}` as LogoType} size="small" />
          <Typography className="nostra__token" variant="body-primary">
            {asset.token}
          </Typography>
          <AssetTierPill tier={asset.assetTier} />
        </div>
      </TableBodyCell>
      <TableBodyCell align="right">
        <div className="nostra__borrow-positions__multiple-values">
          {formattedBalance && formattedBalanceUsd ? (
            <>
              <Typography variant="body-primary">{formattedBalanceUsd}</Typography>
              <Typography variant="body-tertiary" color="text-disabled">
                {formattedBalance}
              </Typography>
            </>
          ) : (
            <Loading color="primary" size={16} />
          )}
        </div>
      </TableBodyCell>
      <TableBodyCell align="right">
        <Typography variant="body-primary">{formattedApy}</Typography>
      </TableBodyCell>
      <TableBodyCell>
        <div className="nostra__borrow-positions__action-cell">
          <ActionButton
            variant="tertiary"
            labels={{ default: t('BorrowPositionRow.btnManage') }}
            onClick={handleManageAsset}
            size="large"
          />
        </div>
      </TableBodyCell>
    </TableRow>
  );
};

export default memo(BorrowPositionRow);
