import { FC } from 'react';
import { colors } from '../Colors';
import Icon from '../Icon';
import Typography from '../Typography';

import './InfoTooltipContent.scss';

interface InfoTooltipContentProps {
  message: string;
}

const InfoTooltipContent: FC<InfoTooltipContentProps> = props => {
  const { message } = props;

  return (
    <div className="nostra__info-tooltip-content">
      <Icon variant="info-bordered-rounded" color={colors.textHighlight} size={20} />
      <Typography variant="body-primary">{message}</Typography>
    </div>
  );
};

export default InfoTooltipContent;
