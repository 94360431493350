import { bind } from '@react-rxjs/core';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  concatMap,
  distinctUntilChanged,
  from,
  interval,
  map,
  Observable,
  of,
  retry,
  startWith,
  Subscription,
  tap,
  timer,
} from 'rxjs';
import { Call } from 'starknet';
import { POLLING_INTERVAL_IN_MS, MULTICALL_CONTRACT_ADDRESS } from '../constants';
import { Decimal, DEFAULT_DECIMAL_PRECISION } from '../datastructures';
import { getMultiCallContract } from '../services';
import { getConfigManager } from '../config/getConfigManager';
import { fibonacci } from '../utils';
import { Nullable } from '../interfaces';
import { logGlobalError } from './useGlobalError';

export type TokenRateMap = {
  [tokenAddress: string]: Decimal;
};

type TokenRateResponse = {
  tokenRates: Nullable<TokenRateMap>;
  tokenRatesInBaseAsset: Nullable<TokenRateMap>;
};

const DEFAULT_VALUE = {} as TokenRateMap;
const RETRY_COUNT = 10;
const RETRY_DELAY_IN_MS = 500;

const intervalBeat$: Observable<number> = interval(POLLING_INTERVAL_IN_MS).pipe(startWith(0));

const rawTokenRates$ = new BehaviorSubject<TokenRateMap>(DEFAULT_VALUE);
export const tokenRates$ = rawTokenRates$.pipe(
  distinctUntilChanged(
    (previous, current) =>
      Object.keys(current).length === Object.keys(previous).length &&
      Object.keys(current).every(token => current[token].equals(previous[token])),
  ),
);

const rawTokenRatesInBaseAsset$ = new BehaviorSubject<TokenRateMap>(DEFAULT_VALUE);
export const tokenRatesInBaseAsset$ = rawTokenRatesInBaseAsset$.pipe(
  distinctUntilChanged(
    (previous, current) =>
      Object.keys(current).length === Object.keys(previous).length &&
      Object.keys(current).every(token => current[token].equals(previous[token])),
  ),
);

const tokenAddresses$ = of(getConfigManager().getTokenAddresses());

// stream$ for periodic polling to fetch data
const stream$: Observable<TokenRateResponse> = combineLatest([tokenAddresses$, intervalBeat$]).pipe(
  concatMap(([tokenAddresses]) => {
    try {
      const oracleContractAddress = getConfigManager().getConfig().oracle;
      const calldata = [
        {
          contractAddress: oracleContractAddress,
          entrypoint: 'getBaseAssetPriceInUsd',
        },
        ...tokenAddresses.map(
          tokenAddress =>
            ({
              contractAddress: oracleContractAddress,
              entrypoint: 'getAssetPrice',
              calldata: [tokenAddress],
            } as Call),
        ),
      ];

      return from(
        getMultiCallContract(MULTICALL_CONTRACT_ADDRESS)
          .aggregateUint256Array(calldata)
          .pipe(
            map(result => {
              if (!result) {
                throw new Error('No rates found for tokens');
              }

              const [baseAssetPriceInUsd, ...tokenPricesInBaseAsset] = result;
              const decimalBaseAssetPriceInUsd = new Decimal(baseAssetPriceInUsd, DEFAULT_DECIMAL_PRECISION);

              const tokenRatesInBaseAsset = tokenPricesInBaseAsset
                .map((tokenPriceInBaseAsset, index) => {
                  const tokenAddress = tokenAddresses[index];
                  const decimalTokenPriceInBaseAsset = new Decimal(tokenPriceInBaseAsset, DEFAULT_DECIMAL_PRECISION);

                  return {
                    [tokenAddress]: decimalTokenPriceInBaseAsset,
                  } as TokenRateMap;
                })
                .reduce((acc, tokenRateMap) => ({ ...acc, ...tokenRateMap }), {});

              const tokenRates = Object.entries(tokenRatesInBaseAsset).reduce(
                (acc, [tokenAddress, decimalTokenPriceInBaseAsset]) => ({
                  ...acc,
                  [tokenAddress]: decimalTokenPriceInBaseAsset.mul(decimalBaseAssetPriceInUsd),
                }),
                {} as TokenRateMap,
              );

              return {
                tokenRates,
                tokenRatesInBaseAsset,
              } as TokenRateResponse;
            }),
            retry({
              count: RETRY_COUNT,
              delay: (_error, retryNumber) => {
                const retryIn = RETRY_DELAY_IN_MS * fibonacci(retryNumber);

                console.error(`Failed to fetch token rates, retrying in ${retryIn} milliseconds. Error: `, _error);

                return timer(retryIn);
              },
              resetOnSuccess: true,
            }),
            catchError(error => {
              console.error(`useTokenRates - failed to fetch token rates`, error);
              logGlobalError(error);
              return of({
                tokenRates: null,
                tokenRatesInBaseAsset: null,
              });
            }),
          ),
      );
    } catch (error) {
      console.error(`useTokenRates - failed to fetch token rates`, error);
      logGlobalError(error);
      return of({
        tokenRates: null,
        tokenRatesInBaseAsset: null,
      });
    }
  }),
  tap(response => {
    const { tokenRates, tokenRatesInBaseAsset } = response;
    if (tokenRates) {
      rawTokenRates$.next(tokenRates);
    }
    if (tokenRatesInBaseAsset) {
      rawTokenRatesInBaseAsset$.next(tokenRatesInBaseAsset);
    }
  }),
);

export const [useTokenRates] = bind(tokenRates$, DEFAULT_VALUE);

let subscription: Subscription;

export const subscribeTokenRates = (): void => {
  unsubscribeTokenRates();
  subscription = stream$.subscribe();
};
export const unsubscribeTokenRates = (): void => subscription?.unsubscribe?.();
export const resetTokenRates = (): void => rawTokenRates$.next(DEFAULT_VALUE);
