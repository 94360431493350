import { AccountInterface } from 'starknet';
import { Nullable } from '../interfaces';
import { getCDPManagerContract } from './getCDPManagerContract';
import { DeferredBatchCallAdapterContract, CDPManagerContract, NostraWrapperPriceOracleContract } from './contracts';
import { getNostraWrapperPriceOracleContract } from './getNostraWrapperPriceOracleContract';
import { getDeferredBatchCallAdapterContract } from './getDeferredBatchCallAdapterContract';

interface ServiceMap {
  cdpManagerContract: Nullable<CDPManagerContract>;
  deferredBatchCallAdapterContract: Nullable<DeferredBatchCallAdapterContract>;
  priceOracleMockContract: Nullable<NostraWrapperPriceOracleContract>;
}

const serviceMap: ServiceMap = {
  cdpManagerContract: null,
  deferredBatchCallAdapterContract: null,
  priceOracleMockContract: null,
};

export const initServices = (account?: AccountInterface): boolean => {
  try {
    if (account) {
      serviceMap.deferredBatchCallAdapterContract = getDeferredBatchCallAdapterContract(account);
    } else {
      serviceMap.deferredBatchCallAdapterContract = getDeferredBatchCallAdapterContract();
    }
    serviceMap.cdpManagerContract = getCDPManagerContract();
    serviceMap.priceOracleMockContract = getNostraWrapperPriceOracleContract();
  } catch (error) {
    throw new Error(`initServices - ${error}`);
  }

  return true;
};

export const getServices = (): ServiceMap => {
  return serviceMap;
};
