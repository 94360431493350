import { bind } from '@react-rxjs/core';
import { BehaviorSubject, combineLatest, filter, Subscription, tap } from 'rxjs';
import { AccountInterface } from 'starknet';
import { getConfigManager } from '../config/getConfigManager';
import { Decimal, ONE, ZERO } from '../datastructures';
import { NostraToken, Nullable, Token } from '../interfaces';
import { walletAccount$ } from './useWalletAccount';
import { nostraTokenBalance$, NostraTokenBalanceMap } from './useNostraTokenBalance';
import { nostraTokenList$ } from './useNostraTokenList';
import { accountList$ } from './useAccounts';
import { TokenRateMap, tokenRates$ } from './useTokenRates';

type PerTokenTotalSupply = {
  [tokenAddress in Token]: Nullable<Decimal>;
};

interface PerTokenAccountTotalSupply {
  [walletAddress: string]: PerTokenTotalSupply;
}

export interface AccountTotalSupplyMap {
  [walletAddressAccountId: string]: Decimal;
}

export const perTokenAccountTotalSupply$ = new BehaviorSubject<PerTokenAccountTotalSupply>({});
export const accountTotalSupply$ = new BehaviorSubject<AccountTotalSupplyMap>({});

type FetchStreamNonNullInputs = [NostraTokenBalanceMap, string[], AccountInterface, NostraToken[], TokenRateMap];

const fetchStream$ = combineLatest([
  nostraTokenBalance$,
  accountList$,
  walletAccount$,
  nostraTokenList$,
  tokenRates$,
]).pipe(
  filter((value): value is FetchStreamNonNullInputs => {
    const [, accounts, walletAccount] = value;
    return Boolean(accounts) && Boolean(walletAccount);
  }),
  tap(([nostraTokenBalance, accounts, walletAccount, nostraTokens, tokenRates]) => {
    const configManager = getConfigManager();

    const perTokenAccountTotalSupplyMap: PerTokenAccountTotalSupply = {};
    const accountTotalSupplyMap: AccountTotalSupplyMap = {};

    accounts
      .filter(account => account !== '')
      .forEach((_, accountId) => {
        nostraTokens.forEach(nostraToken => {
          try {
            // Do not count non lend tokens when calculating total supply for a account
            if (nostraToken.variant === 'debt' || !nostraToken.variant.interestBearing) {
              return;
            }

            const accountTotalSupplyKey = `${walletAccount.address}-${accountId}`;
            const tokenBalanceKey = `${walletAccount.address}-${accountId}-${nostraToken.address}`;
            const tokenRate = tokenRates[configManager.getNostraTokenAssetAddress(nostraToken.address)] ?? ONE;
            const tokenBalance = nostraTokenBalance[tokenBalanceKey];

            if (!perTokenAccountTotalSupplyMap[walletAccount.address]) {
              perTokenAccountTotalSupplyMap[walletAccount.address] = {
                WBTC: null,
                ETH: null,
                USDT: null,
                USDC: null,
                DAI: null,
              };
            }

            if (!tokenBalance) {
              return;
            }

            perTokenAccountTotalSupplyMap[walletAccount.address][nostraToken.underlyingToken] = (
              perTokenAccountTotalSupplyMap[walletAccount.address][nostraToken.underlyingToken] ?? ZERO
            ).add(tokenBalance);

            accountTotalSupplyMap[accountTotalSupplyKey] = (accountTotalSupplyMap[accountTotalSupplyKey] ?? ZERO).add(
              tokenBalance.mul(tokenRate),
            );
          } catch (error) {
            console.error(`useAccountTotalSupply - error calculating total supply for ID ${accountId}`, error);
          }
        });
      });

    perTokenAccountTotalSupply$.next(perTokenAccountTotalSupplyMap);
    accountTotalSupply$.next(accountTotalSupplyMap);
  }),
);

export const [usePerTokenAccountTotalSupply] = bind(perTokenAccountTotalSupply$, {});
export const [useAccountTotalSupply] = bind(accountTotalSupply$, {});

let subscription: Subscription;
export const subscribeAccountTotalSupply = (): void => {
  unsubscribeAccountTotalSupply();
  subscription = fetchStream$.subscribe();
};

export const unsubscribeAccountTotalSupply = (): void => subscription?.unsubscribe();
export const resetAccountTotalSupply = (): void => {
  accountTotalSupply$.next({});
};
