import { getConfigManager } from '../config/getConfigManager';
import { ContractCache } from '../utils';
import { InterestRateModelContract } from './contracts';

const contractCache = new ContractCache(
  (address, providerOrAccount) => new InterestRateModelContract(address, providerOrAccount),
);

export const getInterestRateModelContract = () => {
  const interestRateModelDataAddress = getConfigManager().getConfig().interestRateModel;
  return contractCache.get(interestRateModelDataAddress);
};
