import { FC, PropsWithChildren } from 'react';

import './TableBodyCell.scss';

interface TableBodyCellProps {
  align?: 'left' | 'center' | 'right';
  border?: 'left' | 'right' | 'none';
  className?: string[];
  colSpan?: number;
  rowSpan?: number;
}

const TableBodyCell: FC<PropsWithChildren<TableBodyCellProps>> = props => {
  const { align = 'left', border = 'none', className, colSpan, rowSpan, children } = props;

  let derivedClassName = 'nostra__tableBodyCell';
  if (className) {
    derivedClassName += ' ' + className.join(' ');
  }

  return (
    <td className={derivedClassName} data-align={align} data-border={border} colSpan={colSpan} rowSpan={rowSpan}>
      <div className="nostra__tableBodyCell__content">{children}</div>
    </td>
  );
};
export default TableBodyCell;
