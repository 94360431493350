import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../Button/Button';
import ButtonPrimary from '../ButtonPrimary';
import ModalWrapper from '../ModalWrapper';
import Typography from '../Typography';

import './ModalPrompt.scss';

interface ModalPromptProps {
  text: string;
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

const ModalPrompt: FC<ModalPromptProps> = ({ text, open, onConfirm, onClose }) => {
  const { t } = useTranslation();

  return (
    <ModalWrapper onClose={onClose} open={open}>
      <div className="nostra__modal-prompt">
        <div className="nostra__modal-prompt__text">
          <Typography variant="body-primary" weight="medium">
            {text}
          </Typography>
        </div>
        <div className="nostra__modal-prompt__actions">
          <Button label={t('ModalPrompt.btnNo')} onClick={onClose} />
          <ButtonPrimary label={t('ModalPrompt.btnYes')} onClick={onConfirm} />
        </div>
      </div>
    </ModalWrapper>
  );
};
export default ModalPrompt;
