import BN from 'bn.js';
import axios from 'axios';
import { BehaviorSubject, switchMap, filter, tap, map, of, from, Subscription, catchError } from 'rxjs';
import { AccountInterface } from 'starknet';
import { bind } from '@react-rxjs/core';
import { Nullable } from '../interfaces';
import { walletAccount$ } from './useWalletAccount';

const DEFAULT_VALUE = null;
const STARKNET_ID_DOMAIN_QUERY_URL = 'https://app.starknet.id/api/indexer/addr_to_domain?addr={base10Address}';

export const starknetDomain$ = new BehaviorSubject<Nullable<string>>(DEFAULT_VALUE);

const fetchStream$ = walletAccount$.pipe(
  filter((walletAccount): walletAccount is AccountInterface => Boolean(walletAccount)),
  switchMap(walletAccount => {
    if (!walletAccount.address) {
      return of(DEFAULT_VALUE);
    }

    try {
      const base10Address = new BN(walletAccount.address.slice(2), 16).toString(10);
      return from(axios.get(STARKNET_ID_DOMAIN_QUERY_URL.replace('{base10Address}', base10Address))).pipe(
        map(response => {
          const result = response.data as any;
          if (result.domain === undefined || result.error) {
            return DEFAULT_VALUE;
          }

          return result.domain as string;
        }),
        catchError(error => {
          console.error(`useStarknetId - failed to retrieve domain for ${walletAccount.address}`, error);
          return of(DEFAULT_VALUE);
        }),
      );
    } catch (error) {
      console.error(`useStarknetId - failed to retrieve domain for ${walletAccount.address}`, error);
      return of(DEFAULT_VALUE);
    }
  }),
  tap(domain => {
    starknetDomain$.next(domain);
  }),
);

export const [useStarknetDomain] = bind<Nullable<string>>(starknetDomain$, DEFAULT_VALUE);

let subscription: Subscription;

export const subscribeStarknetDomain = (): void => {
  unsubscribeStarknetDomain();
  subscription = fetchStream$.subscribe();
};
export const unsubscribeStarknetDomain = (): void => subscription?.unsubscribe();
export const resetStarknetDomain = (): void => starknetDomain$.next(null);
