import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { colors, Icon, Link, Typography } from '../../../shared';

interface DepositInfoTooltipContentProps {
  title: string;
  message: string;
}

const DepositInfoTooltipContent: FC<DepositInfoTooltipContentProps> = props => {
  const { title, message } = props;

  const { t } = useTranslation();

  return (
    <div className="nostra__deposit-modal__info-tooltip">
      <div className="nostra__deposit-modal__info-tooltip-title">
        <Icon variant="info-bordered-rounded" color={colors.textHighlight} size={20} />
        <Typography variant="body-primary" weight="bold">
          {title}
        </Typography>
      </div>
      <div className="nostra__deposit-modal__info-tooltip-description">
        <Typography variant="body-primary" color="text-gray-dark">
          {message}
        </Typography>
      </div>
      <Link href="https://docs.nostra.finance/nostra-money-market/tokenized-assets-and-debt/tokenized-asset-positions">
        <Typography variant="body-tertiary" color="text-highlight">
          {t('Deposit.tooltipLendingCollateralLockedLink')}
        </Typography>
      </Link>
    </div>
  );
};

export default DepositInfoTooltipContent;
