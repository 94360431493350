import { FC, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  resetBorrowStatus,
  resetBurnAndMintStatus,
  resetDepositStatus,
  resetRepayStatus,
  resetWithdrawStatus,
  useWalletAccount,
  useActiveAccountId,
  useAssets,
  useAssetVariant,
  useBorrow,
  useBorrowPositions,
  useBurnAndMint,
  useConfig,
  useDeposit,
  useDepositPositions,
  useRepay,
  useWithdraw,
} from '../../hooks';
import { Asset } from '../../interfaces';
import AssetTierTooltip from '../AssetTierTooltip';
import ActionModal from './ActionModal';
import { Action } from './ActionModalTab';

export const ActionModalResolver: FC = () => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const walletAccount = useWalletAccount();
  const activeAccountId = useActiveAccountId();
  const config = useConfig();
  const depositPositions = useDepositPositions();
  const borrowPositions = useBorrowPositions();
  const assets = useAssets();
  const { depositStatus } = useDeposit();
  const { withdrawStatus } = useWithdraw();
  const { borrowStatus } = useBorrow();
  const { repayStatus } = useRepay();
  const { burnAndMintStatus } = useBurnAndMint();
  const assetVariants = useAssetVariant();

  const { ticker, action } = useParams();

  const handleCloseModal = () => {
    // If success modal for any action is shown, when we close the modal
    // we want to reset action status so that next time we open the modal
    // default modal state shown
    if (depositStatus?.success) {
      resetDepositStatus();
    }
    if (withdrawStatus?.success) {
      resetWithdrawStatus();
    }
    if (borrowStatus?.success) {
      resetBorrowStatus();
    }
    if (repayStatus?.success) {
      resetRepayStatus();
    }
    if (burnAndMintStatus?.success) {
      resetBurnAndMintStatus();
    }

    navigate(`/${search}`);
  };

  const asset = useMemo(() => assets.find(asset => asset.token === ticker), [assets, ticker]);

  const pendingAsset = useMemo<Asset | undefined>(() => {
    if (depositStatus?.pending || depositStatus?.success) {
      return depositStatus?.request.asset;
    }

    if (withdrawStatus?.pending || withdrawStatus?.success) {
      return withdrawStatus?.request.asset;
    }

    if (borrowStatus?.pending || borrowStatus?.success) {
      return borrowStatus?.request.asset;
    }

    if (repayStatus?.pending || repayStatus?.success) {
      return repayStatus?.request.asset;
    }

    if (burnAndMintStatus?.pending || burnAndMintStatus?.success) {
      return burnAndMintStatus.request.asset;
    }

    return undefined;
  }, [
    depositStatus?.pending,
    depositStatus?.success,
    depositStatus?.request?.asset,
    withdrawStatus?.pending,
    withdrawStatus?.success,
    withdrawStatus?.request?.asset,
    borrowStatus?.pending,
    borrowStatus?.success,
    borrowStatus?.request?.asset,
    repayStatus?.pending,
    repayStatus?.success,
    repayStatus?.request?.asset,
    burnAndMintStatus?.pending,
    burnAndMintStatus?.success,
    burnAndMintStatus?.request.asset,
  ]);

  const disabledActions = useMemo(() => {
    const allDisabledActions = new Set<Action>();

    if (!walletAccount) {
      allDisabledActions.add('withdraw');
      allDisabledActions.add('borrow');
      allDisabledActions.add('repay');
      allDisabledActions.add('adjust');
    }

    if (depositPositions === null) {
      allDisabledActions.add('withdraw');
      allDisabledActions.add('borrow');
      allDisabledActions.add('adjust');

      return allDisabledActions;
    }

    if (borrowPositions === null) {
      allDisabledActions.add('repay');
      allDisabledActions.add('borrow');

      return allDisabledActions;
    }

    if (!borrowPositions.find(position => position.asset.token === ticker) && !repayStatus?.success) {
      allDisabledActions.add('repay');
    }

    if (!depositPositions.find(position => position.asset.token === ticker) && !withdrawStatus?.success) {
      allDisabledActions.add('withdraw');
    }

    if (!depositPositions.find(position => position.asset.token === ticker)) {
      allDisabledActions.add('adjust');
    }

    const isInvalidIsolatedBorrowing =
      Boolean(borrowPositions.find(position => position.asset.assetTier !== 'isolated')) &&
      asset?.assetTier === 'isolated';

    const hasIsolatedBorrow = Boolean(
      borrowPositions.find(position => position.asset.assetTier === 'isolated' && position.asset.token !== ticker),
    );
    const hasAnyCollateral = depositPositions.find(
      position => assetVariants[`${walletAccount?.address}-${activeAccountId}-${position.asset.address}`]?.isCollateral,
    );

    if (
      !depositPositions.length ||
      asset?.assetTier === 'nominal' ||
      isInvalidIsolatedBorrowing ||
      hasIsolatedBorrow ||
      !hasAnyCollateral
    ) {
      allDisabledActions.add('borrow');
    }

    return allDisabledActions;
  }, [
    walletAccount,
    activeAccountId,
    asset?.assetTier,
    assetVariants,
    borrowPositions,
    depositPositions,
    repayStatus?.success,
    ticker,
    withdrawStatus?.success,
  ]);

  const isValidParams = useMemo(() => {
    const isValidTicker = ticker && config && Object.keys(config.assets).includes(ticker);
    const isActionEnabled = action === undefined || !disabledActions.has(action as Action);
    return isValidTicker && isActionEnabled;
  }, [action, config, disabledActions, ticker]);

  const borrowDisabledTooltip = useMemo(() => {
    if (borrowPositions === null) {
      return null;
    }

    const hasIsolatedBorrow = Boolean(
      borrowPositions.find(position => position.asset.assetTier === 'isolated' && position.asset.token !== ticker),
    );

    const hasNonIsolatedBorrow = Boolean(borrowPositions.find(position => position.asset.assetTier !== 'isolated'));

    if ((hasIsolatedBorrow || hasNonIsolatedBorrow) && asset && asset.assetTier === 'isolated') {
      return <AssetTierTooltip assetTier={asset.assetTier} />;
    }
  }, [asset, borrowPositions, ticker]);

  // TODO - Show 404 page, invalid asset message, etc...
  if (!isValidParams) {
    return null;
  }

  return (
    <ActionModal
      asset={pendingAsset ?? asset ?? null}
      disabledActions={disabledActions}
      onClose={handleCloseModal}
      borrowDisabledTooltip={borrowDisabledTooltip}
    />
  );
};
