import { Asset, NostraToken, NostraTokenVariant } from '../interfaces';

export function getNostraTokenFromFlags(asset: Asset, flags: NostraTokenVariant): NostraToken {
  if (flags === 'debt') {
    return {
      address: asset.debtTokenAddress,
      variant: flags,
      symbol: asset.debtTokenSymbol,
      underlyingToken: asset.token,
      tokenPrecision: asset.tokenPrecision,
    };
  }

  if (flags.interestBearing) {
    return flags.collateral
      ? {
          address: asset.nostraInterestBearingCollateralTokenAddress,
          variant: flags,
          symbol: asset.nostraInterestBearingCollateralTokenSymbol,
          underlyingToken: asset.token,
          tokenPrecision: asset.tokenPrecision,
        }
      : {
          address: asset.nostraInterestBearingTokenAddress,
          variant: flags,
          symbol: asset.nostraInterestBearingTokenSymbol,
          underlyingToken: asset.token,
          tokenPrecision: asset.tokenPrecision,
        };
  }

  return flags.collateral
    ? {
        address: asset.nostraCollateralTokenAddress,
        variant: flags,
        symbol: asset.nostraCollateralTokenSymbol,
        underlyingToken: asset.token,
        tokenPrecision: asset.tokenPrecision,
      }
    : {
        address: asset.nostraTokenAddress,
        variant: flags,
        symbol: asset.nostraTokenSymbol,
        underlyingToken: asset.token,
        tokenPrecision: asset.tokenPrecision,
      };
}
