import { bind } from '@react-rxjs/core';
import { BehaviorSubject, catchError, combineLatest, merge, mergeMap, of, Subscription, tap } from 'rxjs';
import { MULTICALL_CONTRACT_ADDRESS } from '../constants';
import { Decimal } from '../datastructures';
import { NostraToken } from '../interfaces';
import { getMultiCallContract } from '../services';
import { logGlobalError } from './useGlobalError';
import { nostraTokenList$ } from './useNostraTokenList';

const DEFAULT_VALUE = null;

export type NostraTokenTotalSupplyCapMap = { [nostraTokenAddress: string]: Decimal };

export const nostraTokenTotalSupplyCap$ = new BehaviorSubject<NostraTokenTotalSupplyCapMap | null>(DEFAULT_VALUE);

const RESPONSE_CHUNK_SIZE = 3;

const fetchData = (nostraTokens: NostraToken[]) => {
  try {
    const calldata = nostraTokens.map(nostraToken => {
      return {
        contractAddress: nostraToken.address,
        entrypoint: 'getTotalSupplyCap',
      };
    });

    const multiCallContract = getMultiCallContract(MULTICALL_CONTRACT_ADDRESS);

    return multiCallContract.aggregate(calldata).pipe(
      mergeMap(result => {
        if (!result) {
          return of(null);
        }

        const nostraTokenTotalSupplyCapMap: NostraTokenTotalSupplyCapMap = {};

        for (let i = 0; i < result.length; i += RESPONSE_CHUNK_SIZE) {
          const nostraToken = nostraTokens[i / RESPONSE_CHUNK_SIZE];

          // Index 1,2 is for totalSupplyCap
          const totalSupplyCap = new Decimal({ low: result[i + 1], high: result[i + 2] }, nostraToken.tokenPrecision);

          nostraTokenTotalSupplyCapMap[nostraToken.address] = totalSupplyCap;
        }

        return of(nostraTokenTotalSupplyCapMap);
      }),
      catchError(error => {
        console.error(`useNostraTokenTotalSupplyCap - Failed to load nostra token total supply cap!`, error);
        logGlobalError(error);
        return of(null);
      }),
    );
  } catch (error) {
    console.error(`useNostraTokenTotalSupplyCap - Failed to load nostra token total supply cap!`, error);
    logGlobalError(error);
    return of(null);
  }
};

/**
 * Load total supply cap for nostra tokens only once on app load.
 */
const initialLoadStream$ = combineLatest([nostraTokenList$]).pipe(
  mergeMap(([nostraTokenList]) => {
    return fetchData(nostraTokenList);
  }),
);

/**
 * Combine all streams into one, and update BehaviorSubject every time one of the streams emits new value
 */
const stream$ = merge(initialLoadStream$).pipe(
  tap(nostraTokenTotalSupplyCapMap => {
    if (nostraTokenTotalSupplyCapMap) {
      nostraTokenTotalSupplyCap$.next(nostraTokenTotalSupplyCapMap);
    }
  }),
);

export const [useNostraTokenTotalSupplyCap] = bind(nostraTokenTotalSupplyCap$, DEFAULT_VALUE);

let subscription: Subscription | null = null;

export const subscribeNostraTokenTotalSupplyCap = (): void => {
  unsubscribeNostraTokenTotalSupplyCap();
  subscription = stream$.subscribe();
};
export const unsubscribeNostraTokenTotalSupplyCap = (): void => subscription?.unsubscribe();
export const resetNostraTokenTotalSupplyCap = (): void => nostraTokenTotalSupplyCap$.next(DEFAULT_VALUE);
