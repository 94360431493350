import { FC, ReactNode, useCallback, useMemo } from 'react';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Asset, Nullable } from '../../interfaces';
import { useBorrow, useBurnAndMint, useDeposit, useRepay, useWithdraw } from '../../hooks';
import { LoadingButtonPlaceholder, Modal } from '../shared';
import ActionModalTab, { Action } from './ActionModalTab';
import ActionModalTitle from './ActionModalTitle';
import { Adjust, Borrow, Deposit, Repay, Withdraw } from './Actions';

import './ActionModal.scss';

interface ActionModalProps {
  asset: Nullable<Asset>;
  disabledActions?: Set<Action>;
  borrowDisabledTooltip?: ReactNode;
  onClose: () => void;
}

const actions: Action[] = ['deposit', 'withdraw', 'borrow', 'repay', 'adjust'];

const ActionModal: FC<ActionModalProps> = props => {
  const { asset, disabledActions = new Set<Action>(), borrowDisabledTooltip, onClose } = props;

  const navigate = useNavigate();
  const { search } = useLocation();
  const { action = 'deposit', ticker } = useParams();

  const { depositStatus } = useDeposit();
  const { withdrawStatus } = useWithdraw();
  const { borrowStatus } = useBorrow();
  const { repayStatus } = useRepay();
  const { burnAndMintStatus } = useBurnAndMint();

  const onActionSelected = useCallback(
    (actionName: Action) => {
      if (!asset) {
        return;
      }

      navigate(`/asset/${asset.token}/${actionName}${search}`);
    },
    [navigate, asset, search],
  );

  /**
   * If any of the actions status is set to 'success', we want to show
   * success version of the modal that does not contain tabs
   **/
  const showTabs = useMemo(() => {
    const depositSuccess = depositStatus?.success;
    const withdrawSuccess = withdrawStatus?.success;
    const borrowSuccess = borrowStatus?.success;
    const repaySuccess = repayStatus?.success;
    const burnAndMintSuccess = burnAndMintStatus?.success;

    const successfulActionFound =
      depositSuccess || withdrawSuccess || borrowSuccess || repaySuccess || burnAndMintSuccess;

    return !successfulActionFound;
  }, [
    borrowStatus?.success,
    depositStatus?.success,
    repayStatus?.success,
    withdrawStatus?.success,
    burnAndMintStatus?.success,
  ]);

  const isPending = useMemo(() => {
    const depositPending = depositStatus?.pending;
    const withdrawPending = withdrawStatus?.pending;
    const borrowPending = borrowStatus?.pending;
    const repayPending = repayStatus?.pending;
    const burnAndMintPending = burnAndMintStatus?.pending;

    return depositPending || withdrawPending || borrowPending || repayPending || burnAndMintPending;
  }, [
    borrowStatus?.pending,
    depositStatus?.pending,
    repayStatus?.pending,
    withdrawStatus?.pending,
    burnAndMintStatus?.pending,
  ]);

  const pendingAction: Nullable<Action> = useMemo(() => {
    if (depositStatus?.pending) {
      return 'deposit';
    }

    if (withdrawStatus?.pending) {
      return 'withdraw';
    }

    if (borrowStatus?.pending) {
      return 'borrow';
    }

    if (repayStatus?.pending) {
      return 'repay';
    }

    if (burnAndMintStatus?.pending) {
      return 'adjust';
    }

    return null;
  }, [
    borrowStatus?.pending,
    depositStatus?.pending,
    repayStatus?.pending,
    withdrawStatus?.pending,
    burnAndMintStatus?.pending,
  ]);

  const currentAction = useMemo(() => {
    if (!asset) {
      return null;
    }

    return pendingAction ?? action;
  }, [action, asset, pendingAction]);

  // if there is pending action but opening modal with another token/action, redirect to the pending one
  if (asset?.token && pendingAction && (asset?.token !== ticker || pendingAction !== action)) {
    return <Navigate to={`/asset/${asset?.token}/${pendingAction}${search}`} />;
  }

  return (
    <Modal
      open={true}
      title={
        <ActionModalTitle
          token={asset?.token ?? null}
          tokenPrecision={asset?.uiTokenPrecision ?? null}
          assetTier={asset?.assetTier ?? null}
        />
      }
      onClose={onClose}
    >
      {/* Horizontal line separator */}
      <div className="nostra__action-modal__separator" />

      {/* Action selection tabs */}
      {showTabs && (
        <div className="nostra__action-modal__tabs">
          {actions.map(action =>
            currentAction ? (
              <ActionModalTab
                key={action}
                action={action}
                selected={action === currentAction}
                disabled={disabledActions.has(action) || isPending}
                tooltip={action === 'borrow' ? borrowDisabledTooltip : undefined}
                onClick={onActionSelected}
              />
            ) : (
              <LoadingButtonPlaceholder width="small" height="small" />
            ),
          )}
        </div>
      )}

      {currentAction === 'deposit' && <Deposit asset={asset} onModalClose={onClose} />}
      {asset && currentAction === 'withdraw' && <Withdraw asset={asset} onModalClose={onClose} />}
      {asset && currentAction === 'borrow' && <Borrow asset={asset} onModalClose={onClose} />}
      {asset && currentAction === 'repay' && <Repay asset={asset} onModalClose={onClose} />}
      {asset && currentAction === 'adjust' && <Adjust asset={asset} onModalClose={onClose} />}
    </Modal>
  );
};
export default ActionModal;
