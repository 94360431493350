import { bind } from '@react-rxjs/core';
import { BehaviorSubject, distinctUntilChanged, filter, map, Subscription, tap } from 'rxjs';
import { AccountInterface } from 'starknet';
import { getAccountService } from '../services';
import { walletAccount$ } from './useWalletAccount';
import { emitAppEvent } from './useAppEvent';

const DEFAULT_ACCOUNT_ID = null;

const rawActiveAccountId$ = new BehaviorSubject<number | null>(DEFAULT_ACCOUNT_ID);

// Update active account ID every time wallet address changes
const fetchStream$ = walletAccount$.pipe(
  filter((walletAccount): walletAccount is AccountInterface => Boolean(walletAccount)),
  map(walletAccount => {
    try {
      const accountService = getAccountService();

      return accountService.getActiveAccountId(walletAccount.address);
    } catch (error) {
      console.error(`useActiveAccountId - failed to get active account id for ${walletAccount.address}`, error);
      return null;
    }
  }),
  tap(activeAccountId => {
    if (activeAccountId !== null) {
      rawActiveAccountId$.next(activeAccountId);

      emitAppEvent({
        eventType: 'setActiveAccount',
        accountId: activeAccountId,
        timestamp: Date.now(),
      });
    }
  }),
);

export const activeAccountId$ = rawActiveAccountId$.pipe(distinctUntilChanged());

export const [useActiveAccountId] = bind(activeAccountId$, DEFAULT_ACCOUNT_ID);

let subscription: Subscription | null = null;

export const subscribeActiveAccountId = (): void => {
  unsubscribeActiveAccountId();
  subscription = fetchStream$.subscribe();
};
export const unsubscribeActiveAccountId = (): void => subscription?.unsubscribe();
export const resetActiveAccountId = (): void => rawActiveAccountId$.next(DEFAULT_ACCOUNT_ID);

export function setActiveAccount(walletAddress: string, accountId: number) {
  const accountService = getAccountService();
  accountService.setActiveAccount(walletAddress, accountId);

  rawActiveAccountId$.next(accountId);

  emitAppEvent({
    eventType: 'setActiveAccount',
    accountId,
    timestamp: Date.now(),
  });
}
