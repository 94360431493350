import { FC, memo, useCallback, useMemo } from 'react';
import { AssetTier } from '../../../interfaces';
import { ButtonWrapper, TooltipWrapper, Typography } from '../../shared';
import AssetTierTooltip from '../../AssetTierTooltip';

import './MarketsFilter.scss';

interface MarketsFilterProps {
  name: string;
  variant: AssetTier;
  count: number;
  selected: boolean;
  onClick: (id: AssetTier, selected: boolean) => void;
}

const MarketsFilter: FC<MarketsFilterProps> = props => {
  const { name, variant, count, selected, onClick } = props;

  const labelTextColor = useMemo(() => {
    if (!selected || ['cross', 'isolated'].includes(variant)) {
      return 'text-primary';
    }

    return 'text-primary-inverted';
  }, [selected, variant]);

  const handleClick = useCallback(() => {
    onClick(variant, !selected);
  }, [selected, variant, onClick]);

  return (
    <TooltipWrapper
      anchorClasses="nostra__markets-filter__container"
      tooltipContent={<AssetTierTooltip assetTier={variant} />}
      openEvent="mouseenter"
      placement="bottom-start"
    >
      <ButtonWrapper
        className={`nostra__markets-filter nostra__markets-filter-${variant} ${
          selected ? 'nostra__markets-filter-selected' : ''
        }`}
        onClick={handleClick}
        value={name.toLocaleLowerCase()}
      >
        <Typography variant="body-primary" weight="medium" color={labelTextColor}>
          {name}
        </Typography>
        <div className="nostra__markets-filter__count">
          <Typography variant="body-tertiary" weight="bold" color="text-primary">
            {count}
          </Typography>
        </div>
      </ButtonWrapper>
    </TooltipWrapper>
  );
};

export default memo(MarketsFilter);
