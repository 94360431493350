import { FC, useCallback } from 'react';
import ButtonWrapper from '../ButtonWrapper';
import Typography from '../Typography';

import './TablePaginationButton.scss';

interface TablePaginationButtonProps {
  page: number;
  selected: boolean;
  onClick: (page: number) => void;
}

const TablePaginationButton: FC<TablePaginationButtonProps> = ({ page, selected, onClick }) => {
  const handleOnClick = useCallback(() => {
    onClick(page);
  }, [page, onClick]);

  return (
    <ButtonWrapper className="nostra__tablePagination__button" selected={selected} onClick={handleOnClick}>
      <Typography variant="body-secondary" color="text-secondary">
        {page}
      </Typography>
    </ButtonWrapper>
  );
};
export default TablePaginationButton;
