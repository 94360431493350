import { Decimal } from '../datastructures';
import { Nullable } from './Nullable';
import { Token } from './Token';

export const DEBT_TIERS = ['restricted', 'isolated', 'shared'] as const;
export const ASSET_TIERS = ['nominal', 'isolated', 'cross', 'shared', 'restricted'] as const;

export type DebtTier = typeof DEBT_TIERS[number];
export type AssetTier = typeof ASSET_TIERS[number];

export type IdleToken = `n${Token}`;
export type InterestBearingToken = `i${Token}`;
export type CollateralToken = `${IdleToken}-c`;
export type InterestBearingCollateralToken = `${InterestBearingToken}-c`;
export type DebtToken = `d${Token}`;

export interface Asset {
  address: string;
  token: Token;
  tokenPrecision: number;
  uiTokenPrecision: number;
  fiatTokenPrecision: number;
  nostraInterestBearingTokenAddress: string;
  nostraInterestBearingCollateralTokenAddress: string;
  nostraTokenAddress: string;
  nostraCollateralTokenAddress: string;
  debtTokenAddress: string;
  nostraInterestBearingTokenSymbol: InterestBearingToken;
  nostraInterestBearingCollateralTokenSymbol: InterestBearingCollateralToken;
  nostraTokenSymbol: IdleToken;
  nostraCollateralTokenSymbol: CollateralToken;
  debtTokenSymbol: DebtToken;
  assetTier: Nullable<AssetTier>;
  supplyApy: Nullable<Decimal>;
  borrowApy: Nullable<Decimal>;
  optimalUtilizationRate: Nullable<Decimal>;
  baseBorrowRate: Nullable<Decimal>;
  rateSlope1: Nullable<Decimal>;
  rateSlope2: Nullable<Decimal>;
  generalProtocolFee: Nullable<Decimal>;
  totalSupply: Nullable<Decimal>;
  totalBorrow: Nullable<Decimal>;
  availableForBorrowing: Nullable<Decimal>;
  utilizationRate: Nullable<Decimal>;
  collateralFactor: Nullable<Decimal>;
  debtFactor: Nullable<Decimal>;
  nostraInterestBearingCollateralSupply: Nullable<Decimal>;
  nostraInterestBearingSupply: Nullable<Decimal>;
  nostraSupply: Nullable<Decimal>;
  nostraCollateralSupply: Nullable<Decimal>;
  debtTokenSupply: Nullable<Decimal>;
  nostraInterestBearingCollateralSupplyCap: Nullable<Decimal>;
  nostraInterestBearingSupplyCap: Nullable<Decimal>;
  nostraSupplyCap: Nullable<Decimal>;
  nostraCollateralSupplyCap: Nullable<Decimal>;
  debtTokenSupplyCap: Nullable<Decimal>;
}

/**
 * Returns an asset tier from the contracts' debt tier and collateral eligibility.
 */
export function getAssetTier(debtTier: DebtTier, isCollateralEnabled: boolean): AssetTier {
  switch (debtTier) {
    case 'restricted':
      return isCollateralEnabled ? 'nominal' : 'restricted';

    case 'isolated':
      if (isCollateralEnabled) {
        console.warn('getAssetTier -> Isolated asset with collateral enabled is not supported.');
      }

      return 'isolated';

    case 'shared':
      return isCollateralEnabled ? 'shared' : 'cross';
  }
}
