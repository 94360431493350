import { FC } from 'react';
import { Nullable } from '../../../interfaces';
import { LoadingPlaceholder, Typography } from '../../shared';

interface SummaryBoxMainValueProps {
  title: string;
  value: Nullable<string>;
}

const SummaryBoxMainValue: FC<SummaryBoxMainValueProps> = props => {
  const { title, value } = props;

  return (
    <div className="nostra__summary-box__main-value">
      <Typography variant="body-primary" color="text-secondary">
        {title}
      </Typography>
      {value ? (
        <Typography variant="subheader" weight="bold" color="text-primary-accent">
          {value}
        </Typography>
      ) : (
        <LoadingPlaceholder shape={{ width: 'large', height: 'small' }} />
      )}
    </div>
  );
};

export default SummaryBoxMainValue;
