import { FC, memo, useCallback, useEffect, useMemo, useRef } from 'react';
import Blockies from 'react-blockies';
import { useTranslation } from 'react-i18next';
import { getConfigManager } from '../../config/getConfigManager';
import { truncateHex } from '../../helpers';
import { useWalletAccount, useNotifications } from '../../hooks';
import { Notification } from '../../interfaces';
import { timeago } from '../../utils';
import { ShadowContainer, Typography } from '../shared';

import './NotificationItem.scss';

interface NotificationItemProps {
  notification: Notification;
}

const NotificationItem: FC<NotificationItemProps> = props => {
  const { notification } = props;
  const { userAddress, action, amount, tokenAddress, error, timestamp } = notification;
  const { t } = useTranslation();
  const walletAccount = useWalletAccount();
  const [, { removeNotification }] = useNotifications();
  const notifcationRef = useRef<HTMLDivElement>(null);
  const token = getConfigManager().getTokenByAddress(tokenAddress);
  const time = useMemo(() => timeago(timestamp), [timestamp]);
  const user = useMemo(
    () => truncateHex(userAddress ?? walletAccount?.address ?? ''),
    [walletAccount?.address, userAddress],
  );
  const isCurrentUser = !userAddress || userAddress === walletAccount?.address;

  // auto-close after 20s
  const handleClose = useCallback(() => {
    if (notifcationRef.current) {
      // these are for the transition effect. when a notification is closed,
      //  instead of rendering instantly like teleport, the notifcations after this one will drop gradually
      //  to achieve this we use max-height as transition-property (translateY/scaleY wont work)
      //  we need to set an explicit height for max-height first, and then set it to zero some time after
      const divHeight = notifcationRef.current.getBoundingClientRect().height;
      notifcationRef.current.style.maxHeight = `${divHeight}px`;
      notifcationRef.current.classList.add('dismissing');

      setTimeout(() => {
        if (notifcationRef.current) {
          notifcationRef.current.style.maxHeight = '0';
        }
      }, 10);

      // after 0.5s (the transition-delay time), we dismiss this notification from the list
      setTimeout(() => {
        removeNotification(notification.id);
      }, 500);
    }
  }, [notification.id, removeNotification]);

  useEffect(() => {
    // automatically dismiss this notification after 20s
    setTimeout(handleClose, 20000);
  }, [handleClose]);

  const content = useMemo(() => {
    const param = { amount: amount.toString(), token };
    if (!error) {
      switch (action) {
        case 'approve':
          return isCurrentUser ? t('NotificationItem.detailApproveByUser', param) : '';
        case 'deposit':
          return isCurrentUser
            ? t('NotificationItem.detailDepositByUser', param)
            : t('NotificationItem.detailDepositByOthers', param);
        case 'add-collateral':
          return isCurrentUser ? t('NotificationItem.detailAddCollateralByUser', param) : '';
        case 'add-supply':
          return isCurrentUser
            ? t('NotificationItem.detailAddSupplyByUser', param)
            : t('NotificationItem.detailAddSupplyByOthers', param);
        case 'withdraw':
          return isCurrentUser
            ? t('NotificationItem.detailWithdrawByUser', param)
            : t('NotificationItem.detailWithdrawByOthers', param);
        case 'borrow':
          return isCurrentUser
            ? t('NotificationItem.detailBorrowByUser', param)
            : t('NotificationItem.detailBorrowByOthers', param);
        case 'repay':
          return isCurrentUser
            ? t('NotificationItem.detailRepayByUser', param)
            : t('NotificationItem.detailRepayByOthers', param);
        case 'remove-collateral':
          return isCurrentUser ? t('NotificationItem.detailRemoveCollateralByUser', param) : '';
        case 'remove-supply':
          return isCurrentUser
            ? t('NotificationItem.detailRemoveSupplyByUser', param)
            : t('NotificationItem.detailRemoveSupplyByOthers', param);
        default:
          return '';
      }
    } else {
      switch (action) {
        case 'approve':
          return t('NotificationItem.detailApproveFailedByUser', param);
        case 'deposit':
          return t('NotificationItem.detailDepositFailedByUser', param);
        case 'add-collateral':
          return t('NotificationItem.detailAddCollateralFailedByUser', param);
        case 'add-supply':
          return t('NotificationItem.detailAddSupplyFailedByUser', param);
        case 'withdraw':
          return t('NotificationItem.detailWithdrawFailedByUser', param);
        case 'borrow':
          return t('NotificationItem.detailBorrowFailedByUser', param);
        case 'repay':
          return t('NotificationItem.detailRepayFailedByUser', param);
        case 'remove-collateral':
          return t('NotificationItem.detailRemoveCollateralFailedByUser', param);
        case 'remove-supply':
          return t('NotificationItem.detailRemoveSupplyFailedByUser', param);
        default:
          return '';
      }
    }
  }, [isCurrentUser, action, amount, token, error, t]);

  if (!content) {
    return null;
  }

  return (
    <div className="nostra__notification-item__outer-container" ref={notifcationRef}>
      <ShadowContainer className="nostra__notification-item">
        <div className="nostra__notification-item__user-avatar">
          <Blockies seed={user} scale={4} size={8} />
        </div>
        <div className="nostra__notification-item__detail">
          <Typography className="nostra__notification-item__detail-desc" variant="body-primary" weight="medium">
            {content}
          </Typography>
          {error && (
            <div className="nostra__notification-item__detail-error">
              <Typography variant="body-tertiary">{error}</Typography>
            </div>
          )}
          <div className="nostra__notification-item__detail-info">
            <Typography
              className="nostra__notification-item__detail-time"
              variant="body-tertiary"
              color="text-disabled"
            >
              {time}
            </Typography>
            <Typography
              className="nostra__notification-item__detail-time"
              variant="body-tertiary"
              color="text-disabled"
            >
              by
            </Typography>
            <Typography className="nostra__notification-item__detail-user" variant="body-tertiary">
              {user}
            </Typography>
          </div>
        </div>
      </ShadowContainer>
    </div>
  );
};

export default memo(NotificationItem);
