import { sub } from 'date-fns';
import { FC, memo, useEffect, useMemo, useState } from 'react';
import { Nullable, ParsedChartData, TimeResolution } from '../../../interfaces';
import { DecimalUtilsCurrency } from '../../../utils/decimalUtils';
import { ChartDataPoint, DateChart, LabelFormat, Loading, Logo, LogoType, Typography } from '../../shared';
import TimeResolutionSelector from './TimeResolutionSelector';
import YAxisTick from './YAxisTick';

import './Graph.scss';

export interface DataPoint extends ChartDataPoint<Date, number> {
  visible?: boolean;
  selected?: boolean;
}

type GraphProps = {
  title: string;
  titleLogo?: LogoType;
  parsedChartData1: Nullable<ParsedChartData[]>;
  parsedChartData2?: Nullable<ParsedChartData[]>;
  yAxisLabel?: string;
  currencySymbol?: DecimalUtilsCurrency;
  yAxisLabelFormatting?: LabelFormat;
  tooltipValueFormatting?: LabelFormat;
  tooltipValueLabel1?: string;
  tooltipValueLabel2?: string;
  onDateFromChange: (timestamp: Nullable<number>) => void;
};

const Graph: FC<GraphProps> = ({
  title,
  titleLogo,
  parsedChartData1,
  parsedChartData2,
  yAxisLabel,
  yAxisLabelFormatting = 'decimal',
  tooltipValueFormatting = 'decimal',
  tooltipValueLabel1,
  tooltipValueLabel2,
  currencySymbol,
  onDateFromChange,
}) => {
  const [timeResolution, setTimeResolution] = useState<TimeResolution>('1d');

  const handleTimeResolutionChange = (timeResolution: TimeResolution) => {
    setTimeResolution(timeResolution);
  };

  useEffect(() => {
    switch (timeResolution) {
      case 'a':
        onDateFromChange(null);
        break;
      case '1y':
        onDateFromChange(sub(new Date(), { years: 1 }).getTime());
        break;
      case '6m':
        onDateFromChange(sub(new Date(), { months: 6 }).getTime());
        break;
      case '3m':
        onDateFromChange(sub(new Date(), { months: 3 }).getTime());
        break;
      case '1m':
        onDateFromChange(sub(new Date(), { months: 1 }).getTime());
        break;
      case '1d':
        onDateFromChange(sub(new Date(), { days: 1 }).getTime());
        break;
    }
  }, [timeResolution, onDateFromChange]);

  const data = useMemo((): Nullable<DataPoint[]> => {
    if (!parsedChartData1) {
      return null;
    }

    const dataPoint1: DataPoint[] = parsedChartData1.map(dataEntry => ({
      x: new Date(dataEntry.timestamp),
      y1: dataEntry.value,
    }));

    if (!parsedChartData2) {
      return dataPoint1;
    }

    const dataPoint2: DataPoint[] = parsedChartData2.map(dataEntry => ({
      x: new Date(dataEntry.timestamp),
      y2: dataEntry.value,
    }));
    const combinedDataPoint = dataPoint1.concat(dataPoint2).sort((a, b) => a.x.getTime() - b.x.getTime());
    let lastY1: number;
    let lastY2: number;

    return combinedDataPoint.map((point, i) => {
      lastY1 = point.y1 ?? lastY1;
      lastY2 = point.y2 ?? lastY2;

      return {
        x: point.x,
        y1: lastY1,
        y2: lastY2,
      };
    });
  }, [parsedChartData1, parsedChartData2]);

  return (
    <div className="nostra__graph__container">
      <div className="nostra__graph__heading">
        <Typography variant="subtitle" weight="bold" color="chart">
          {titleLogo && <Logo type={titleLogo} />}
          {title}
        </Typography>
        <TimeResolutionSelector onTimeResolutionChange={handleTimeResolutionChange} selected={timeResolution} />
      </div>
      <div className="nostra__graph">
        {data ? (
          <DateChart
            timeScale={timeResolution}
            data={data}
            height={448}
            width="100%"
            yTick={<YAxisTick formatting={yAxisLabelFormatting} label={yAxisLabel} />}
            tooltipValueFormatting={tooltipValueFormatting}
            tooltipValueLabel1={tooltipValueLabel1}
            tooltipValueLabel2={tooltipValueLabel2}
            currencySymbol={currencySymbol}
          />
        ) : (
          <Loading size={48} color="secondary" />
        )}
      </div>
    </div>
  );
};

export default memo(Graph);
