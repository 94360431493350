import { Decimal, ZERO } from '../datastructures';

export function weightedAverage(values: Decimal[], weights: Decimal[]) {
  const [sum, weightSum] = weights.reduce(
    (acc, weight, index) => {
      acc[0] = acc[0].add(values[index].mul(weight));
      acc[1] = acc[1].add(weight);
      return acc;
    },
    [ZERO, ZERO],
  );

  if (sum.equals(ZERO)) {
    return ZERO;
  }

  return sum.div(weightSum);
}
