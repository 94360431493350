import { bind } from '@react-rxjs/core';
import {
  BehaviorSubject,
  combineLatest,
  debounce,
  distinctUntilChanged,
  interval,
  map,
  merge,
  mergeMap,
  Observable,
  of,
  scan,
  Subscription,
  tap,
  filter,
  catchError,
} from 'rxjs';
import { getConfigManager } from '../config/getConfigManager';
import { DEBOUNCE_IN_MS } from '../constants';
import { Decimal, ZERO } from '../datastructures';
import { Asset, AssetConfig, AssetTier, getAssetTier, Nullable } from '../interfaces';
import { collateralData$, CollateralDataMap } from './useCollateralData';
import { debtData$, DebtDataMap } from './useDebtData';
import { interestRateConfig$, InterestRateConfigMap } from './useInterestRateConfig';
import { interestState$, InterestStateMap } from './useInterestState';
import { nostraTokenTotalSupply$, NostraTokenTotalSupplyMap } from './useNostraTokenTotalSupply';
import { nostraTokenTotalSupplyCap$, NostraTokenTotalSupplyCapMap } from './useNostraTokenTotalSupplyCap';

type AddressAssetMap = { [address: string]: Asset };

interface AssetData {
  assetTier: AssetTier;
  totalSupply: Decimal;
  nostraInterestBearingSupply: Decimal;
  nostraInterestBearingCollateralSupply: Decimal;
  nostraSupply: Decimal;
  nostraCollateralSupply: Decimal;
  debtTokenSupply: Decimal;
  nostraInterestBearingSupplyCap: Decimal;
  nostraInterestBearingCollateralSupplyCap: Decimal;
  nostraSupplyCap: Decimal;
  nostraCollateralSupplyCap: Decimal;
  debtTokenSupplyCap: Decimal;
  currentLendingRate: Decimal;
  currentBorrowRate: Decimal;
  optimalUtilizationRate: Decimal;
  baseBorrowRate: Decimal;
  rateSlope1: Decimal;
  rateSlope2: Decimal;
  generalProtocolFee: Decimal;
  collateralFactor: Decimal;
  debtTokenAddress: string;
  debtFactor: Decimal;
}

const DEFAULT_VALUE: Asset[] = Object.values(getConfigManager().getConfig().assets).map(
  config =>
    ({
      address: config.address,
      token: config.ticker,
      tokenPrecision: config.tokenPrecision,
      uiTokenPrecision: config.uiTokenPrecision,
      nostraInterestBearingTokenAddress: config.nostraInterestBearingTokenAddress,
      nostraInterestBearingCollateralTokenAddress: config.nostraInterestBearingCollateralTokenAddress,
      nostraTokenAddress: config.nostraTokenAddress,
      nostraCollateralTokenAddress: config.nostraCollateralTokenAddress,
      debtTokenAddress: config.debtTokenAddress,
    } as Asset),
);

const rawAssets$ = new BehaviorSubject<Asset[]>(DEFAULT_VALUE);
export const assets$ = rawAssets$.pipe(distinctUntilChanged());

const fetchData = (
  asset: AssetConfig,
  debtData: DebtDataMap,
  interestRateConfig: InterestRateConfigMap,
  collateralData: CollateralDataMap,
  interestState: InterestStateMap,
  nostraTokenTotalSupply: NostraTokenTotalSupplyMap,
  nostraTokenTotalSupplyCap: NostraTokenTotalSupplyCapMap,
): Observable<AssetData | null> => {
  const { address } = asset;

  try {
    return of({
      assetTier: getAssetTier(
        debtData[asset.debtTokenAddress].debtTier,
        !nostraTokenTotalSupplyCap[asset.nostraInterestBearingCollateralTokenAddress]
          .add(nostraTokenTotalSupplyCap[asset.nostraCollateralTokenAddress])
          .equals(ZERO),
      ),
      totalSupply: nostraTokenTotalSupply[asset.nostraInterestBearingTokenAddress]
        .add(nostraTokenTotalSupply[asset.nostraInterestBearingCollateralTokenAddress])
        .add(nostraTokenTotalSupply[asset.nostraTokenAddress])
        .add(nostraTokenTotalSupply[asset.nostraCollateralTokenAddress]),
      nostraInterestBearingSupply: nostraTokenTotalSupply[asset.nostraInterestBearingTokenAddress],
      nostraInterestBearingCollateralSupply: nostraTokenTotalSupply[asset.nostraInterestBearingCollateralTokenAddress],
      nostraSupply: nostraTokenTotalSupply[asset.nostraTokenAddress],
      nostraCollateralSupply: nostraTokenTotalSupply[asset.nostraCollateralTokenAddress],
      debtTokenSupply: nostraTokenTotalSupply[asset.debtTokenAddress],
      nostraInterestBearingSupplyCap: nostraTokenTotalSupplyCap[asset.nostraInterestBearingTokenAddress],
      nostraInterestBearingCollateralSupplyCap:
        nostraTokenTotalSupplyCap[asset.nostraInterestBearingCollateralTokenAddress],
      nostraSupplyCap: nostraTokenTotalSupplyCap[asset.nostraTokenAddress],
      nostraCollateralSupplyCap: nostraTokenTotalSupplyCap[asset.nostraCollateralTokenAddress],
      debtTokenSupplyCap: nostraTokenTotalSupplyCap[asset.debtTokenAddress],
      currentLendingRate: interestState[asset.debtTokenAddress].lendingRate,
      currentBorrowRate: interestState[asset.debtTokenAddress].borrowRate,
      optimalUtilizationRate: interestRateConfig[asset.debtTokenAddress].optimalUtilizationRate,
      baseBorrowRate: interestRateConfig[asset.debtTokenAddress].baseBorrowRate,
      rateSlope1: interestRateConfig[asset.debtTokenAddress].rateSlope1,
      rateSlope2: interestRateConfig[asset.debtTokenAddress].rateSlope2,
      generalProtocolFee: interestRateConfig[asset.debtTokenAddress].generalProtocolFee,
      collateralFactor: collateralData[asset.address].collateralFactor,
      debtTokenAddress: asset.debtTokenAddress,
      debtFactor: debtData[asset.debtTokenAddress].debtFactor,
    });
  } catch (error) {
    console.error(`useAssets - Cannot load reserve data for address ${address}`, error);
    return of(null);
  }
};

const processAssetData = (assetConfig: AssetConfig, assetData: Nullable<AssetData>): Nullable<Asset> => {
  if (!assetData) {
    return null;
  }

  const {
    address,
    ticker,
    tokenPrecision,
    uiTokenPrecision,
    fiatTokenPrecision,
    nostraInterestBearingTokenAddress,
    nostraInterestBearingCollateralTokenAddress,
    nostraTokenAddress,
    nostraCollateralTokenAddress,
    debtTokenAddress,
    nostraInterestBearingTokenSymbol,
    nostraInterestBearingCollateralTokenSymbol,
    nostraTokenSymbol,
    nostraCollateralTokenSymbol,
    debtTokenSymbol,
  } = assetConfig;
  const {
    assetTier,
    currentLendingRate,
    currentBorrowRate,
    optimalUtilizationRate,
    baseBorrowRate,
    rateSlope1,
    rateSlope2,
    generalProtocolFee,
    totalSupply,
    collateralFactor,
    debtFactor,
    nostraInterestBearingSupply,
    nostraInterestBearingCollateralSupply,
    nostraCollateralSupply,
    nostraSupply,
    debtTokenSupply,
    nostraInterestBearingSupplyCap,
    nostraInterestBearingCollateralSupplyCap,
    nostraCollateralSupplyCap,
    nostraSupplyCap,
    debtTokenSupplyCap,
  } = assetData;
  const availableForBorrowing = Decimal.max(
    assetData.nostraInterestBearingSupply
      .add(assetData.nostraInterestBearingCollateralSupply)
      .sub(assetData.debtTokenSupply),
    ZERO,
  );

  let utilizationRate;
  if (assetData.debtTokenSupply.equals(ZERO)) {
    utilizationRate = ZERO;
  } else {
    utilizationRate = assetData.debtTokenSupply.div(
      assetData.nostraInterestBearingSupply.add(assetData.nostraInterestBearingCollateralSupply),
    );
  }

  return {
    address,
    token: ticker,
    tokenPrecision,
    uiTokenPrecision,
    fiatTokenPrecision,
    nostraInterestBearingTokenAddress,
    nostraInterestBearingCollateralTokenAddress,
    nostraTokenAddress,
    nostraCollateralTokenAddress,
    debtTokenAddress,
    nostraInterestBearingTokenSymbol,
    nostraInterestBearingCollateralTokenSymbol,
    nostraTokenSymbol,
    nostraCollateralTokenSymbol,
    debtTokenSymbol,
    assetTier,
    supplyApy: currentLendingRate,
    borrowApy: currentBorrowRate,
    optimalUtilizationRate,
    baseBorrowRate,
    rateSlope1,
    rateSlope2,
    generalProtocolFee,
    totalSupply,
    totalBorrow: debtTokenSupply,
    availableForBorrowing,
    collateralFactor,
    debtFactor,
    nostraInterestBearingSupply,
    nostraInterestBearingCollateralSupply,
    nostraCollateralSupply,
    nostraSupply,
    debtTokenSupply,
    nostraInterestBearingSupplyCap,
    nostraInterestBearingCollateralSupplyCap,
    nostraCollateralSupplyCap,
    nostraSupplyCap,
    debtTokenSupplyCap,
    utilizationRate,
  };
};

const fetchAssets = (
  assetConfigList: AssetConfig[],
  debtDataMap: DebtDataMap,
  interestRateConfig: InterestRateConfigMap,
  collateralData: CollateralDataMap,
  interestState: InterestStateMap,
  nostraTokenTotalSupply: NostraTokenTotalSupplyMap,
  nostraTokenTotalSupplyCap: NostraTokenTotalSupplyCapMap,
): Observable<Nullable<Asset>>[] => {
  return assetConfigList.map(assetConfig =>
    fetchData(
      assetConfig,
      debtDataMap,
      interestRateConfig,
      collateralData,
      interestState,
      nostraTokenTotalSupply,
      nostraTokenTotalSupplyCap,
    ).pipe(
      map(assetData => processAssetData(assetConfig, assetData)),
      catchError(error => {
        console.error(`useAssets - Cannot process the asset data for address ${assetConfig.address}`, error);
        return of(null);
      }),
    ),
  );
};

const assetConfigList$ = of(Object.values(getConfigManager().getConfig().assets));

const updateStream$: Observable<Asset | null> = combineLatest([
  assetConfigList$,
  debtData$,
  interestRateConfig$,
  collateralData$,
  interestState$,
  nostraTokenTotalSupply$,
  nostraTokenTotalSupplyCap$,
]).pipe(
  filter(
    (
      value,
    ): value is [
      AssetConfig[],
      DebtDataMap,
      InterestRateConfigMap,
      CollateralDataMap,
      InterestStateMap,
      NostraTokenTotalSupplyMap,
      NostraTokenTotalSupplyCapMap,
    ] => {
      const [
        ,
        debtDataMap,
        interestRateConfigMap,
        collateralDataMap,
        interestStateMap,
        nostraTokenTotalSupply,
        nostraTokenTotalSupplyCap,
      ] = value;

      return (
        debtDataMap !== null &&
        interestRateConfigMap !== null &&
        collateralDataMap !== null &&
        interestStateMap !== null &&
        nostraTokenTotalSupply !== null &&
        nostraTokenTotalSupplyCap !== null
      );
    },
  ),
  mergeMap(
    ([
      assetConfigList,
      debtDataMap,
      interestRateConfigMap,
      collateralDataMap,
      interestStateMap,
      nostraTokenTotalSupply,
      nostraTokenTotalSupplyCap,
    ]) =>
      merge(
        ...fetchAssets(
          assetConfigList,
          debtDataMap,
          interestRateConfigMap,
          collateralDataMap,
          interestStateMap,
          nostraTokenTotalSupply,
          nostraTokenTotalSupplyCap,
        ),
      ),
  ),
);

const stream$ = merge(updateStream$).pipe(
  scan((allAssets, asset) => {
    if (!asset) {
      return allAssets;
    }

    return {
      ...allAssets,
      [asset.address]: asset,
    };
  }, {} as AddressAssetMap),
  map(assets => Object.values(assets).sort((a, b) => a.token.localeCompare(b.token))), // TODO: support more sorting options
  debounce(() => interval(DEBOUNCE_IN_MS)),
  tap(assets => rawAssets$.next(assets)),
);

export const [useAssets] = bind(assets$, DEFAULT_VALUE);

let subscription: Subscription;

export const subscribeAssets = (): void => {
  unsubscribeAssets();
  subscription = stream$.subscribe();
};
export const unsubscribeAssets = (): void => subscription?.unsubscribe?.();
export const resetAssets = (): void => rawAssets$.next(DEFAULT_VALUE);
