import { TableBodyCell, TableRow, Typography } from '../../shared';

const DepositPositionRowEmpty = () => {
  return (
    <TableRow id="deposit-position-row-empty">
      <TableBodyCell>
        <Typography variant="body-primary">&#8213;</Typography>
      </TableBodyCell>
      <TableBodyCell align="center">
        <Typography variant="body-primary">&#8213;</Typography>
      </TableBodyCell>
      <TableBodyCell align="right">
        <Typography variant="body-primary">&#8213;</Typography>
      </TableBodyCell>
      <TableBodyCell align="center">
        <Typography variant="body-primary">&#8213;</Typography>
      </TableBodyCell>
      <TableBodyCell align="right">
        <Typography variant="body-primary">&#8213;</Typography>
      </TableBodyCell>
      <TableBodyCell />
    </TableRow>
  );
};
export default DepositPositionRowEmpty;
