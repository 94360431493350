import { bind, state, useStateObservable } from '@react-rxjs/core';
import { createSignal } from '@react-rxjs/utils';
import { combineLatest, distinctUntilChanged, map } from 'rxjs';
import { useCallback } from 'react';
import { AssetTier, FilterType } from '../interfaces';
import { ONE } from '../datastructures';
import { assets$ } from './useAssets';
import { tokenBalanceMap$ } from './useTokenBalance';
import { tokenRates$ } from './useTokenRates';

export interface AssetViewOptions {
  filters: FilterType;
  smallBalancesHidden: boolean;
}

export const SMALL_BALANCE_THRESHOLD = ONE;

const [rawFilters$, setFilters] = createSignal<FilterType>();
const [rawFilterSmallBalances$, setFilterSmallBalances] = createSignal<boolean>();

const filters$ = rawFilters$.pipe(distinctUntilChanged());
const filterSmallBalances$ = rawFilterSmallBalances$.pipe(distinctUntilChanged());

const stateFilters$ = state(filters$, {
  nominal: true,
  isolated: true,
  cross: true,
  shared: true,
  restricted: true,
});
const stateFilterSmallBalances$ = state(filterSmallBalances$, false);

const filteredAssetList$ = combineLatest([
  assets$,
  stateFilters$,
  stateFilterSmallBalances$,
  tokenBalanceMap$,
  tokenRates$,
]).pipe(
  map(([assets, filters, filterSmallBalances, tokenBalanceMap, tokenRates]) =>
    assets.filter(asset => {
      // asset is not yet loaded, so it shouldn't be filtered as it is needed for skeleton
      if (!asset.assetTier) {
        return true;
      }

      const smallBalances =
        !filterSmallBalances ||
        tokenBalanceMap[asset.address]?.mul(tokenRates[asset.address]).gte(SMALL_BALANCE_THRESHOLD);

      return filters[asset.assetTier] && smallBalances;
    }),
  ),
);

export function useAssetFilterOptions(): [
  AssetViewOptions,
  (partial: AssetTier, selected: boolean) => void,
  (hide: boolean) => void,
] {
  const filters = useStateObservable(stateFilters$);
  const smallBalancesHidden = useStateObservable(stateFilterSmallBalances$);

  const setPartialPoolViewOptions = useCallback(
    (partial: AssetTier, selected: boolean) => {
      setFilters({ ...filters, [partial]: selected });
    },
    [filters],
  );

  const setHideSmallBalances = useCallback((hide: boolean) => setFilterSmallBalances(hide), []);

  return [{ filters, smallBalancesHidden }, setPartialPoolViewOptions, setHideSmallBalances];
}

export const [useFilteredAssetList] = bind(filteredAssetList$, []);
