import { FC } from 'react';
import { InnerIconProps } from './IconProps';
import withIcon from './withIcon';

const NavigateNext: FC<InnerIconProps> = ({ size, color }) => (
  <svg
    className="nostra__icon nostra__icon-navigate-next"
    width={size}
    height={size}
    viewBox="0 0 6 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.293 9.70741C0.105529 9.51988 0.000213623 9.26557 0.000213623 9.00041C0.000213623 8.73524 0.105529 8.48094 0.293 8.29341L3.586 5.00041L0.293 1.70741C0.110842 1.5188 0.0100473 1.2662 0.0123258 1.00401C0.0146042 0.741809 0.119773 0.490997 0.305182 0.305589C0.49059 0.120181 0.741402 0.0150115 1.0036 0.0127331C1.2658 0.0104547 1.5184 0.111249 1.707 0.293408L5.707 4.29341C5.89447 4.48094 5.99979 4.73524 5.99979 5.00041C5.99979 5.26557 5.89447 5.51988 5.707 5.70741L1.707 9.70741C1.51947 9.89488 1.26516 10.0002 1 10.0002C0.734836 10.0002 0.480528 9.89488 0.293 9.70741Z"
      fill={color}
    />
  </svg>
);

export default withIcon(NavigateNext);
