import { FC, memo } from 'react';
import BorrowPositions from './BorrowPositions';
import DepositPositions from './DepositPositions';

import './Positions.scss';

const Positions: FC = () => (
  <div className="nostra__positions">
    <DepositPositions />
    <BorrowPositions />
  </div>
);

export default memo(Positions);
