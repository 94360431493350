import { state, useStateObservable } from '@react-rxjs/core';
import { createSignal } from '@react-rxjs/utils';
import { distinctUntilChanged } from 'rxjs';
import { Nullable } from '../interfaces';

const [rawGlobalError$, setGlobalError] = createSignal<Nullable<Error>>();
const globalError$ = rawGlobalError$.pipe(distinctUntilChanged());
const stateGlobalError$ = state(globalError$, null);

export const useGlobalError = (): [Nullable<Error>, (value: Nullable<Error>) => void] => {
  const globalError = useStateObservable(stateGlobalError$);
  return [globalError, setGlobalError];
};

export const logGlobalError = (error: unknown) => setGlobalError(error instanceof Error ? error : null);

export { globalError$ };
