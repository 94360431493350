import { bind } from '@react-rxjs/core';
import { BehaviorSubject, catchError, combineLatest, merge, mergeMap, of, Subscription, tap } from 'rxjs';
import { getConfigManager } from '../config/getConfigManager';
import { MULTICALL_CONTRACT_ADDRESS } from '../constants';
import { Decimal, DEFAULT_DECIMAL_PRECISION } from '../datastructures';
import { AssetConfig } from '../interfaces';
import { getMultiCallContract } from '../services';
import { config$ } from './useConfig';
import { logGlobalError } from './useGlobalError';

const DEFAULT_VALUE = null;

export interface CollateralData {
  collateralFactor: Decimal;
}

export type CollateralDataMap = { [assetAddress: string]: CollateralData };

export const collateralData$ = new BehaviorSubject<CollateralDataMap | null>(DEFAULT_VALUE);

const RESPONSE_CHUNK_SIZE = 6;

const fetchData = (assets: AssetConfig[]) => {
  try {
    const config = getConfigManager().getConfig();

    const calldata = assets.map(asset => {
      return {
        contractAddress: config.cdpManager,
        entrypoint: 'getCollateralData',
        calldata: [asset.address],
      };
    });

    const multiCallContract = getMultiCallContract(MULTICALL_CONTRACT_ADDRESS);

    return multiCallContract.aggregate(calldata).pipe(
      mergeMap(result => {
        if (!result) {
          return of(null);
        }

        const collateralDataMap: CollateralDataMap = {};

        for (let i = 0; i < result.length; i += RESPONSE_CHUNK_SIZE) {
          // Index 3,4 is for collateralFactor
          const collateralFactor = new Decimal({ low: result[i + 3], high: result[i + 4] }, DEFAULT_DECIMAL_PRECISION);

          collateralDataMap[assets[i / RESPONSE_CHUNK_SIZE].address] = {
            collateralFactor,
          };
        }

        return of(collateralDataMap);
      }),
      catchError(error => {
        console.error(`useCollateralData - Failed to load collateral data!`, error);
        logGlobalError(error);
        return of(null);
      }),
    );
  } catch (error) {
    console.error(`useCollateralData - Failed to load collateral data!`, error);
    logGlobalError(error);
    return of(null);
  }
};

/**
 * Collateral data does not change often. We can load it only once on app load.
 */
const initialLoadStream$ = combineLatest([config$]).pipe(
  mergeMap(([config]) => {
    const assetsConfig = Object.entries(config.assets).map(([, assetConfig]) => assetConfig);

    return fetchData(assetsConfig);
  }),
);

const stream$ = merge(initialLoadStream$).pipe(
  tap(collateralDataMap => {
    if (collateralDataMap) {
      collateralData$.next(collateralDataMap);
    }
  }),
);

export const [useCollateralData] = bind(collateralData$, DEFAULT_VALUE);

let subscription: Subscription | null = null;

export const subscribeCollateralData = (): void => {
  unsubscribeCollateralData();
  subscription = stream$.subscribe();
};
export const unsubscribeCollateralData = (): void => subscription?.unsubscribe();
export const resetCollateralData = (): void => collateralData$.next(DEFAULT_VALUE);
