import { bind } from '@react-rxjs/core';
import { combineLatest, map } from 'rxjs';
import { ZERO } from '../datastructures';
import { assets$ } from './useAssets';
import { tokenRates$ } from './useTokenRates';

const DEFAULT_VALUE = ZERO;

const totalBorrow$ = combineLatest([assets$, tokenRates$]).pipe(
  map(([assets, tokenRates]) => {
    if (assets.length === 0) {
      return null;
    }

    return assets
      .map(asset => {
        const rate = tokenRates[asset.address];
        if (!rate || !asset.totalBorrow) {
          return null;
        }

        return asset.totalBorrow.mul(rate);
      })
      .reduce((acc, totalBorrow) => {
        if (!acc || !totalBorrow) {
          return null;
        }

        return acc.add(totalBorrow);
      }, ZERO);
  }),
);

export const [useTotalBorrow] = bind(totalBorrow$, DEFAULT_VALUE);
