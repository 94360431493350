import { bind } from '@react-rxjs/core';
import { BehaviorSubject, combineLatest, filter, Subscription, tap } from 'rxjs';
import { AccountInterface } from 'starknet';
import { Decimal, ONE, ZERO } from '../datastructures';
import { Asset } from '../interfaces';
import { weightedAverage } from '../utils';
import { walletAccount$ } from './useWalletAccount';
import { assets$ } from './useAssets';
import { nostraTokenBalance$, NostraTokenBalanceMap } from './useNostraTokenBalance';
import { accountList$ } from './useAccounts';
import { accountTotalSupply$, AccountTotalSupplyMap } from './useAccountTotalSupply';
import { TokenRateMap, tokenRates$ } from './useTokenRates';

interface AccountSupplyApyMap {
  [walletAddressAccountId: string]: Decimal;
}

const accountSupplyApy$ = new BehaviorSubject<AccountSupplyApyMap>({});

type FetchStreamNonNullInputs = [
  NostraTokenBalanceMap,
  string[],
  AccountInterface,
  TokenRateMap,
  Asset[],
  AccountTotalSupplyMap,
];

const fetchStream$ = combineLatest([
  nostraTokenBalance$,
  accountList$,
  walletAccount$,
  tokenRates$,
  assets$,
  accountTotalSupply$,
]).pipe(
  filter((value): value is FetchStreamNonNullInputs => {
    const [, accounts, walletAccount] = value;
    return Boolean(accounts) && Boolean(walletAccount);
  }),
  tap(([nostraTokenBalance, accounts, walletAccount, tokenRates, assets, accountTotalSupply]) => {
    const result: AccountSupplyApyMap = {};
    accounts
      .filter(account => account !== '')
      .forEach((_, accountId) => {
        try {
          const totalSupply = accountTotalSupply[`${walletAccount.address}-${accountId}`];
          if (!totalSupply) {
            return;
          }

          const values = assets.map(asset => asset.supplyApy ?? ZERO);

          const weights = assets.map(asset => {
            const tokenRate = tokenRates[asset.address] ?? ONE;

            const nostraInterestBearingBalanceKey = `${walletAccount.address}-${accountId}-${asset.nostraInterestBearingTokenAddress}`;
            const nostraInterestBearingBalance = (nostraTokenBalance[nostraInterestBearingBalanceKey] ?? ZERO).mul(
              tokenRate,
            );

            const nostraInterestBearingCollateralBalanceKey = `${walletAccount.address}-${accountId}-${asset.nostraInterestBearingCollateralTokenAddress}`;
            const nostraInterestBearingCollateralBalance = (
              nostraTokenBalance[nostraInterestBearingCollateralBalanceKey] ?? ZERO
            ).mul(tokenRate);

            const accountAssetSupply = nostraInterestBearingBalance.add(nostraInterestBearingCollateralBalance);
            if (accountAssetSupply.equals(ZERO) || totalSupply.equals(ZERO)) {
              return ZERO;
            }

            return accountAssetSupply.div(totalSupply);
          });

          result[`${walletAccount.address}-${accountId}`] = weightedAverage(values, weights);
        } catch (error) {
          console.error(`useAccountBorrowApy - error calculating borrow apy for ID ${accountId}`, error);
        }
      });

    accountSupplyApy$.next(result);
  }),
);

export const [useAccountSupplyApy] = bind(accountSupplyApy$, {});

let subscription: Subscription;
export const subscribeAccountSupplyApy = (): void => {
  unsubscribeAccountSupplyApy();
  subscription = fetchStream$.subscribe();
};

export const unsubscribeAccountSupplyApy = (): void => subscription?.unsubscribe();
export const resetAccountSupplyApy = (): void => {
  accountSupplyApy$.next({});
};
