import { bind } from '@react-rxjs/core';
import { combineLatest, map } from 'rxjs';
import { Decimal, ZERO } from '../datastructures';
import { weightedAverage } from '../utils';
import { assets$ } from './useAssets';
import { tokenRates$ } from './useTokenRates';

const DEFAULT_VALUE = null;

const utilizationRate$ = combineLatest([assets$, tokenRates$]).pipe(
  map(([assets, tokenRates]) => {
    try {
      if (assets.length === 0) {
        return null;
      }

      const totalSupplyUsd = assets
        .map(asset => {
          const { address, nostraInterestBearingCollateralSupply, nostraInterestBearingSupply } = asset;

          const rate = tokenRates[address];
          if (!rate || !nostraInterestBearingCollateralSupply || !nostraInterestBearingSupply) {
            return null;
          }

          return ZERO.add(nostraInterestBearingSupply).add(nostraInterestBearingCollateralSupply).mul(rate);
        })
        .reduce((acc, totalSupply) => {
          if (!acc || !totalSupply) {
            return null;
          }

          return acc.add(totalSupply);
        }, ZERO);

      const values = assets.map(asset => asset.utilizationRate);
      const weights = assets.map(asset => {
        const { address, nostraInterestBearingCollateralSupply, nostraInterestBearingSupply } = asset;

        const rate = tokenRates[address];
        if (!rate || !nostraInterestBearingCollateralSupply || !nostraInterestBearingSupply || !totalSupplyUsd) {
          return null;
        }

        const assetSupplyUsd = ZERO.add(nostraInterestBearingSupply)
          .add(nostraInterestBearingCollateralSupply)
          .mul(rate);

        if (assetSupplyUsd.equals(ZERO) || totalSupplyUsd.equals(ZERO)) {
          return ZERO;
        }

        return assetSupplyUsd.div(totalSupplyUsd);
      });

      if (values.some(value => value === null) || weights.some(weight => weight === null)) {
        return null;
      }

      const nonNullValues = values.filter((value): value is Decimal => value !== null);
      const nonNullWeights = weights.filter((weight): weight is Decimal => weight !== null);

      return weightedAverage(nonNullValues, nonNullWeights);
    } catch (error) {
      console.error('useUtilizationRate -> ', error);

      return null;
    }
  }),
);

/**
 * Total value locked is sum of all nostra tokens currently deposited, without taking into account borrows.
 */
export const [useUtilizationRate] = bind(utilizationRate$, DEFAULT_VALUE);
