import { FC, useCallback, useMemo } from 'react';
import { DayPicker } from 'react-day-picker';
import { useTranslation } from 'react-i18next';
import { Nullable } from '../../../interfaces';
import Typography from '../Typography';
import ButtonWrapper from '../ButtonWrapper';
import DateRangePickerHeadRow from './DateRangePickerHeadRow';
import DateRangePickerCaption from './DateRangePickerCaption';

import 'react-day-picker/dist/style.css';

export interface DateRangePickerPanelProps {
  selectedDate: Nullable<Date>;
  onChange: (value: Nullable<Date>) => void;
}

const DateRangePickerPanel: FC<DateRangePickerPanelProps> = props => {
  const { selectedDate, onChange } = props;

  const { t } = useTranslation();

  const onSelect = useCallback(
    (day, selectedDay: Date) => {
      onChange(selectedDay);
    },
    [onChange],
  );
  const resetSelect = useCallback(() => onChange(null), [onChange]);

  const footer = useMemo(
    () => (
      <ButtonWrapper onClick={resetSelect}>
        <Typography variant="body-secondary">{t('DateRangePicker.btnClear')}</Typography>
      </ButtonWrapper>
    ),
    [resetSelect, t],
  );

  return (
    <Typography className="nostra__date-range-picker-input__panel" variant="body-secondary">
      <DayPicker
        mode="single"
        showOutsideDays
        defaultMonth={selectedDate ?? undefined}
        selected={selectedDate ?? undefined}
        onSelect={onSelect}
        footer={footer}
        components={{
          HeadRow: DateRangePickerHeadRow,
          Caption: DateRangePickerCaption,
        }}
      />
    </Typography>
  );
};

export default DateRangePickerPanel;
