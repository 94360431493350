const fibonacciNumbers = new Map<number, number>([
  [1, 1],
  [2, 1],
]);

export function fibonacci(n: number): number {
  if (n < 1) {
    throw new Error(`Invalid argument: ${n}`);
  }

  if (fibonacciNumbers.has(n)) {
    return fibonacciNumbers.get(n) as number;
  }

  const result = fibonacci(n - 1) + fibonacci(n - 2);
  fibonacciNumbers.set(n, result);

  return result;
}
