import { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Decimal } from '../../../datastructures';
import { useAssets, useTotalValueLocked, useUtilizationRate } from '../../../hooks';
import { DecimalUtils } from '../../../utils';
import SummaryBox, { SummaryBoxMainValue, SummaryBoxSideValue } from '../SummaryBox';

import './SummaryMarketOverview.scss';

const SummaryMarketOverview: FC = () => {
  const { t } = useTranslation();

  const assets = useAssets();
  const totalValueLocked = useTotalValueLocked();
  const utilizationRate = useUtilizationRate();

  const formattedTotalValueLocked = useMemo(
    () =>
      totalValueLocked !== null
        ? DecimalUtils.format(new Decimal(totalValueLocked), {
            style: 'multiplier',
            fractionDigits: 2,
            currency: '$',
            lessThanFormat: true,
          })
        : null,
    [totalValueLocked],
  );

  const formattedUtilizationRate = useMemo(
    () =>
      utilizationRate !== null
        ? DecimalUtils.format(utilizationRate, { style: 'percentage', fractionDigits: 2 })
        : null,
    [utilizationRate],
  );

  return (
    <SummaryBox title={t('SummaryMarketOverview.title')}>
      <div className="nostra__summary__market-overview">
        <SummaryBoxMainValue
          title={t('SummaryMarketOverview.labelTotalValueLocked')}
          value={formattedTotalValueLocked}
        />
        <div className="nostra__summary__market-overview-side-values">
          <SummaryBoxSideValue
            title={t('SummaryMarketOverview.labelMarkets')}
            value={assets && assets.length > 0 ? `${assets.length}` : null}
            placeholderWidth="large"
          />
          <div className="nostra__summary-box__horizontal-divider" />
          <SummaryBoxSideValue
            title={t('SummaryMarketOverview.labelUtilizationRate')}
            value={formattedUtilizationRate}
            placeholderWidth="small"
          />
        </div>
      </div>
    </SummaryBox>
  );
};

export default memo(SummaryMarketOverview);
