import { Provider } from 'starknet';
import { NetworkName } from '../interfaces';

let provider: Provider | null = null;

export const getDefaultProvider = (network: NetworkName = 'mainnet-alpha') => {
  if (provider) {
    return provider;
  }

  const urlParams = new URLSearchParams(window.location.search);

  // Connect to local devnet if url param ?devnet=true is present
  const devnet = urlParams.get('devnet');
  if (devnet) {
    provider = new Provider({
      sequencer: {
        baseUrl: 'http://127.0.0.1:5050',
        feederGatewayUrl: 'feeder_gateway',
        gatewayUrl: 'gateway',
      },
    });
    return provider;
  }

  provider = new Provider({
    rpc: {
      nodeUrl: process.env.REACT_APP_RPC_URL || '',
    },
  });

  /*provider = new Provider({
    sequencer: {
      network,
    },
  });*/

  return provider;
};
