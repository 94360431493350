import { FC } from 'react';
import { InnerIconProps } from './IconProps';
import withIcon from './withIcon';

const Wallet: FC<InnerIconProps> = ({ size, color }) => (
  <svg
    className="nostra__icon nostra__icon-wallet"
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.88112 4.16851C1.83189 4.23002 1 5.10038 1 6.16504V12.165C1 13.2696 1.89543 14.165 3 14.165H13C14.1046 14.165 15 13.2696 15 12.165V6.16504C15 5.16317 14.2633 4.33336 13.3021 4.18771C13.2866 4.15497 13.2699 4.11999 13.2519 4.08307C13.1404 3.85416 12.9792 3.54549 12.7828 3.23378C12.5888 2.92581 12.3479 2.59479 12.0729 2.33506C11.8089 2.08566 11.4417 1.8341 11.0001 1.83398C10.8128 1.83393 10.5428 1.88327 10.2597 1.94595C9.96033 2.01223 9.5948 2.10571 9.19127 2.21602C8.38339 2.43686 7.40445 2.73033 6.46103 3.0225C5.51676 3.31493 4.60429 3.60722 3.92815 3.82632C3.58999 3.93589 3.31077 4.02722 3.116 4.09118L2.89034 4.16547L2.88112 4.16851ZM12.1695 4.16504H6.1561C6.35356 4.10309 6.55449 4.04041 6.75686 3.97774C7.69646 3.68676 8.66305 3.3971 9.45495 3.18063C9.8513 3.07228 10.1992 2.98355 10.4758 2.92231C10.7687 2.85747 10.9372 2.83397 10.9999 2.83398C11.0583 2.834 11.1873 2.87407 11.3863 3.06203C11.5743 3.23966 11.7634 3.49175 11.9367 3.76686C12.0217 3.90166 12.0999 4.03721 12.1695 4.16504ZM2.96774 5.16555L2.96788 5.16646H12.9695H13.0537C13.581 5.19435 14 5.63076 14 6.16504V12.165C14 12.7173 13.5523 13.165 13 13.165H3C2.44772 13.165 2 12.7173 2 12.165V6.16504C2 5.62355 2.43038 5.18258 2.96774 5.16555Z"
      fill={color}
    />
    <path
      d="M11 8.16504C11.2761 8.16504 11.5 8.3889 11.5 8.66504L11.5 9.66504C11.5 9.94118 11.2761 10.165 11 10.165V10.165C10.7239 10.165 10.5 9.94118 10.5 9.66504L10.5 8.66504C10.5 8.3889 10.7239 8.16504 11 8.16504V8.16504Z"
      fill={color}
    />
  </svg>
);

export default withIcon(Wallet);
