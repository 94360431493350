import { FC, ReactNode } from 'react';
import ButtonWrapper from '../ButtonWrapper';
import Typography from '../Typography';

import './ButtonPrimary.scss';

interface ButtonPrimaryProps {
  label: string | ReactNode;
  disabled?: boolean;
  onClick: () => void;
}

const ButtonPrimary: FC<ButtonPrimaryProps> = ({ label, disabled = false, onClick }) => {
  return (
    <ButtonWrapper
      className={`nostra__button-primary ${disabled && 'nostra__button-primary__disabled'}`}
      onClick={onClick}
      disabled={disabled}
    >
      {typeof label === 'string' ? (
        <Typography
          variant="body-primary"
          weight="bold"
          color={disabled ? 'text-disabled-secondary' : 'text-primary-inverted'}
        >
          {label}
        </Typography>
      ) : (
        label
      )}
    </ButtonWrapper>
  );
};
export default ButtonPrimary;
