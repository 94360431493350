import { FC } from 'react';
import ButtonWrapper from '../ButtonWrapper';
import { colors } from '../Colors';
import Icon from '../Icon';

import './TablePaginationNavigateButton.scss';

interface TablePaginationNavigateButtonProps {
  variant: 'previous' | 'next';
  onClick: () => void;
}

const TablePaginationNavigateButton: FC<TablePaginationNavigateButtonProps> = ({ variant, onClick }) => {
  return (
    <ButtonWrapper className="nostra__tablePaginationNavigateButton" onClick={onClick}>
      {variant === 'previous' && <Icon variant="left-chevron" color={colors.textSecondary} size="small" />}
      {variant === 'next' && <Icon variant="right-chevron" color={colors.textSecondary} size="small" />}
    </ButtonWrapper>
  );
};
export default TablePaginationNavigateButton;
