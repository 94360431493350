import { FC, MouseEvent, forwardRef, memo, HTMLProps, ReactNode } from 'react';
import Link from '../Link';

import './ButtonWrapper.scss';

export interface ButtonWrapperProps {
  className?: string;
  title?: string;
  disabled?: boolean;
  selected?: boolean;
  value?: string;
  download?: boolean;
  url?: string;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  children: ReactNode;
}

const ButtonWrapper = forwardRef<HTMLButtonElement, ButtonWrapperProps>((props, ref) => {
  const {
    children,
    className = '',
    title,
    disabled = false,
    selected = false,
    value,
    download,
    url,
    onClick,
    onMouseOver,
    onMouseOut,
    onFocus,
    onBlur,
  } = props;

  if (download && url) {
    return (
      <Link className={`nostra__btn ${className}`} href={url} download target="_self">
        {children}
      </Link>
    );
  }

  return (
    <button
      ref={ref}
      className={`nostra__btn ${className}`}
      title={title}
      disabled={disabled}
      value={value}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onFocus={onFocus}
      onBlur={onBlur}
      data-selected={selected}
      data-disabled={disabled}
      type="button"
    >
      {children}
    </button>
  );
});

export default memo(ButtonWrapper as FC<ButtonWrapperProps & HTMLProps<HTMLButtonElement>>);
