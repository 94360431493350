import { AccountInterface } from 'starknet';
import { getConfigManager } from '../config/getConfigManager';
import { DEFAULT_DECIMAL_PRECISION } from '../datastructures';
import { ContractCache } from '../utils';
import { NostraTokenContract } from './contracts';

const contractCache = new ContractCache((address, providerOrAccount) => {
  const asset = getConfigManager().getAssetByAddress(address);
  return new NostraTokenContract(asset?.tokenPrecision ?? DEFAULT_DECIMAL_PRECISION, address, providerOrAccount);
});

export const getNostraTokenContract = (address: string, account?: AccountInterface) =>
  contractCache.get(address, account);
