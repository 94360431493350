import { FC, useEffect, useState } from 'react';
import { DecimalUtils } from '../../../utils';
import { LabelFormat } from '../../shared';

interface YAxisTickProps {
  x?: number;
  y?: number;
  index?: number;
  payload?: {
    value: number;
  };
  formatting?: LabelFormat;
  label?: string;
}

const YAxisTick: FC<YAxisTickProps> = props => {
  const { x, y, index, payload, formatting, label } = props;

  const [value, setValue] = useState<string>('');

  useEffect(() => {
    if (!payload) {
      return;
    }

    if (index === 0 && label) {
      setValue(label);
      return;
    }

    let value = '';
    switch (formatting) {
      case 'decimal':
        value = DecimalUtils.format(payload.value, {
          style: 'decimal',
          fractionDigits: 0,
        });
        break;
      case 'multiplier':
        value = DecimalUtils.format(payload.value, {
          style: 'multiplier',
          fractionDigits: 2,
          noMultiplierFractionDigits: 2,
        });
        break;
      case 'percentage':
        value = DecimalUtils.format(payload.value, {
          style: 'percentage',
          fractionDigits: 2,
        });
    }

    setValue(value);
  }, [formatting, index, label, payload]);

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={5}
        dx={-4}
        fill="#27334D"
        textAnchor="end"
        fontFamily="Albert Sans, sans-serif"
        fontWeight={index === 0 && label ? 700 : 400}
      >
        {value}
      </text>
    </g>
  );
};
export default YAxisTick;
