import { FC, ReactNode, useCallback, useMemo } from 'react';
import Typography from '../Typography';
import TablePaginationButton from './TablePaginationButton';
import TablePaginationNavigateButton from './TablePaginationNavigateButton';

import './TablePagination.scss';

interface TablePaginationProps {
  page: number;
  pageSize: number;
  totalItems: number;
  onPageChange: (page: number) => void;
}

const TablePagination: FC<TablePaginationProps> = ({ pageSize, totalItems, page, onPageChange }) => {
  const onPageSelected = useCallback(
    (page: number) => {
      onPageChange(page);
    },
    [onPageChange],
  );

  const onClickNextPage = useCallback(() => {
    onPageChange(page + 1);
  }, [page, onPageChange]);

  const onClickPreviousPage = useCallback(() => {
    onPageChange(page - 1);
  }, [page, onPageChange]);

  const pageCount = useMemo(() => {
    return Math.ceil(totalItems / pageSize);
  }, [pageSize, totalItems]);

  const pageButtons = useMemo(() => {
    const result: ReactNode[] = [];

    // Add button for currently selected page
    result.push(<TablePaginationButton key={page} selected={true} page={page} onClick={onPageSelected} />);

    // Add buttons for previous 5 pages (as needed)
    for (let i = page - 1; i >= page - 4 && i >= 1; i--) {
      result.unshift(<TablePaginationButton key={i} selected={false} page={i} onClick={onPageSelected} />);
    }

    // Add buttons for following 5 pages (as needed)
    for (let i = page + 1; i <= pageCount && i <= page + 4; i++) {
      result.push(<TablePaginationButton key={i} selected={page === i} page={i} onClick={onPageSelected} />);
    }

    return result;
  }, [pageCount, page, onPageSelected]);

  return (
    <div className="nostra__tablePagination">
      {/* Add previous page button as needed */}
      {page > 1 && <TablePaginationNavigateButton variant="previous" onClick={onClickPreviousPage} />}

      {/* Add three dot separator and first page button as needed */}
      {page > 5 && (
        <>
          <TablePaginationButton selected={false} page={1} onClick={onPageSelected} />
          <div className="nostra__tablePagination__separator">
            <Typography variant="body-secondary">...</Typography>
          </div>
        </>
      )}

      {/* Page buttons in range (current page, plus 4 previous and 4 following pages) */}
      {pageButtons}

      {/* Add three dot separator and last page button as needed */}
      {page < pageCount - 4 && (
        <>
          <div className="nostra__tablePagination__separator">
            <Typography variant="body-secondary">...</Typography>
          </div>
          <TablePaginationButton selected={false} page={pageCount} onClick={onPageSelected} />
        </>
      )}

      {/* Add next page button as needed */}
      {page < pageCount && <TablePaginationNavigateButton variant="next" onClick={onClickNextPage} />}
    </div>
  );
};
export default TablePagination;
