import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useAccountsHealthFactors,
  useAccountBorrowApy,
  useAccountSupplyApy,
  useAccountTotalDebt,
  useAccountTotalSupply,
  closeAccount,
  useNostraTokenBalance,
  useConfig,
  useAccountTotalIdle,
  useActiveAccountId,
  setActiveAccount,
  useWalletAccount,
} from '../../../hooks';
import { DecimalUtils } from '../../../utils';
import { ZERO } from '../../../datastructures';
import {
  Loading,
  HealthFactor,
  Icon,
  TableBodyCell,
  TableRow,
  Typography,
  ModalPrompt,
  ModalInfo,
  ButtonWrapper,
  Menu,
  MenuWrapper,
  SelectedAccountIndicator,
} from '../../shared';
import { HealthFactorTooltip } from '../../Tooltips';
import { AccountMenu } from './AccountMenu';
import AccountNameInputField from './AccountNameInputField';

import './AccountManagementRow.scss';

interface AccountManagementRowProps {
  accountId: number;
  accountName: string;
}

const MAX_ACCOUNT_NAME_LENGTH = 30;

const AccountManagementRow: FC<AccountManagementRowProps> = props => {
  const { accountId, accountName } = props;

  const { t } = useTranslation();

  const walletAccount = useWalletAccount();
  const config = useConfig();
  const accountTotalIdle = useAccountTotalIdle();
  const accountTotalSupply = useAccountTotalSupply();
  const accountTotalDebt = useAccountTotalDebt();
  const accountSupplyApy = useAccountSupplyApy();
  const accountBorrowApy = useAccountBorrowApy();
  const accountHealthFactor = useAccountsHealthFactors();
  const nostraTokenBalance = useNostraTokenBalance();
  const activeAccountId = useActiveAccountId();

  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [isNameEditorOpen, setIsNameEditorOpen] = useState<boolean>(false);
  const [closeAccountModalOpen, setCloseAccountModalOpen] = useState<boolean>(false);
  const [accountNotEmptyModalOpen, setAccountNotEmptyModalOpen] = useState<boolean>(false);

  const paddedAccountId = useMemo(() => `${accountId}`.padStart(3, '0'), [accountId]);

  const debtTokenBalance = useMemo(() => {
    let totalDebt = ZERO;

    Object.entries(config.assets).forEach(([, config]) => {
      const dataKey = `${walletAccount?.address}-${accountId}-${config.debtTokenAddress}`;

      totalDebt = totalDebt.add(nostraTokenBalance[dataKey] || ZERO);
    });

    return totalDebt;
  }, [walletAccount?.address, config.assets, nostraTokenBalance, accountId]);

  const lendTokenBalance = useMemo(() => {
    let totalLend = ZERO;

    Object.entries(config.assets).forEach(([, config]) => {
      const dataKey = `${walletAccount?.address}-${accountId}-${config.nostraInterestBearingTokenAddress}`;

      totalLend = totalLend.add(nostraTokenBalance[dataKey] || ZERO);
    });

    return totalLend;
  }, [walletAccount?.address, config.assets, nostraTokenBalance, accountId]);

  const idleTokenBalance = useMemo(() => {
    let totalIdle = ZERO;

    Object.entries(config.assets).forEach(([, config]) => {
      const dataKey = `${walletAccount?.address}-${accountId}-${config.nostraTokenAddress}`;

      totalIdle = totalIdle.add(nostraTokenBalance[dataKey] || ZERO);
    });

    return totalIdle;
  }, [walletAccount?.address, config.assets, nostraTokenBalance, accountId]);

  const collateralTokenBalance = useMemo(() => {
    let totalIdleCollateral = ZERO;

    Object.entries(config.assets).forEach(([, config]) => {
      const dataKey = `${walletAccount?.address}-${accountId}-${config.nostraCollateralTokenAddress}`;

      totalIdleCollateral = totalIdleCollateral.add(nostraTokenBalance[dataKey] || ZERO);
    });

    return totalIdleCollateral;
  }, [walletAccount?.address, config.assets, nostraTokenBalance, accountId]);

  const lendCollateralTokenBalance = useMemo(() => {
    let totalLendCollateral = ZERO;

    Object.entries(config.assets).forEach(([, config]) => {
      const dataKey = `${walletAccount?.address}-${accountId}-${config.nostraInterestBearingCollateralTokenAddress}`;

      totalLendCollateral = totalLendCollateral.add(nostraTokenBalance[dataKey] || ZERO);
    });

    return totalLendCollateral;
  }, [walletAccount?.address, config.assets, nostraTokenBalance, accountId]);

  const totalIdleFormatted = useMemo(() => {
    const dataKey = `${walletAccount?.address}-${accountId}`;

    if (!accountTotalIdle[dataKey]) {
      return null;
    }

    return DecimalUtils.format(accountTotalIdle[dataKey], {
      style: 'multiplier',
      fractionDigits: 2,
      noMultiplierFractionDigits: 2,
      currency: '$',
      lessThanFormat: true,
    });
  }, [walletAccount?.address, accountId, accountTotalIdle]);

  const totalSupplyFormatted = useMemo(() => {
    const dataKey = `${walletAccount?.address}-${accountId}`;

    if (!accountTotalSupply[dataKey]) {
      return null;
    }

    return DecimalUtils.format(accountTotalSupply[dataKey], {
      style: 'multiplier',
      fractionDigits: 2,
      noMultiplierFractionDigits: 2,
      currency: '$',
      lessThanFormat: true,
    });
  }, [walletAccount?.address, accountId, accountTotalSupply]);

  const supplyApyFormatted = useMemo(() => {
    const dataKey = `${walletAccount?.address}-${accountId}`;

    if (!accountSupplyApy[dataKey]) {
      return null;
    }

    return DecimalUtils.format(accountSupplyApy[dataKey], { style: 'percentage', fractionDigits: 2 });
  }, [walletAccount?.address, accountId, accountSupplyApy]);

  const totalDebtFormatted = useMemo(() => {
    const dataKey = `${walletAccount?.address}-${accountId}`;
    const totalDebt = accountTotalDebt[dataKey];

    if (!totalDebt) {
      return null;
    }

    return DecimalUtils.format(totalDebt, {
      style: 'multiplier',
      fractionDigits: 2,
      noMultiplierFractionDigits: 2,
      currency: '$',
      lessThanFormat: true,
    });
  }, [walletAccount?.address, accountId, accountTotalDebt]);

  const borrowApyFormatted = useMemo(() => {
    const dataKey = `${walletAccount?.address}-${accountId}`;

    if (!accountBorrowApy[dataKey]) {
      return null;
    }

    return DecimalUtils.format(accountBorrowApy[dataKey], { style: 'percentage', fractionDigits: 2 });
  }, [walletAccount?.address, accountBorrowApy, accountId]);

  const healthFactor = useMemo(() => {
    const dataKey = `${walletAccount?.address}-${accountId}`;

    if (!accountHealthFactor[dataKey]) {
      return null;
    }

    return accountHealthFactor[dataKey].value;
  }, [walletAccount?.address, accountHealthFactor, accountId]);

  const handleRenameAccount = useCallback(() => {
    setIsNameEditorOpen(true);
    setMenuOpen(false);
  }, []);

  const onCloseMenu = useCallback(() => {
    setMenuOpen(false);
  }, []);

  const handleCloseAccount = useCallback(() => {
    // TODO - Disable close account option until all account balances are loaded
    if (
      lendTokenBalance.gt(ZERO) ||
      debtTokenBalance.gt(ZERO) ||
      lendCollateralTokenBalance.gt(ZERO) ||
      collateralTokenBalance.gt(ZERO) ||
      idleTokenBalance.gt(ZERO)
    ) {
      setAccountNotEmptyModalOpen(true);
    } else {
      setCloseAccountModalOpen(true);
    }
    onCloseMenu();
  }, [
    debtTokenBalance,
    collateralTokenBalance,
    idleTokenBalance,
    lendCollateralTokenBalance,
    lendTokenBalance,
    onCloseMenu,
  ]);

  const handleNameChange = useCallback(() => {
    setIsNameEditorOpen(false);
  }, []);

  const onCloseAccountCloseModal = useCallback(() => {
    setCloseAccountModalOpen(false);
  }, []);

  const onCloseAccountNotEmptyModal = useCallback(() => {
    setAccountNotEmptyModalOpen(false);
  }, []);

  const onCloseAccount = useCallback(() => {
    if (!walletAccount) {
      return;
    }

    if (accountId === activeAccountId) {
      setActiveAccount(walletAccount.address, 0);
    }

    closeAccount(walletAccount.address, accountId);
  }, [walletAccount, activeAccountId, accountId]);

  const onToggleMenu = useCallback(() => {
    setMenuOpen(prevState => !prevState);
  }, []);

  const suppliesLoaded = totalIdleFormatted !== null && totalSupplyFormatted !== null && totalDebtFormatted !== null;

  const clippedAccountName = useMemo(() => {
    if (accountName.length > MAX_ACCOUNT_NAME_LENGTH) {
      return `${[...accountName].slice(0, MAX_ACCOUNT_NAME_LENGTH).join('')}...`;
    }

    return accountName;
  }, [accountName]);

  return (
    <>
      <TableRow id={`account-management-row-${accountId}`}>
        {/* Account */}
        <TableBodyCell className={['nostra__tableBodyCell__account']}>
          {accountId === activeAccountId && (
            <div className="nostra__account-management-row__selected-indicator">
              <SelectedAccountIndicator />
            </div>
          )}
          {isNameEditorOpen ? (
            <AccountNameInputField
              accountId={accountId}
              paddedAccountId={paddedAccountId}
              accountName={accountName}
              onNameChange={handleNameChange}
            />
          ) : (
            <div className="nostra__market-table-row__cell-account">
              <Typography variant="body-primary" weight="bold" title={accountName}>
                {accountName}
              </Typography>
              <Typography variant="body-tertiary" color="text-wallet">
                ID: {paddedAccountId}
              </Typography>
            </div>
          )}
        </TableBodyCell>
        {/* Idle tokens */}
        <TableBodyCell align="center" border="left">
          <Typography variant="body-primary" weight="bold">
            {totalIdleFormatted || '---'}
          </Typography>
        </TableBodyCell>
        {/* Total supply */}
        <TableBodyCell align="center" border="left">
          <Typography variant="body-primary" weight="bold">
            {totalSupplyFormatted || '---'}
          </Typography>
        </TableBodyCell>
        {/* Supply Apy */}
        <TableBodyCell align="center">
          <Typography variant="body-primary" weight="bold">
            {supplyApyFormatted || '---'}
          </Typography>
        </TableBodyCell>
        {/* Total debt */}
        <TableBodyCell align="center" border="left">
          <Typography variant="body-primary" weight="bold">
            {totalDebtFormatted || '---'}
          </Typography>
        </TableBodyCell>
        {/* Borrow Apy */}
        <TableBodyCell align="center">
          <Typography variant="body-primary" weight="bold">
            {borrowApyFormatted || '---'}
          </Typography>
        </TableBodyCell>
        {/* Health factor */}
        <TableBodyCell align="center" border="left">
          {healthFactor ? (
            <HealthFactor healthFactor={healthFactor} size="large" tooltip={<HealthFactorTooltip />} showStatus />
          ) : (
            <Loading color="primary" />
          )}
        </TableBodyCell>
        {/* Actions */}
        <TableBodyCell align="right">
          <MenuWrapper>
            <ButtonWrapper onClick={onToggleMenu}>
              <Icon variant="more" size="small" />
            </ButtonWrapper>
            <Menu open={menuOpen} onClose={onToggleMenu}>
              <AccountMenu
                accountId={accountId}
                disableClosing={accountId === 0 || !suppliesLoaded}
                onRenameAccount={handleRenameAccount}
                onCloseAccount={handleCloseAccount}
              />
            </Menu>
          </MenuWrapper>
        </TableBodyCell>
      </TableRow>
      <ModalPrompt
        text={t('AccountManagement.textCloseAccount', { accountName: clippedAccountName })}
        open={closeAccountModalOpen}
        onConfirm={onCloseAccount}
        onClose={onCloseAccountCloseModal}
      />
      <ModalInfo
        text={t('AccountManagement.textAccountNotEmpty')}
        open={accountNotEmptyModalOpen}
        onClose={onCloseAccountNotEmptyModal}
      />
    </>
  );
};
export default AccountManagementRow;
