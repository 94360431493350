import { ApolloQueryResult, gql } from '@apollo/client';
import { bind } from '@react-rxjs/core';
import { createSignal } from '@react-rxjs/utils';
import { catchError, combineLatest, concatMap, from, map, of, Subscription, tap } from 'rxjs';
import { GraphqlQuery, Nullable, ParsedChartData } from '../interfaces';
import { getGraphqlClient } from '../services';
import { downsampleChartData } from '../utils';
import { selectedChartToken$ } from './useSelectedChartToken';

const DEFAULT_VALUE = null;

const [fromTotalUtilizationRateChartDate$, setFromTotalUtilizationRateChartDate] = createSignal<Nullable<number>>();
const [rawChartData$, setRawChartData] = createSignal<Nullable<ParsedChartData[]>>();

const totalUtilizationRateChartData$ = combineLatest([fromTotalUtilizationRateChartDate$, selectedChartToken$]).pipe(
  concatMap(([fromTotalUtilizationRateChartDate, selectedChartToken]) => {
    try {
      setRawChartData(null);

      const graphqlClient = getGraphqlClient();

      const filters = [];
      if (fromTotalUtilizationRateChartDate) {
        filters.push(`fromTimestamp: "${new Date(fromTotalUtilizationRateChartDate).toISOString()}"`);
      }
      if (selectedChartToken) {
        filters.push(`token: "${selectedChartToken}"`);
      }

      const searchCriteria = filters.length > 0 ? `(${filters.join(', ')})` : '';

      return from(
        graphqlClient.query<GraphqlQuery>({
          query: gql`
        query GetTotalUtilizationRateChartData {
            totalUtilizationRate ${searchCriteria} {
                timestamp
                totalUtilizationRate
          }
        }
        `,
        }),
      ).pipe(
        catchError(error => {
          console.error('Error while fetching total utilization rate chart data!', error);
          return of(null);
        }),
      );
    } catch (error) {
      console.error('Error while fetching total utilization rate chart data!', error);
      return of(null);
    }
  }),
  map<Nullable<ApolloQueryResult<GraphqlQuery>>, Nullable<ParsedChartData[]>>(result => {
    if (!result) {
      return null;
    }

    if (!result.data.totalUtilizationRate.length) {
      return [];
    }

    try {
      const downsampledData = downsampleChartData(
        result.data.totalUtilizationRate.map(dataEntry => {
          return {
            timestamp: new Date(dataEntry.timestamp).getTime(),
            value: dataEntry.totalUtilizationRate,
          };
        }),
      );

      return downsampledData.map(downSampledDataEntry => ({
        value: downSampledDataEntry.value,
        timestamp: downSampledDataEntry.timestamp,
      }));
    } catch (error) {
      console.error('Error while fetching total utilization rate chart data!', error);
      return DEFAULT_VALUE;
    }
  }),
  tap(chartData => setRawChartData(chartData)),
);

export const [useTotalUtilizationRateChartData] = bind(rawChartData$, DEFAULT_VALUE);

let subscription: Nullable<Subscription> = null;

export const subscribeTotalUtilizationRateChartData = (): void => {
  unsubscribeTotalUtilizationRateChartData();
  subscription = totalUtilizationRateChartData$.subscribe();
};
export const unsubscribeTotalUtilizationRateChartData = (): void => subscription?.unsubscribe();

export { setFromTotalUtilizationRateChartDate };
