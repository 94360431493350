import { ApolloQueryResult, gql } from '@apollo/client';
import { bind } from '@react-rxjs/core';
import { createSignal } from '@react-rxjs/utils';
import { catchError, combineLatest, concatMap, from, map, of, Subscription } from 'rxjs';
import { GraphqlQuery, Nullable, ParsedChartData } from '../interfaces';
import { getGraphqlClient } from '../services';
import { downsampleChartData } from '../utils';

const DEFAULT_VALUE = null;

const [fromTotalBorrowersChartDate$, setFromTotalBorrowersChartDate] = createSignal<Nullable<number>>();

const totalBorrowersChartData$ = combineLatest([fromTotalBorrowersChartDate$]).pipe(
  concatMap(([fromTotalBorrowersChartDate]) => {
    try {
      const graphqlClient = getGraphqlClient();

      const searchCriteria = fromTotalBorrowersChartDate
        ? `(fromTimestamp: "${new Date(fromTotalBorrowersChartDate).toISOString()}")`
        : '';

      return from(
        graphqlClient.query<GraphqlQuery>({
          query: gql`
        query GetTotalBorrowersChartData {
            totalBorrowers ${searchCriteria} {
                timestamp
                totalBorrowers
          }
        }
        `,
        }),
      ).pipe(
        catchError(error => {
          console.error('Error while fetching total borrowers chart data!', error);
          return of(null);
        }),
      );
    } catch (error) {
      console.error('Error while fetching total borrowers chart data!', error);
      return of(null);
    }
  }),
  map<Nullable<ApolloQueryResult<GraphqlQuery>>, Nullable<ParsedChartData[]>>(result => {
    if (!result?.data.totalBorrowers.length) {
      return [];
    }

    try {
      const downsampledData = downsampleChartData(
        result.data.totalBorrowers.map(dataEntry => {
          return {
            timestamp: new Date(dataEntry.timestamp).getTime(),
            value: dataEntry.totalBorrowers,
          };
        }),
      );

      return downsampledData.map(downSampledDataEntry => ({
        value: downSampledDataEntry.value,
        timestamp: downSampledDataEntry.timestamp,
      }));
    } catch (error) {
      console.error('Error while fetching total borrowers chart data!', error);
      return DEFAULT_VALUE;
    }
  }),
);

export const [useTotalBorrowersChartData] = bind(totalBorrowersChartData$, DEFAULT_VALUE);

let subscription: Nullable<Subscription> = null;

export const subscribeTotalBorrowersChartData = (): void => {
  unsubscribeTotalBorrowersChartData();
  subscription = totalBorrowersChartData$.subscribe();
};
export const unsubscribeTotalBorrowersChartData = (): void => subscription?.unsubscribe();

export { setFromTotalBorrowersChartDate };
