import { bind } from '@react-rxjs/core';
import { AccountInterface, Call } from 'starknet';
import {
  BehaviorSubject,
  map,
  combineLatest,
  from,
  of,
  merge,
  mergeMap,
  tap,
  filter,
  Observable,
  catchError,
  debounce,
  interval,
  scan,
  Subscription,
  withLatestFrom,
  concatMap,
} from 'rxjs';
import { getConfigManager } from '../config/getConfigManager';
import { DEBOUNCE_IN_MS, MULTICALL_CONTRACT_ADDRESS } from '../constants';
import { Decimal } from '../datastructures';
import { getErc20Contract } from '../services/getErc20Contract';
import { walletAccount$ } from './useWalletAccount';
import { appEvent$ } from './useAppEvent';
import { Asset } from '../interfaces';
import { getMultiCallContract } from '../services';

export interface TokenBalance {
  balance: Decimal | null;
  address: string;
}

interface TokenBalanceData {
  subject$: BehaviorSubject<TokenBalance>;
  address: string;
}

interface TokenBalanceMap {
  [tokenAddress: string]: TokenBalance;
}

// Each token will have a separate BehaviorSubject, and this map will store them with some additional token data
export const tokenBalanceDataMap = new Map<string, TokenBalanceData>();

const tokenAddressList = getConfigManager().getTokenAddresses();
tokenAddressList.forEach(tokenAddress => {
  tokenBalanceDataMap.set(tokenAddress, {
    subject$: new BehaviorSubject<TokenBalance>({
      balance: null,
      address: tokenAddress,
    }),
    address: tokenAddress,
  });
});

const fetchData = (tokenAddress: string, address: string, account?: AccountInterface): Observable<Decimal | null> => {
  try {
    const contract = getErc20Contract(tokenAddress, account);

    return from(contract.balanceOf(address)).pipe(
      catchError(error => {
        console.error(
          `useTokenBalances - failed to fetch token '${tokenAddress}' balance for $wallet '${address}'`,
          error,
        );
        return of(null);
      }),
    );
  } catch (error) {
    console.error(`useTokenBalances - failed to fetch token '${tokenAddress}' balance for $wallet '${address}'`, error);
    return of(null);
  }
};

// Stream that goes over all tokens and fetches their balance, this happens only when wallet address changes
const walletStream$ = walletAccount$.pipe(
  filter((walletAccount): walletAccount is AccountInterface => Boolean(walletAccount)),
  concatMap<AccountInterface, Observable<TokenBalanceMap | null>>(walletAccount => {
    try {
      const calldata = tokenAddressList.map(
        tokenAddress =>
          ({
            contractAddress: tokenAddress,
            entrypoint: 'balanceOf',
            calldata: [walletAccount.address],
          } as Call),
      );

      return from(getMultiCallContract(MULTICALL_CONTRACT_ADDRESS).aggregateUint256Array(calldata)).pipe(
        map(result => {
          if (!result) {
            return null;
          }

          return result
            .map((uint256Value, index) => {
              const tokenAddress = tokenAddressList[index];
              const tokenPrecision = getConfigManager().getTokenPrecision(tokenAddress);

              return {
                [tokenAddress]: {
                  balance: new Decimal(uint256Value, tokenPrecision),
                  address: tokenAddress,
                },
              };
            })
            .reduce((acc, cur) => ({ ...acc, ...cur }), {});
        }),
        catchError(error => {
          console.error(
            `useTokenBalances - failed to fetch token balances for $wallet '${walletAccount.address}'`,
            error,
          );
          return of(null);
        }),
      );
    } catch (error) {
      console.error(`useTokenBalances - failed to fetch token balances for $wallet '${walletAccount.address}'`, error);
      return of(null);
    }
  }),
);

// TODO - Fetch token balance on each ERC20 Transfer event

const eventStream$ = appEvent$.pipe(
  withLatestFrom(walletAccount$),
  map(([{ asset }, walletAccount]) => [asset, walletAccount]),
  filter((value): value is [Asset, AccountInterface] => {
    const [asset, walletAccount] = value;
    return Boolean(asset) && Boolean(walletAccount);
  }),
  mergeMap(([asset, walletAccount]) => {
    const erc20TokenBalance$ = fetchData(asset.address, walletAccount.address, walletAccount);
    const balanceMap$ = erc20TokenBalance$.pipe(
      filter(balance => Boolean(balance)),
      map(balance => ({
        [`${asset.address}`]: {
          balance,
          address: asset.address,
        },
      })),
    );
    // set the balance map to null first when doing fetching
    const nullMap$ = of({
      [`${asset.address}`]: {
        balance: null,
        address: asset.address,
      },
    });

    return merge(nullMap$, balanceMap$);
  }),
);

// merge all stream$ into one if there are multiple
const stream$ = merge(walletStream$, eventStream$).pipe(
  filter(tokenDataMap => Boolean(tokenDataMap)),
  scan(
    (allTokenDataMap, tokenDataMap) => ({
      ...allTokenDataMap,
      ...tokenDataMap,
    }),
    {} as TokenBalanceMap,
  ),
  debounce<TokenBalanceMap>(() => interval(DEBOUNCE_IN_MS)),
  tap(tokenDataMap => {
    if (tokenDataMap === null) {
      return;
    }

    Object.keys(tokenDataMap).forEach(key => {
      const tokenData = tokenDataMap[key];
      if (tokenData.balance) {
        const tokenBalanceData = tokenBalanceDataMap.get(tokenData.address);
        if (tokenBalanceData) {
          tokenBalanceData.subject$.next({
            balance: tokenData.balance,
            address: tokenBalanceData.address,
          });
        }
      }
    });
  }),
);

export const [useTokenBalance] = bind((tokenAddress: string) => {
  const tokenBalanceData = tokenBalanceDataMap.get(tokenAddress);
  if (tokenBalanceData) {
    return tokenBalanceData.subject$;
  }
  return of(null);
}, null);

export const tokenBalanceMap$ = combineLatest(
  [...tokenBalanceDataMap.values()].map(tokenBalanceData => tokenBalanceData.subject$),
).pipe(
  map(tokenBalancesData => {
    let tokenBalanceMap: { [tokenAddress: string]: Decimal | null } = {};

    tokenBalancesData.forEach(tokenBalanceData => {
      tokenBalanceMap = {
        ...tokenBalanceMap,
        [tokenBalanceData.address]: tokenBalanceData.balance,
      };
    });

    return tokenBalanceMap;
  }),
);

export const [useTokenBalances] = bind(tokenBalanceMap$, {});

let subscription: Subscription;

export const subscribeTokenBalances = (): void => {
  unsubscribeTokenBalances();
  subscription = stream$.subscribe();
};
export const unsubscribeTokenBalances = (): void => subscription?.unsubscribe();
export const resetTokenBalances = (): void => {
  tokenBalanceDataMap.forEach(tokenBalanceData =>
    tokenBalanceData.subject$.next({
      balance: null,
      address: tokenBalanceData.address,
    }),
  );
};
