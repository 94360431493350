import { FC } from 'react';
import { InnerLogoProps } from './LogoProps';
import withLogo from './withLogo';

const TokenWBTC: FC<InnerLogoProps> = ({ size }) => (
  <svg
    className="nostra__logo nostra__logo-token-wBTC"
    width={size}
    height={size}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.6157 8.39477L31.5174 9.49306C34.1382 12.359 35.5915 16.1019 35.5915 19.9855C35.5915 23.869 34.1382 27.6119 31.5174 30.4779L32.6157 31.5762C35.5303 28.4148 37.1484 24.2725 37.1484 19.9727C37.1484 15.6728 35.5303 11.5305 32.6157 8.36914V8.39477Z"
      fill="#5A5564"
    />
    <path
      d="M9.5186 8.48981C12.3845 5.86909 16.1275 4.41577 20.011 4.41577C23.8945 4.41577 27.6375 5.86909 30.5034 8.48981L31.6017 7.39151C28.4404 4.477 24.298 2.85889 19.9982 2.85889C15.6984 2.85889 11.556 4.477 8.39468 7.39151L9.5186 8.48981Z"
      fill="#5A5564"
    />
    <path
      d="M8.48981 30.4889C5.8722 27.6236 4.42079 23.8829 4.42079 20.002C4.42079 16.121 5.8722 12.3803 8.48981 9.51505L7.39151 8.41675C4.477 11.5781 2.85889 15.7204 2.85889 20.0203C2.85889 24.3201 4.477 28.4624 7.39151 31.6238L8.48981 30.4889Z"
      fill="#5A5564"
    />
    <path
      d="M30.4888 31.5027C27.6228 34.1234 23.8799 35.5767 19.9964 35.5767C16.1128 35.5767 12.3699 34.1234 9.50396 31.5027L8.40566 32.601C11.567 35.5155 15.7094 37.1336 20.0092 37.1336C24.309 37.1336 28.4514 35.5155 31.6127 32.601L30.4888 31.5027Z"
      fill="#5A5564"
    />
    <path
      d="M26.9339 16.3355C26.7142 14.0437 24.7373 13.2749 22.2368 13.0406V9.88485H20.3038V12.982C19.795 12.982 19.2751 12.982 18.7589 12.982V9.88485H16.8405V13.0626H12.9196V15.131C12.9196 15.131 14.3474 15.1054 14.3254 15.131C14.5829 15.1027 14.8413 15.1754 15.0461 15.3339C15.251 15.4923 15.3864 15.7241 15.4237 15.9804V24.6789C15.4182 24.7693 15.3947 24.8577 15.3544 24.9388C15.3142 25.0199 15.2581 25.0921 15.1894 25.1512C15.1221 25.2114 15.0433 25.2573 14.9577 25.2862C14.8721 25.3152 14.7816 25.3265 14.6915 25.3196C14.7172 25.3416 13.2857 25.3196 13.2857 25.3196L12.9196 27.6297H16.8039V30.8587H18.7369V27.6773H20.2819V30.844H22.2185V27.6516C25.4841 27.4539 27.7613 26.6485 28.0468 23.5916C28.2775 21.1314 27.1206 20.0331 25.2718 19.5901C26.3957 19.0373 27.0913 18.0122 26.9339 16.3355ZM24.2248 23.2109C24.2248 25.6125 20.1098 25.3379 18.7992 25.3379V21.0765C20.1098 21.0802 24.2248 20.7031 24.2248 23.2109ZM23.3278 17.2068C23.3278 19.4034 19.8938 19.1362 18.8028 19.1362V15.2628C19.8938 15.2628 23.3278 14.9187 23.3278 17.2068Z"
      fill="#F09242"
    />
    <path
      d="M19.9963 40C16.041 39.9993 12.1747 38.8258 8.88632 36.6279C5.5979 34.43 3.03502 31.3064 1.52172 27.652C0.00841897 23.9976 -0.387339 19.9766 0.384482 16.0973C1.1563 12.218 3.06104 8.6547 5.85787 5.85787C8.6547 3.06104 12.218 1.1563 16.0973 0.384482C19.9766 -0.387339 23.9976 0.00841897 27.652 1.52172C31.3064 3.03502 34.43 5.5979 36.6279 8.88632C38.8258 12.1747 39.9993 16.041 40 19.9963C40.0005 22.6234 39.4834 25.2248 38.4783 27.652C37.4732 30.0792 35.9997 32.2845 34.1421 34.1421C32.2845 35.9997 30.0792 37.4732 27.652 38.4783C25.2248 39.4834 22.6234 40.0005 19.9963 40ZM19.9963 1.55959C16.3523 1.56249 12.7909 2.64563 9.76225 4.67212C6.73364 6.69861 4.37376 9.57747 2.98091 12.9448C1.58807 16.3122 1.22476 20.0169 1.93693 23.5907C2.64909 27.1645 4.40475 30.4469 6.982 33.0232C9.55925 35.5994 12.8424 37.3538 16.4165 38.0645C19.9906 38.7752 23.6951 38.4105 27.0619 37.0163C30.4287 35.6221 33.3067 33.2611 35.332 30.2317C37.3572 27.2022 38.439 23.6404 38.4404 19.9963C38.4414 17.5743 37.9649 15.1758 37.0383 12.9381C36.1116 10.7003 34.753 8.66715 33.04 6.95485C31.327 5.24255 29.2933 3.8847 27.0552 2.95894C24.817 2.03318 22.4184 1.55767 19.9963 1.55959Z"
      fill="#282138"
    />
  </svg>
);

export default withLogo(TokenWBTC);
