import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Decimal, ZERO } from '../../../datastructures';
import { useAssets, useStatisticsData, useTotalBorrow } from '../../../hooks';
import { DecimalUtils, weightedAverage } from '../../../utils';
import SummaryBox, { SummaryBoxMainValue, SummaryBoxSideValue } from '../SummaryBox';

import './SummaryMarketBorrow.scss';

const SummaryMarketBorrow: FC = () => {
  const { t } = useTranslation();

  const assets = useAssets();
  const totalBorrow = useTotalBorrow();
  const statisticsData = useStatisticsData();

  const formattedWeighedAverageApy = useMemo(() => {
    const values = assets.map(asset => asset.borrowApy);
    const weights = assets.map(asset => {
      if (!asset.totalBorrow || !totalBorrow) {
        return null;
      }

      if (asset.totalBorrow.equals(ZERO) || totalBorrow.equals(ZERO)) {
        return ZERO;
      }

      return asset.totalBorrow.div(totalBorrow);
    });

    if (values.some(value => value === null) || weights.some(weight => weight === null)) {
      return null;
    }

    const nonNullValues = values.filter((value): value is Decimal => value !== null);
    const nonNullWeights = weights.filter((weight): weight is Decimal => weight !== null);

    return DecimalUtils.format(weightedAverage(nonNullValues, nonNullWeights), {
      style: 'percentage',
      fractionDigits: 2,
    });
  }, [assets, totalBorrow]);

  const formattedTotalBorrow = useMemo(
    () =>
      totalBorrow
        ? DecimalUtils.format(totalBorrow, {
            style: 'multiplier',
            fractionDigits: 2,
            currency: '$',
            lessThanFormat: true,
          })
        : null,
    [totalBorrow],
  );

  const formattedBorrowers = useMemo(
    () => (statisticsData !== null ? DecimalUtils.format(statisticsData.borrowersCount, { style: 'decimal' }) : null),
    [statisticsData],
  );

  return (
    <SummaryBox title={t('SummaryMarketBorrow.title')}>
      <div className="nostra__summary__market-borrow">
        <SummaryBoxMainValue
          title={t('SummaryMarketBorrow.labelAverageBorrowApy')}
          value={formattedWeighedAverageApy}
        />
        <div className="nostra__summary__market-borrow-side-values">
          <SummaryBoxSideValue
            title={t('SummaryMarketBorrow.labelTotalBorrowed')}
            value={formattedTotalBorrow}
            placeholderWidth="large"
          />
          <div className="nostra__summary-box__horizontal-divider" />
          <SummaryBoxSideValue
            title={t('SummaryMarketBorrow.labelBorrowers')}
            value={formattedBorrowers}
            placeholderWidth="small"
          />
        </div>
      </div>
    </SummaryBox>
  );
};

export default SummaryMarketBorrow;
