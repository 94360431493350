import simplify from 'simplify-js';

export function downsampleChartData(data: { timestamp: number; value: number }[]) {
  let minValueX = new Date(data[0].timestamp).getTime();
  let maxValueX = new Date(data[0].timestamp).getTime();
  let minValueY = data[0].value;
  let maxValueY = data[0].value;

  data.forEach(dataEntry => {
    const timestamp = dataEntry.timestamp;

    if (timestamp < minValueX) {
      minValueX = timestamp;
    }
    if (timestamp > maxValueX) {
      maxValueX = timestamp;
    }

    if (dataEntry.value < minValueY) {
      minValueY = dataEntry.value;
    }
    if (dataEntry.value > maxValueY) {
      maxValueY = dataEntry.value;
    }
  });

  const dataToDownsample = data.map(dataEntry => {
    const timestamp = dataEntry.timestamp;
    const value = dataEntry.value;

    const oldRangeX = maxValueX - minValueX;
    const newRangeX = 500; // target scale

    const oldRangeY = maxValueY - minValueY;
    const newRangeY = 400; // target scale

    return {
      x: ((timestamp - minValueX) * newRangeX) / oldRangeX,
      y: ((value - minValueY) * newRangeY) / oldRangeY,
      timestamp: timestamp,
      value: value,
    };
  });

  return simplify(dataToDownsample, 2) as {
    x: number;
    y: number;
    value: number;
    timestamp: number;
  }[];
}
