import { bind } from '@react-rxjs/core';
import { BehaviorSubject, catchError, combineLatest, merge, mergeMap, of, Subscription, tap } from 'rxjs';
import { getConfigManager } from '../config/getConfigManager';
import { MULTICALL_CONTRACT_ADDRESS } from '../constants';
import { Decimal, DEFAULT_DECIMAL_PRECISION } from '../datastructures';
import { DebtTier, DEBT_TIERS, NostraToken } from '../interfaces';
import { getMultiCallContract } from '../services';
import { logGlobalError } from './useGlobalError';
import { nostraTokenList$ } from './useNostraTokenList';

const DEFAULT_VALUE = null;

interface DebtData {
  debtFactor: Decimal;
  debtTier: DebtTier;
}

export type DebtDataMap = { [debtTokenAddress: string]: DebtData };

export const debtData$ = new BehaviorSubject<DebtDataMap | null>(DEFAULT_VALUE);

const RESPONSE_CHUNK_SIZE = 7;

const fetchData = (debtTokens: NostraToken[]) => {
  try {
    const config = getConfigManager().getConfig();

    const calldata = debtTokens.map(debtToken => {
      return {
        contractAddress: config.cdpManager,
        entrypoint: 'getDebtData',
        calldata: [debtToken.address],
      };
    });

    const multiCallContract = getMultiCallContract(MULTICALL_CONTRACT_ADDRESS);

    return multiCallContract.aggregate(calldata).pipe(
      mergeMap(result => {
        if (!result) {
          return of(null);
        }

        const debtDataMap: DebtDataMap = {};

        for (let i = 0; i < result.length; i += RESPONSE_CHUNK_SIZE) {
          // Index 2 is for debtTier
          // Index 4 and 5 are low and high BNs for debtFactor
          const debtTier = DEBT_TIERS[result[i + 2].toNumber()];
          const debtFactor = new Decimal({ low: result[i + 4], high: result[i + 5] }, DEFAULT_DECIMAL_PRECISION);

          debtDataMap[debtTokens[i / RESPONSE_CHUNK_SIZE].address] = {
            debtFactor,
            debtTier,
          };
        }

        return of(debtDataMap);
      }),
      catchError(error => {
        console.error(`useDebtData - Failed to load debt data!`, error);
        logGlobalError(error);
        return of(null);
      }),
    );
  } catch (error) {
    console.error(`useDebtData - Failed to load debt data!`, error);
    logGlobalError(error);
    return of(null);
  }
};

/**
 * Debt data does not change often. We can load it only once on app load.
 */
const initialLoadStream$ = combineLatest([nostraTokenList$]).pipe(
  mergeMap(([nostraTokenList]) => {
    const debtTokens = nostraTokenList.filter(nostraToken => nostraToken.variant === 'debt');

    return fetchData(debtTokens);
  }),
);

const stream$ = merge(initialLoadStream$).pipe(
  tap(debtDataMap => {
    if (debtDataMap) {
      debtData$.next(debtDataMap);
    }
  }),
);

export const [useDebtData] = bind(debtData$, DEFAULT_VALUE);

let subscription: Subscription | null = null;

export const subscribeDebtData = (): void => {
  unsubscribeDebtData();
  subscription = stream$.subscribe();
};
export const unsubscribeDebtData = (): void => subscription?.unsubscribe();
export const resetDebtData = (): void => debtData$.next(DEFAULT_VALUE);
