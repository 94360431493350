import { catchError, combineLatest, from, map, Observable, of } from 'rxjs';
import { Abi, AccountInterface, ProviderInterface } from 'starknet';
import { Decimal, DEFAULT_DECIMAL_PRECISION, Uint256 } from '../../datastructures';
import NostraWrapperPriceOracle_ABI from './abi/NostraWrapperPriceOracle_abi.json';
import { ContractBase } from './base';
import { NostraWrapperPriceOracle } from './typechain';

export class NostraWrapperPriceOracleContract extends ContractBase<NostraWrapperPriceOracle> {
  constructor(address: string, providerOrAccount: ProviderInterface | AccountInterface) {
    super(NostraWrapperPriceOracle_ABI as Abi, address, providerOrAccount);
  }

  /** Returns the price in ETH of the asset that is defined by the given address. */
  getAssetPrice(address: string): Observable<Decimal | undefined> {
    if (!this.contract) {
      return of(undefined);
    }

    return from(this.contract.getAssetPrice(address)).pipe(
      map(([assetPrice]) => new Decimal(assetPrice as Uint256, DEFAULT_DECIMAL_PRECISION)),
      catchError(error => {
        console.log(`Failed to fetch price for asset with address: ${address}`, error);
        return of(undefined);
      }),
    );
  }

  /** Returns the price in USD of the asset that is defined by the given address. */
  getAssetPriceInUsd(address: string): Observable<Decimal | null> {
    if (!this.contract) {
      return of(null);
    }

    const assetPriceInBaseToken$ = this.getAssetPrice(address);
    const baseAssetPriceInUsd$ = this.contract.getBaseAssetPriceInUsd();

    return combineLatest([assetPriceInBaseToken$, baseAssetPriceInUsd$]).pipe(
      map(([assetPrice, [baseAssetPriceInUsd]]) => {
        if (!assetPrice) {
          return null;
        }

        return assetPrice.mul(new Decimal(baseAssetPriceInUsd as Uint256, DEFAULT_DECIMAL_PRECISION));
      }),
      catchError(error => {
        console.log(error);
        return of(null);
      }),
    );
  }
}
