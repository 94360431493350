import { FC, useCallback, useState, KeyboardEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonWrapper from '../ButtonWrapper';
import BaseInput from '../BaseInput';
import Icon from '../Icon';
import Typography from '../Typography';
import { colors } from '../Colors';

import './SearchInput.scss';

export interface SearchInputProps {
  value?: string;
  onChange: (value: string) => void;
}

const SearchInput: FC<SearchInputProps> = props => {
  const { value, onChange } = props;

  const { t } = useTranslation();
  const [localValue, setLocalValue] = useState<string>(value ?? '');

  const updateValue = useCallback(() => onChange(localValue), [localValue, onChange]);

  const onKeyUp = useCallback(
    (ev: KeyboardEvent<HTMLInputElement>) => {
      if (ev.key === 'Enter') {
        updateValue();
      }
    },
    [updateValue],
  );

  const handleClick = useCallback(() => {
    setLocalValue('');
    onChange('');
  }, [onChange]);

  useEffect(() => {
    if (!localValue) {
      onChange('');
    }
  }, [localValue, onChange]);

  return (
    <Typography className="nostra__search-input" variant="body-secondary">
      <Icon variant="search" size="small" color={colors.searchInputIcon} />
      <BaseInput
        placeholder={t('SearchInput.textPlaceholder')}
        value={localValue}
        onChange={setLocalValue}
        onBlur={updateValue}
        onKeyUp={onKeyUp}
      />
      <ButtonWrapper onClick={handleClick}>
        <Icon variant="x-solid" size="small" color={colors.searchInputIcon} />
      </ButtonWrapper>
    </Typography>
  );
};

export default SearchInput;
