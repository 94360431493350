import { Fragment, ReactElement, useCallback } from 'react';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { AxisDomain, Margin, ScaleType } from 'recharts/types/util/types';
import { colors } from '../Colors';
import ChartTooltip from './ChartTooltip';
import { LabelFormat } from './DateChart';
import { DecimalUtilsCurrency } from '../../../utils/decimalUtils';

import './Chart.scss';

type ChartAxisType = 'number' | 'category';

export type ChartDotElement<X, Y> = (
  dataX: X,
  dataY: Y,
  index: number,
  dotCenterX: number,
  dotCenterY: number,
) => ReactElement<SVGElement> | undefined;

export interface ChartDataPoint<X, Y extends ValueType> {
  x: X;
  y1?: Y;
  y2?: Y;
}

export interface SelectableChartDataPoint<X, Y extends ValueType> extends ChartDataPoint<X, Y> {
  visible?: boolean;
  selected?: boolean;
}

export interface ChartProps<X, Y extends ValueType> {
  data: ChartDataPoint<X, Y>[];
  tooltipValueFormatting?: LabelFormat;
  tooltipTimeFormatting?: string;
  tooltipValueLabel1?: string;
  tooltipValueLabel2?: string;
  xAxisType?: ChartAxisType;
  xScale?: ScaleType;
  xTick?: ReactElement;
  xTicks?: (string | number)[];
  xTickFormatter?: (value: X, index: number) => string;
  xDomain?: AxisDomain;
  yAxisType?: ChartAxisType;
  yTick?: ReactElement;
  yTickFormatter?: (value: Y, index: number) => string;
  margin?: Margin;
  topPercentageProjected?: number;
  currencySymbol?: DecimalUtilsCurrency;
  dot?: ChartDotElement<X, Y>;
}

export interface ChartCommonProps {
  width?: string | number;
  height?: string | number;
  hideData?: boolean;
}

const calculateInterval = (dataRange: number) => {
  const exponent = Math.floor(Math.log10(dataRange));
  const leadingNum = Math.ceil(dataRange / 10 ** exponent);

  switch (leadingNum) {
    case 1:
      return 10 ** (exponent - 1);
    case 2:
    case 3:
    case 4:
      return 5 * 10 ** (exponent - 1);
    default:
      return 10 ** exponent;
  }
};

function Chart<X, Y extends ValueType>(
  props: ChartProps<X, Y> & ChartCommonProps,
): ReactElement<ChartProps<X, Y> & ChartCommonProps> {
  const {
    data,
    width,
    height,
    xAxisType,
    xScale = 'linear',
    xTick,
    xTicks,
    xTickFormatter,
    xDomain = ['dataMin', 'dataMax'],
    yAxisType,
    yTick,
    topPercentageProjected,
    tooltipValueFormatting,
    tooltipTimeFormatting,
    tooltipValueLabel1,
    tooltipValueLabel2,
    dot,
    hideData,
    currencySymbol,
  } = props;

  const chartDot = useCallback(
    dotProps => {
      const { payload, index, cx, cy } = dotProps;
      return dot?.(payload.x, payload.y, index, cx, cy) ?? <Fragment key={`chart-dot-${cx}-${cy}`} />;
    },
    [dot],
  );

  const yAxisDomain = useCallback(([dataMin, dataMax]: [number, number]): AxisDomain => {
    const dataRange = dataMax - dataMin;
    const offset = dataRange / 10;
    const interval = calculateInterval(dataRange);
    const dataMinWithOffset = Math.max(0, dataMin - offset);
    const dataMaxWithOffset = dataMax + offset;

    if (!interval) {
      return [dataMin * 0.9, dataMax * 1.1];
    }

    return [Math.floor(dataMinWithOffset / interval) * interval, Math.ceil(dataMaxWithOffset / interval) * interval];
  }, []) as AxisDomain;

  return (
    <ResponsiveContainer width={width} height={height}>
      <AreaChart data={data} margin={{ top: 20, left: 10 }}>
        <defs>
          <linearGradient id="color1" x1="0" y1="0" x2="0" y2="1">
            <stop offset="67%" stopColor={colors.chartArea1} stopOpacity={0.2} />
            <stop offset="100%" stopColor={colors.chartArea1} stopOpacity={0} />
          </linearGradient>
          <linearGradient id="color2" x1="0" y1="0" x2="0" y2="1">
            <stop offset="67%" stopColor={colors.chartArea2} stopOpacity={0.2} />
            <stop offset="100%" stopColor={colors.chartArea2} stopOpacity={0} />
          </linearGradient>
          <pattern id="pattern" width="4" height="4" patternUnits="userSpaceOnUse" patternTransform="rotate(45 0 0)">
            <rect fill={colors.chartStroke1} opacity="0.2" x="0" y="0" width="100%" height="100%" />
            <line stroke="#000000" opacity="0.3" strokeWidth="2px" y2="4" />
          </pattern>
          {topPercentageProjected && (
            <clipPath id="projectedValueClip">
              <rect x={`${(1 - topPercentageProjected) * 100}%`} y="0" width="100%" height="100%" />
            </clipPath>
          )}
        </defs>
        <XAxis
          dataKey="x"
          type={xAxisType}
          scale={xScale}
          tick={xTick}
          ticks={xTicks}
          tickFormatter={xTickFormatter}
          tickLine={false}
          domain={xDomain}
          interval={xTicks ? 0 : 'preserveStart'}
        />
        <YAxis
          type={yAxisType}
          axisLine={false}
          tick={yTick}
          tickCount={6}
          tickLine={false}
          domain={yAxisDomain}
          interval="preserveStart"
        />
        <CartesianGrid stroke={colors.chartGrid} horizontal vertical={false} />
        <Tooltip
          cursor={{ stroke: '#27334D', strokeDasharray: '6 6' }}
          content={
            <ChartTooltip
              valueFormat={tooltipValueFormatting}
              timeFormat={tooltipTimeFormatting}
              currencySymbol={currencySymbol}
              valueLabel1={tooltipValueLabel1}
              valueLabel2={tooltipValueLabel2}
            />
          }
        />
        <Area
          type="monotone"
          dataKey="y1"
          activeDot={{ fill: '#0A1326', strokeWidth: '8px', width: '12px', strokeOpacity: 0.5, paintOrder: 'stroke' }}
          stroke={hideData ? 'transparent' : colors.chartStroke1}
          strokeWidth={2}
          fill={hideData ? 'transparent' : 'url(#color1)'}
        />
        <Area
          type="monotone"
          dataKey="y2"
          activeDot={{ fill: '#0A1326', strokeWidth: '8px', width: '12px', strokeOpacity: 0.5, paintOrder: 'stroke' }}
          stroke={hideData ? 'transparent' : colors.chartStroke2}
          strokeWidth={2}
          fill={hideData ? 'transparent' : 'url(#color2)'}
        />
        {topPercentageProjected && (
          <Area
            type="natural"
            dataKey="y1"
            dot={chartDot}
            activeDot={false}
            strokeWidth={0}
            clipPath="url(#projectedValueClip)"
            fill={hideData ? 'transparent' : 'url(#pattern)'}
          />
        )}
      </AreaChart>
    </ResponsiveContainer>
  );
}

export default Chart;
