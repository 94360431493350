import { FC } from 'react';
import { InnerIconProps } from './IconProps';
import withIcon from './withIcon';

const InfinityIcon: FC<InnerIconProps> = ({ size, color }) => (
  <svg
    className="nostra__icon nostra__icon-infinity"
    width={size}
    height={size}
    viewBox="0 0 18 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.75"
      d="M3.9088 7C1.728 7 1 5.5 1 4C1 2.5 1.728 1 3.9088 1C6.8184 1 11.1816 7 14.0912 7C16.272 7 17 5.5 17 4C17 2.5 16.272 1 14.0912 1C11.1816 1 6.8184 7 3.9088 7Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default withIcon(InfinityIcon);
