import { getConfigManager } from '../config/getConfigManager';
import { ContractCache } from '../utils';
import { NostraWrapperPriceOracleContract } from './contracts';

const contractCache = new ContractCache(
  (address, providerOrAccount) => new NostraWrapperPriceOracleContract(address, providerOrAccount),
);

export const getNostraWrapperPriceOracleContract = () => {
  const address = getConfigManager().getConfig().oracle;
  return contractCache.get(address);
};
