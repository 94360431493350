import { FC, memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SMALL_BALANCE_THRESHOLD,
  useWalletAccount,
  useAssetFilterOptions,
  useAssets,
  useFilteredAssetList,
  useGlobalError,
  useTokenRates,
} from '../../hooks';
import { AssetTier } from '../../interfaces';
import { DecimalUtils } from '../../utils';
import {
  Checkbox,
  Icon,
  InfoTooltipContent,
  LoadingButtonPlaceholder,
  ShadowContainer,
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableRow,
  TooltipWrapper,
  Typography,
} from '../shared';
import MarketsFilter from './MarketsFilter';
import MarketsTableRow from './MarketsTableRow';

import './Markets.scss';

interface FilterData {
  variant: AssetTier;
  name: string;
}

const filterList = [
  {
    variant: 'shared',
    // t('Markets.btnShared')
    name: 'Markets.btnShared',
  },
  {
    variant: 'cross',
    // t('Markets.btnCross')
    name: 'Markets.btnCross',
  },
  {
    variant: 'isolated',
    // t('Markets.btnIsolated')
    name: 'Markets.btnIsolated',
  },
  {
    variant: 'nominal',
    // t('Markets.btnNominal')
    name: 'Markets.btnNominal',
  },
] as FilterData[];

const Markets: FC = () => {
  const [globalError] = useGlobalError();
  const walletAccount = useWalletAccount();
  const assets = useAssets();
  const tokenRates = useTokenRates();
  const filteredAssets = useFilteredAssetList();
  const [{ filters, smallBalancesHidden }, setAssetFilterOptions, hideSmallBalances] = useAssetFilterOptions();
  const { t } = useTranslation();

  const handleFilterChange = useCallback(
    (id: AssetTier, selected: boolean) => {
      setAssetFilterOptions(id, selected);
    },
    [setAssetFilterOptions],
  );

  const handleHideSmallBalancesChange = useCallback(() => {
    hideSmallBalances(!smallBalancesHidden);
  }, [hideSmallBalances, smallBalancesHidden]);

  const perFilterCount = useMemo(
    () =>
      assets
        .map(asset => asset.assetTier)
        .filter((assetTier): assetTier is AssetTier => Boolean(assetTier))
        .reduce((acc, assetTier) => {
          acc[assetTier] = (acc[assetTier] ?? 0) + 1;
          return acc;
        }, {} as Record<AssetTier, number>),
    [assets],
  );

  const loadedAssets = useMemo(() => {
    const numberOfLoadedAssets = Object.values(perFilterCount).reduce((sum, value) => sum + value, 0);
    return numberOfLoadedAssets === assets.length;
  }, [assets.length, perFilterCount]);

  return (
    <ShadowContainer className="nostra__markets-container">
      <div className="nostra__markets__header">
        <Typography variant="subheader" weight="bold">
          {t('Markets.title')}
        </Typography>
      </div>
      <div className="nostra__markets__body">
        <div className="nostra__markets__filters">
          {filterList.map(filter =>
            loadedAssets ? (
              <MarketsFilter
                key={filter.variant}
                variant={filter.variant}
                name={t(filter.name)}
                selected={filters[filter.variant]}
                count={perFilterCount[filter.variant] ?? 0}
                onClick={handleFilterChange}
              />
            ) : (
              <LoadingButtonPlaceholder key={filter.variant} width="small" height="small" />
            ),
          )}
          {walletAccount && loadedAssets && (
            <div className="nostra__markets__small-balances-checkbox">
              <TooltipWrapper
                tooltipContent={
                  <InfoTooltipContent
                    message={t('Markets.descHideSmallBalancesTooltip', {
                      threshold: DecimalUtils.format(SMALL_BALANCE_THRESHOLD, {
                        style: 'currency',
                        fractionDigits: 2,
                        currency: '$',
                      }),
                    })}
                  />
                }
                placement="bottom-start"
              >
                <Checkbox checked={smallBalancesHidden} onChange={handleHideSmallBalancesChange} />
              </TooltipWrapper>
              <Typography variant="body-secondary" weight="bold">
                {t('Markets.descHideSmallBalancesLabel')}
              </Typography>
            </div>
          )}
        </div>
        <div className="nostra__markets__table">
          <Table>
            <TableHead>
              <TableRow id="header">
                <TableHeadCell label={t('Markets.headerMarket')} />
                <TableHeadCell label={t('Markets.headerAssetTier')} align="center" />
                <TableHeadCell label={t('Markets.headerPrice')} align="right" />
                <TableHeadCell label={t('Markets.headerSupplyAPY')} align="right" />
                <TableHeadCell label={t('Markets.headerBorrowAPY')} align="right" />
                <TableHeadCell label={t('Markets.headerTotalSupply')} align="right" />
                <TableHeadCell label={t('Markets.headerTotalBorrow')} align="right" />
                <TableHeadCell label={t('Markets.headerAvailableForBorrowing')} align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {!globalError &&
                filteredAssets.map(asset => (
                  <MarketsTableRow key={asset.address} asset={asset} price={tokenRates[asset.address]} />
                ))}
              {globalError && (
                <TableRow id="market-body-global-error">
                  <TableBodyCell className={['nostra__markets__table__global-error']} colSpan={8}>
                    <Icon variant="info-bordered-rounded" size={48} />
                    <Typography variant="body-primary">{t('Markets.textGlobalError1')}</Typography>
                    <Typography variant="body-primary">{t('Markets.textGlobalError2')}</Typography>
                  </TableBodyCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </div>
    </ShadowContainer>
  );
};

export default memo(Markets);
