import { FC, memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setFromTotalUniqueLendersChartDate, useTotalUniqueLendersChartData } from '../../../hooks';
import { Nullable } from '../../../interfaces';
import Graph from '../Graph';

const TotalUniqueLenders: FC = () => {
  const { t } = useTranslation();

  const totalUniqueLendersChartData = useTotalUniqueLendersChartData();

  const [fromTimestamp, setFromTimestamp] = useState<Nullable<number>>(-1);

  const handleDataFromChange = useCallback((fromTimestamp: Nullable<number>) => {
    setFromTimestamp(fromTimestamp);
  }, []);

  useEffect(() => {
    if (fromTimestamp === -1) {
      return;
    }

    setFromTotalUniqueLendersChartDate(fromTimestamp);
  }, [fromTimestamp]);

  return (
    <Graph
      title={t('Analytics.titleTotalUniqueLenders')}
      parsedChartData1={totalUniqueLendersChartData}
      onDateFromChange={handleDataFromChange}
    />
  );
};

export default memo(TotalUniqueLenders);
