import { FC } from 'react';
import { InnerLogoProps } from './LogoProps';
import withLogo from './withLogo';

const TokenETH: FC<InnerLogoProps> = ({ size }) => (
  <svg
    className="nostra__logo nostra__logo-token-ETH"
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
      fill="#FEFEFD"
    />
    <path
      d="M16.1723 4.03579V4.04771C16.1842 4.10736 16.1842 4.17893 16.1842 4.2505V12.8509C16.1723 12.8986 16.1365 12.9105 16.1007 12.9344C15.8502 13.0537 15.6117 13.161 15.3612 13.2684C15.0152 13.4235 14.6574 13.5905 14.3115 13.7455L13.059 14.3181C12.7131 14.4732 12.3671 14.6282 12.0331 14.7833C11.6276 14.9742 11.2101 15.1531 10.8045 15.3439C10.4586 15.499 10.1127 15.666 9.7548 15.8211C9.46852 15.9523 9.18224 16.0716 8.90788 16.2028C8.88402 16.2147 8.86017 16.2266 8.83631 16.2266C8.82438 16.2266 8.82438 16.2266 8.81245 16.2147L9.13452 15.6779C9.7548 14.6521 10.3632 13.6382 10.9834 12.6123C11.6395 11.5149 12.3075 10.4175 12.9635 9.32008C13.5719 8.30616 14.1922 7.29225 14.8005 6.27833C15.2419 5.53877 15.6952 4.7992 16.1365 4.05964C16.1484 4.03579 16.1604 4.02386 16.1604 4H16.1723C16.1604 4.01193 16.1723 4.02386 16.1723 4.03579Z"
      fill="#8A92B2"
    />
    <path
      d="M23.4958 16.2147L23.5077 16.2266L21.7543 17.2644L16.2433 20.5209C16.2195 20.5328 16.1956 20.5447 16.1837 20.5567C16.1479 20.5567 16.1479 20.5209 16.1479 20.5089V20.4016V13.0298C16.1479 12.994 16.1479 12.9463 16.1598 12.9105C16.1717 12.8628 16.2075 12.8747 16.2433 12.8867C16.3984 12.9582 16.5654 13.0298 16.7205 13.1014C17.1857 13.3161 17.6509 13.5308 18.1161 13.7336C18.5216 13.9125 18.9153 14.1034 19.3209 14.2823C19.7264 14.4612 20.132 14.6521 20.5376 14.831C20.8835 14.9861 21.2413 15.1531 21.5873 15.3081C21.9332 15.4632 22.291 15.6302 22.637 15.7853C22.9113 15.9046 23.1857 16.0358 23.46 16.1551C23.46 16.1909 23.4719 16.2028 23.4958 16.2147Z"
      fill="#454A75"
    />
    <path
      d="M16.1718 27.9642C16.1718 27.9761 16.1599 27.9881 16.1599 28H16.1479C16.1479 27.9761 16.1241 27.9642 16.1122 27.9404C15.3726 26.9026 14.633 25.8529 13.8935 24.8151C13.142 23.7535 12.3786 22.6799 11.6271 21.6183C10.8994 20.5924 10.1599 19.5547 9.43223 18.5288C9.24137 18.2545 9.05052 17.9921 8.85966 17.7177C8.84774 17.6938 8.83581 17.6819 8.81195 17.6461C8.84774 17.6461 8.87159 17.67 8.88352 17.6819C9.92129 18.2903 10.9471 18.8986 11.9849 19.507C13.1778 20.2107 14.3587 20.9145 15.5515 21.6183L16.1599 21.9761C16.1837 22 16.1837 22.0239 16.1837 22.0477V27.7972C16.1837 27.8569 16.1837 27.9165 16.1718 27.9642Z"
      fill="#8A92B2"
    />
    <path
      d="M8.8 16.2386V16.2266C9.18171 16.0596 9.55149 15.8807 9.9332 15.7137C10.4223 15.4871 10.9113 15.2724 11.4004 15.0457C11.7702 14.8787 12.1519 14.6998 12.5217 14.5328C13.0704 14.2823 13.6072 14.0437 14.1559 13.7932C14.5256 13.6262 14.8954 13.4592 15.2771 13.2803C15.5396 13.161 15.8139 13.0417 16.0763 12.9225C16.1002 12.9105 16.136 12.8986 16.1479 12.8747C16.1598 12.8747 16.1598 12.8867 16.1479 12.8986V20.4732C16.1479 20.5089 16.136 20.5447 16.1598 20.5686C16.136 20.6044 16.1121 20.5686 16.1002 20.5567C15.9928 20.497 15.8855 20.4374 15.7781 20.3658C13.4759 19.006 11.1618 17.6342 8.85965 16.2744C8.84772 16.2624 8.82386 16.2505 8.8 16.2386Z"
      fill="#62688F"
    />
    <path
      d="M23.472 17.6461H23.4839C23.4839 17.67 23.4601 17.6938 23.4482 17.7177C21.2533 20.8072 19.0585 23.9086 16.8637 26.998C16.637 27.3201 16.3985 27.6422 16.1718 27.9642C16.1599 27.9523 16.1599 27.9404 16.1599 27.9284V27.8569V22.0716V21.9642C16.6609 21.666 17.1499 21.3797 17.6509 21.0815C19.5833 19.9364 21.5157 18.8032 23.4362 17.6581C23.4482 17.67 23.4601 17.6581 23.472 17.6461Z"
      fill="#62688F"
    />
    <path
      d="M16.1598 12.8986V12.8747V12.8032V4.14314C16.1598 4.10735 16.1479 4.0835 16.1718 4.04771C18.5932 8.06759 21.0147 12.0755 23.4243 16.0954C23.4481 16.1312 23.4839 16.1789 23.4958 16.2266C23.3288 16.167 23.1738 16.0835 23.0187 16.0119C22.8278 15.9284 22.6251 15.833 22.4342 15.7495C22.3149 15.6899 22.1837 15.6421 22.0644 15.5825C21.8616 15.4871 21.6589 15.4036 21.4561 15.3081C21.3368 15.2604 21.2175 15.2008 21.0982 15.1411L20.3109 14.7833C20.1797 14.7237 20.0485 14.664 19.9054 14.6044L19.3328 14.3539C19.2135 14.3062 19.0942 14.2465 18.975 14.1869L18.1877 13.829C18.0565 13.7694 17.9253 13.7097 17.7821 13.6501L17.2095 13.3996C17.0783 13.34 16.959 13.2803 16.8278 13.2207C16.6012 13.1133 16.3746 13.006 16.136 12.9105C16.1718 12.8986 16.1598 12.8986 16.1598 12.8986Z"
      fill="#62688F"
    />
  </svg>
);

export default withLogo(TokenETH);
