import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Typography, colors } from '../shared';

import './HealthFactorTooltip.scss';

const HealthFactorTooltip = () => {
  const { t } = useTranslation();
  return (
    <div className="nostra__health-factor-tooltip">
      <div className="nostra__health-factor-tooltip__title">
        <Icon variant="info-bordered-rounded" color={colors.textHighlight} size={20} />
        <Typography variant="body-primary" weight="bold">
          {t('HealthFactorTooltip.title')}
        </Typography>
      </div>
      <Typography variant="body-primary" color="text-gray-dark" className="nostra__health-factor-tooltip__desc">
        {t('HealthFactorTooltip.desc')}
      </Typography>
      <ul className="nostra__health-factor-tooltip__example">
        <li className="nostra__health-factor-tooltip__example__high">
          <Typography variant="body-tertiary" weight="medium" color="text-secondary">
            {t('HealthFactorTooltip.labelHigh')}
          </Typography>
        </li>
        <li className="nostra__health-factor-tooltip__example__medium">
          <Typography variant="body-tertiary" weight="medium" color="text-secondary">
            {t('HealthFactorTooltip.labelMedium')}
          </Typography>
        </li>
        <li className="nostra__health-factor-tooltip__example__low">
          <Typography variant="body-tertiary" weight="medium" color="text-secondary">
            {t('HealthFactorTooltip.labelLow')}
          </Typography>
        </li>
      </ul>
    </div>
  );
};

export default memo(HealthFactorTooltip);
