import { FC } from 'react';
import { InnerIconProps } from './IconProps';
import withIcon from './withIcon';

const RightSidebar: FC<InnerIconProps> = ({ size, color }) => (
  <svg
    className="nostra__icon nostra__icon-right-sidebar"
    width={size}
    height={size}
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.99999 0C7.36937 0 6.85524 0.514132 6.85524 1.14475C6.85524 1.77537 7.36937 2.2895 7.99999 2.2895H14.8551C15.4857 2.2895 15.9998 1.77537 15.9998 1.14475C15.9998 0.514132 15.4857 0 14.8551 0H7.99999Z"
        fill={color}
      />
      <path
        d="M0.192034 6.22443C0.171378 6.25178 0.164684 6.2929 0.150912 6.32733C0.116675 6.38911 0.0891331 6.45759 0.0686657 6.52606C0.0548943 6.56718 0.0344285 6.60142 0.0206571 6.64254C0.0137716 6.68366 0.0206571 6.7179 0.0206571 6.75902C0.0206571 6.79326 0 6.8275 0 6.86193C0 6.89617 0.0206571 6.9304 0.0206571 6.96484C0.027543 7.00596 0.0137716 7.0402 0.0206571 7.08132C0.027543 7.12244 0.0548943 7.15668 0.0686657 7.1978C0.089323 7.26627 0.116675 7.33494 0.150912 7.39653C0.17157 7.43077 0.171569 7.465 0.192034 7.49943L2.47483 10.927C2.69422 11.256 3.05742 11.4342 3.42774 11.4342C3.64713 11.4342 3.86651 11.3724 4.05836 11.2422C4.58625 10.8925 4.7232 10.1797 4.37376 9.65865L3.27014 8.00667H14.8552C15.4859 8.00667 16 7.49254 16 6.86192C16 6.2313 15.4859 5.71716 14.8552 5.71716H3.27699L4.38061 4.06518C4.73025 3.53729 4.58623 2.83132 4.06521 2.48166C3.53732 2.13202 2.83134 2.27604 2.48168 2.79706L0.192034 6.22443Z"
        fill={color}
      />
      <path
        d="M14.8551 11.4274H7.99999C7.36937 11.4274 6.85524 11.9416 6.85524 12.5722C6.85524 13.203 7.36937 13.7102 7.99999 13.7102H14.8551C15.4857 13.7102 15.9998 13.1961 15.9998 12.5655C15.9998 11.9347 15.4857 11.4274 14.8551 11.4274Z"
        fill={color}
      />
    </svg>
    ;
  </svg>
);

export default withIcon(RightSidebar);
