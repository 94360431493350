import { FC, memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setFromTotalDebtChartDate, useChartCurrency, useTotalDebtChartData } from '../../../hooks';
import { Nullable } from '../../../interfaces';
import Graph from '../Graph';

const TotalDebt: FC = () => {
  const { t } = useTranslation();

  const totalDebtChartData = useTotalDebtChartData();
  const [chartCurrency] = useChartCurrency();

  const [fromTimestamp, setFromTimestamp] = useState<Nullable<number>>(-1);

  const handleTimeResolutionChange = useCallback((fromTimestamp: number | null) => {
    setFromTimestamp(fromTimestamp);
  }, []);

  useEffect(() => {
    if (fromTimestamp === -1) {
      return;
    }

    setFromTotalDebtChartDate(fromTimestamp);
  }, [fromTimestamp]);

  return (
    <Graph
      title={t('Analytics.titleTotalDebt')}
      parsedChartData1={totalDebtChartData}
      yAxisLabel={chartCurrency}
      currencySymbol="$"
      yAxisLabelFormatting="multiplier"
      onDateFromChange={handleTimeResolutionChange}
    />
  );
};

export default memo(TotalDebt);
