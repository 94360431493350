import { TableBodyCell, TableRow, Typography } from '../../shared';

const BorrowPositionRowEmpty = () => {
  return (
    <TableRow id="borrow-position-row-empty">
      <TableBodyCell>
        <Typography variant="body-primary">&#8213;</Typography>
      </TableBodyCell>
      <TableBodyCell align="right">
        <Typography variant="body-primary">&#8213;</Typography>
      </TableBodyCell>
      <TableBodyCell align="right">
        <Typography variant="body-primary">&#8213;</Typography>
      </TableBodyCell>
      <TableBodyCell />
    </TableRow>
  );
};
export default BorrowPositionRowEmpty;
