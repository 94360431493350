import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useWallet } from '../../../hooks';
import { NostraToken } from '../../../interfaces';
import { ButtonWrapper, Icon, Link, Typography } from '../../shared';

import './ActionModalSuccessInfo.scss';

interface ActionModalSuccessInfoProps {
  txHash?: string;
  tokenToAdd?: NostraToken;
  onClose?: () => void;
}

const ActionModalSuccessInfo: FC<ActionModalSuccessInfoProps> = ({ txHash, tokenToAdd, onClose, children }) => {
  const { t } = useTranslation();
  const wallet = useWallet();

  const addTokenToWallet = useCallback(async () => {
    if (!wallet || !wallet.isConnected || !tokenToAdd) {
      return;
    }

    wallet.request({
      type: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options: {
          address: tokenToAdd.address,
          symbol: tokenToAdd.symbol,
          decimals: tokenToAdd.tokenPrecision,
          image: '',
        },
      },
    });
  }, [tokenToAdd, wallet]);

  return (
    <>
      {children}
      <div className="nostra__action-modal__success-info">
        <Icon variant="checkmark-solid" size={20} />
        <Typography className="nostra__action-modal__success-info-text" variant="body-tertiary" color="text-success">
          {t('ActionModalSuccessInfo.labelSuccess')}
        </Typography>
        {tokenToAdd && (
          <ButtonWrapper className="nostra__action-modal__add-token-button" onClick={addTokenToWallet}>
            <Typography variant="body-tertiary" weight="medium">
              {t('ActionModalSuccessInfo.btnAddToWallet', { token: tokenToAdd.symbol })}
            </Typography>
          </ButtonWrapper>
        )}
      </div>
      <ButtonWrapper className="nostra__action-modal__close-button" onClick={onClose}>
        <Typography variant="body-secondary" weight="medium">
          {t('ActionModalSuccessInfo.btnClose')}
        </Typography>
      </ButtonWrapper>
      {txHash && (
        <div className="nostra__action-modal__chain-view">
          <Link href={`https://starkscan.co/tx/${txHash}`}>
            <Typography variant="body-tertiary" weight="medium">
              {t('ActionModalSuccessInfo.linkViewStarkscan')}
            </Typography>
          </Link>
        </div>
      )}
    </>
  );
};
export default ActionModalSuccessInfo;
