import { bind } from '@react-rxjs/core';
import { BehaviorSubject, filter, map, Subscription, tap } from 'rxjs';
import { AccountInterface } from 'starknet';
import { getAccountService } from '../services';
import { walletAccount$ } from './useWalletAccount';
import { setActiveAccount } from './useActiveAccountId';

const DEFAULT_ACCOUNTS: string[] = [];

export const accountList$ = new BehaviorSubject<string[]>(DEFAULT_ACCOUNTS);

// Fetch account list on app load
const fetchStream$ = walletAccount$.pipe(
  filter((walletAccount): walletAccount is AccountInterface => Boolean(walletAccount)),
  map(walletAccount => {
    try {
      const accountService = getAccountService();

      return accountService.getAccounts(walletAccount.address);
    } catch (error) {
      console.error(`useAccounts - failed to get accounts for ${walletAccount.address}`, error);
      return null;
    }
  }),
  tap(accounts => {
    if (accounts) {
      accountList$.next(accounts);
    }
  }),
);

export const [useAccounts] = bind(accountList$, []);

let subscription: Subscription;
export const subscribeAccounts = (): void => {
  unsubscribeAccounts();
  subscription = fetchStream$.subscribe();
};

export const unsubscribeAccounts = (): void => subscription?.unsubscribe();
export const resetAccounts = (): void => accountList$.next(DEFAULT_ACCOUNTS);

export function addAccount(walletAddress: string) {
  const accountService = getAccountService();

  const accountId = accountService.addAccount(walletAddress);

  if (accountId) {
    const updatedAccounts = accountService.getAccounts(walletAddress);
    accountList$.next([...updatedAccounts]);
    setActiveAccount(walletAddress, accountId);
  } else {
    console.warn(`useAccounts - all accounts for ${walletAddress} are in use`);
  }
}

export function closeAccount(walletAddress: string, accountId: number) {
  const accountService = getAccountService();

  const accounts = accountService.closeAccount(walletAddress, accountId);

  accountList$.next([...accounts]);
}

export function renameAccount(walletAddress: string, accountId: number, name: string) {
  const accountService = getAccountService();

  accountService.setAccountName(walletAddress, accountId, name);

  const updatedAccounts = accountService.getAccounts(walletAddress);
  accountList$.next([...updatedAccounts]);
}
