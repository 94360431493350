import { FC, memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setFromTotalAssetsChartDate, useChartCurrency, useTotalAssetsChartData } from '../../../hooks';
import { Nullable } from '../../../interfaces';
import Graph from '../Graph';

const TotalAssets: FC = () => {
  const { t } = useTranslation();

  const totalAssetsChartData = useTotalAssetsChartData();
  const [chartCurrency] = useChartCurrency();

  const [fromTimestamp, setFromTimestamp] = useState<Nullable<number>>(-1);

  const handleDataFromChange = useCallback((fromTimestamp: Nullable<number>) => {
    setFromTimestamp(fromTimestamp);
  }, []);

  useEffect(() => {
    if (fromTimestamp === -1) {
      return;
    }

    setFromTotalAssetsChartDate(fromTimestamp);
  }, [fromTimestamp]);

  return (
    <Graph
      title={t('Analytics.titleTotalAssets')}
      parsedChartData1={totalAssetsChartData}
      yAxisLabel={chartCurrency}
      yAxisLabelFormatting="multiplier"
      currencySymbol="$"
      onDateFromChange={handleDataFromChange}
    />
  );
};

export default memo(TotalAssets);
