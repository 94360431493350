import { FC } from 'react';
import { InnerLogoProps } from './LogoProps';
import withLogo from './withLogo';

const TokenMIM: FC<InnerLogoProps> = ({ size }) => (
  <svg
    className="nostra__logo nostra__logo-token-MIM"
    width={size}
    height={size}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
      fill="#5552FD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26 8H14V14H26V8ZM8.00002 14H11V26H8.00002V14ZM29 14H32V26H29V14ZM23 14V17H17V20H14V17H17V14H23ZM23 17H26V20H23V17ZM26 32V20H14V32H26Z"
      fill="#FED955"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 5H26V8H14V5ZM14 8L14 11H11V8L14 8ZM26 8L29 8V11H26L26 8ZM7.99997 14H4.99998V26H7.99998V14H11V11H7.99997V14ZM35 14H32V11H29V14H32V26H35V14ZM26 32H14V29H11V32H14V35H26V32H29V29H26V32ZM7.99997 26H11V29H7.99997V26ZM32 26H29V29H32V26Z"
      fill="#9695F8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5 2C14.2238 2 14 2.22386 14 2.5V5L11 5V8H14L14 5H26V2.5C26 2.22386 25.7761 2 25.5 2H14.5ZM2 14.5C2 14.2239 2.22386 14 2.5 14H5V26H7.99998V29H4.99998V26H2.5C2.22386 26 2 25.7762 2 25.5V14.5ZM35 14H37.5C37.7762 14 38 14.2239 38 14.5V25.5C38 25.7762 37.7762 26 37.5 26H35V14ZM26 5H29V8H26V5ZM26 14H23V17H17V20H23V17H26V14ZM14 14H17V17H14V29H11L11 11L14 11V14ZM11 11H8.00002V8H11V11ZM11 29L11 32H8.00002V29H11ZM26 11H29V29H32V32H29V29H26V11ZM29 11H32V14H35V11L32 11V8H29L29 11ZM35 26H32V29H35V26ZM29 32H26V35H29V32ZM11 32H14V35H11V32ZM14 35L26 35V37.5C26 37.7761 25.7761 38 25.5 38H14.5C14.2238 38 14 37.7761 14 37.5L14 35ZM7.99998 11H4.99998V14H7.99998V11Z"
      fill="#5552FD"
    />
  </svg>
);

export default withLogo(TokenMIM);
