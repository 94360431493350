import { FC, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { Typography } from '../shared';
import { useSelectedChartToken, useTokenList } from '../../hooks';
import { Token } from '../../interfaces';
import Breadcrumb from '../Breadcrumb';
import AnalyticsStats from './Stats';
import TotalAssets from './TotalAssets';
import TotalDebt from './TotalDebt';
import TotalUniqueLenders from './TotalUniqueLenders';
import TotalUniqueBorrowers from './TotalUniqueBorrowers';
import TotalUtilizationRate from './TotalUtilizationRate';
import CumulativeInterestEarned from './CumulativeInterestEarned';
import CurrencySwitcher from './CurrencySwitcher';
import TokenSelector from './TokenSelector';
import SupplyBorrowApy from './SupplyBorrowApy';
import TotalAssetsAndDebt from './TotalAssetsAndDebt';
import AnalyticsTokenStats from './TokenStats';

import './Analytics.scss';

const Analytics: FC = () => {
  const { t } = useTranslation();
  const tokenList = useTokenList();
  const [selectedChartToken, setSelectedChartToken] = useSelectedChartToken();
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      setSelectedChartToken(null);
    } else if ((tokenList as string[]).includes(token)) {
      setSelectedChartToken(token as Token);
    } else {
      navigate('/analytics');
    }
  }, [navigate, selectedChartToken, setSelectedChartToken, token, tokenList]);

  return (
    <div className="nostra__analytics">
      <div className="nostra__analytics__header">
        <Breadcrumb>
          <Typography variant="title" color="breadcrumb">
            {t('Analytics.pathAnalytics')}
          </Typography>
          <Typography variant="title" color="breadcrumb">
            {t('Analytics.pathMoneyMarket')}
          </Typography>
          <TokenSelector />
        </Breadcrumb>
        <CurrencySwitcher />
      </div>
      <div className="nostra__analytics__divider" />
      {!selectedChartToken && <AnalyticsStats />}
      {!selectedChartToken && (
        <div className="nostra__analytics__graphs">
          <TotalAssets />
          <TotalDebt />
          <TotalUniqueLenders />
          <TotalUniqueBorrowers />
          <TotalUtilizationRate />
          <CumulativeInterestEarned />
        </div>
      )}
      {selectedChartToken && (
        <div className="nostra__analytics__graphs">
          <AnalyticsTokenStats />
          <SupplyBorrowApy />
          <TotalAssetsAndDebt />
          <TotalUtilizationRate />
          <CumulativeInterestEarned />
        </div>
      )}
    </div>
  );
};

export default memo(Analytics);
