import { v4 as uuidv4 } from 'uuid';
import { state, useStateObservable } from '@react-rxjs/core';
import { of, take, tap, withLatestFrom } from 'rxjs';
import { Notification, NotificationData } from '../interfaces';
import { createSignal } from '@react-rxjs/utils';

const [notifications$, setNotifications] = createSignal<Notification[]>();
const state$ = state(notifications$, []);

export const addNotification = (data: NotificationData): void => {
  const notification = { ...data, id: uuidv4() } as Notification;
  of(notification)
    .pipe(
      withLatestFrom(state$),
      tap(([newNotification, notifications]) => setNotifications([newNotification, ...notifications])),
      take(1),
    )
    .subscribe();
};

export const removeNotification = (id: string): void => {
  of(id)
    .pipe(
      withLatestFrom(state$),
      tap(([removeId, notifications]) =>
        setNotifications(notifications.filter(notification => notification.id !== removeId)),
      ),
      take(1),
    )
    .subscribe();
};

export function useNotifications(): [
  Notification[],
  {
    addNotification: (value: NotificationData) => void;
    removeNotification: (id: string) => void;
  },
] {
  const notifications = useStateObservable(state$);

  return [notifications, { addNotification, removeNotification }];
}
