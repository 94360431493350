import { sub, startOfHour } from 'date-fns';
import { FC, ReactElement, ReactNode, useMemo } from 'react';
import { AxisDomain } from 'recharts/types/util/types';
import { TimeResolution } from '../../../interfaces';
import { DecimalUtilsCurrency } from '../../../utils/decimalUtils';
import Chart, { ChartDataPoint, ChartDotElement, ChartCommonProps } from './Chart';
import XAxisTick from './XAxisTick';

export type LabelFormat = 'decimal' | 'multiplier' | 'percentage';

export interface DateChartProps {
  data: ChartDataPoint<Date, number>[];
  tooltipValueFormatting?: LabelFormat;
  tooltipValueLabel1?: string;
  tooltipValueLabel2?: string;
  yTick: ReactElement;
  yTickFormatter?: (value: number, index: number) => string;
  topPercentageProjected?: number;
  currencySymbol?: DecimalUtilsCurrency;
  timeScale: TimeResolution;
  dot?: ChartDotElement<number, number>;
  tooltipContent?: (x: Date, y: number) => ReactNode;
}

const CHART_DATA_FILLED_THRESHOLD = 0.9;
const TIME_SCALES: TimeResolution[] = ['1d', '1m', '3m', '6m', '1y', 'a'];

const getStartDateByTimeScale = (end: Date, timeScale: TimeResolution) => {
  switch (timeScale) {
    case '1y': {
      return sub(end, { years: 1 });
    }
    case '6m': {
      return sub(end, { months: 6 });
    }
    case '3m': {
      return sub(end, { months: 3 });
    }
    case '1m': {
      return sub(end, { months: 1 });
    }
    case '1d': {
      return sub(end, { days: 1 });
    }
  }

  return null;
};

const DateChart: FC<DateChartProps & ChartCommonProps> = props => {
  const {
    data,
    timeScale,
    width,
    height,
    yTick,
    topPercentageProjected,
    tooltipValueFormatting,
    tooltipValueLabel1,
    tooltipValueLabel2,
    currencySymbol,
    dot,
    hideData,
  } = props;

  const transformedData = useMemo(
    () => data.map(value => ({ x: value.x.getTime(), y1: value.y1, y2: value.y2 })),
    [data],
  );
  const dataRange = useMemo(
    () => (transformedData.length ? transformedData[transformedData.length - 1].x - transformedData[0].x : 0),
    [transformedData],
  );

  // data may not be able to fill in large time scale (e.g. 3M data to fill 1Y scale)
  // in this case calculate the effective time scale to display corresponding format
  const effectiveTimeScale = useMemo(() => {
    if (!data[0] || timeScale === TIME_SCALES[0]) {
      return timeScale;
    }

    const firstDate = data[0].x;
    const end = startOfHour(new Date());
    let start = getStartDateByTimeScale(end, timeScale) ?? firstDate;
    let timeScaleIndex = TIME_SCALES.findIndex(scale => scale === timeScale);

    // find the smallest time scale that can contain all data
    while (firstDate >= start && timeScaleIndex > 0) {
      start = getStartDateByTimeScale(end, TIME_SCALES[--timeScaleIndex]) as Date;
    }

    return TIME_SCALES[timeScaleIndex + 1];
  }, [data, timeScale]);

  const tooltipTimeFormatting = useMemo(() => {
    switch (effectiveTimeScale) {
      case 'a':
      case '1y':
      case '6m':
      case '3m':
        return 'd MMMM y';
      case '1m':
      case '1d':
        return "d MMM, HH:mm 'UTC'";
      default:
        return undefined;
    }
  }, [effectiveTimeScale]);

  const xTicks = useMemo(() => {
    const end = startOfHour(new Date());
    const start = getStartDateByTimeScale(end, effectiveTimeScale);

    // for 1d, we want to show integral ticks no matter what
    if (effectiveTimeScale === '1d') {
      return [
        sub(end, { hours: 21 }).getTime(),
        sub(end, { hours: 15 }).getTime(),
        sub(end, { hours: 9 }).getTime(),
        sub(end, { hours: 3 }).getTime(),
      ];
    }

    if (start && dataRange >= (end.getTime() - start.getTime()) * CHART_DATA_FILLED_THRESHOLD) {
      // check whether data can fill 90% of the selected time scale
      switch (effectiveTimeScale) {
        case '1y':
          return [
            sub(end, { months: 10 }).getTime(),
            sub(end, { months: 7 }).getTime(),
            sub(end, { months: 4 }).getTime(),
            sub(end, { months: 1 }).getTime(),
          ];
        case '6m':
          return [
            sub(end, { months: 5 }).getTime(),
            sub(end, { months: 3 }).getTime(),
            sub(end, { months: 1 }).getTime(),
          ];
        case '3m':
          return [
            sub(end, { days: 9 }).getTime(),
            sub(end, { days: 33 }).getTime(),
            sub(end, { days: 57 }).getTime(),
            sub(end, { days: 81 }).getTime(),
          ];
        case '1m':
          return [
            sub(end, { days: 27 }).getTime(),
            sub(end, { days: 20 }).getTime(),
            sub(end, { days: 13 }).getTime(),
            sub(end, { days: 6 }).getTime(),
          ];
      }
    }

    // calculate x-axis ticks based on data
    return [
      end.getTime() - dataRange * 0.875,
      end.getTime() - dataRange * 0.625,
      end.getTime() - dataRange * 0.375,
      end.getTime() - dataRange * 0.125,
    ];
  }, [effectiveTimeScale, dataRange]) as number[] | undefined;

  const xDomain = useMemo((): AxisDomain => {
    const end = startOfHour(new Date());

    if (effectiveTimeScale === '1d') {
      const start = getStartDateByTimeScale(end, effectiveTimeScale) as Date;
      return () => [start.getTime(), end.getTime()];
    }

    return ['dataMin', 'dataMax'];
  }, [effectiveTimeScale]);

  return (
    <Chart
      data={transformedData}
      width={width}
      height={height}
      xAxisType="number"
      yAxisType="number"
      yTick={yTick}
      xTick={<XAxisTick timeScale={effectiveTimeScale} />}
      xTicks={xTicks}
      xDomain={xDomain}
      dot={dot}
      topPercentageProjected={topPercentageProjected}
      hideData={hideData}
      tooltipValueFormatting={tooltipValueFormatting}
      tooltipTimeFormatting={tooltipTimeFormatting}
      tooltipValueLabel1={tooltipValueLabel1}
      tooltipValueLabel2={tooltipValueLabel2}
      currencySymbol={currencySymbol}
    />
  );
};

export default DateChart;
