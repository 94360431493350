import { bind } from '@react-rxjs/core';
import { AccountInterface } from 'starknet';
import {
  BehaviorSubject,
  combineLatest,
  debounce,
  filter,
  interval,
  map,
  merge,
  mergeMap,
  Observable,
  of,
  pairwise,
  scan,
  Subscription,
  tap,
} from 'rxjs';
import { Asset, BorrowPosition, Nullable } from '../interfaces';
import { walletAccount$ } from './useWalletAccount';
import { Decimal, ONE } from '../datastructures';
import { DEBOUNCE_IN_MS } from '../constants';
import { getConfigManager } from '../config/getConfigManager';
import { nostraTokenBalance$ } from './useNostraTokenBalance';
import { assets$ } from './useAssets';
import { TokenRateMap, tokenRates$ } from './useTokenRates';
import { activeAccountId$ } from './useActiveAccountId';

type AssetAddressPositionMap = { [walletAddressAccountIdAssetAddress: string]: BorrowPosition };

// If value is null we can assume positions are still loading and we should show skeleton view
const DEFAULT_VALUE: Nullable<BorrowPosition[]> = null;

const fetchData = (
  walletAddress: string,
  accountId: number,
  asset: Asset,
  collateralFactor: Decimal,
  borrowRate: Decimal,
  tokenRate: Decimal,
): Observable<BorrowPosition | null> => {
  return nostraTokenBalance$.pipe(
    map(nostraTokenBalances => {
      const balance = nostraTokenBalances[`${walletAddress}-${accountId}-${asset.debtTokenAddress}`];

      // still loading the nostra token balance
      if (!balance) {
        return null;
      }

      const balanceUsd = balance.mul(tokenRate);

      return {
        asset,
        balance,
        balanceUsd,
        apy: borrowRate,
        collateralFactor,
      };
    }),
  );
};

export const borrowPositionMap$ = new BehaviorSubject<Nullable<AssetAddressPositionMap>>(DEFAULT_VALUE);
export const borrowPositions$ = new BehaviorSubject<Nullable<BorrowPosition[]>>(DEFAULT_VALUE);

type UpdateStreamInputs = [Nullable<AccountInterface>, Nullable<number>, Asset[], TokenRateMap];
type UpdateStreamNonNullInputs = [AccountInterface, number, Asset[], TokenRateMap];

const updateStream$ = combineLatest([walletAccount$, activeAccountId$, assets$, tokenRates$]).pipe(
  pairwise(),
  tap<[UpdateStreamInputs, UpdateStreamInputs]>(value => {
    const previousWalletAccount = value[0][0];
    const walletAccount = value[1][0];

    /*
    In case user changes the wallet, we want to reset borrow positions map to null.
    (show loading skeleton while positions for new wallet are being loaded)
    */
    if (previousWalletAccount !== walletAccount) {
      borrowPositionMap$.next(DEFAULT_VALUE);
    }
    return value;
  }),
  filter((value): value is [UpdateStreamNonNullInputs, UpdateStreamNonNullInputs] => {
    const [walletAccount, activeAccountId] = value[1];
    return Boolean(walletAccount) && activeAccountId !== null;
  }),
  mergeMap(([, [walletAccount, activeAccountId, assets, tokenRates]]) => {
    const debtTokenBalances$ = assets.map(asset => {
      if (!asset.borrowApy || !asset.collateralFactor) {
        return of(null);
      }

      const tokenRate = tokenRates[asset.address] ?? ONE;

      return fetchData(
        walletAccount.address,
        activeAccountId,
        asset,
        asset.collateralFactor,
        asset.borrowApy,
        tokenRate,
      ).pipe(
        map(position =>
          // if position is null, nostra token is still loading, skip update
          position
            ? {
                ...position,
                walletAddress: walletAccount.address,
                accountId: activeAccountId,
              }
            : null,
        ),
      );
    });

    return merge(...debtTokenBalances$);
  }),
  scan((allPositions, position) => {
    if (position === null) {
      return allPositions;
    }

    return {
      ...(allPositions ?? {}),
      [`${position.walletAddress}-${position.accountId}-${position.asset.address}`]: position,
    };
  }, null as Nullable<AssetAddressPositionMap>),
  debounce(() => interval(DEBOUNCE_IN_MS)),
  filter((positions): positions is AssetAddressPositionMap => positions !== null),
  tap(positions => borrowPositionMap$.next(positions)),
);

type PositionsStreamInputs = [Nullable<AccountInterface>, Nullable<number>, Nullable<AssetAddressPositionMap>];
type PositionsStreamNonNullInputs = [AccountInterface, number, AssetAddressPositionMap];

const positionsStream$ = combineLatest([walletAccount$, activeAccountId$, borrowPositionMap$]).pipe(
  pairwise(),
  tap<[PositionsStreamInputs, PositionsStreamInputs]>(value => {
    const previousWalletAccount = value[0][0];
    const walletAccount = value[1][0];

    /*
    In case user changes the wallet, we want to reset deposit positions to null.
    (show loading skeleton while positions for new wallet are being loaded)
    */
    if (previousWalletAccount !== walletAccount) {
      borrowPositions$.next(DEFAULT_VALUE);
    }
    return value;
  }),
  filter((value): value is [PositionsStreamNonNullInputs, PositionsStreamNonNullInputs] => {
    const [walletAccount, accountId, depositPositionMap] = value[1];

    return Boolean(walletAccount) && accountId !== undefined && accountId !== null && depositPositionMap !== null;
  }),
  map(([, [walletAccount, accountId, map]]) => {
    const positions = Object.keys(map)
      .filter(key => key.startsWith(`${walletAccount?.address}-${accountId}-`))
      .sort((a, b) => map[a].asset.token.localeCompare(map[b].asset.token))
      .map(key => map[key]);

    const config = getConfigManager().getConfig();

    // If not all positions are loaded, skip the UI update until all positions are loaded
    if (positions.length < Object.keys(config.assets).length) {
      return null;
    }

    // Return only non zero balance positions
    return positions.filter(position => !position.balance.isZero());
  }),
  debounce(() => interval(DEBOUNCE_IN_MS)),
  tap(positions => {
    if (positions !== null) {
      borrowPositions$.next(positions);
    }
  }),
);

export const [useBorrowPositions] = bind(borrowPositions$, DEFAULT_VALUE);

let subscriptions: Subscription[];

export const subscribeBorrowPositions = (): void => {
  unsubscribeBorrowPositions();
  subscriptions = [updateStream$.subscribe(), positionsStream$.subscribe()];
};
export const unsubscribeBorrowPositions = (): void =>
  subscriptions?.forEach(subscription => subscription?.unsubscribe());
export const resetBorrowPositions = (): void => {
  borrowPositionMap$.next(null);
  borrowPositions$.next(DEFAULT_VALUE);
};
