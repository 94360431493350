import { bind } from '@react-rxjs/core';
import {
  of,
  merge,
  mergeMap,
  tap,
  filter,
  debounce,
  interval,
  scan,
  Subscription,
  Observable,
  concatMap,
  combineLatest,
  BehaviorSubject,
  map,
} from 'rxjs';
import { AccountInterface } from 'starknet';
import { DEBOUNCE_IN_MS } from '../constants';
import { ZERO } from '../datastructures';
import { Asset } from '../interfaces';
import { walletAccount$ } from './useWalletAccount';
import { activeAccountId$ } from './useActiveAccountId';
import { appEvent$ } from './useAppEvent';
import { assets$ } from './useAssets';
import { nostraTokenBalance$, NostraTokenBalanceMap } from './useNostraTokenBalance';

export interface AssetVariant {
  address: string;
  isLending: boolean;
  isCollateral: boolean;
}

export interface AssetVariantMap {
  [walletAddressAccountIdAssetAddress: string]: AssetVariant;
}

// We should keep asset variant data for each asset per each account
export const assetVariant$ = new BehaviorSubject<AssetVariantMap>({});

const fetchData = (
  walletAddress: string,
  activeAccountId: number,
  asset: Asset,
  nostraTokenBalanceMap: NostraTokenBalanceMap,
): Observable<AssetVariantMap | null> => {
  const nostraInterestBearingDataKey = `${walletAddress}-${activeAccountId}-${asset.nostraInterestBearingTokenAddress}`;
  const nostraInterestBearingCollateralDataKey = `${walletAddress}-${activeAccountId}-${asset.nostraInterestBearingCollateralTokenAddress}`;
  const nostraDataKey = `${walletAddress}-${activeAccountId}-${asset.nostraTokenAddress}`;
  const nostraCollateralDataKey = `${walletAddress}-${activeAccountId}-${asset.nostraCollateralTokenAddress}`;

  const nostraInterestBearingBalance = nostraTokenBalanceMap[nostraInterestBearingDataKey];
  const nostraInterestBearingCollateralBalance = nostraTokenBalanceMap[nostraInterestBearingCollateralDataKey];
  const nostraBalance = nostraTokenBalanceMap[nostraDataKey];
  const nostraCollateralBalance = nostraTokenBalanceMap[nostraCollateralDataKey];

  if (
    !nostraInterestBearingBalance ||
    !nostraInterestBearingCollateralBalance ||
    !nostraBalance ||
    !nostraCollateralBalance
  ) {
    return of(null);
  }

  const isLending =
    (nostraInterestBearingBalance.gt(ZERO) || nostraInterestBearingCollateralBalance.gt(ZERO)) &&
    nostraBalance.equals(ZERO) &&
    nostraCollateralBalance.equals(ZERO);

  const isCollateral =
    (nostraInterestBearingCollateralBalance.gt(ZERO) || nostraCollateralBalance.gt(ZERO)) &&
    nostraInterestBearingBalance.equals(ZERO) &&
    nostraBalance.equals(ZERO);

  return of({
    [`${walletAddress}-${activeAccountId}-${asset.address}`]: {
      address: asset.address,
      isLending,
      isCollateral,
    },
  });
};

type WalletStreamNonNullInputs = [AccountInterface, number, Asset[], NostraTokenBalanceMap];

// Stream that goes over all assets and fetches their variant, this happens only when wallet address changes
const walletStream$ = combineLatest([walletAccount$, activeAccountId$, assets$, nostraTokenBalance$]).pipe(
  filter((value): value is WalletStreamNonNullInputs => {
    const [walletAccount, activeAccountId] = value;
    return Boolean(walletAccount) && activeAccountId !== null;
  }),
  mergeMap(([walletAccount, activeAccountId, assetList, nostraTokenBalanceMap]) => {
    const assetVariantFetchMap = assetList.map(asset => {
      return fetchData(walletAccount.address, activeAccountId, asset, nostraTokenBalanceMap);
    });

    return merge(...assetVariantFetchMap);
  }),
);

type AppEventStreamNonNullInputs = [Asset, AccountInterface, number, NostraTokenBalanceMap];

const appEventStream$ = combineLatest([appEvent$, walletAccount$, activeAccountId$, nostraTokenBalance$]).pipe(
  map(([{ asset }, walletAccount, activeAccountId, nostraTokenBalanceMap]) => [
    asset,
    walletAccount,
    activeAccountId,
    nostraTokenBalanceMap,
  ]),
  filter((value): value is AppEventStreamNonNullInputs => {
    const [asset, walletAccount, activeAccountId] = value;
    return Boolean(asset) && Boolean(walletAccount) && activeAccountId !== null;
  }),
  concatMap(([asset, walletAccount, activeAccountId, nostraTokenBalanceMap]) =>
    fetchData(walletAccount.address, activeAccountId, asset, nostraTokenBalanceMap),
  ),
);

// merge all stream$ into one if there are multiple
const stream$ = merge(walletStream$, appEventStream$).pipe(
  filter(assetVariantMap => Boolean(assetVariantMap)),
  scan((accumulator, value) => {
    return {
      ...accumulator,
      ...value,
    };
  }, {} as AssetVariantMap),
  debounce<AssetVariantMap>(() => interval(DEBOUNCE_IN_MS)),
  tap(assetDataMap => {
    assetVariant$.next(assetDataMap);
  }),
);

export const [useAssetVariant] = bind(assetVariant$, {});

let subscription: Subscription;

export const subscribeAssetVariants = (): void => {
  unsubscribeAssetVariants();
  subscription = stream$.subscribe();
};
export const unsubscribeAssetVariants = (): void => {
  if (subscription) {
    subscription.unsubscribe();
  }
};
export const resetAssetVariants = (): void => {
  assetVariant$.next({});
};
