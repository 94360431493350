import { FC } from 'react';
import { colors, Icon, IconVariant, Link, Typography } from '../shared';
import './WideMessage.scss';

type WideMessageProps = {
  icon?: IconVariant;
  link?: string;
  linkText?: string;
};

export const WideMessage: FC<WideMessageProps> = ({ icon, link, linkText }) => {
  return (
    <div className="nostra__wide-message">
      {icon && <Icon variant={icon} color={colors.wideMessageIconFill} size="small" />}
      <Typography variant="body-secondary" color="text-secondary">
        We're deprecating Nostra Money Market Alpha. Migrate your funds to the latest version of{' '}
        {link && linkText && <Link href={link}>{linkText}</Link>} before 29 February, 2024.
      </Typography>
    </div>
  );
};
