import { FC } from 'react';
import { InnerIconProps } from './IconProps';
import withIcon from './withIcon';

const XSolid: FC<InnerIconProps> = ({ size }) => (
  <svg
    className="nostra__icon nostra__icon-x-solid"
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="10" r="10" fill="#BFC0C0" />
    <circle cx="10" cy="10" r="10" fill="#BFC0C0" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.19102 5.19102C5.44571 4.93633 5.85864 4.93633 6.11333 5.19102L14.809 13.8867C15.0637 14.1414 15.0637 14.5543 14.809 14.809C14.5543 15.0637 14.1414 15.0637 13.8867 14.809L5.19102 6.11333C4.93633 5.85864 4.93633 5.44571 5.19102 5.19102Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.809 5.19102C15.0637 5.44571 15.0637 5.85864 14.809 6.11333L6.11333 14.809C5.85864 15.0637 5.44571 15.0637 5.19102 14.809C4.93633 14.5543 4.93633 14.1414 5.19102 13.8867L13.8867 5.19102C14.1414 4.93633 14.5543 4.93633 14.809 5.19102Z"
      fill="white"
    />
  </svg>
);

export default withIcon(XSolid);
