import { AccountInterface, GetTransactionReceiptResponse } from 'starknet';
import { getDefaultProvider } from '../services';

const POLL_INTERVAL = 12000;

export async function waitForTransactionReceipt(
  tx: string,
  account: AccountInterface,
): Promise<GetTransactionReceiptResponse> {
  // Wait for sequencer to confirm the transaction
  await account.waitForTransaction(tx, POLL_INTERVAL);

  const provider = getDefaultProvider();

  // Wait for RPC to confirm the transaction (sync with sequencer)
  let receipt: GetTransactionReceiptResponse | null = null;
  try {
    receipt = await provider.getTransactionReceipt(tx);
  } catch (error) {
    console.warn(`Failed to find tx with hash! Trying again in ${POLL_INTERVAL} milliseconds.`);
  }

  if (receipt) {
    return receipt;
  }

  await wait(POLL_INTERVAL);

  return waitForTransactionReceipt(tx, account);
}

async function wait(ms: number) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(true);
    }, ms);
  });
}
