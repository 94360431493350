import { ethers } from 'ethers';
import { GetTransactionReceiptResponse, hash } from 'starknet';
import { Decimal } from '../datastructures';
import { formatAddress } from '../helpers';
import { Asset } from '../interfaces';

export function getTokenAmountTransferredInReceipt(receipt: GetTransactionReceiptResponse, asset: Asset): Decimal {
  // Narrow down type to InvokeTransactionReceiptResponse
  if ('events' in receipt && receipt.events) {
    const transferEventKey = hash.getSelectorFromName('Transfer');

    for (const event of receipt.events) {
      // Check if event is Transfer event and if event is emitted from our asset contact
      if (
        event.keys.indexOf(transferEventKey) > -1 &&
        formatAddress(event.from_address).toLowerCase() === asset.address.toLowerCase()
      ) {
        // Transfer event has following data (from, to, value), we want to get value(amount) transferred, so we read index no. 2
        const valueIndex = 2;

        return new Decimal(ethers.utils.formatUnits(event.data[valueIndex], asset.tokenPrecision));
      }
    }

    throw new Error('Failed to find transferred token amount in receipt!');
  } else {
    throw new Error('Provided receipt is not of type invoke transaction receipt!');
  }
}
