import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { GAS_FEE_UI_DECIMALS, HEALTH_FACTOR_BUFFER, HEALTH_FACTOR_DANGER } from '../../../../constants';
import { Decimal, ZERO } from '../../../../datastructures';
import {
  useWalletAccount,
  useActiveAccountId,
  useMaxWithdraw,
  useTokenRates,
  useWithdraw,
  useNostraTokenBalance,
  useNewWithdrawHealthFactor,
  useAccountsHealthFactors,
  AssetVariant,
  useAssetVariant,
} from '../../../../hooks';
import { Asset, Nullable } from '../../../../interfaces';
import { DecimalUtils, getNostraTokenFromFlags } from '../../../../utils';
import {
  ActionButton,
  ActionButtonLabels,
  ActionButtonState,
  CurrencyInput,
  ShadowContainer,
  Typography,
} from '../../../shared';
import ActionModalHealthFactorData from '../../ActionModalHealthFactorData';
import ActionModalSuccessInfo, { ActionModalSuccessRow } from '../../ActionModalSuccess';

import './Withdraw.scss';

interface WithdrawProps {
  asset: Asset;
  onModalClose?: () => void;
}

const Withdraw: FC<WithdrawProps> = props => {
  const { asset, onModalClose } = props;

  const { t } = useTranslation();

  const walletAccount = useWalletAccount();
  const activeAccountId = useActiveAccountId();
  const nostraTokenBalance = useNostraTokenBalance();
  const tokenRates = useTokenRates();
  const accountHealthFactor = useAccountsHealthFactors();
  const assetVariantMap = useAssetVariant();
  const { withdrawStatus, withdraw } = useWithdraw();
  const calculateMaxWithdraw = useMaxWithdraw();
  const calculateNewWithdrawHealthFactor = useNewWithdrawHealthFactor();

  const [amount, setAmount] = useState<string>('');
  const [state, setState] = useState<ActionButtonState>('default');

  // We want to store copy of balance when withdraw is started,
  // we need this info to calculate remaining balance and show it in success modal
  const activeNostraTokenBalanceStore = useRef<null | Decimal>(null);

  /**
   * Address of currently connected user wallet.
   */
  const walletAddress = useMemo(() => {
    return walletAccount?.address || null;
  }, [walletAccount?.address]);

  /**
   * Token data key is used to access values from hook maps. Key is set to null if wallet is not yet connected.
   */
  const nostraInterestBearingTokenDataKey = useMemo(() => {
    if (!walletAddress) {
      return null;
    }

    return `${walletAddress}-${activeAccountId}-${asset.nostraInterestBearingTokenAddress}`;
  }, [activeAccountId, asset.nostraInterestBearingTokenAddress, walletAddress]);

  /**
   * Token data key is used to access values from hook maps. Key is set to null if wallet is not yet connected.
   */
  const nostraInterestBearingCollateralDataKey = useMemo(() => {
    if (!walletAddress) {
      return null;
    }

    return `${walletAddress}-${activeAccountId}-${asset.nostraInterestBearingCollateralTokenAddress}`;
  }, [activeAccountId, asset.nostraInterestBearingCollateralTokenAddress, walletAddress]);

  /**
   * Token data key is used to access values from hook maps. Key is set to null if wallet is not yet connected.
   */
  const nostraCollateralDataKey = useMemo(() => {
    if (!walletAddress) {
      return null;
    }

    return `${walletAddress}-${activeAccountId}-${asset.nostraCollateralTokenAddress}`;
  }, [activeAccountId, asset.nostraCollateralTokenAddress, walletAddress]);

  /**
   * Token data key is used to access values from hook maps. Key is set to null if wallet is not yet connected.
   */
  const nostraTokenDataKey = useMemo(() => {
    if (!walletAddress) {
      return null;
    }

    return `${walletAddress}-${activeAccountId}-${asset.nostraTokenAddress}`;
  }, [activeAccountId, asset.nostraTokenAddress, walletAddress]);

  /**
   * Nostra token variant currently present in active account
   */
  const assetVariant = useMemo((): Nullable<AssetVariant> => {
    if (!walletAddress) {
      return null;
    }

    return assetVariantMap[`${walletAddress}-${activeAccountId}-${asset.address}`] || null;
  }, [walletAddress, activeAccountId, asset.address, assetVariantMap]);

  /**
   * Each account can hold only one type of nostra token, this key is for nostra token that is present in currently selected account.
   * Token data key is used to access values from hook maps. Key is set to null if wallet is not yet connected or asset variant is not yet calculated.
   */
  const activeNostraTokenDataKey = useMemo(() => {
    if (assetVariant && walletAddress) {
      const activeNostraToken = getNostraTokenFromFlags(asset, {
        interestBearing: assetVariant.isLending,
        collateral: assetVariant.isCollateral,
      });

      return `${walletAddress}-${activeAccountId}-${activeNostraToken.address}`;
    }
  }, [assetVariant, walletAddress, asset, activeAccountId]);

  /**
   * Balance of active nostra token for currently selected account.
   */
  const activeNostraTokenBalance = useMemo(() => {
    if (!activeNostraTokenDataKey) {
      return null;
    }

    return nostraTokenBalance[activeNostraTokenDataKey] || null;
  }, [activeNostraTokenDataKey, nostraTokenBalance]);

  /**
   * Calculate maximum amount user can withdraw based on amount of tokens available in nostra smart contracts, account health factor,
   * account active token balance.
   */
  const maxAmount = useMemo(() => {
    if (!walletAddress || !activeNostraTokenBalance || activeAccountId === null) {
      return null;
    }

    const healthFactorWithdrawLimit = calculateMaxWithdraw?.(
      walletAddress,
      activeAccountId,
      asset,
      new Decimal(HEALTH_FACTOR_DANGER + HEALTH_FACTOR_BUFFER),
    );
    if (!healthFactorWithdrawLimit) {
      return null;
    }

    /**
     * In case user is withdrawing nostra tokens (idle tokens), we don't need to check account health factor,
     * or available liquidity.
     */
    if (activeNostraTokenDataKey === nostraTokenDataKey) {
      return activeNostraTokenBalance;
    }

    /**
     * In case user is withdrawing nostra interest bearing tokens (lend tokens), we don't need to check user health factor,
     * we only check if nostra contracts have enough liquidity.
     */
    if (activeNostraTokenDataKey === nostraInterestBearingTokenDataKey) {
      return Decimal.min(activeNostraTokenBalance, asset.availableForBorrowing ?? ZERO);
    }

    /**
     * In case user is withdrawing nostra collateral tokens, we should check account health factor, so that final health factor after
     * withdrawal stays at minimum allowed health factor (HEALTH_FACTOR_DANGER). We don't need to check available liquidity,
     * because other users can't borrow nostra collateral tokens.
     */
    if (activeNostraTokenDataKey === nostraCollateralDataKey) {
      return Decimal.min(activeNostraTokenBalance, Decimal.max(healthFactorWithdrawLimit, ZERO));
    }

    /**
     * In case user is withdrawing nostra interest bearing collateral tokens (lend-collateral tokens) we want to check both account health factor,
     * and available liquidity, because nostra interest bearing collateral
     * tokens (lend-collateral tokens) can be borrowed by other users, and they also affect account health factor.
     */
    if (activeNostraTokenDataKey === nostraInterestBearingCollateralDataKey) {
      const balanceMin = Decimal.min(activeNostraTokenBalance, asset.availableForBorrowing ?? ZERO);
      const healthFactorMin = Decimal.min(activeNostraTokenBalance, Decimal.max(healthFactorWithdrawLimit, ZERO));

      return Decimal.min(balanceMin, healthFactorMin);
    }

    return null;
  }, [
    walletAddress,
    activeAccountId,
    asset,
    activeNostraTokenBalance,
    activeNostraTokenDataKey,
    nostraTokenDataKey,
    nostraInterestBearingTokenDataKey,
    nostraCollateralDataKey,
    nostraInterestBearingCollateralDataKey,
    calculateMaxWithdraw,
  ]);

  const decimalAmount = useMemo(() => Decimal.parse(amount, ZERO), [amount]);

  const healthFactor = useMemo(() => {
    const dataKey = `${walletAccount?.address}-${activeAccountId}`;

    if (!accountHealthFactor[dataKey]) {
      return null;
    }

    return accountHealthFactor[dataKey].value;
  }, [walletAccount?.address, activeAccountId, accountHealthFactor]);

  const newHealthFactor = useMemo(() => {
    if (!walletAccount || activeAccountId === null) {
      return null;
    }

    return decimalAmount.isZero() || !calculateNewWithdrawHealthFactor
      ? healthFactor
      : calculateNewWithdrawHealthFactor(walletAccount.address, activeAccountId, decimalAmount, asset);
  }, [activeAccountId, decimalAmount, calculateNewWithdrawHealthFactor, walletAccount, healthFactor, asset]);

  const formattedAvailableSupply = useMemo(() => {
    if (!asset.availableForBorrowing) {
      return null;
    }
    return DecimalUtils.format(asset.availableForBorrowing, {
      style: 'multiplier',
      fractionDigits: 2,
      noMultiplierFractionDigits: asset.uiTokenPrecision,
      currency: asset.token,
      lessThanFormat: true,
    });
  }, [asset.availableForBorrowing, asset.token, asset.uiTokenPrecision]);

  const amountWithdrawnFormatted = useMemo(() => {
    if (!withdrawStatus?.transactionData) {
      return '';
    }

    return DecimalUtils.format(withdrawStatus.transactionData?.withdrawnAmount, {
      style: 'multiplier',
      fractionDigits: 2,
      noMultiplierFractionDigits: asset.uiTokenPrecision,
      currency: asset.token,
      lessThanFormat: true,
    });
  }, [asset.token, asset.uiTokenPrecision, withdrawStatus?.transactionData]);

  const gasFeeFormatted = useMemo(() => {
    if (!withdrawStatus?.transactionData) {
      return '';
    }

    return DecimalUtils.format(withdrawStatus.transactionData.gasFee, {
      style: 'currency',
      fractionDigits: GAS_FEE_UI_DECIMALS,
      currency: 'ETH',
      lessThanFormat: true,
    });
  }, [withdrawStatus?.transactionData]);

  const remainingBalanceFormatted = useMemo(() => {
    if (!activeNostraTokenBalanceStore.current || !withdrawStatus?.transactionData) {
      return '';
    }

    return DecimalUtils.format(
      Decimal.max(activeNostraTokenBalanceStore.current.sub(withdrawStatus.transactionData.withdrawnAmount), ZERO),
      {
        style: 'multiplier',
        fractionDigits: 2,
        noMultiplierFractionDigits: asset.uiTokenPrecision,
        currency: asset.token,
        lessThanFormat: true,
      },
    );
  }, [withdrawStatus?.transactionData, asset.uiTokenPrecision, asset.token]);

  /**
   * Update action button state based on current withdraw request status
   */
  useEffect(() => {
    if (!withdrawStatus) {
      return;
    }

    if (withdrawStatus.pending) {
      setState('loading');
      setAmount(withdrawStatus.request.inputAmount.toString());
    } else if (withdrawStatus.success) {
      setState('success');
    } else {
      setState('default');
    }
  }, [withdrawStatus]);

  /**
   * Called when user wants to withdraw tokens
   */
  const handleWithdraw = useCallback(() => {
    // Do not allow user to start withdraw process before connecting wallet
    if (!walletAccount || !activeNostraTokenBalance || activeAccountId === null) {
      return;
    }

    activeNostraTokenBalanceStore.current = activeNostraTokenBalance;

    const withdrawTxnId = uuid();

    let isCollateral: boolean | null = null;
    let isInterestBearing: boolean | null = null;
    if (activeNostraTokenDataKey === nostraTokenDataKey) {
      isInterestBearing = false;
      isCollateral = false;
    }
    if (activeNostraTokenDataKey === nostraInterestBearingTokenDataKey) {
      isInterestBearing = true;
      isCollateral = false;
    }
    if (activeNostraTokenDataKey === nostraCollateralDataKey) {
      isInterestBearing = false;
      isCollateral = true;
    }
    if (activeNostraTokenDataKey === nostraInterestBearingCollateralDataKey) {
      isInterestBearing = true;
      isCollateral = true;
    }

    if (isInterestBearing === null || isCollateral === null) {
      return;
    }

    // In case user wants to withdraw all of his deposits, we want to pass max Decimal as the amount,
    // reason for this is because nostra token balance is constantly increasing in user wallet (based on Lend APY), and by
    // the time withdraw tx is executed, withdrawn amount will be less then nostra token balance in the wallet.
    // Passing max decimal as amount tells the contract to calculate current nostra balance for user and withdraw everything.
    const withdrawAll = decimalAmount.equals(activeNostraTokenBalance);

    withdraw({
      amount: withdrawAll ? Decimal.MAX_DECIMAL : decimalAmount,
      inputAmount: decimalAmount,
      asset,
      to: walletAccount.address,
      accountId: activeAccountId,
      isInterestBearing,
      isCollateral,
      txnId: withdrawTxnId,
    });

    setState('loading');
  }, [
    walletAccount,
    activeNostraTokenBalance,
    activeNostraTokenDataKey,
    nostraTokenDataKey,
    nostraInterestBearingTokenDataKey,
    nostraCollateralDataKey,
    nostraInterestBearingCollateralDataKey,
    decimalAmount,
    withdraw,
    asset,
    activeAccountId,
  ]);

  const handleAmountChange = useCallback((newAmount: string) => {
    setAmount(newAmount);
  }, []);

  const actionButtonLabels: ActionButtonLabels = {
    default: t('Withdraw.actionWithdraw'),
    loading: t('Withdraw.actionExecuting'),
    success: t('Withdraw.actionSuccess'),
  };

  const notEnoughBalance = decimalAmount.gt(activeNostraTokenBalance ?? ZERO) || decimalAmount.gt(maxAmount || ZERO);

  const error = useMemo(() => {
    if (!walletAccount) {
      return undefined;
    }

    if (amount && notEnoughBalance) {
      return t('Withdraw.errorInsufficientBalance');
    }

    if (amount && asset.availableForBorrowing && decimalAmount.gt(asset.availableForBorrowing)) {
      return t('Withdraw.errorExceedAvailableSupply');
    }

    return undefined;
  }, [walletAccount, amount, notEnoughBalance, asset.availableForBorrowing, decimalAmount, t]);

  if (withdrawStatus?.success) {
    return (
      <ActionModalSuccessInfo onClose={onModalClose} txHash={withdrawStatus.getTransactionResponse?.transaction_hash}>
        <ActionModalSuccessRow
          label={t('ActionModalSuccessInfo.labelWithdrawAmount')}
          value={`${amountWithdrawnFormatted}`}
        />
        <ActionModalSuccessRow
          label={t('ActionModalSuccessInfo.labelRemainingBalance')}
          value={`${remainingBalanceFormatted}`}
        />
        <ActionModalSuccessRow label={t('ActionModalSuccessInfo.labelNetworkFee')} value={gasFeeFormatted} />
      </ActionModalSuccessInfo>
    );
  }

  return (
    <>
      {/* Input field */}
      <div className="nostra__withdraw-modal__input">
        <CurrencyInput
          asset={asset}
          amount={amount}
          showAmount={!!walletAccount}
          maxAmount={maxAmount}
          maxAmountLabel={t('Withdraw.labelMaxWithdrawableAmount')}
          showWalletIcon={false}
          tokenRate={tokenRates[asset.address]}
          disabled={state === 'loading'}
          error={error}
          autoFocus
          onUpdate={handleAmountChange}
        />
      </div>

      {/* Collateral */}
      <ShadowContainer className="nostra__withdraw-modal__collateral nostra__action-modal__data-container">
        <div className="nostra__action-modal__data-row">
          <Typography variant="body-tertiary">{t('Withdraw.labelAvailableSupply')}</Typography>
          <Typography variant="body-tertiary">{formattedAvailableSupply}</Typography>
        </div>
        {assetVariant?.isCollateral && (
          <ActionModalHealthFactorData
            amount={decimalAmount}
            healthFactor={healthFactor}
            newHealthFactor={newHealthFactor}
          />
        )}
      </ShadowContainer>

      {/* Action button */}
      <ActionButton
        size="large"
        variant="primary"
        labels={actionButtonLabels}
        onClick={handleWithdraw}
        state={decimalAmount.isZero() || notEnoughBalance ? 'disabled' : state}
        fullWidth
      />
    </>
  );
};

export default Withdraw;
