import { FC, MouseEvent, useCallback, useState } from 'react';
import { truncateAddress } from '../../../helpers';
import { ButtonWrapper, colors, Icon, Link, Typography } from '../../shared';

interface MarketsAssetContractProps {
  address: string;
  name: string;
}

const MarketsAssetContract: FC<MarketsAssetContractProps> = props => {
  const { address, name } = props;
  const [copied, setCopied] = useState(false);

  const handleCopy = useCallback(
    async (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      navigator.clipboard.writeText(address);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    },
    [address],
  );

  return (
    <>
      <Typography variant="body-tertiary">{name}</Typography>
      <Typography variant="body-tertiary" color="primary">
        <Link href={`https://starkscan.co/contract/${address}`}>{truncateAddress(address)}</Link>
      </Typography>
      <ButtonWrapper onClick={handleCopy}>
        <Icon
          variant={copied ? 'checkmark' : 'copy'}
          size="small"
          color={copied ? colors.iconLightestColor : colors.iconDefaultColor}
        />
      </ButtonWrapper>
    </>
  );
};

export default MarketsAssetContract;
