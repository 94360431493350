import { Decimal, ONE, ZERO } from '../datastructures';
import { Asset, Nullable } from '../interfaces';

const calculateBorrowRate = (newUtilizationRate: Decimal, asset: Asset): Nullable<Decimal> => {
  if (!asset.optimalUtilizationRate || !asset.baseBorrowRate || !asset.rateSlope1 || !asset.rateSlope2) {
    return null;
  }

  if (asset.optimalUtilizationRate.isZero() || asset.optimalUtilizationRate.equals(ONE)) {
    return null;
  }

  if (newUtilizationRate.lt(asset.optimalUtilizationRate)) {
    return asset.baseBorrowRate.add(asset.rateSlope1.mul(newUtilizationRate).div(asset.optimalUtilizationRate));
  } else {
    return asset.baseBorrowRate
      .add(asset.rateSlope1)
      .add(
        asset.rateSlope2
          .mul(newUtilizationRate.sub(asset.optimalUtilizationRate))
          .div(ONE.sub(asset.optimalUtilizationRate)),
      );
  }
};

// ref: https://docs.nostra.finance/nostra-money-market/interest-rates/borrowing-rate
export const calculateNewBorrowRate = (newBorrow: Decimal, asset: Asset): Nullable<Decimal> => {
  if (
    !asset.totalBorrow ||
    !asset.nostraInterestBearingSupply ||
    !asset.nostraInterestBearingCollateralSupply ||
    asset.nostraInterestBearingSupply.add(asset.nostraInterestBearingCollateralSupply).isZero()
  ) {
    return null;
  }

  const utilizationRate = asset.totalBorrow
    .add(newBorrow)
    .div(asset.nostraInterestBearingSupply.add(asset.nostraInterestBearingCollateralSupply));

  return calculateBorrowRate(utilizationRate, asset);
};

// ref: https://docs.nostra.finance/nostra-money-market/interest-rates/lending-rate
export const calculateNewLendingRate = (newDeposit: Decimal, asset: Asset): Nullable<Decimal> => {
  if (
    !asset.totalBorrow ||
    !asset.nostraInterestBearingSupply ||
    !asset.nostraInterestBearingCollateralSupply ||
    !asset.generalProtocolFee ||
    asset.nostraInterestBearingSupply.add(asset.nostraInterestBearingCollateralSupply).isZero()
  ) {
    return null;
  }

  // nostraInterestBearingSupply + nostraInterestBearingCollateralSupply + newDeposit === ZERO means withdraw all supply, so rate should be 0%
  if (asset.nostraInterestBearingSupply.add(asset.nostraInterestBearingCollateralSupply).add(newDeposit).equals(ZERO)) {
    return ZERO;
  }

  const utilizationRate = asset.totalBorrow.div(
    asset.nostraInterestBearingSupply.add(asset.nostraInterestBearingCollateralSupply).add(newDeposit),
  );
  const newBorrowRate = calculateBorrowRate(utilizationRate, asset);

  if (!newBorrowRate) {
    return null;
  }
  return newBorrowRate.mul(utilizationRate).mul(ONE.sub(asset.generalProtocolFee));
};
