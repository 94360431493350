import { FC, ReactNode, useMemo } from 'react';
import { Icon, Typography, IconVariant } from '../../shared';

interface ActionModalSuccessRowProps {
  label: string;
  value: string | ReactNode;
  newValue?: string | ReactNode;
  icon?: IconVariant;
}

const ActionModalSuccessRow: FC<ActionModalSuccessRowProps> = ({ label, value, newValue, icon }) => {
  const valueNode = useMemo(() => {
    if (typeof value === 'string' && typeof newValue === 'string') {
      return (
        <>
          <Typography variant="body-tertiary">{value}</Typography>
          <Icon variant="right-arrow-thin" size="tiny" />
          <Typography variant="body-tertiary">{newValue}</Typography>
        </>
      );
    } else if (typeof value === 'string' && !newValue) {
      return <Typography variant="body-tertiary">{value}</Typography>;
    } else {
      return value;
    }
  }, [newValue, value]);

  return (
    <div className="nostra__action-modal__success-row">
      <div className="nostra__action-modal__success-row-label">
        {icon && <Icon variant={icon} size="small" />}
        <Typography variant="body-tertiary">{label}</Typography>
      </div>
      <div className="nostra__action-modal__success-row-value">{valueNode}</div>
    </div>
  );
};
export default ActionModalSuccessRow;
