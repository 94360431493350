import { FC } from 'react';
import ButtonWrapper from '../ButtonWrapper';

import './ButtonSecondary.scss';

interface ButtonSecondaryProps {
  download?: boolean;
  url?: string;
  onClick?: () => void;
}

const ButtonSecondary: FC<ButtonSecondaryProps> = ({ children, download, url, onClick }) => {
  return (
    <ButtonWrapper className="nostra__button-secondary" download={download} url={url} onClick={onClick}>
      {children}
    </ButtonWrapper>
  );
};
export default ButtonSecondary;
