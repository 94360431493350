import { LoadingButtonPlaceholder, LoadingPlaceholder, TableBodyCell, TableRow } from '../../shared';

const BorrowPositionRowSkeleton = () => {
  return (
    <TableRow id="debt-position-row-skeleton">
      <TableBodyCell>
        <div className="nostra__borrow-positions__loading-asset-cell">
          <LoadingPlaceholder shape={{ circle: 'medium' }} />
          <LoadingPlaceholder shape={{ width: 'medium', height: 'small' }} />
          <LoadingPlaceholder shape={{ width: 'small', height: 'small' }} />
        </div>
      </TableBodyCell>
      <TableBodyCell align="right">
        <LoadingPlaceholder shape={{ width: 'small', height: 'small' }} />
      </TableBodyCell>
      <TableBodyCell align="right">
        <LoadingPlaceholder shape={{ width: 'small', height: 'small' }} />
      </TableBodyCell>
      <TableBodyCell>
        <div className="nostra__borrow-positions__action-cell">
          <LoadingButtonPlaceholder />
        </div>
      </TableBodyCell>
    </TableRow>
  );
};
export default BorrowPositionRowSkeleton;
