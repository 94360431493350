import { FC } from 'react';
import { InnerIconProps } from './IconProps';
import withIcon from './withIcon';

const Starknet: FC<InnerIconProps> = ({ size }) => (
  <svg
    className="nostra__icon nostra__icon-starknet"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 12C24 18.6275 18.6276 24 12.0002 24C5.37279 24 0 18.6275 0 12C0 5.37252 5.37279 0 12.0002 0C18.6276 0 24 5.37252 24 12Z"
      fill="#29296E"
    />
    <path
      d="M17.5844 8.79173L17.2793 7.849C17.2173 7.65726 17.0659 7.50806 16.8735 7.44923L15.9262 7.1579C15.795 7.11785 15.794 6.93285 15.924 6.89067L16.8671 6.58553C17.0585 6.5235 17.2077 6.37217 17.2669 6.17973L17.5578 5.23238C17.5979 5.10161 17.7829 5.10019 17.8251 5.23061L18.1302 6.17335C18.1922 6.36473 18.3436 6.51394 18.536 6.57312L19.4833 6.86409C19.6145 6.9045 19.6159 7.08914 19.4855 7.13132L18.5424 7.43647C18.351 7.49849 18.2018 7.65018 18.1426 7.84262L17.8517 8.78961C17.8116 8.92074 17.6266 8.92216 17.5844 8.79173Z"
      fill="#FAFAFA"
    />
    <path
      d="M1.09375 11.8099C1.37905 11.251 1.94326 10.8299 2.52945 10.5638C3.12166 10.3004 3.7727 10.1388 4.41205 10.0864C5.7028 9.96978 6.96023 10.1856 8.10567 10.5588C8.69575 10.7346 9.22382 10.9788 9.76925 11.2255C10.0343 11.353 10.2835 11.4976 10.5401 11.6373L11.2489 12.048C12.0272 12.5251 12.7817 12.9475 13.4905 13.2757C14.2011 13.6011 14.8433 13.8212 15.4486 13.9335C16.0539 14.0473 16.6692 14.0459 17.4258 13.8722C18.1765 13.7024 19.021 13.3363 19.9038 12.8667C20.792 12.3968 21.7028 11.8205 22.7419 11.2574C22.6377 12.4343 22.3046 13.538 21.7843 14.6037C21.2513 15.6567 20.5099 16.6837 19.4289 17.5155C18.3685 18.3541 16.9123 18.9385 15.4298 19.0264C13.9466 19.1267 12.5357 18.7999 11.3354 18.3084C10.1304 17.8097 9.08914 17.1544 8.16627 16.431C7.91145 16.2312 7.77465 16.1177 7.58646 15.9586L7.06088 15.4982C6.70683 15.2211 6.36376 14.8897 6.01326 14.615C5.31401 14.0306 4.62221 13.4451 3.86379 12.9376C3.48138 12.6796 3.08693 12.4404 2.63967 12.2284C2.41746 12.126 2.18142 12.0307 1.92802 11.9555C1.6686 11.8708 1.40492 11.8205 1.09375 11.8099Z"
      fill="#FF4F0A"
    />
    <path
      d="M1.09375 11.8099C1.23941 10.5989 1.80398 9.46297 2.70878 8.49614C3.60897 7.53852 5.01525 6.80028 6.5757 6.70247C7.34582 6.65108 8.12764 6.73684 8.84638 6.93602C9.56192 7.13449 10.2325 7.42369 10.8367 7.76428C11.1387 7.93546 11.4211 8.12259 11.7025 8.31149L12.4525 8.87926L13.6231 9.80285C14.3829 10.4078 15.108 10.9674 15.7782 11.4296C16.4548 11.8925 17.031 12.2306 17.5814 12.4287C18.1268 12.6449 18.8158 12.7002 19.7266 12.4712C20.1789 12.367 20.6506 12.1767 21.1538 11.9704C21.6542 11.7592 22.1738 11.5072 22.7419 11.2574C22.6735 11.8662 22.5498 12.4726 22.3149 13.0393C22.0891 13.6138 21.7918 14.1759 21.376 14.7001C21.1634 14.9553 20.9355 15.2119 20.6697 15.4486C20.4039 15.6797 20.1094 15.8991 19.784 16.0915C19.1351 16.4676 18.3654 16.7422 17.5757 16.84C16.7861 16.9393 15.9933 16.8897 15.2714 16.7365C14.5456 16.5877 13.8843 16.3478 13.2772 16.0706C12.0672 15.5078 11.055 14.8004 10.1602 14.0515C9.71077 13.6776 9.28938 13.2871 8.89032 12.8905L8.41896 12.4173C8.27685 12.2802 8.13331 12.1423 7.99119 12.0161C7.41847 11.5097 6.88687 11.1227 6.30139 10.8813C5.72016 10.6286 4.97626 10.5248 4.06367 10.6956C3.15462 10.8647 2.15732 11.2577 1.09375 11.8099Z"
      fill="#FAFAFA"
    />
    <path
      d="M5.88537 18.0553C5.88537 18.4137 5.59476 18.7045 5.23645 18.7045C4.87815 18.7045 4.58789 18.4137 4.58789 18.0553C4.58789 17.6969 4.87815 17.4061 5.23645 17.4061C5.59476 17.4061 5.88537 17.6969 5.88537 18.0553Z"
      fill="#FAFAFA"
    />
    <path
      d="M5.88537 18.0553C5.88537 18.4137 5.59476 18.7045 5.23645 18.7045C4.87815 18.7045 4.58789 18.4137 4.58789 18.0553C4.58789 17.6969 4.87815 17.4061 5.23645 17.4061C5.59476 17.4061 5.88537 17.6969 5.88537 18.0553Z"
      fill="#FAFAFA"
    />
  </svg>
);

export default withIcon(Starknet);
