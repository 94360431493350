import { Decimal } from '../datastructures';
import { Token } from '../interfaces';

type DepositLimits = {
  [token in Token]: Decimal;
};

export const depositLimits: DepositLimits = {
  WBTC: new Decimal(0.0003),
  ETH: new Decimal(0.004),
  USDT: new Decimal(5),
  USDC: new Decimal(5),
  DAI: new Decimal(5),
};
