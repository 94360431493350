import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import { GAS_FEE_UI_DECIMALS, HEALTH_FACTOR_BUFFER, HEALTH_FACTOR_DANGER } from '../../../../constants';
import { Decimal, ZERO } from '../../../../datastructures';
import {
  useWalletAccount,
  useActiveAccountId,
  useBorrow,
  useAccountsHealthFactors,
  useMaxBorrow,
  useNewBorrowHealthFactor,
  useTokenRates,
  useNostraTokenBalance,
} from '../../../../hooks';
import { Asset } from '../../../../interfaces';
import { calculateNewBorrowRate, DecimalUtils, getNostraTokenFromFlags } from '../../../../utils';
import {
  ActionButton,
  ActionButtonLabels,
  ActionButtonState,
  CurrencyInput,
  Link,
  ShadowContainer,
  Typography,
} from '../../../shared';
import ActionModalHealthFactorData from '../../ActionModalHealthFactorData';
import ActionModalSuccessInfo, { ActionModalSuccessRow } from '../../ActionModalSuccess';

import './Borrow.scss';

interface BorrowProps {
  asset: Asset;
  onModalClose?: () => void;
}

const Borrow: FC<BorrowProps> = props => {
  const { asset, onModalClose } = props;

  const walletAccount = useWalletAccount();
  const activeAccountId = useActiveAccountId();
  const tokenRates = useTokenRates();
  const nostraTokenBalances = useNostraTokenBalance();
  const accountHealthFactor = useAccountsHealthFactors();
  const calculateNewBorrowHealthFactor = useNewBorrowHealthFactor();
  const calculateMaxBorrow = useMaxBorrow();
  const { borrowStatus, borrow } = useBorrow();

  const [amount, setAmount] = useState<string>('');
  const [state, setState] = useState<ActionButtonState>('default');

  // We want to store copy of health factor data when user executes borrow,
  // this will be shown in success modal after the transaction is finished
  const healthFactorStore = useRef<null | string>(null);
  const newHealthFactorStore = useRef<null | string>();
  const debtTokenBalanceStore = useRef<null | Decimal>(null);

  const { t } = useTranslation();

  const debtTokenKey = useMemo(
    () => `${walletAccount?.address}-${activeAccountId}-${asset.debtTokenAddress}`,
    [walletAccount?.address, activeAccountId, asset.debtTokenAddress],
  );
  const totalBorrowedBalance = nostraTokenBalances[debtTokenKey];
  const previouslyBorrowed = useMemo(() => totalBorrowedBalance?.gt(ZERO), [totalBorrowedBalance]);

  const maxAmount = useMemo(() => {
    if (!walletAccount || activeAccountId === null) {
      return null;
    }

    const maxSafe = calculateMaxBorrow?.(
      walletAccount.address,
      activeAccountId,
      asset,
      new Decimal(HEALTH_FACTOR_DANGER + HEALTH_FACTOR_BUFFER),
    );

    if (!maxSafe) {
      return null;
    }

    if (!asset.debtTokenSupply || !asset.debtTokenSupplyCap || asset.debtTokenSupply.gte(asset.debtTokenSupplyCap)) {
      return ZERO;
    }

    const maxUserAmount = Decimal.min(Decimal.max(maxSafe, ZERO), asset.availableForBorrowing ?? ZERO);
    return Decimal.min(maxUserAmount, asset.debtTokenSupplyCap.sub(asset.debtTokenSupply));
  }, [walletAccount, activeAccountId, asset, calculateMaxBorrow]);

  const healthFactor = useMemo(() => {
    const dataKey = `${walletAccount?.address}-${activeAccountId}`;

    if (!accountHealthFactor[dataKey]) {
      return null;
    }

    return accountHealthFactor[dataKey].value;
  }, [walletAccount, activeAccountId, accountHealthFactor]);

  const pendingAmount = useMemo(
    () => (borrowStatus?.pending ? borrowStatus.request.amount : undefined),
    [borrowStatus],
  );
  const decimalAmount = useMemo(() => pendingAmount ?? Decimal.parse(amount, ZERO), [amount, pendingAmount]);

  const newHealthFactor = useMemo(() => {
    if (!walletAccount || activeAccountId === null) {
      return null;
    }

    return decimalAmount.isZero() || !calculateNewBorrowHealthFactor
      ? healthFactor
      : calculateNewBorrowHealthFactor(walletAccount.address, activeAccountId, decimalAmount, asset);
  }, [walletAccount, activeAccountId, decimalAmount, calculateNewBorrowHealthFactor, healthFactor, asset]);

  const formattedUtilizationRate = useMemo(
    () =>
      asset.utilizationRate
        ? DecimalUtils.format(asset.utilizationRate, { style: 'percentage', fractionDigits: 2, pad: true })
        : null,
    [asset.utilizationRate],
  );

  const formattedBorrowMarketLiquidity = useMemo(
    () =>
      asset.availableForBorrowing
        ? DecimalUtils.format(asset.availableForBorrowing, {
            style: 'multiplier',
            fractionDigits: 2,
            noMultiplierFractionDigits: asset.uiTokenPrecision,
            currency: asset.token,
            lessThanFormat: true,
          })
        : null,
    [asset.availableForBorrowing, asset.token, asset.uiTokenPrecision],
  );

  const amountBorrowedFormatted = useMemo(() => {
    if (!borrowStatus?.transactionData) {
      return '';
    }

    return DecimalUtils.format(borrowStatus.transactionData?.borrowedAmount, {
      style: 'multiplier',
      fractionDigits: 2,
      noMultiplierFractionDigits: asset.uiTokenPrecision,
      currency: asset.token,
      lessThanFormat: true,
    });
  }, [asset.token, asset.uiTokenPrecision, borrowStatus?.transactionData]);

  const totalBorrowedFormatted = useMemo(() => {
    if (!debtTokenBalanceStore.current || !borrowStatus?.transactionData) {
      return '';
    }

    return DecimalUtils.format(
      Decimal.max(debtTokenBalanceStore.current.add(borrowStatus.transactionData.borrowedAmount), ZERO),
      {
        style: 'multiplier',
        fractionDigits: 2,
        noMultiplierFractionDigits: asset.uiTokenPrecision,
        currency: asset.token,
        lessThanFormat: true,
      },
    );
  }, [asset.token, asset.uiTokenPrecision, borrowStatus?.transactionData]);

  const gasFeeFormatted = useMemo(() => {
    if (!borrowStatus?.transactionData) {
      return '';
    }

    return DecimalUtils.format(borrowStatus.transactionData.gasFee, {
      style: 'currency',
      fractionDigits: GAS_FEE_UI_DECIMALS,
      currency: 'ETH',
      lessThanFormat: true,
    });
  }, [borrowStatus?.transactionData]);

  const formattedBorrowFactor = useMemo(
    // Contracts use debtFactor term for 'borrow factor' (it's the same thing)
    () =>
      asset.debtFactor
        ? DecimalUtils.format(asset.debtFactor, { style: 'percentage', fractionDigits: 2, pad: true })
        : null,
    [asset.debtFactor],
  );

  /**
   * Update action button state based on current borrow request status
   */
  useEffect(() => {
    if (!borrowStatus) {
      return;
    }

    if (borrowStatus.pending) {
      setState('loading');
      setAmount(borrowStatus.request.amount.toString());
    } else if (borrowStatus.success) {
      setState('success');
    } else {
      setState('default');
    }
  }, [borrowStatus]);

  const handleBorrow = useCallback(() => {
    // Do not allow user to start borrow process before connecting wallet
    if (!walletAccount || activeAccountId === null) {
      return;
    }

    healthFactorStore.current = healthFactor ? healthFactor.toString() : null;
    newHealthFactorStore.current = newHealthFactor ? newHealthFactor.toString() : null;
    debtTokenBalanceStore.current = totalBorrowedBalance;

    const borrowTxnId = uuid();

    borrow({
      amount: decimalAmount,
      asset,
      onBehalfOf: walletAccount.address,
      accountId: activeAccountId,
      txnId: borrowTxnId,
    });

    setState('loading');
  }, [
    walletAccount,
    healthFactor,
    newHealthFactor,
    totalBorrowedBalance,
    borrow,
    decimalAmount,
    asset,
    activeAccountId,
  ]);

  const handleAmountChange = useCallback((newAmount: string) => {
    setAmount(newAmount);
  }, []);

  const actionButtonLabels: ActionButtonLabels = {
    default: t('Borrow.actionBorrow'),
    loading: t('Borrow.actionExecuting'),
    success: t('Borrow.actionSuccess'),
  };

  const notEnoughBalance = decimalAmount.gt(asset.availableForBorrowing ?? ZERO);
  const lowHealthFactor = healthFactor === null || healthFactor.lt(HEALTH_FACTOR_DANGER);
  const lowNewHealthFactor = newHealthFactor === null || newHealthFactor.lt(HEALTH_FACTOR_DANGER);
  const lowHealthFactorChange = lowHealthFactor || lowNewHealthFactor;

  const formattedApy = useMemo(() => {
    // Do not calculate estimated borrow APY if user entered amount exceeds available supply.
    if (notEnoughBalance) {
      return '---';
    }

    const borrowApy = calculateNewBorrowRate(decimalAmount, asset);
    return borrowApy ? DecimalUtils.format(borrowApy, { style: 'percentage', fractionDigits: 2, pad: true }) : null;
  }, [decimalAmount, asset, notEnoughBalance]);

  const error = useMemo(() => {
    if (!walletAccount) {
      return undefined;
    }

    if (amount && notEnoughBalance) {
      return t('Borrow.errorInsufficientBalance');
    }

    if (lowHealthFactorChange && previouslyBorrowed) {
      return t('Borrow.errorLowHealthFactorBorrowed');
    }

    if (lowHealthFactorChange) {
      return t('Borrow.errorLowHealthFactorNotBorrowed');
    }

    return undefined;
  }, [walletAccount, amount, lowHealthFactorChange, notEnoughBalance, previouslyBorrowed, t]);

  if (borrowStatus?.success) {
    return (
      <ActionModalSuccessInfo
        onClose={onModalClose}
        tokenToAdd={getNostraTokenFromFlags(asset, 'debt')}
        txHash={borrowStatus.getTransactionResponse?.transaction_hash}
      >
        <ActionModalSuccessRow
          label={t('ActionModalSuccessInfo.labelBorrowAmount')}
          value={`${amountBorrowedFormatted} (${asset.debtTokenSymbol})`}
        />
        <ActionModalSuccessRow label={t('ActionModalSuccessInfo.labelTotalBorrowed')} value={totalBorrowedFormatted} />
        {healthFactorStore.current && newHealthFactorStore.current && (
          <ActionModalSuccessRow
            label={t('ActionModalSuccessInfo.labelHealthFactor')}
            value={
              <ActionModalHealthFactorData
                amount={decimalAmount}
                healthFactor={new Decimal(healthFactorStore.current)}
                newHealthFactor={new Decimal(newHealthFactorStore.current)}
                showLabel={false}
              />
            }
          />
        )}
        <ActionModalSuccessRow label={t('ActionModalSuccessInfo.labelNetworkFee')} value={gasFeeFormatted} />
      </ActionModalSuccessInfo>
    );
  }

  return (
    <>
      {/* Input field */}
      <div className="nostra__borrow-modal__input">
        <CurrencyInput
          asset={asset}
          amount={pendingAmount ? pendingAmount.toString() : amount}
          showAmount={!!walletAccount}
          maxAmount={maxAmount}
          maxAmountLabel={t('Borrow.labelMaxBorrowableAmount')}
          showWalletIcon={false}
          tokenRate={tokenRates[asset.address]}
          disabled={state === 'loading'}
          error={error}
          autoFocus
          onUpdate={handleAmountChange}
        />
      </div>

      {/* Borrowing */}
      <div className="nostra__borrow-modal__borrowing">
        <ShadowContainer className="nostra__action-modal__data-container">
          <div className="nostra__action-modal__data-row">
            <div className="nostra__action-modal__data-token-label">
              <Typography variant="body-tertiary">{t('Borrow.labelApy')}</Typography>
            </div>
            <Typography variant="body-tertiary">{formattedApy}</Typography>
          </div>
          <div className="nostra__action-modal__data-row">
            <Typography variant="body-tertiary">{t('Borrow.labelUtilizationRate')}</Typography>
            <Typography variant="body-tertiary">{formattedUtilizationRate}</Typography>
          </div>
          <div className="nostra__action-modal__data-row">
            <Typography variant="body-tertiary">{t('Borrow.labelBorrowMarketLiquidity')}</Typography>
            <Typography variant="body-tertiary">{formattedBorrowMarketLiquidity}</Typography>
          </div>
        </ShadowContainer>
      </div>

      {/* Borrow and health factors */}
      <div className="nostra__borrow-modal__collateral">
        <ShadowContainer className="nostra__action-modal__data-container">
          <div className="nostra__action-modal__data-row">
            <Typography variant="body-tertiary">{t('Borrow.labelBorrowFactor')}</Typography>
            <Typography variant="body-tertiary">{formattedBorrowFactor}</Typography>
          </div>
          <ActionModalHealthFactorData
            amount={decimalAmount}
            healthFactor={healthFactor}
            newHealthFactor={newHealthFactor}
          />
        </ShadowContainer>
      </div>

      <div className="nostra__action-modal__info-message">
        <Typography variant="body-secondary" color="text-error">
          New borrows are disabled for the alpha version. Click <Link href="https://app.nostra.finance">here</Link> to
          borrow using latest version of Nostra.
        </Typography>
      </div>

      {/* Action button */}
      <ActionButton
        size="large"
        variant="primary"
        labels={actionButtonLabels}
        onClick={handleBorrow}
        state="disabled"
        fullWidth
      />
    </>
  );
};

export default Borrow;
