import { bind } from '@react-rxjs/core';
import { BehaviorSubject, catchError, combineLatest, interval, merge, mergeMap, of, Subscription, tap } from 'rxjs';
import { getConfigManager } from '../config/getConfigManager';
import { MULTICALL_CONTRACT_ADDRESS } from '../constants';
import { Decimal, DEFAULT_DECIMAL_PRECISION } from '../datastructures';
import { NostraToken } from '../interfaces';
import { getMultiCallContract } from '../services';
import { InterestRateStateData } from '../services/contracts/InterestRateModelContract';
import { logGlobalError } from './useGlobalError';
import { nostraTokenList$ } from './useNostraTokenList';

const DEFAULT_VALUE = null;

export type InterestStateMap = { [debtTokenAddress: string]: InterestRateStateData };

export const interestState$ = new BehaviorSubject<InterestStateMap | null>(DEFAULT_VALUE);

const POLLING_INTERVAL_IN_MS = 300000; // 5 minutes
const RESPONSE_CHUNK_SIZE = 10;

const fetchData = (debtTokens: NostraToken[]) => {
  try {
    const config = getConfigManager().getConfig();

    const calldata = debtTokens.map(debtToken => {
      return {
        contractAddress: config.interestRateModel,
        entrypoint: 'getInterestState',
        calldata: [debtToken.address],
      };
    });

    const multiCallContract = getMultiCallContract(MULTICALL_CONTRACT_ADDRESS);

    return multiCallContract.aggregate(calldata).pipe(
      mergeMap(result => {
        if (!result) {
          return of(null);
        }

        const interestStateMap: InterestStateMap = {};

        for (let i = 0; i < result.length; i += RESPONSE_CHUNK_SIZE) {
          // Index 1,2 is lendingRate
          // Index 3,4 is borrowRate
          const lendingRate = new Decimal({ low: result[i + 1], high: result[i + 2] }, DEFAULT_DECIMAL_PRECISION);
          const borrowRate = new Decimal({ low: result[i + 3], high: result[i + 4] }, DEFAULT_DECIMAL_PRECISION);

          interestStateMap[debtTokens[i / RESPONSE_CHUNK_SIZE].address] = {
            lendingRate,
            borrowRate,
          };
        }

        return of(interestStateMap);
      }),
      catchError(error => {
        console.error(`useInterestState - Failed to load interest state!`, error);
        logGlobalError(error);
        return of(null);
      }),
    );
  } catch (error) {
    console.error(`useInterestState - Failed to load interest state!`, error);
    logGlobalError(error);
    return of(null);
  }
};

/**
 * Fetch interest rate state on app load.
 */
const initialLoadStream$ = combineLatest([nostraTokenList$]).pipe(
  mergeMap(([nostraTokenList]) => {
    const debtTokens = nostraTokenList.filter(nostraToken => nostraToken.variant === 'debt');

    return fetchData(debtTokens);
  }),
);

/**
 * Fetch interest rate state every [POLLING_INTERVAL_IN_MS] milliseconds
 */
const intervalBeat$ = interval(POLLING_INTERVAL_IN_MS);

const intervalLoadStream$ = combineLatest([nostraTokenList$, intervalBeat$]).pipe(
  mergeMap(([nostraTokenList]) => {
    const debtTokens = nostraTokenList.filter(nostraToken => nostraToken.variant === 'debt');

    return fetchData(debtTokens);
  }),
);

const stream$ = merge(initialLoadStream$, intervalLoadStream$).pipe(
  tap(interestStateMap => {
    if (interestStateMap) {
      interestState$.next(interestStateMap);
    }
  }),
);

export const [useInterestState] = bind(interestState$, DEFAULT_VALUE);

let subscription: Subscription | null = null;

export const subscribeInterestState = (): void => {
  unsubscribeInterestState();
  subscription = stream$.subscribe();
};
export const unsubscribeInterestState = (): void => subscription?.unsubscribe();
export const resetInterestState = (): void => interestState$.next(DEFAULT_VALUE);
