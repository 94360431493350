import { FC } from 'react';
import { InnerIconProps } from './IconProps';
import withIcon from './withIcon';

const Lock: FC<InnerIconProps> = ({ size, color }) => (
  <svg
    className="nostra__icon nostra__icon-lock"
    width={size}
    height={size}
    viewBox="0 0 8 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 5C8 4.4485 7.5515 4 7 4H6.5V2.5C6.5 1.1215 5.3785 0 4 0C2.6215 0 1.5 1.1215 1.5 2.5V4H1C0.4485 4 0 4.4485 0 5V9C0 9.5515 0.4485 10 1 10H7C7.5515 10 8 9.5515 8 9V5ZM2.5 2.5C2.5 1.673 3.173 1 4 1C4.827 1 5.5 1.673 5.5 2.5V4H2.5V2.5Z"
      fill={color}
    />
  </svg>
);

export default withIcon(Lock);
