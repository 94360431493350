import { FC, memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  setFromCumulativeInterestEarnedChartDate,
  useChartCurrency,
  useCumulativeInterestEarnedChartData,
  useSelectedChartToken,
} from '../../../hooks';
import { Nullable } from '../../../interfaces';
import Graph from '../Graph';

const CumulativeInterestEarned: FC = () => {
  const { t } = useTranslation();

  const cumulativeInterestEarnedChartData = useCumulativeInterestEarnedChartData();
  const [selectedChartToken] = useSelectedChartToken();
  const [chartCurrency] = useChartCurrency();

  const [fromTimestamp, setFromTimestamp] = useState<Nullable<number>>(-1);

  const handleDataFromChange = useCallback((fromTimestamp: Nullable<number>) => {
    setFromTimestamp(fromTimestamp);
  }, []);

  useEffect(() => {
    if (fromTimestamp === -1) {
      return;
    }

    setFromCumulativeInterestEarnedChartDate(fromTimestamp);
  }, [fromTimestamp]);

  return (
    <Graph
      title={t('Analytics.titleCumulativeInterestEarned')}
      titleLogo={selectedChartToken ? `token-${selectedChartToken}` : undefined}
      parsedChartData1={cumulativeInterestEarnedChartData}
      onDateFromChange={handleDataFromChange}
      yAxisLabel={chartCurrency}
      currencySymbol="$"
      yAxisLabelFormatting="multiplier"
    />
  );
};

export default memo(CumulativeInterestEarned);
