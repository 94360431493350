import { CSSProperties, FC, memo, PropsWithChildren } from 'react';
import parse from 'html-react-parser';
import { colors } from '../Colors';

import './Typography.scss';

export type TypographyVariant =
  | 'header'
  | 'subheader'
  | 'title'
  | 'subtitle'
  | 'body-primary'
  | 'body-secondary'
  | 'body-tertiary';

export type TypographyColor =
  | 'text-primary'
  | 'text-primary-accent'
  | 'text-secondary'
  | 'text-primary-inverted'
  | 'text-highlight'
  | 'text-success'
  | 'text-disabled'
  | 'text-disabled-secondary'
  | 'text-disabled-tertiary'
  | 'text-caption'
  | 'text-error'
  | 'text-warning'
  | 'text-wallet'
  | 'text-supply-apy'
  | 'text-borrow-apy'
  | 'text-wallet-address'
  | 'primary'
  | 'secondary'
  | 'secondary-light'
  | 'text-gray-dark'
  | 'text-light'
  | 'primary-main'
  | 'breadcrumb'
  | 'chart'
  | 'switcher-button'
  | 'switcher-button-disabled'
  | 'text-chart-primary'
  | 'text-chart-secondary'
  | 'text-chart-gray';

export type TypographyWeight = 'regular' | 'medium' | 'bold';

export type TypographyType = 'regular';

const typographyVariantMap = new Map<TypographyVariant, CSSProperties>();
typographyVariantMap.set('header', {
  fontStyle: 'normal',
  fontSize: '40px',
  lineHeight: '48px',
});
typographyVariantMap.set('subheader', {
  fontStyle: 'normal',
  fontSize: '32px',
  lineHeight: '40px',
});
typographyVariantMap.set('title', {
  fontStyle: 'normal',
  fontSize: '24px',
  lineHeight: '32px',
});
typographyVariantMap.set('subtitle', {
  fontStyle: 'normal',
  fontSize: '20px',
  lineHeight: '28px',
});
typographyVariantMap.set('body-primary', {
  fontStyle: 'normal',
  fontSize: '16px',
  lineHeight: '22px',
});
typographyVariantMap.set('body-secondary', {
  fontStyle: 'normal',
  fontSize: '14px',
  lineHeight: '16px',
});
typographyVariantMap.set('body-tertiary', {
  fontStyle: 'normal',
  fontSize: '12px',
  lineHeight: '16px',
});

const typographyColorMap = new Map<TypographyColor, string>();
typographyColorMap.set('text-primary', colors.textPrimary);
typographyColorMap.set('text-primary-accent', colors.textPrimaryAccent);
typographyColorMap.set('text-secondary', colors.textSecondary);
typographyColorMap.set('text-highlight', colors.textHighlight);
typographyColorMap.set('text-primary-inverted', colors.textPrimaryInverted);
typographyColorMap.set('text-success', colors.textSuccess);
typographyColorMap.set('text-disabled', colors.textDisabled);
typographyColorMap.set('text-disabled-secondary', colors.textDisabledSecondary);
typographyColorMap.set('text-disabled-tertiary', colors.textDisabledTertiary);
typographyColorMap.set('text-caption', colors.textCaption);
typographyColorMap.set('text-error', colors.textError);
typographyColorMap.set('text-warning', colors.textWarning);
typographyColorMap.set('text-wallet', colors.textWallet);
typographyColorMap.set('text-supply-apy', colors.textSupplyApy);
typographyColorMap.set('text-borrow-apy', colors.textBorrowApy);
typographyColorMap.set('text-wallet-address', colors.textWalletAddress);
typographyColorMap.set('primary', colors.primaryMain);
typographyColorMap.set('secondary', colors.secondaryRegular);
typographyColorMap.set('secondary-light', colors.secondaryLight);
typographyColorMap.set('text-gray-dark', colors.textGrayDark);
typographyColorMap.set('text-light', colors.textLight);
typographyColorMap.set('primary-main', colors.primaryMain);
typographyColorMap.set('breadcrumb', colors.breadcrumbText);
typographyColorMap.set('chart', colors.chartTitle);
typographyColorMap.set('switcher-button', colors.currencySwitcherButtonText);
typographyColorMap.set('switcher-button-disabled', colors.currencySwitcherButtonDisabledText);
typographyColorMap.set('text-chart-primary', colors.textChartPrimary);
typographyColorMap.set('text-chart-secondary', colors.textChartSecondary);
typographyColorMap.set('text-chart-gray', colors.textChartGray);

const typographyWeightMap = new Map<TypographyWeight, number>();
typographyWeightMap.set('regular', 400);
typographyWeightMap.set('medium', 500);
typographyWeightMap.set('bold', 700);

const typographyTypeMap = new Map<TypographyType, string>();
typographyTypeMap.set('regular', 'Albert Sans, sans-serif');

export interface TypographyProps {
  variant: TypographyVariant;
  color?: TypographyColor;
  opacity?: number;
  weight?: TypographyWeight;
  type?: TypographyType;
  html?: string;
  className?: string;
  title?: string;
}

const Typography: FC<PropsWithChildren<TypographyProps>> = props => {
  const { variant, color, weight, opacity = 1, type, html, children, className, title } = props;

  let fontColor: string | undefined;
  if (color) {
    fontColor = typographyColorMap.get(color);
  } else {
    fontColor = typographyColorMap.get('text-primary');
  }

  let fontWeight: number | undefined;
  if (weight) {
    fontWeight = typographyWeightMap.get(weight);
  } else {
    fontWeight = typographyWeightMap.get('regular');
  }

  let fontFamily: string | undefined;
  if (type) {
    fontFamily = typographyTypeMap.get(type);
  } else {
    fontFamily = typographyTypeMap.get('regular');
  }

  let derivedClassName = 'nostra__typography';
  if (className) {
    derivedClassName += ` ${className}`;
  }

  return (
    <div
      className={derivedClassName}
      style={{
        ...typographyVariantMap.get(variant),
        color: fontColor,
        fontWeight,
        fontFamily,
        opacity,
      }}
      title={title}
    >
      {html ? parse(html) : children}
    </div>
  );
};
export default memo(Typography);
