import { FC } from 'react';
import { InnerIconProps } from './IconProps';
import withIcon from './withIcon';

const RightSidebarOpen: FC<InnerIconProps> = ({ size, color }) => (
  <svg
    className="nostra__icon nostra__icon-right-sidebar-open"
    width={size}
    height={size}
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00001 13.7102C8.63063 13.7102 9.14476 13.1961 9.14477 12.5655C9.14477 11.9348 8.63063 11.4207 8.00001 11.4207L1.14492 11.4207C0.5143 11.4207 0.000166095 11.9348 0.000166039 12.5655C0.000165984 13.1961 0.514299 13.7102 1.14492 13.7102L8.00001 13.7102Z"
      fill="#6B7280"
    />
    <path
      d="M15.808 7.48578C15.8286 7.45843 15.8353 7.41731 15.8491 7.38288C15.8833 7.3211 15.9109 7.25262 15.9313 7.18414C15.9451 7.14302 15.9656 7.10878 15.9793 7.06766C15.9862 7.02654 15.9793 6.9923 15.9793 6.95118C15.9793 6.91694 16 6.88271 16 6.84828C16 6.81404 15.9793 6.7798 15.9793 6.74537C15.9725 6.70425 15.9862 6.67001 15.9793 6.62889C15.9725 6.58777 15.9451 6.55353 15.9313 6.51241C15.9107 6.44393 15.8833 6.37526 15.8491 6.31368C15.8284 6.27944 15.8284 6.2452 15.808 6.21077L13.5252 2.78322C13.3058 2.45424 12.9426 2.276 12.5723 2.276C12.3529 2.276 12.1335 2.33777 11.9416 2.46803C11.4138 2.81767 11.2768 3.53052 11.6262 4.05156L12.7299 5.70354L1.14476 5.70354C0.514135 5.70354 6.55018e-07 6.21767 5.99888e-07 6.84829C5.44757e-07 7.47891 0.514135 7.99304 1.14475 7.99304L12.723 7.99304L11.6194 9.64502C11.2697 10.1729 11.4138 10.8789 11.9348 11.2285C12.4627 11.5782 13.1687 11.4342 13.5183 10.9131L15.808 7.48578Z"
      fill="#6B7280"
    />
    <path
      d="M1.14492 2.28276L8.00001 2.28276C8.63063 2.28276 9.14477 1.76863 9.14477 1.13801C9.14477 0.507195 8.63063 -3.30692e-05 8.00001 -3.31243e-05L1.14492 -3.37236e-05C0.514301 -3.37787e-05 0.000167093 0.514098 0.000167038 1.14472C0.000166983 1.77553 0.5143 2.28276 1.14492 2.28276Z"
      fill="#6B7280"
    />
  </svg>
);

export default withIcon(RightSidebarOpen);
