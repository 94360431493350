import { FC, memo, useCallback } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Link, Typography } from '../shared';
import { useSelectedChartToken } from '../../hooks';
import Wallet from '../Wallet';
import Logo from './Logo';

import './Header.scss';

const NOSTRA_DOCS = 'https://docs.nostra.finance/nostra-money-market/introduction';

const Header: FC = () => {
  const location = useLocation();
  const [selectedChartToken] = useSelectedChartToken();
  const { search } = location;
  const { t } = useTranslation();

  const isActive = useCallback(currentRoute => !!matchPath(currentRoute, location.pathname), [location]);

  return (
    <div className="nostra__header">
      <div className="nostra__header__logo">
        <Link href={`/${search}`}>
          <Logo />
        </Link>
      </div>

      <div className="nostra__header__content">
        <div className="nostra__header__links">
          <div className="nostra__header__link-container">
            <Link
              className={`nostra__header__link nostra__hover-animation ${isActive('/') ? 'active' : null}`}
              href={`/${search}`}
            >
              <Typography variant="body-primary" weight="bold" color="text-primary-inverted">
                {t('Header.linkDashboard')}
              </Typography>
            </Link>
          </div>
          <div className="nostra__header__link-container">
            <Link
              className={`nostra__header__link nostra__hover-animation ${isActive('analytics') ? 'active' : null}`}
              href={selectedChartToken ? `/analytics/${selectedChartToken}` : '/analytics'}
            >
              <Typography variant="body-primary" weight="bold" color="text-primary-inverted">
                {t('Header.linkAnalytics')}
              </Typography>
            </Link>
          </div>
          <div className="nostra__header__link-container">
            <Link className="nostra__header__link nostra__hover-animation" href={NOSTRA_DOCS}>
              <Typography variant="body-primary" weight="bold" color="text-primary-inverted">
                {t('Header.linkDocs')}
              </Typography>
            </Link>
          </div>
        </div>
        <Wallet connectWalletButtonLabel={t('Wallet.btnConnectWallet')} />
      </div>
    </div>
  );
};

export default memo(Header);
