import { FC } from 'react';
import { InnerIconProps } from './IconProps';
import withIcon from './withIcon';

const LeftDoubleChevron: FC<InnerIconProps> = ({ size, color }) => (
  <svg
    className="nostra__icon nostra__icon-right-chevron"
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.70679 5.29315C9.89426 5.48092 9.99957 5.73554 9.99957 6.00104C9.99957 6.26653 9.89426 6.52116 9.70679 6.70892L6.41379 10.006L9.70679 13.3032C9.88894 13.492 9.98974 13.7449 9.98746 14.0074C9.98518 14.27 9.88001 14.5211 9.6946 14.7067C9.5092 14.8924 9.25838 14.9977 8.99619 15C8.73399 15.0022 8.48139 14.9013 8.29279 14.7189L4.29279 10.7139C4.10532 10.5262 4 10.2715 4 10.006C4 9.74055 4.10532 9.48592 4.29279 9.29816L8.29279 5.29315C8.48031 5.10545 8.73462 5 8.99979 5C9.26495 5 9.51926 5.10545 9.70679 5.29315Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.7065 5.29315C15.894 5.48092 15.9993 5.73554 15.9993 6.00104C15.9993 6.26653 15.894 6.52116 15.7065 6.70892L12.4135 10.006L15.7065 13.3032C15.8887 13.492 15.9895 13.7449 15.9872 14.0074C15.9849 14.27 15.8798 14.5211 15.6944 14.7067C15.509 14.8924 15.2581 14.9977 14.9959 15C14.7337 15.0022 14.4811 14.9013 14.2925 14.7189L10.2925 10.7139C10.1051 10.5262 9.99976 10.2715 9.99976 10.006C9.99976 9.74055 10.1051 9.48592 10.2925 9.29816L14.2925 5.29315C14.4801 5.10545 14.7344 5 14.9995 5C15.2647 5 15.519 5.10545 15.7065 5.29315Z"
      fill={color}
    />
  </svg>
);

export default withIcon(LeftDoubleChevron);
