import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import { Decimal, ZERO } from '../../../../datastructures';
import {
  useWalletAccount,
  useActiveAccountId,
  useAccountsHealthFactors,
  useNewRepayHealthFactor,
  useRepay,
  useTokenBalance,
  useTokenRates,
  useNostraTokenBalance,
} from '../../../../hooks';
import { Asset } from '../../../../interfaces';
import { DecimalUtils } from '../../../../utils';
import { GAS_FEE_UI_DECIMALS } from '../../../../constants';
import {
  ActionButton,
  CurrencyInput,
  ShadowContainer,
  Typography,
  ActionButtonState,
  ActionButtonLabels,
} from '../../../shared';
import ActionModalHealthFactorData from '../../ActionModalHealthFactorData';
import ActionModalSuccessInfo, { ActionModalSuccessRow } from '../../ActionModalSuccess';

import './Repay.scss';

interface RepayProps {
  asset: Asset;
  onModalClose?: () => void;
}

const Repay: FC<RepayProps> = props => {
  const { asset, onModalClose } = props;

  const { t } = useTranslation();

  const walletAccount = useWalletAccount();
  const activeAccountId = useActiveAccountId();
  const assetBalance = useTokenBalance(asset.address);
  const nostraTokenBalance = useNostraTokenBalance();
  const tokenRates = useTokenRates();
  const { repayStatus, repay } = useRepay();
  const accountHealthFactor = useAccountsHealthFactors();
  const calculateNewRepayHealthFactor = useNewRepayHealthFactor();

  const [amount, setAmount] = useState<string>('');
  const [state, setState] = useState<ActionButtonState>('default');

  // We want to store copy of health factor data when user executes repay,
  // this will be shown in success modal after the transaction is finished
  const healthFactorStore = useRef<null | string>(null);
  const newHealthFactorStore = useRef<null | string>(null);
  const debtTokenBalanceStore = useRef<null | Decimal>(null);

  const debtTokenDataKey = useMemo(() => {
    return `${walletAccount?.address}-${activeAccountId}-${asset.debtTokenAddress}`;
  }, [walletAccount?.address, activeAccountId, asset.debtTokenAddress]);

  const debtTokenBalance = useMemo(() => {
    if (!nostraTokenBalance[debtTokenDataKey]) {
      return null;
    }

    return nostraTokenBalance[debtTokenDataKey];
  }, [debtTokenDataKey, nostraTokenBalance]);

  const healthFactor = useMemo(() => {
    const dataKey = `${walletAccount?.address}-${activeAccountId}`;

    if (!accountHealthFactor[dataKey]) {
      return null;
    }

    return accountHealthFactor[dataKey].value;
  }, [walletAccount?.address, activeAccountId, accountHealthFactor]);

  const maxAmount = useMemo(() => {
    if (!debtTokenBalance || !assetBalance?.balance) {
      return null;
    }

    return debtTokenBalance.lt(assetBalance.balance) ? debtTokenBalance : assetBalance.balance;
  }, [debtTokenBalance, assetBalance]);

  const pendingAmount = useMemo(
    () => (repayStatus?.pending ? repayStatus?.request.inputAmount : undefined),
    [repayStatus],
  );
  const decimalAmount = useMemo(() => pendingAmount ?? Decimal.parse(amount, ZERO), [amount, pendingAmount]);

  const newHealthFactor = useMemo(() => {
    if (!walletAccount || activeAccountId === null) {
      return null;
    }

    return decimalAmount.isZero() || !calculateNewRepayHealthFactor
      ? healthFactor
      : calculateNewRepayHealthFactor(walletAccount.address, activeAccountId, decimalAmount, asset);
  }, [walletAccount, activeAccountId, decimalAmount, calculateNewRepayHealthFactor, healthFactor, asset]);

  const formattedBorrowFactor = useMemo(
    // Contracts use debtFactor term for 'borrow factor' (it's the same thing)
    () =>
      asset.debtFactor
        ? DecimalUtils.format(asset.debtFactor, { style: 'percentage', fractionDigits: 2, pad: true })
        : null,
    [asset.debtFactor],
  );

  const amountRepaidFormatted = useMemo(() => {
    if (!repayStatus?.transactionData) {
      return '';
    }

    return DecimalUtils.format(repayStatus.transactionData?.repayAmount, {
      style: 'multiplier',
      fractionDigits: 2,
      noMultiplierFractionDigits: asset.uiTokenPrecision,
      currency: asset.token,
      lessThanFormat: true,
    });
  }, [asset.token, asset.uiTokenPrecision, repayStatus?.transactionData]);

  const outstandingBorrowAmountFormatted = useMemo(() => {
    if (!debtTokenBalanceStore.current || !repayStatus?.transactionData) {
      return '';
    }

    return DecimalUtils.format(
      Decimal.max(debtTokenBalanceStore.current.sub(repayStatus.transactionData.repayAmount), ZERO),
      {
        style: 'multiplier',
        fractionDigits: 2,
        noMultiplierFractionDigits: asset.uiTokenPrecision,
        currency: asset.token,
        lessThanFormat: true,
      },
    );
  }, [asset.token, asset.uiTokenPrecision, repayStatus?.transactionData]);

  const gasFeeFormatted = useMemo(() => {
    if (!repayStatus?.transactionData) {
      return '';
    }

    return DecimalUtils.format(repayStatus.transactionData.gasFee, {
      style: 'currency',
      fractionDigits: GAS_FEE_UI_DECIMALS,
      currency: 'ETH',
      lessThanFormat: true,
    });
  }, [repayStatus?.transactionData]);

  /**
   * Update action button state based on current repay request status
   */
  useEffect(() => {
    if (!repayStatus) {
      return;
    }

    if (repayStatus.pending) {
      setState('loading');
      setAmount(repayStatus.request.inputAmount.toString());
    } else if (repayStatus.success) {
      setState('success');
    } else {
      setState('default');
    }
  }, [repayStatus]);

  const handleRepay = useCallback(() => {
    // Do not allow user to start repay process before connecting wallet
    if (!walletAccount || !debtTokenBalance || activeAccountId === null) {
      return;
    }

    healthFactorStore.current = healthFactor ? healthFactor.toString() : null;
    newHealthFactorStore.current = newHealthFactor ? newHealthFactor.toString() : null;
    debtTokenBalanceStore.current = debtTokenBalance;

    const repayTxnId = uuid();

    // In case user wants to repay all of his debt, we want to pass max Decimal as the amount,
    // reason for this is because debt token balance is constantly increasing in user wallet, and by
    // the time repay tx is executed, repaid amount will be less then debt token balance in the wallet.
    // Passing max decimal as amount tells the contract to calculate current debt balance for user and repay everything.
    const repayAll = decimalAmount.equals(debtTokenBalance);

    repay({
      amount: repayAll ? Decimal.MAX_DECIMAL : decimalAmount,
      inputAmount: decimalAmount,
      asset,
      onBehalfOf: walletAccount.address,
      accountId: activeAccountId,
      txnId: repayTxnId,
    });

    setState('loading');
  }, [walletAccount, debtTokenBalance, healthFactor, newHealthFactor, decimalAmount, repay, asset, activeAccountId]);

  const handleAmountChange = useCallback((newAmount: string) => {
    setAmount(newAmount);
  }, []);

  const actionButtonLabels: ActionButtonLabels = {
    default: t('Repay.actionRepay'),
    loading: t('Repay.actionExecuting'),
    success: t('Repay.actionSuccess'),
  };

  const notEnoughBalance = decimalAmount.gt(maxAmount ?? ZERO);

  const error = useMemo(() => {
    if (!walletAccount) {
      return undefined;
    }

    return amount && notEnoughBalance ? t('Repay.errorInsufficientBalance') : undefined;
  }, [walletAccount, amount, notEnoughBalance, t]);

  if (repayStatus?.success) {
    return (
      <ActionModalSuccessInfo onClose={onModalClose} txHash={repayStatus.getTransactionResponse?.transaction_hash}>
        <ActionModalSuccessRow
          label={t('ActionModalSuccessInfo.labelRepayAmount')}
          value={`${amountRepaidFormatted}`}
        />
        <ActionModalSuccessRow
          label={t('ActionModalSuccessInfo.labelOutstandingBorrowAmount')}
          value={`${outstandingBorrowAmountFormatted}`}
        />
        {healthFactorStore.current && newHealthFactorStore.current && (
          <ActionModalSuccessRow
            label={t('ActionModalSuccessInfo.labelHealthFactor')}
            value={
              <ActionModalHealthFactorData
                amount={decimalAmount}
                healthFactor={new Decimal(healthFactorStore.current)}
                newHealthFactor={new Decimal(newHealthFactorStore.current)}
                showLabel={false}
              />
            }
          />
        )}
        <ActionModalSuccessRow label={t('ActionModalSuccessInfo.labelNetworkFee')} value={gasFeeFormatted} />
      </ActionModalSuccessInfo>
    );
  }

  return (
    <>
      {/* Input field */}
      <div className="nostra__deposit-modal__input">
        <CurrencyInput
          asset={asset}
          amount={pendingAmount ? pendingAmount.toString() : amount}
          showAmount={!!walletAccount}
          maxAmount={maxAmount}
          maxAmountLabel={t('Repay.labelMaxRepayAmount')}
          showWalletIcon={false}
          tokenRate={tokenRates[asset.address]}
          disabled={state === 'loading'}
          error={error}
          autoFocus
          onUpdate={handleAmountChange}
        />
      </div>

      {/* Asset info */}
      <ShadowContainer className="nostra__action-modal__data-container nostra__repay-modal__info-large">
        <div className="nostra__action-modal__data-row">
          <Typography variant="body-tertiary">{t('Repay.labelBorrowFactor')}</Typography>
          <Typography variant="body-tertiary">{formattedBorrowFactor}</Typography>
        </div>
        <ActionModalHealthFactorData
          amount={decimalAmount}
          healthFactor={healthFactor}
          newHealthFactor={newHealthFactor}
        />
      </ShadowContainer>

      {/* Action button */}
      <ActionButton
        size="large"
        variant="primary"
        labels={actionButtonLabels}
        onClick={handleRepay}
        state={decimalAmount.isZero() || notEnoughBalance ? 'disabled' : state}
        fullWidth
      />
    </>
  );
};

export default Repay;
