import { FC } from 'react';
import { AssetTier, Token } from '../../interfaces';
import { AssetTierPill, Logo, Typography } from '../shared';

interface AlphaPopupAssetRowProps {
  token: Token;
  tier: AssetTier;
  depositLimit: number;
}

const AlphaPopupAssetRow: FC<AlphaPopupAssetRowProps> = ({ token, tier, depositLimit }) => {
  return (
    <div className="nostra__alpha-popup-info-row">
      <div className="nostra__alpha-popup-asset-column">
        <Logo type={`token-${token}`} />
        <Typography variant="body-primary" color="text-primary-accent">
          {token}
        </Typography>
      </div>
      <div className="nostra__alpha-popup-asset-tier-column">
        <Typography variant="body-tertiary" color="text-disabled">
          <AssetTierPill tier={tier} />
        </Typography>
      </div>
      <div className="nostra__alpha-popup-limit-column">
        <Typography variant="body-secondary" color="text-secondary">
          {depositLimit}
        </Typography>
      </div>
    </div>
  );
};
export default AlphaPopupAssetRow;
