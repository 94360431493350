import { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { HEALTH_FACTOR_DANGER, HEALTH_FACTOR_INFINITY, HEALTH_FACTOR_WARNING } from '../../../constants';
import { useWalletAccount, useActiveAccountId, useActiveAccountName, useAccountsHealthFactors } from '../../../hooks';
import { getHealthFactorStatus } from '../../../utils';
import ActionButton, { ActionButtonVariant } from '../ActionButton';
import ButtonWrapper from '../ButtonWrapper';
import { colors } from '../Colors';
import HealthFactorStatusDot from '../HealthFactorStatusDot';
import Icon from '../Icon';
import ShadowContainer from '../ShadowContainer';
import Typography from '../Typography';

import './WalletButton.scss';

export interface WalletButtonProps {
  label?: string;
  connected: boolean;
  walletPopupOpen: boolean;
  onConnect: () => void;
  onWalletClick: () => void;
  connectWalletButtonFullWidth?: boolean;
  connectWalletButtonVariant?: ActionButtonVariant;
}

const WalletButton: FC<WalletButtonProps> = props => {
  const {
    label = 'Connect Wallet',
    connected,
    walletPopupOpen,
    onConnect,
    onWalletClick,
    connectWalletButtonFullWidth,
    connectWalletButtonVariant,
  } = props;

  const walletAccount = useWalletAccount();
  const activeAccountId = useActiveAccountId();
  const activeAccountName = useActiveAccountName();
  const accountHealthFactor = useAccountsHealthFactors();
  const { t } = useTranslation();

  const healthFactor = useMemo(() => {
    const dataKey = `${walletAccount?.address}-${activeAccountId}`;

    if (!accountHealthFactor[dataKey]) {
      return null;
    }

    return accountHealthFactor[dataKey].value;
  }, [walletAccount?.address, activeAccountId, accountHealthFactor]);

  const formattedHealthFactor = useMemo(() => {
    return healthFactor ? healthFactor.toTruncated(2) : null;
  }, [healthFactor]);

  const healthFactorColor = useMemo(() => {
    if (healthFactor === null) {
      return null;
    }

    if (healthFactor.lt(HEALTH_FACTOR_DANGER)) {
      return 'text-error';
    }

    if (healthFactor.lte(HEALTH_FACTOR_WARNING)) {
      return 'text-caption';
    }

    return 'text-success';
  }, [healthFactor]);

  const healthFactorStatus = useMemo(() => getHealthFactorStatus(healthFactor), [healthFactor]);

  return (
    <>
      {!connected && (
        <ShadowContainer className="nostra__walletButton__disconnected">
          <ActionButton
            labels={{ default: label }}
            onClick={onConnect}
            variant={connectWalletButtonVariant ?? 'secondary'}
            size="large"
            state="default"
            fullWidth={connectWalletButtonFullWidth}
          />
        </ShadowContainer>
      )}
      {connected && (
        <div className="nostra__walletButton__connected" data-wallet-popup-open={walletPopupOpen}>
          <ButtonWrapper onClick={onWalletClick}>
            <div className="nostra__walletButton__connected-wallet-info">
              <Typography
                variant="body-primary"
                weight="bold"
                color="text-primary"
                className="nostra__hover-animation"
                title={activeAccountName}
              >
                {activeAccountName}
              </Typography>
              <div className="nostra__walletButton__connected-wallet-health-factor">
                <HealthFactorStatusDot status={healthFactorStatus} />
                <Typography variant="body-tertiary" color="text-wallet">
                  {(healthFactorStatus === 'loading' || healthFactorStatus === 'high') &&
                    t('WalletButton.healthFactor')}
                  {(healthFactorStatus === 'medium' || healthFactorStatus === 'low') &&
                    t('WalletButton.healthFactorRisk')}
                </Typography>
                {healthFactor && formattedHealthFactor && healthFactorColor ? (
                  healthFactor.gte(HEALTH_FACTOR_INFINITY) ? (
                    <Icon variant="infinity" color={colors.textSuccess} size="small" />
                  ) : (
                    <Typography variant="body-tertiary" color={healthFactorColor} weight="bold">
                      {formattedHealthFactor}
                    </Typography>
                  )
                ) : null}
              </div>
            </div>
            {walletPopupOpen ? (
              <Icon variant="right-sidebar-open" size="small" />
            ) : (
              <Icon variant="right-sidebar" size="small" />
            )}
          </ButtonWrapper>
        </div>
      )}
    </>
  );
};
export default memo(WalletButton);
