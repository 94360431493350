import { Abi, AccountInterface, Contract, ProviderInterface } from 'starknet';

export class ContractBase<T extends Contract> {
  protected contract: T;

  constructor(abi: Abi, address: string, providerOrAccount: ProviderInterface | AccountInterface) {
    this.contract = new Contract(abi, address, providerOrAccount) as T;
  }

  getAddress() {
    return this.contract.address;
  }
}
