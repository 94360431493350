import { FC, forwardRef, HTMLProps } from 'react';

import './Tooltip.scss';

export type TooltipPlacement = 'left' | 'right' | 'bottom-left' | 'bottom-center' | 'bottom-right';
export type TooltipVariant = 'default' | 'error';

export interface TooltipProps {
  open: boolean;
  placement?: TooltipPlacement;
  variant?: TooltipVariant;
}

const Tooltip = forwardRef<HTMLDivElement, TooltipProps>((props, ref) => {
  const { open, placement = 'bottom-right', variant = 'default', children } = props;

  if (!open) {
    return null;
  }

  return (
    <div className={`nostra__tooltip nostra__tooltip__${placement} nostra__tooltip__${variant}`} ref={ref}>
      {children}
    </div>
  );
});

export default Tooltip as FC<TooltipProps & HTMLProps<HTMLDivElement>>;
