import { ChangeEvent, FC, useCallback, useMemo } from 'react';
import { colors } from '../Colors';
import Icon from '../Icon';
import Typography from '../Typography';
import './ToggleSwitch.scss';

export interface ToggleSwitchProps {
  checked: boolean;
  locked?: boolean;
  label?: string;
  disabled?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

let idCounter = 0;

const ToggleSwitch: FC<ToggleSwitchProps> = props => {
  const { checked, locked, label, disabled, onChange } = props;

  const id = useMemo(() => {
    idCounter += 1;
    return `toggle-switch-${idCounter}`;
  }, []);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!locked) {
        onChange?.(event);
      }
    },
    [locked, onChange],
  );

  const iconColor = useMemo(() => {
    if (disabled) {
      return colors.toggleSwitchDisabledBackground;
    }

    return checked ? colors.secondaryRegular : colors.toggleSwitchThumbInactiveBorder;
  }, [checked, disabled]);

  return (
    <span className="nostra__toggle-switch">
      <span
        className={`nostra__toggle-switch__switch-container ${
          disabled ? 'nostra__toggle-switch__switch-container-disabled' : ''
        } ${locked ? 'nostra__toggle-switch__switch-container-locked' : ''}`}
      >
        <input id={id} checked={checked} disabled={disabled} type="checkbox" onChange={handleChange} />
        <span
          className={`nostra__toggle-switch__thumb ${disabled ? 'nostra__toggle-switch__thumb-disabled' : ''} ${
            checked ? 'nostra__toggle-switch__thumb-active' : ''
          }`}
        >
          {locked && <Icon variant="lock" size={10} color={iconColor} />}
        </span>
        <span
          className={`nostra__toggle-switch__background ${
            disabled ? 'nostra__toggle-switch__background-disabled' : ''
          } ${checked ? 'nostra__toggle-switch__background-active' : ''}`}
        />
      </span>
      {label && (
        <label htmlFor={id} className="nostra__toggle-switch__label">
          <Typography variant="body-tertiary">{label}</Typography>
        </label>
      )}
    </span>
  );
};

export default ToggleSwitch;
