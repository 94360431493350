import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { addAccount, useWalletAccount, useAccounts } from '../../hooks';
import {
  ButtonWrapper,
  colors,
  Icon,
  Table,
  TableBody,
  TableHead,
  TableHeadCell,
  TableRow,
  Typography,
} from '../shared';
import AccountManagementRow from './AccountManagementRow/AccountManagementRow';

import './AccountManagement.scss';

const AccountManagement = () => {
  const { t } = useTranslation();

  const walletAccount = useWalletAccount();
  const accounts = useAccounts();

  const onAddNew = useCallback(() => {
    if (walletAccount) {
      addAccount(walletAccount.address);
    }
  }, [walletAccount]);

  const canCreateNewAccount = useMemo(
    () => accounts.filter(name => Boolean(name)).length < accounts.length,
    [accounts],
  );

  return (
    <div className="nostra__accountManagement">
      <Typography variant="subheader" weight="bold">
        {t('AccountManagement.labelHeader')}
      </Typography>
      {/* TODO - Add liquidation warning */}
      <div className="nostra__accountManagement-table">
        <Table>
          <TableHead>
            <TableRow id="header">
              <TableHeadCell label={t('AccountManagement.columnHeaderAccount')} />
              <TableHeadCell label={t('AccountManagement.columnHeaderIdle')} align="center" />
              <TableHeadCell label={t('AccountManagement.columnHeaderTotalSupply')} align="center" />
              <TableHeadCell label={t('AccountManagement.columnHeaderApy')} align="center" />
              <TableHeadCell label={t('AccountManagement.columnHeaderTotalDebt')} align="center" />
              <TableHeadCell label={t('AccountManagement.columnHeaderApy')} align="center" />
              <TableHeadCell label={t('AccountManagement.columnHeaderHealthFactor')} align="center" />
              <TableHeadCell label={t('AccountManagement.columnHeaderActions')} align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {accounts.map((account, index) =>
              account !== '' ? <AccountManagementRow key={index} accountId={index} accountName={account} /> : null,
            )}
          </TableBody>
        </Table>
        <div className="nostra__accountManagement-actions">
          <ButtonWrapper
            className="nostra__accountManagement-button"
            disabled={!canCreateNewAccount}
            onClick={onAddNew}
          >
            <Typography
              variant="body-primary"
              weight={canCreateNewAccount ? 'bold' : 'regular'}
              color={canCreateNewAccount ? 'text-primary' : 'text-primary-inverted'}
              opacity={canCreateNewAccount ? 1 : 0.5}
            >
              {t('AccountManagement.btnCreateAccount')}
            </Typography>
            <Icon
              variant="plus-round"
              size="small"
              color={canCreateNewAccount ? colors.textPrimary : `rgba(${colors.textPrimaryInvertedSemiTransparent}`}
            />
          </ButtonWrapper>
        </div>
      </div>
    </div>
  );
};
export default AccountManagement;
