import { FC } from 'react';
import { InnerIconProps } from './IconProps';
import withIcon from './withIcon';

const RightDoubleChevron: FC<InnerIconProps> = ({ size, color }) => (
  <svg
    className="nostra__icon nostra__icon-left-double-chevron"
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2933 14.7068C10.1058 14.5191 10.0005 14.2645 10.0005 13.999C10.0005 13.7335 10.1058 13.4788 10.2933 13.2911L13.5863 9.99396L10.2933 6.69683C10.1111 6.50799 10.0103 6.25508 10.0126 5.99255C10.0149 5.73003 10.12 5.4789 10.3055 5.29326C10.4909 5.10762 10.7417 5.00232 11.0039 5.00004C11.2661 4.99776 11.5187 5.09868 11.7073 5.28106L15.7073 9.28607C15.8947 9.47383 16.0001 9.72846 16.0001 9.99396C16.0001 10.2595 15.8947 10.5141 15.7073 10.7018L11.7073 14.7068C11.5197 14.8946 11.2654 15 11.0003 15C10.7351 15 10.4808 14.8946 10.2933 14.7068Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.29352 14.7068C4.10605 14.5191 4.00073 14.2645 4.00073 13.999C4.00073 13.7335 4.10605 13.4788 4.29352 13.2911L7.58652 9.99395L4.29352 6.69683C4.11136 6.50799 4.01057 6.25508 4.01285 5.99255C4.01512 5.73003 4.12029 5.4789 4.3057 5.29326C4.49111 5.10762 4.74192 5.00232 5.00412 5.00004C5.26632 4.99776 5.51892 5.09868 5.70752 5.28106L9.70752 9.28607C9.89499 9.47383 10.0003 9.72846 10.0003 9.99396C10.0003 10.2595 9.89499 10.5141 9.70752 10.7018L5.70752 14.7068C5.51999 14.8946 5.26568 15 5.00052 15C4.73535 15 4.48105 14.8946 4.29352 14.7068Z"
      fill={color}
    />
  </svg>
);

export default withIcon(RightDoubleChevron);
