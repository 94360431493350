import { ApolloQueryResult, gql } from '@apollo/client';
import { bind } from '@react-rxjs/core';
import { createSignal } from '@react-rxjs/utils';
import { BN } from 'bn.js';
import { catchError, combineLatest, concatMap, filter, from, map, Subscription } from 'rxjs';
import { AccountInterface } from 'starknet';
import { getConfigManager } from '../config/getConfigManager';
import { Decimal } from '../datastructures';
import { GraphqlQuery, Nullable, ParsedTransaction, Transaction } from '../interfaces';
import { getGraphqlClient } from '../services';
import { getAccountAddressFromId } from '../utils';
import { walletAccount$ } from './useWalletAccount';

const DEFAULT_VALUE = null;

const [accountId$, setTransactionHistoryAccountId] = createSignal<number>();
const [transactionKeywords$, setTransactionKeywords] = createSignal<string>();
const [fromTransactionDate$, setFromTransactionDate] = createSignal<Nullable<Date>>();
const [toTransactionDate$, setToTransactionDate] = createSignal<Nullable<Date>>();
const [limit$, setTransactionHistoryLimit] = createSignal<number>();
const [skip$, setTransactionHistorySkip] = createSignal<number>();

const transactionHistory$ = combineLatest([
  walletAccount$,
  accountId$,
  transactionKeywords$,
  fromTransactionDate$,
  toTransactionDate$,
  limit$,
  skip$,
]).pipe(
  filter((value): value is [AccountInterface, number, string, Date, Date, number, number] => {
    const [walletAccount] = value;
    return Boolean(walletAccount);
  }),
  concatMap(([walletAccount, accountId, transactionKeywords, fromTransactionDate, toTransactionDate, limit, skip]) => {
    const accountAddress = getAccountAddressFromId(walletAccount.address, accountId);
    const graphqlClient = getGraphqlClient();

    const keywordsCriteria = transactionKeywords ? `keywords: "${transactionKeywords}",` : '';
    const fromDateCriteria = fromTransactionDate ? `fromTimestamp: "${fromTransactionDate.toISOString()}",` : '';
    const toDateCriteria = toTransactionDate ? `toTimestamp: "${toTransactionDate.toISOString()}",` : '';

    return from(
      graphqlClient.query<GraphqlQuery>({
        query: gql`
        query GetTransactions {
          transactions(
            account: "${accountAddress}",
            ${keywordsCriteria}
            ${fromDateCriteria}
            ${toDateCriteria}
            limit: ${limit},
            skip: ${skip}
          ) {
            timestamp
            transactionHash
            amount
            type
            tokens {
              ... on AdjustTransactionTokens {
                fromTokenAddress
                toTokenAddress
              }
              ... on TransactionToken {
                tokenAddress
              }
            }
          }
          transactionCount(
            account: "${accountAddress}",
            ${keywordsCriteria}
            ${fromDateCriteria}
            ${toDateCriteria}
          )
        }
        `,
      }),
    );
  }),
  map<ApolloQueryResult<GraphqlQuery>, { transactions: ParsedTransaction[]; transactionCount: number }>(result => {
    const parsedTransactions = result.data.transactions.map(transaction => {
      const asset = getTransactionAsset(transaction);

      if (!asset) {
        throw new Error('Failed to find asset data for ticker ${}');
      }

      if (transaction.type === 'ADJUST') {
        return {
          type: transaction.type,
          amount: new Decimal(new BN(transaction.amount), asset.tokenPrecision),
          hash: transaction.transactionHash,
          date: new Date(transaction.timestamp),
          tokens: transaction.tokens,
        };
      } else {
        return {
          type: transaction.type,
          amount: new Decimal(new BN(transaction.amount), asset.tokenPrecision),
          hash: transaction.transactionHash,
          date: new Date(transaction.timestamp),
          tokens: transaction.tokens,
        };
      }
    });

    return {
      transactions: parsedTransactions,
      transactionCount: result.data.transactionCount,
    };
  }),
  catchError(error => {
    console.error('Error while fetching transaction history', error);
    return from([DEFAULT_VALUE]);
  }),
);

export const [useTransactionHistory] = bind(transactionHistory$, DEFAULT_VALUE);

let subscription: Nullable<Subscription> = null;

export const subscribeTransactionHistory = (): void => {
  unsubscribeTransactionHistory();
  subscription = transactionHistory$.subscribe();
};
export const unsubscribeTransactionHistory = (): void => subscription?.unsubscribe();

export {
  setTransactionHistoryAccountId,
  setTransactionKeywords,
  setFromTransactionDate,
  setToTransactionDate,
  setTransactionHistoryLimit,
  setTransactionHistorySkip,
};

function getTransactionAsset(transaction: Transaction) {
  const configManager = getConfigManager();

  let assetAddress: string | null = null;
  if (transaction.tokens.__typename === 'TransactionToken') {
    assetAddress = configManager.getNostraTokenAssetAddress(transaction.tokens.tokenAddress);
  } else if (transaction.tokens.__typename === 'AdjustTransactionTokens') {
    assetAddress = configManager.getNostraTokenAssetAddress(transaction.tokens.toTokenAddress);
  }

  if (assetAddress === null) {
    console.error(`Failed to find asset address for transaction ${transaction.transactionHash}`);
    return null;
  }

  return configManager.getAssetByAddress(assetAddress);
}
