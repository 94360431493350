import { bind } from '@react-rxjs/core';
import { BehaviorSubject, combineLatest, filter, Subscription, tap } from 'rxjs';
import { AccountInterface } from 'starknet';
import { getConfigManager } from '../config/getConfigManager';
import { Decimal, ZERO } from '../datastructures';
import { NostraToken, Nullable } from '../interfaces';
import { walletAccount$ } from './useWalletAccount';
import { nostraTokenBalance$, NostraTokenBalanceMap } from './useNostraTokenBalance';
import { nostraTokenList$ } from './useNostraTokenList';
import { accountList$ } from './useAccounts';
import { TokenRateMap, tokenRates$ } from './useTokenRates';

export interface AccountTotalDebtMap {
  [walletAddressAccountId: string]: Nullable<Decimal>;
}

export const accountTotalDebt$ = new BehaviorSubject<AccountTotalDebtMap>({});

type FetchStreamNonNullInputs = [NostraTokenBalanceMap, string[], AccountInterface, NostraToken[], TokenRateMap];

const fetchStream$ = combineLatest([
  nostraTokenBalance$,
  accountList$,
  walletAccount$,
  nostraTokenList$,
  tokenRates$,
]).pipe(
  filter((value): value is FetchStreamNonNullInputs => {
    const [, accounts, walletAccount] = value;
    return Boolean(accounts) && Boolean(walletAccount);
  }),
  tap(([nostraTokenBalance, accounts, walletAccount, nostraTokens, tokenRates]) => {
    const configManager = getConfigManager();

    const result: AccountTotalDebtMap = {};
    accounts
      .filter(account => account !== '')
      .forEach((_, accountId) => {
        nostraTokens.forEach(nostraToken => {
          try {
            // Do not count non debt tokens when calculating total debt for a account
            if (nostraToken.variant !== 'debt') {
              return;
            }

            const accountTotalDebtKey = `${walletAccount.address}-${accountId}`;
            const tokenBalanceKey = `${walletAccount.address}-${accountId}-${nostraToken.address}`;
            const tokenRate = tokenRates[configManager.getNostraTokenAssetAddress(nostraToken.address)];
            const tokenBalance = nostraTokenBalance[tokenBalanceKey];

            if (!tokenRate || !tokenBalance) {
              return;
            }

            result[accountTotalDebtKey] = (result[accountTotalDebtKey] ?? ZERO).add(tokenBalance.mul(tokenRate));
          } catch (error) {
            console.error(`useAccountTotalDebt - error calculating total debt for ID ${accountId}`, error);
          }
        });
      });

    accountTotalDebt$.next(result);
  }),
);

export const [useAccountTotalDebt] = bind(accountTotalDebt$, {});

let subscription: Subscription;
export const subscribeAccountTotalDebt = (): void => {
  unsubscribeAccountTotalDebt();
  subscription = fetchStream$.subscribe();
};

export const unsubscribeAccountTotalDebt = (): void => subscription?.unsubscribe();
export const resetAccountTotalDebt = (): void => {
  accountTotalDebt$.next({});
};
