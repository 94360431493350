import { memo } from 'react';
import { NOSTRA_HOMEPAGE_URL } from '../../constants';
import { Link } from '../shared';
import Icon from '../shared/Icon';

import './Footer.scss';

const iconColor = '#FFFFFF';

const Footer = (): JSX.Element => (
  <div className="nostra__footer">
    <div className="nostra__footer__links-container">
      <div className="nostra__footer__links-column">
        <div className="nostra__footer__link-container">
          <Link
            className="nostra__footer__link nostra__footer__link-icon-right nostra__hover-animation"
            href={NOSTRA_HOMEPAGE_URL}
          >
            Nostra <Icon variant="nostra" size="tiny" color={iconColor} />
          </Link>
        </div>
        <div className="nostra__footer__link-container">
          <span>Alpha {process.env.REACT_APP_VERSION}</span>
        </div>
      </div>
      <div className="nostra__footer__links-column">
        <div className="nostra__footer__link-container">
          <Link className="nostra__footer__link nostra__hover-animation" href="/terms">
            Terms &amp; Conditions
          </Link>
        </div>
        <div className="nostra__footer__link-container">
          <Link className="nostra__footer__link nostra__hover-animation" href="https://nostra.finance/disclaimer">
            Disclaimer
          </Link>
        </div>
      </div>
    </div>
    <div className="nostra__footer__links-container right">
      <div className="nostra__footer__links-column">
        <div className="nostra__footer__link-container">
          <Link className="nostra__footer__link nostra__hover-animation" href="https://discord.gg/nostrafinance">
            Discord <Icon variant="discord" size="tiny" color={iconColor} />
          </Link>
        </div>
        <div className="nostra__footer__link-container">
          <Link className="nostra__footer__link nostra__hover-animation" href="https://twitter.com/nostrafinance">
            Twitter <Icon variant="twitter" size="tiny" color={iconColor} />
          </Link>
        </div>
      </div>
      <div className="nostra__footer__links-column">
        <div className="nostra__footer__link-container">
          <Link
            className="nostra__footer__link nostra__hover-animation"
            href="https://github.com/tempus-finance/nostra-app"
          >
            GitHub <Icon variant="github" size="tiny" color={iconColor} />
          </Link>
        </div>
        <div className="nostra__footer__link-container">
          <Link className="nostra__footer__link nostra__hover-animation" href="https://docs.nostra.finance">
            GitBook <Icon variant="gitbook" size="tiny" color={iconColor} />
          </Link>
        </div>
      </div>
    </div>
  </div>
);

export default memo(Footer);
