import { ApolloQueryResult, gql } from '@apollo/client';
import { bind } from '@react-rxjs/core';
import { createSignal } from '@react-rxjs/utils';
import { catchError, combineLatest, concatMap, from, map, of, Subscription, tap } from 'rxjs';
import { GraphqlQuery, Nullable, ParsedChartData } from '../interfaces';
import { getGraphqlClient } from '../services';
import { downsampleChartData } from '../utils';

const DEFAULT_VALUE = null;

const [fromTotalUniqueBorrowersChartDate$, setFromTotalUniqueBorrowersChartDate] = createSignal<Nullable<number>>();
const [rawChartData$, setRawChartData] = createSignal<Nullable<ParsedChartData[]>>();

const totalUniqueBorrowersChartData$ = combineLatest([fromTotalUniqueBorrowersChartDate$]).pipe(
  concatMap(([fromTotalUniqueBorrowersChartDate]) => {
    try {
      setRawChartData(null);

      const graphqlClient = getGraphqlClient();

      const searchCriteria = fromTotalUniqueBorrowersChartDate
        ? `(fromTimestamp: "${new Date(fromTotalUniqueBorrowersChartDate).toISOString()}")`
        : '';

      return from(
        graphqlClient.query<GraphqlQuery>({
          query: gql`
        query GetTotalUniqueBorrowersChartData {
            totalUniqueBorrowers ${searchCriteria} {
                timestamp
                totalUniqueBorrowers
          }
        }
        `,
        }),
      ).pipe(
        catchError(error => {
          console.error('Error while fetching total unique borrowers chart data!', error);
          return of(null);
        }),
      );
    } catch (error) {
      console.error('Error while fetching total unique borrowers chart data!', error);
      return of(null);
    }
  }),
  map<Nullable<ApolloQueryResult<GraphqlQuery>>, Nullable<ParsedChartData[]>>(result => {
    if (!result) {
      return null;
    }

    if (!result.data.totalUniqueBorrowers.length) {
      return [];
    }

    try {
      const downsampledData = downsampleChartData(
        result.data.totalUniqueBorrowers.map(dataEntry => {
          return {
            timestamp: new Date(dataEntry.timestamp).getTime(),
            value: dataEntry.totalUniqueBorrowers,
          };
        }),
      );

      return downsampledData.map(downSampledDataEntry => ({
        value: downSampledDataEntry.value,
        timestamp: downSampledDataEntry.timestamp,
      }));
    } catch (error) {
      console.error('Error while fetching total unique borrowers chart data!', error);
      return DEFAULT_VALUE;
    }
  }),
  tap(chartData => setRawChartData(chartData)),
);

export const [useTotalUniqueBorrowersChartData] = bind(rawChartData$, DEFAULT_VALUE);

let subscription: Nullable<Subscription> = null;

export const subscribeTotalUniqueBorrowersChartData = (): void => {
  unsubscribeTotalUniqueBorrowersChartData();
  subscription = totalUniqueBorrowersChartData$.subscribe();
};
export const unsubscribeTotalUniqueBorrowersChartData = (): void => subscription?.unsubscribe();

export { setFromTotalUniqueBorrowersChartDate };
