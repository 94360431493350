import { FC, memo } from 'react';
import IconProps from './IconProps';
import PlusRound from './PlusRound';
import CheckmarkRound from './CheckmarkRound';
import Copyright from './Copyright';
import MinusRound from './MinusRound';
import CrossRound from './CrossRound';
import UpChevron from './UpChevron';
import RightChevron from './RightChevron';
import LeftChevron from './LeftChevron';
import DownChevron from './DownChevron';
import UpArrow from './UpArrow';
import RightDoubleChevron from './RightDoubleChevron';
import LeftDoubleChevron from './LeftDoubleChevron';
import RightArrow from './RightArrow';
import LeftArrow from './LeftArrow';
import DownArrow from './DownArrow';
import UpArrowThin from './UpArrowThin';
import RightArrowThin from './RightArrowThin';
import LeftArrowThin from './LeftArrowThin';
import DownArrowThin from './DownArrowThin';
import GridView from './GridView';
import ListView from './ListView';
import Plus from './Plus';
import Minus from './Minus';
import Menu from './Menu';
import Close from './Close';
import Info from './Info';
import InfoBordered from './InfoBordered';
import InfoSolid from './InfoSolid';
import Exclamation from './Exclamation';
import ExclamationBordered from './ExclamationBordered';
import ExclamationNeutral from './ExclamationNeutral';
import ExclamationError from './ExclamationError';
import Checkmark from './Checkmark';
import CheckmarkBordered from './CheckmarkBordered';
import CheckmarkSolid from './CheckmarkSolid';
import External from './External';
import Twitter from './Twitter';
import Discord from './Discord';
import Medium from './Medium';
import Github from './Github';
import Gitbook from './Gitbook';
import Governance from './Governance';
import Telegram from './Telegram';
import TermsConditions from './TermsConditions';
import Scroll from './Scroll';
import Slippage from './Slippage';
import Starknet from './Starknet';
import Globe from './Globe';
import Dark from './Dark';
import MagicWand from './MagicWand';
import Wallet from './Wallet';
import Gas from './Gas';
import InfoBorderedRounded from './InfoBorderedRounded';
import InfinityIcon from './Infinity';
import Lock from './Lock';
import More from './More';
import Copy from './Copy';
import XSolid from './XSolid';
import RightSidebar from './RightSidebar';
import Nostra from './Nostra';
import RightSidebarOpen from './RightSidebarOpen';
import Back from './Back';
import Download from './Download';
import NavigateNext from './NavigateNext';
import Calendar from './Calendar';
import Search from './Search';

export type IconVariant =
  | 'plus-round'
  | 'checkmark-round'
  | 'copyright'
  | 'minus-round'
  | 'cross-round'
  | 'up-chevron'
  | 'right-chevron'
  | 'left-chevron'
  | 'down-chevron'
  | 'up-arrow'
  | 'right-double-chevron'
  | 'left-double-chevron'
  | 'right-arrow'
  | 'left-arrow'
  | 'down-arrow'
  | 'up-arrow-thin'
  | 'right-arrow-thin'
  | 'left-arrow-thin'
  | 'down-arrow-thin'
  | 'list-view'
  | 'grid-view'
  | 'plus'
  | 'minus'
  | 'menu'
  | 'close'
  | 'info'
  | 'info-bordered'
  | 'info-solid'
  | 'exclamation'
  | 'exclamation-bordered'
  | 'exclamation-neutral'
  | 'exclamation-error'
  | 'checkmark'
  | 'checkmark-bordered'
  | 'checkmark-solid'
  | 'external'
  | 'twitter'
  | 'discord'
  | 'medium'
  | 'github'
  | 'gitbook'
  | 'governance'
  | 'telegram'
  | 'terms-conditions'
  | 'scroll'
  | 'slippage'
  | 'starknet'
  | 'globe'
  | 'dark'
  | 'magic-wand'
  | 'wallet'
  | 'gas'
  | 'info-bordered-rounded'
  | 'infinity'
  | 'lock'
  | 'more'
  | 'copy'
  | 'x-solid'
  | 'right-sidebar'
  | 'nostra'
  | 'right-sidebar-open'
  | 'back'
  | 'download'
  | 'navigate-next'
  | 'calendar'
  | 'search';

const Icon: FC<IconProps & { variant: IconVariant }> = props => {
  switch (props.variant) {
    case 'wallet':
      return <Wallet {...props} />;
    case 'plus-round':
      return <PlusRound {...props} />;
    case 'gas':
      return <Gas {...props} />;
    case 'checkmark-round':
      return <CheckmarkRound {...props} />;
    case 'copyright':
      return <Copyright {...props} />;
    case 'minus-round':
      return <MinusRound {...props} />;
    case 'info-bordered-rounded':
      return <InfoBorderedRounded {...props} />;
    case 'cross-round':
      return <CrossRound {...props} />;
    case 'up-chevron':
      return <UpChevron {...props} />;
    case 'right-chevron':
      return <RightChevron {...props} />;
    case 'left-chevron':
      return <LeftChevron {...props} />;
    case 'down-chevron':
      return <DownChevron {...props} />;
    case 'right-double-chevron':
      return <RightDoubleChevron {...props} />;
    case 'left-double-chevron':
      return <LeftDoubleChevron {...props} />;
    case 'up-arrow':
      return <UpArrow {...props} />;
    case 'right-arrow':
      return <RightArrow {...props} />;
    case 'left-arrow':
      return <LeftArrow {...props} />;
    case 'down-arrow':
      return <DownArrow {...props} />;
    case 'up-arrow-thin':
      return <UpArrowThin {...props} />;
    case 'right-arrow-thin':
      return <RightArrowThin {...props} />;
    case 'left-arrow-thin':
      return <LeftArrowThin {...props} />;
    case 'down-arrow-thin':
      return <DownArrowThin {...props} />;
    case 'grid-view':
      return <GridView {...props} />;
    case 'list-view':
      return <ListView {...props} />;
    case 'plus':
      return <Plus {...props} />;
    case 'minus':
      return <Minus {...props} />;
    case 'menu':
      return <Menu {...props} />;
    case 'close':
      return <Close {...props} />;
    case 'info':
      return <Info {...props} />;
    case 'info-bordered':
      return <InfoBordered {...props} />;
    case 'info-solid':
      return <InfoSolid {...props} />;
    case 'exclamation':
      return <Exclamation {...props} />;
    case 'exclamation-bordered':
      return <ExclamationBordered {...props} />;
    case 'exclamation-neutral':
      return <ExclamationNeutral {...props} />;
    case 'exclamation-error':
      return <ExclamationError {...props} />;
    case 'checkmark':
      return <Checkmark {...props} />;
    case 'checkmark-bordered':
      return <CheckmarkBordered {...props} />;
    case 'checkmark-solid':
      return <CheckmarkSolid {...props} />;
    case 'external':
      return <External {...props} />;
    case 'twitter':
      return <Twitter {...props} />;
    case 'discord':
      return <Discord {...props} />;
    case 'medium':
      return <Medium {...props} />;
    case 'github':
      return <Github {...props} />;
    case 'gitbook':
      return <Gitbook {...props} />;
    case 'governance':
      return <Governance {...props} />;
    case 'telegram':
      return <Telegram {...props} />;
    case 'terms-conditions':
      return <TermsConditions {...props} />;
    case 'scroll':
      return <Scroll {...props} />;
    case 'slippage':
      return <Slippage {...props} />;
    case 'starknet':
      return <Starknet {...props} />;
    case 'globe':
      return <Globe {...props} />;
    case 'dark':
      return <Dark {...props} />;
    case 'magic-wand':
      return <MagicWand {...props} />;
    case 'infinity':
      return <InfinityIcon {...props} />;
    case 'lock':
      return <Lock {...props} />;
    case 'more':
      return <More {...props} />;
    case 'copy':
      return <Copy {...props} />;
    case 'x-solid':
      return <XSolid {...props} />;
    case 'right-sidebar':
      return <RightSidebar {...props} />;
    case 'nostra':
      return <Nostra {...props} />;
    case 'right-sidebar-open':
      return <RightSidebarOpen {...props} />;
    case 'back':
      return <Back {...props} />;
    case 'download':
      return <Download {...props} />;
    case 'navigate-next':
      return <NavigateNext {...props} />;
    case 'calendar':
      return <Calendar {...props} />;
    case 'search':
      return <Search {...props} />;
    default:
      return null;
  }
};

export default memo(Icon);
