import { FC, memo, MouseEvent, useCallback } from 'react';
import { TimeResolution } from '../../../interfaces';
import { ButtonWrapper, Typography } from '../../shared';

import './TimeResolutionSelector.scss';

type GraphProps = {
  selected: TimeResolution;
  onTimeResolutionChange: (timeResolution: TimeResolution) => void;
};

const TimeResolutionSelector: FC<GraphProps> = ({ selected = 'a', onTimeResolutionChange }) => {
  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      const value = (event.currentTarget as HTMLButtonElement).getAttribute('value');
      onTimeResolutionChange(value as TimeResolution);
    },
    [onTimeResolutionChange],
  );

  return (
    <div className="nostra__graph__time-resolution">
      <ButtonWrapper
        className="nostra__graph__time-resolution__button"
        value="1d"
        selected={selected === '1d'}
        onClick={handleClick}
      >
        <Typography variant="body-secondary" color={selected === '1d' ? 'text-primary-inverted' : 'chart'}>
          1D
        </Typography>
      </ButtonWrapper>
      <ButtonWrapper
        className="nostra__graph__time-resolution__button"
        value="1m"
        selected={selected === '1m'}
        onClick={handleClick}
      >
        <Typography variant="body-secondary" color={selected === '1m' ? 'text-primary-inverted' : 'chart'}>
          1M
        </Typography>
      </ButtonWrapper>
      <ButtonWrapper
        className="nostra__graph__time-resolution__button"
        value="3m"
        selected={selected === '3m'}
        onClick={handleClick}
      >
        <Typography variant="body-secondary" color={selected === '3m' ? 'text-primary-inverted' : 'chart'}>
          3M
        </Typography>
      </ButtonWrapper>
      <ButtonWrapper
        className="nostra__graph__time-resolution__button"
        value="a"
        selected={selected === 'a'}
        onClick={handleClick}
      >
        <Typography variant="body-secondary" color={selected === 'a' ? 'text-primary-inverted' : 'chart'}>
          All
        </Typography>
      </ButtonWrapper>
    </div>
  );
};

export default memo(TimeResolutionSelector);
