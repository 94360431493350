import { FC, memo, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Line, LineChart, ResponsiveContainer, YAxis } from 'recharts';
import { useAssets, useSelectedChartToken, useTokenRates } from '../../../hooks';
import { DecimalUtils } from '../../../utils';
import { Nullable } from '../../../interfaces';
import { Decimal } from '../../../datastructures';
import { LoadingPlaceholder, Logo, Typography } from '../../shared';

import './AnalyticsTokenStats.scss';

const UPDATE_FREQUENCY = 50; // milliseconds

const AnalyticsTokenStats: FC = () => {
  const { t } = useTranslation();

  const assets = useAssets();
  const tokenRates = useTokenRates();
  const [selectedChartToken] = useSelectedChartToken();

  const lastProjectionUpdateTime = useRef<number>(0);

  const [projectedUtilizationRate, setProjectedUtilizationRate] = useState<Nullable<number>>(null);

  const totalAssets = useMemo(() => {
    const assetData = assets.find(asset => asset.token === selectedChartToken);

    if (!assetData || !assetData.totalSupply) {
      return null;
    }

    const tokenRate = tokenRates[assetData.address];
    if (!tokenRate) {
      return null;
    }

    return DecimalUtils.format(assetData.totalSupply.mul(tokenRate), {
      style: 'multiplier',
      currency: '$',
      fractionDigits: 2,
    });
  }, [assets, selectedChartToken, tokenRates]);

  const totalDebt = useMemo(() => {
    const assetData = assets.find(asset => asset.token === selectedChartToken);

    if (!assetData || !assetData.totalBorrow) {
      return null;
    }

    const tokenRate = tokenRates[assetData.address];
    if (!tokenRate) {
      return null;
    }

    return DecimalUtils.format(assetData.totalBorrow.mul(tokenRate), {
      style: 'multiplier',
      currency: '$',
      fractionDigits: 2,
    });
  }, [assets, selectedChartToken, tokenRates]);

  const availableToBorrow = useMemo(() => {
    const assetData = assets.find(asset => asset.token === selectedChartToken);

    if (!assetData || !assetData.availableForBorrowing) {
      return null;
    }

    const tokenRate = tokenRates[assetData.address];
    if (!tokenRate) {
      return null;
    }

    return DecimalUtils.format(assetData.availableForBorrowing.mul(tokenRate), {
      style: 'multiplier',
      currency: '$',
      fractionDigits: 2,
    });
  }, [assets, selectedChartToken, tokenRates]);

  const supplyApy = useMemo(() => {
    const assetData = assets.find(asset => asset.token === selectedChartToken);

    if (!assetData || !assetData.supplyApy) {
      return null;
    }

    const tokenRate = tokenRates[assetData.address];
    if (!tokenRate) {
      return null;
    }

    return DecimalUtils.format(assetData.supplyApy, {
      style: 'percentage',
      fractionDigits: 2,
    });
  }, [assets, selectedChartToken, tokenRates]);

  const borrowApy = useMemo(() => {
    const assetData = assets.find(asset => asset.token === selectedChartToken);

    if (!assetData || !assetData.borrowApy) {
      return null;
    }

    const tokenRate = tokenRates[assetData.address];
    if (!tokenRate) {
      return null;
    }

    return DecimalUtils.format(assetData.borrowApy, {
      style: 'percentage',
      fractionDigits: 2,
    });
  }, [assets, selectedChartToken, tokenRates]);

  const utilizationRate = useMemo(() => {
    const assetData = assets.find(asset => asset.token === selectedChartToken);

    if (!assetData || !assetData.utilizationRate) {
      return null;
    }

    return assetData.utilizationRate;
  }, [assets, selectedChartToken]);

  const utilizationRateFormatted = useMemo(() => {
    if (!utilizationRate) {
      return null;
    }

    return DecimalUtils.format(utilizationRate, {
      style: 'percentage',
      fractionDigits: 2,
      pad: true,
    });
  }, [utilizationRate]);

  const projectedUtilizationRateFormatted = useMemo(() => {
    if (!projectedUtilizationRate) {
      return null;
    }

    return DecimalUtils.format(projectedUtilizationRate, {
      style: 'percentage',
      fractionDigits: 2,
      pad: true,
    });
  }, [projectedUtilizationRate]);

  const optimalUtilizationRate = useMemo(() => {
    const assetData = assets.find(asset => asset.token === selectedChartToken);

    if (!assetData || !assetData.optimalUtilizationRate) {
      return null;
    }

    return assetData.optimalUtilizationRate;
  }, [assets, selectedChartToken]);

  const baseBorrowRate = useMemo(() => {
    const assetData = assets.find(asset => asset.token === selectedChartToken);

    if (!assetData || !assetData.baseBorrowRate) {
      return null;
    }

    return assetData.baseBorrowRate;
  }, [assets, selectedChartToken]);

  const rateSlope1 = useMemo(() => {
    const assetData = assets.find(asset => asset.token === selectedChartToken);

    if (!assetData || !assetData.rateSlope1) {
      return null;
    }

    return assetData.rateSlope1;
  }, [assets, selectedChartToken]);

  const rateSlope2 = useMemo(() => {
    const assetData = assets.find(asset => asset.token === selectedChartToken);

    if (!assetData || !assetData.rateSlope2) {
      return null;
    }

    return assetData.rateSlope2;
  }, [assets, selectedChartToken]);

  const generalProtocolFee = useMemo(() => {
    const assetData = assets.find(asset => asset.token === selectedChartToken);

    if (!assetData || !assetData.generalProtocolFee) {
      return null;
    }

    return assetData.generalProtocolFee;
  }, [assets, selectedChartToken]);

  const projectedApy = useMemo(() => {
    if (
      !projectedUtilizationRate ||
      !optimalUtilizationRate ||
      !rateSlope1 ||
      !rateSlope2 ||
      !baseBorrowRate ||
      !generalProtocolFee
    ) {
      return null;
    }

    const utilizationRateDecimal = new Decimal(projectedUtilizationRate);

    let borrowRate: Decimal;
    if (utilizationRateDecimal.lte(optimalUtilizationRate)) {
      borrowRate = utilizationRateDecimal.div(optimalUtilizationRate).mul(rateSlope1).add(baseBorrowRate);
    } else {
      borrowRate = utilizationRateDecimal
        .sub(optimalUtilizationRate)
        .div(new Decimal(1).sub(optimalUtilizationRate))
        .mul(rateSlope2)
        .add(rateSlope1)
        .add(baseBorrowRate);
    }

    const lendingRate = new Decimal(1).sub(generalProtocolFee).mul(utilizationRateDecimal).mul(borrowRate);

    return {
      lendingRate: DecimalUtils.format(lendingRate, {
        style: 'percentage',
        fractionDigits: 2,
        pad: true,
      }),
      borrowRate: DecimalUtils.format(borrowRate, {
        style: 'percentage',
        fractionDigits: 2,
        pad: true,
      }),
    };
  }, [baseBorrowRate, generalProtocolFee, optimalUtilizationRate, projectedUtilizationRate, rateSlope1, rateSlope2]);

  const chartData = useMemo(() => {
    if (!optimalUtilizationRate || !rateSlope1 || !rateSlope2 || !baseBorrowRate || !generalProtocolFee) {
      return null;
    }

    const maxUtilization = 100;

    return Array.from(
      Array(maxUtilization + 1 /*+1 because we want array from 0 to 100 which is 101 elements*/).keys(),
    ).map(utilizationRate => {
      const utilizationRateDecimal = new Decimal(utilizationRate / 100);

      let borrowRate: Decimal;
      if (utilizationRateDecimal.lte(optimalUtilizationRate)) {
        borrowRate = utilizationRateDecimal.div(optimalUtilizationRate).mul(rateSlope1).add(baseBorrowRate);
      } else {
        borrowRate = utilizationRateDecimal
          .sub(optimalUtilizationRate)
          .div(new Decimal(1).sub(optimalUtilizationRate))
          .mul(rateSlope2)
          .add(rateSlope1)
          .add(baseBorrowRate);
      }

      const lendingRate = new Decimal(1).sub(generalProtocolFee).mul(utilizationRateDecimal).mul(borrowRate);

      return {
        x: utilizationRate,
        lendingRate: Number(lendingRate.toString()),
        borrowRate: Number(borrowRate.toString()),
        utilizationRate: Number(utilizationRateDecimal.div(100).toString()),
      };
    });
  }, [baseBorrowRate, generalProtocolFee, optimalUtilizationRate, rateSlope1, rateSlope2]);

  const handleMouseOverChart = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    const currentTime = Date.now();

    if (currentTime - UPDATE_FREQUENCY > lastProjectionUpdateTime.current) {
      const mousePosX = event.nativeEvent.offsetX;
      const chartWidth = event.currentTarget.offsetWidth;

      setProjectedUtilizationRate(Number(new Decimal(mousePosX).div(chartWidth).toString()));

      lastProjectionUpdateTime.current = currentTime;
    }
  }, []);

  const handleMouseOutChart = useCallback(() => {
    setProjectedUtilizationRate(null);
  }, []);

  const chartUtilizationRate = useMemo(() => {
    if (!utilizationRate) {
      return null;
    }

    if (projectedUtilizationRate !== null) {
      return projectedUtilizationRate * 100;
    }
    return Number(utilizationRate.toString()) * 100;
  }, [projectedUtilizationRate, utilizationRate]);

  return (
    <>
      <div className="nostra__analyticsTokenStats">
        <div className="nostra__analyticsTokenStats__title">
          {selectedChartToken && <Logo type={`token-${selectedChartToken}`} />}
          <Typography variant="subtitle" weight="bold">
            Protocol stats
          </Typography>
        </div>
        <ul className="nostra__analyticsTokenStats__data">
          <li className="nostra__analyticsTokenStats__dataItem">
            <Typography variant="body-primary" color="text-secondary" weight="bold">
              {t('Analytics.titleTotalAssets')}
            </Typography>
            {totalAssets ? (
              <Typography variant="body-primary" color="text-secondary" weight="medium">
                {totalAssets}
              </Typography>
            ) : (
              <LoadingPlaceholder shape={{ width: 'large', height: 'small' }} />
            )}
          </li>
          <li className="nostra__analyticsTokenStats__dataItem">
            <Typography variant="body-primary" color="text-secondary" weight="bold">
              {t('Analytics.titleTotalDebt')}
            </Typography>
            {totalDebt ? (
              <Typography variant="body-primary" color="text-secondary" weight="medium">
                {totalDebt}
              </Typography>
            ) : (
              <LoadingPlaceholder shape={{ width: 'large', height: 'small' }} />
            )}
          </li>
          <li className="nostra__analyticsTokenStats__dataItem">
            <Typography variant="body-primary" color="text-secondary" weight="bold">
              {t('Analytics.titleAvailableToBorrow')}
            </Typography>
            {availableToBorrow ? (
              <Typography variant="body-primary" color="text-secondary" weight="medium">
                {availableToBorrow}
              </Typography>
            ) : (
              <LoadingPlaceholder shape={{ width: 'large', height: 'small' }} />
            )}
          </li>
        </ul>
      </div>
      <div className="nostra__analyticsTokenStats">
        <div className="nostra__analyticsTokenStats__title">
          {selectedChartToken && <Logo type={`token-${selectedChartToken}`} />}
          <Typography variant="subtitle" weight="bold">
            Interest rate model
          </Typography>
        </div>
        <div className="nostra__analyticsTokenStats__chart">
          <div className="nostra__analyticsTokenStats__chartData">
            <div className="nostra__analyticsTokenStats__chartDataValues">
              <div className="nostra__analyticsTokenStats__chartDataValuesLabel">
                <Typography variant="body-secondary" weight="bold" color="text-chart-secondary">
                  Borrow APY
                </Typography>
                <Typography variant="body-secondary" weight="bold" color="text-chart-primary">
                  Supply APY
                </Typography>
                <Typography variant="body-secondary" weight="bold">
                  Utilization
                </Typography>
              </div>
              <div className="nostra__analyticsTokenStats__chartDataValue">
                <Typography variant="body-tertiary" weight="bold" color="text-chart-gray">
                  {projectedUtilizationRate ? 'Projected' : 'Current'}
                </Typography>
                <Typography variant="body-secondary" weight="bold" color="text-chart-secondary">
                  {projectedApy ? projectedApy.borrowRate : borrowApy}
                </Typography>
                <Typography variant="body-secondary" weight="bold" color="text-chart-primary">
                  {projectedApy ? projectedApy.lendingRate : supplyApy}
                </Typography>
                <Typography variant="body-secondary" weight="bold">
                  {projectedUtilizationRateFormatted || utilizationRateFormatted}
                </Typography>
              </div>
            </div>
          </div>
          {chartData && (
            <div className="nostra__analyticsTokenStats__chartContainer">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  data={chartData}
                  margin={{
                    top: 0,
                    right: 0,
                  }}
                >
                  <YAxis domain={[-0.05, 1.05]} hide={true} />
                  <defs>
                    <linearGradient id="supplyApyGradient" x1="0%" y1="0" x2="100%" y2="0">
                      <stop offset="0%" stopColor="rgba(255, 66, 64, 1)" />
                      <stop offset={`${chartUtilizationRate}%`} stopColor="rgba(255, 66, 64, 1)" />
                      <stop offset={`${chartUtilizationRate}%`} stopColor="rgba(255, 66, 64, 0.5)" />
                      <stop offset="100%" stopColor="rgba(255, 66, 64, 0.5)" />
                    </linearGradient>
                    <linearGradient id="borrowApyGradient" x1="0%" y1="0" x2="100%" y2="0">
                      <stop offset="0%" stopColor="rgba(112, 127, 157, 1)" />
                      <stop offset={`${chartUtilizationRate}%`} stopColor="rgba(112, 127, 157, 1)" />
                      <stop offset={`${chartUtilizationRate}%`} stopColor="rgba(112, 127, 157, 0.25)" />
                      <stop offset={`${100}%`} stopColor="rgba(112, 127, 157, 0.25)" />
                    </linearGradient>
                    <linearGradient id="utilizationGradient" x1="0%" y1="0" x2="100%" y2="0">
                      <stop offset="0%" stopColor="rgba(14, 26, 51, 1)" />
                      <stop offset={`${chartUtilizationRate}%`} stopColor="rgba(14, 26, 51, 1)" />
                      <stop offset={`${chartUtilizationRate}%`} stopColor="rgba(14, 26, 51, 0.25)" />
                      <stop offset={`${100}%`} stopColor="rgba(14, 26, 51, 0.25)" />
                    </linearGradient>
                  </defs>
                  <Line
                    type="monotone"
                    dataKey="borrowRate"
                    stroke="url(#borrowApyGradient)"
                    strokeWidth={2}
                    dot={false}
                  />
                  <Line
                    type="monotone"
                    dataKey="lendingRate"
                    stroke="url(#supplyApyGradient)"
                    strokeWidth={2}
                    dot={false}
                  />
                  <Line
                    type="monotone"
                    dataKey="utilizationRate"
                    stroke="url(#utilizationGradient)"
                    strokeWidth={2}
                    dot={false}
                  />
                </LineChart>
              </ResponsiveContainer>
              {projectedUtilizationRate && (
                <div
                  className="nostra__analyticsTokenStats__referenceLine"
                  style={{ left: `${utilizationRate?.mul(100).toTruncated()}%` }}
                >
                  <Typography variant="body-tertiary" color="text-chart-gray" weight="bold">
                    Current
                  </Typography>
                  <div className="nostra__analyticsTokenStats__dottedLine" />
                </div>
              )}

              <div className="nostra__analyticsTokenStats__chartLabels">
                {[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map(percentage => {
                  return (
                    <Typography key={`percentage-${percentage}`} variant="body-tertiary">
                      {percentage}%
                    </Typography>
                  );
                })}
              </div>
              <div className="nostra__analyticsTokenStats__axisLabel">
                <Typography variant="body-tertiary" weight="bold">
                  Utilization
                </Typography>
              </div>
              <div
                className="nostra__analyticsTokenStats__chartContainer__event"
                onMouseMove={handleMouseOverChart}
                onMouseOut={handleMouseOutChart}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default memo(AnalyticsTokenStats);
