import {
  LoadingButtonPlaceholder,
  LoadingPlaceholder,
  LoadingToggleSwitchPlaceholder,
  TableBodyCell,
  TableRow,
} from '../../shared';

const DepositPositionRowSkeleton = () => {
  return (
    <TableRow id="deposit-position-row-skeleton">
      <TableBodyCell>
        <div className="nostra__deposit-positions__loading-asset-cell">
          <LoadingPlaceholder shape={{ circle: 'medium' }} />
          <LoadingPlaceholder shape={{ width: 'medium', height: 'small' }} />
          <LoadingPlaceholder shape={{ width: 'small', height: 'small' }} />
        </div>
      </TableBodyCell>
      <TableBodyCell align="center">
        <LoadingToggleSwitchPlaceholder />
      </TableBodyCell>
      <TableBodyCell align="right">
        <LoadingPlaceholder shape={{ width: 'small', height: 'small' }} />
      </TableBodyCell>
      <TableBodyCell align="center">
        <LoadingToggleSwitchPlaceholder />
      </TableBodyCell>
      <TableBodyCell align="right">
        <div className="nostra__deposit-positions__multiple-values">
          <LoadingPlaceholder shape={{ width: 'medium', height: 'small' }} />
          <LoadingPlaceholder shape={{ width: 'small', height: 'small' }} />
        </div>
      </TableBodyCell>
      <TableBodyCell>
        <div className="nostra__deposit-positions__action-cell">
          <LoadingButtonPlaceholder />
        </div>
      </TableBodyCell>
    </TableRow>
  );
};
export default DepositPositionRowSkeleton;
