import { ChangeEvent, FC, useCallback, useMemo, useState, useEffect, ReactNode, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import { Collapse } from 'react-collapse';
import { Asset } from '../../../../interfaces';
import { Decimal, ONE, ZERO } from '../../../../datastructures';
import { GAS_FEE_UI_DECIMALS } from '../../../../constants';
import {
  useWalletAccount,
  useActiveAccountId,
  useAssetVariant,
  useAccountsHealthFactors,
  useBurnAndMint,
  useNostraTokenBalance,
  AssetVariant,
  useNewAdjustHealthFactor,
} from '../../../../hooks';
import { DecimalUtils, getNostraTokenFromFlags } from '../../../../utils';
import {
  ActionButton,
  ActionButtonLabels,
  ActionButtonState,
  ShadowContainer,
  ToggleSwitch,
  TooltipWrapper,
  Typography,
} from '../../../shared';
import AssetTierTooltip from '../../../AssetTierTooltip';
import ActionModalHealthFactorData from '../../ActionModalHealthFactorData';
import ActionModalSuccessInfo, { ActionModalSuccessRow } from '../../ActionModalSuccess';

import './Adjust.scss';

interface AdjustProps {
  asset: Asset;
  onModalClose?: () => void;
}

const Adjust: FC<AdjustProps> = props => {
  const { asset, onModalClose } = props;

  const { t } = useTranslation();

  const walletAccount = useWalletAccount();
  const activeAccountId = useActiveAccountId();
  const nostraTokenBalances = useNostraTokenBalance();
  const accountHealthFactor = useAccountsHealthFactors();
  const assetVariantMap = useAssetVariant();
  const calculateNewAdjustHealthFactor = useNewAdjustHealthFactor();
  const { burnAndMint, burnAndMintStatus } = useBurnAndMint();

  const healthFactorStore = useRef<null | string>(null);
  const newHealthFactorStore = useRef<null | string>(null);

  const [state, setState] = useState<ActionButtonState>('default');

  const healthFactor = useMemo(() => {
    const dataKey = `${walletAccount?.address}-${activeAccountId}`;

    if (!accountHealthFactor[dataKey]) {
      return null;
    }

    return accountHealthFactor[dataKey].value;
  }, [walletAccount?.address, activeAccountId, accountHealthFactor]);

  const formattedCurrentApy = useMemo(() => {
    return asset.supplyApy
      ? DecimalUtils.format(asset.supplyApy, { style: 'percentage', fractionDigits: 2, pad: true })
      : null;
  }, [asset]);

  const formattedUtilizationRate = useMemo(
    () =>
      asset.utilizationRate
        ? DecimalUtils.format(asset.utilizationRate, { style: 'percentage', fractionDigits: 2, pad: true })
        : null,
    [asset.utilizationRate],
  );

  const formattedCollateralFactor = useMemo(
    () =>
      asset.collateralFactor
        ? DecimalUtils.format(asset.collateralFactor, { style: 'percentage', fractionDigits: 2, pad: true })
        : null,
    [asset.collateralFactor],
  );

  const formattedAvailableSupply = useMemo(
    () =>
      asset.availableForBorrowing
        ? DecimalUtils.format(asset.availableForBorrowing, {
            style: 'multiplier',
            fractionDigits: 2,
            noMultiplierFractionDigits: asset.uiTokenPrecision,
            currency: asset.token,
            lessThanFormat: true,
          })
        : null,
    [asset.availableForBorrowing, asset.token, asset.uiTokenPrecision],
  );

  const assetVariant = useMemo((): AssetVariant | null => {
    return assetVariantMap[`${walletAccount?.address}-${activeAccountId}-${asset.address}`] || null;
  }, [walletAccount?.address, activeAccountId, asset.address, assetVariantMap]);

  const activeNostraToken = useMemo(() => {
    if (!assetVariant) {
      return null;
    }

    return getNostraTokenFromFlags(asset, {
      interestBearing: assetVariant.isLending,
      collateral: assetVariant.isCollateral,
    });
  }, [asset, assetVariant]);

  const activeNostraTokenBalance = useMemo(() => {
    const dataKey = `${walletAccount?.address}-${activeAccountId}-${activeNostraToken?.address}`;

    const balance = nostraTokenBalances[dataKey];
    if (!balance) {
      return null;
    }

    return balance;
  }, [walletAccount?.address, activeNostraToken?.address, activeAccountId, nostraTokenBalances]);

  const canBeUsedAsCollateral = asset.assetTier === 'shared' || asset.assetTier === 'nominal';

  const [enableLending, setEnableLending] = useState<boolean>(
    assetVariant && assetVariant.isLending !== null ? assetVariant.isLending : true,
  );

  const [enableCollateral, setEnableCollateral] = useState<boolean>(
    assetVariant && assetVariant.isCollateral !== null ? assetVariant.isCollateral : canBeUsedAsCollateral,
  );

  /**
   * Enable action button only if user changed one of the toggles.
   */
  const collateralChanged = useMemo(() => {
    if (!assetVariant) {
      return null;
    }

    return enableCollateral !== assetVariant.isCollateral;
  }, [assetVariant, enableCollateral]);

  /**
   * Enable action button only if user changed one of the toggles.
   */
  const lendingChanged = useMemo(() => {
    if (!assetVariant) {
      return null;
    }

    return enableLending !== assetVariant.isLending;
  }, [assetVariant, enableLending]);

  const newHealthFactor = useMemo(() => {
    if (!walletAccount || !activeNostraTokenBalance || activeAccountId === null) {
      return null;
    }

    return !collateralChanged || !calculateNewAdjustHealthFactor
      ? healthFactor
      : calculateNewAdjustHealthFactor(
          walletAccount.address,
          activeAccountId,
          activeNostraTokenBalance,
          asset,
          enableCollateral,
        );
  }, [
    collateralChanged,
    activeAccountId,
    activeNostraTokenBalance,
    calculateNewAdjustHealthFactor,
    walletAccount,
    healthFactor,
    asset,
    enableCollateral,
  ]);

  useEffect(() => {
    if (!assetVariant) {
      return;
    }

    if (burnAndMintStatus?.pending) {
      setEnableCollateral(burnAndMintStatus?.request.isToCollateral);
      setEnableLending(burnAndMintStatus?.request.isToInterestBearing);
    } else {
      setEnableCollateral(canBeUsedAsCollateral ? assetVariant.isCollateral : false);
      setEnableLending(assetVariant.isLending);
    }
  }, [assetVariant, canBeUsedAsCollateral, burnAndMintStatus]);

  const gasFeeFormatted = useMemo(() => {
    if (!burnAndMintStatus?.transactionData) {
      return '';
    }

    return DecimalUtils.format(burnAndMintStatus.transactionData.gasFee, {
      style: 'currency',
      fractionDigits: GAS_FEE_UI_DECIMALS,
      currency: 'ETH',
      lessThanFormat: true,
    });
  }, [burnAndMintStatus?.transactionData]);

  /**
   * Update action button state based on current adjust request status
   */
  useEffect(() => {
    if (!burnAndMintStatus) {
      return;
    }

    if (burnAndMintStatus.pending) {
      setState('loading');
    } else if (burnAndMintStatus.success) {
      setState('success');
    } else {
      setState('default');
    }
  }, [burnAndMintStatus]);

  const handleAdjust = useCallback(() => {
    if (!walletAccount || activeAccountId === null || !activeNostraTokenBalance || !assetVariant) {
      return;
    }

    const adjustTxId = uuid();

    healthFactorStore.current = healthFactor ? healthFactor.toString() : null;
    newHealthFactorStore.current = newHealthFactor ? newHealthFactor.toString() : null;

    burnAndMint({
      asset,
      amount: activeNostraTokenBalance,
      accountId: activeAccountId,
      isFromInterestBearing: assetVariant.isLending,
      isFromCollateral: assetVariant.isCollateral,
      isToInterestBearing: enableLending,
      isToCollateral: enableCollateral,
      txnId: adjustTxId,
    });

    setState('loading');
  }, [
    walletAccount,
    asset,
    activeNostraTokenBalance,
    activeAccountId,
    assetVariant,
    enableLending,
    enableCollateral,
    healthFactor,
    newHealthFactor,
    burnAndMint,
  ]);

  const handleLendingToggle = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setEnableLending(event.target.checked);
  }, []);

  const handleCollateralToggle = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setEnableCollateral(event.target.checked);
  }, []);

  const error = useMemo(() => {
    if (!walletAccount || !newHealthFactor) {
      return undefined;
    }

    // TODO - Check if we reached supply cap for target nostra token

    // Check if contracts have enough liquidity to perform burn and mint
    if (asset.availableForBorrowing && activeNostraTokenBalance?.gt(asset.availableForBorrowing)) {
      return t('Adjust.errorExceedAvailableSupply');
    }

    // Check if resulting health factor is healthy
    if (newHealthFactor.lt(ONE)) {
      return 'Disabling collateral will result in Health factor below the liquidation threshold.';
    }

    return undefined;
  }, [walletAccount, activeNostraTokenBalance, asset.availableForBorrowing, newHealthFactor, t]);

  const collateralToggleTooltip = useMemo((): ReactNode | null => {
    if (!canBeUsedAsCollateral && (asset.assetTier === 'isolated' || asset.assetTier === 'cross')) {
      return <AssetTierTooltip assetTier={asset.assetTier} />;
    }

    return null;
  }, [asset.assetTier, canBeUsedAsCollateral]);

  const adjustEnabled = useMemo(() => {
    if (collateralChanged === null || lendingChanged === null) {
      return false;
    }

    return (collateralChanged || lendingChanged) && !error;
  }, [collateralChanged, lendingChanged, error]);

  const actionButtonLabels: ActionButtonLabels = {
    default: t('Adjust.actionAdjust'),
    loading: t('Adjust.actionExecuting'),
    success: t('Adjust.actionSuccess'),
  };

  if (burnAndMintStatus?.success) {
    const fromToken = getNostraTokenFromFlags(asset, {
      interestBearing: burnAndMintStatus.request.isFromInterestBearing,
      collateral: burnAndMintStatus.request.isFromCollateral,
    });
    const toToken = getNostraTokenFromFlags(asset, {
      interestBearing: burnAndMintStatus.request.isToInterestBearing,
      collateral: burnAndMintStatus.request.isToCollateral,
    });

    const burnAndMintedAmount = burnAndMintStatus.transactionData?.burnAndMintedAmount ?? ZERO;
    const amountMintedFormatted = DecimalUtils.format(burnAndMintedAmount, {
      style: 'currency',
      fractionDigits: asset.uiTokenPrecision,
      currency: fromToken.symbol,
      lessThanFormat: true,
    });
    const amountBurnedFormatted = DecimalUtils.format(burnAndMintedAmount, {
      style: 'currency',
      fractionDigits: asset.uiTokenPrecision,
      currency: toToken.symbol,
      lessThanFormat: true,
    });

    return (
      <ActionModalSuccessInfo
        onClose={onModalClose}
        tokenToAdd={toToken}
        txHash={burnAndMintStatus.getTransactionResponse?.transaction_hash}
      >
        {burnAndMintStatus.request.isFromCollateral !== burnAndMintStatus.request.isToCollateral && (
          <ActionModalSuccessRow
            label={t('ActionModalSuccessInfo.labelCollateral')}
            value={burnAndMintStatus.request.isFromCollateral ? 'Enabled' : 'Disabled'}
            newValue={burnAndMintStatus.request.isToCollateral ? 'Enabled' : 'Disabled'}
          />
        )}
        {burnAndMintStatus.request.isFromInterestBearing !== burnAndMintStatus.request.isToInterestBearing && (
          <ActionModalSuccessRow
            label={t('ActionModalSuccessInfo.labelLending')}
            value={burnAndMintStatus.request.isFromInterestBearing ? 'Enabled' : 'Disabled'}
            newValue={burnAndMintStatus.request.isToInterestBearing ? 'Enabled' : 'Disabled'}
          />
        )}
        <ActionModalSuccessRow
          label={t('ActionModalSuccessInfo.labelValue')}
          value={amountMintedFormatted}
          newValue={amountBurnedFormatted}
        />
        {healthFactorStore.current && newHealthFactorStore.current && (
          <ActionModalSuccessRow
            label={t('ActionModalSuccessInfo.labelHealthFactor')}
            value={
              <ActionModalHealthFactorData
                amount={burnAndMintStatus.transactionData?.burnAndMintedAmount || ZERO}
                healthFactor={new Decimal(healthFactorStore.current)}
                newHealthFactor={new Decimal(newHealthFactorStore.current)}
                showLabel={false}
              />
            }
          />
        )}
        <ActionModalSuccessRow label={t('ActionModalSuccessInfo.labelNetworkFee')} value={gasFeeFormatted} />
      </ActionModalSuccessInfo>
    );
  }

  return (
    <>
      {/* Lending */}
      <div className="nostra__adjust-modal__lending">
        <div className="nostra__adjust-modal__lending__title">
          <ToggleSwitch
            checked={enableLending}
            disabled={state === 'loading'}
            label={t('Adjust.labelEnableLending')}
            onChange={handleLendingToggle}
          />
        </div>
        <Collapse isOpened={enableLending}>
          <ShadowContainer className="nostra__action-modal__data-container">
            <div className="nostra__action-modal__data-row">
              <div className="nostra__action-modal__data-token-label">
                <Typography variant="body-tertiary">{t('Adjust.labelCurrentApy')}</Typography>
              </div>
              <Typography variant="body-tertiary">{formattedCurrentApy}</Typography>
            </div>
            <div className="nostra__action-modal__data-row">
              <Typography variant="body-tertiary">{t('Adjust.labelUtilizationRate')}</Typography>
              <Typography variant="body-tertiary">{formattedUtilizationRate}</Typography>
            </div>
          </ShadowContainer>
        </Collapse>
      </div>

      {/* Collateral */}
      <div className="nostra__adjust-modal__collateral">
        <div className="nostra__adjust-modal__collateral__title">
          <TooltipWrapper tooltipContent={collateralToggleTooltip} placement="left" openEvent="mouseenter">
            <ToggleSwitch
              checked={enableCollateral}
              disabled={state === 'loading'}
              locked={!canBeUsedAsCollateral}
              label={t('Adjust.labelEnableCollateral')}
              onChange={handleCollateralToggle}
            />
          </TooltipWrapper>
        </div>
        <Collapse isOpened={canBeUsedAsCollateral}>
          <ShadowContainer className="nostra__action-modal__data-container">
            <div className="nostra__action-modal__data-row">
              <Typography variant="body-tertiary">{t('Adjust.labelCollateralFactor')}</Typography>
              <Typography variant="body-tertiary">{formattedCollateralFactor}</Typography>
            </div>
            <div className="nostra__action-modal__data-row">
              <Typography variant="body-tertiary">{t('Adjust.labelAvailableSupply')}</Typography>
              <Typography variant="body-tertiary">{formattedAvailableSupply}</Typography>
            </div>
            {activeNostraTokenBalance && (
              <ActionModalHealthFactorData
                amount={activeNostraTokenBalance}
                healthFactor={healthFactor}
                newHealthFactor={newHealthFactor}
              />
            )}
          </ShadowContainer>
        </Collapse>
        <Collapse isOpened={Boolean(error)}>
          <div className="nostra__action-modal__adjust-error">
            <Typography variant="body-tertiary" color="text-error">
              {error}
            </Typography>
          </div>
        </Collapse>
      </div>

      {/* Action button */}
      <ActionButton
        size="large"
        variant="primary"
        labels={actionButtonLabels}
        onClick={handleAdjust}
        state={adjustEnabled ? state : 'disabled'}
        fullWidth
      />
    </>
  );
};
export default Adjust;
