import { FC } from 'react';
import { InnerIconProps } from './IconProps';
import withIcon from './withIcon';

const UpArrow: FC<InnerIconProps> = ({ size, color }) => (
  <svg
    className="nostra__icon nostra__icon-up-arrow"
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_909_5453)">
      <path
        d="M8.81096 0.295692L15.6641 6.29352C15.7706 6.38672 15.8551 6.49737 15.9127 6.61915C15.9703 6.74093 16 6.87145 16 7.00326C16 7.13507 15.9703 7.26559 15.9127 7.38737C15.8551 7.50915 15.7706 7.6198 15.6641 7.713C15.5576 7.80621 15.4312 7.88014 15.292 7.93058C15.1529 7.98103 15.0037 8.00699 14.8531 8.00699C14.7025 8.00699 14.5534 7.98103 14.4143 7.93058C14.2751 7.88014 14.1487 7.80621 14.0422 7.713L9.14219 3.41456V15.0004C9.14219 15.2655 9.02185 15.5197 8.80765 15.7072C8.59345 15.8947 8.30293 16 8 16C7.69707 16 7.40655 15.8947 7.19235 15.7072C6.97815 15.5197 6.85781 15.2655 6.85781 15.0004L6.85781 3.41456L1.95782 7.713C1.74274 7.90124 1.45103 8.00699 1.14686 8.00699C0.842696 8.00699 0.550987 7.90124 0.335909 7.713C0.12083 7.52477 1.7828e-08 7.26947 0 7.00326C-1.7828e-08 6.73706 0.12083 6.48175 0.335908 6.29352L7.18905 0.295692C7.29523 0.201998 7.42155 0.127629 7.56074 0.0768784C7.69993 0.0261281 7.84922 5.76833e-09 8 0C8.15078 -5.76833e-09 8.30007 0.026128 8.43926 0.0768783C8.57845 0.127629 8.70477 0.201998 8.81096 0.295692Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_909_5453">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default withIcon(UpArrow);
