import { AccountInterface } from 'starknet';
import { getConfigManager } from '../config/getConfigManager';
import { ContractCache } from '../utils';
import { DeferredBatchCallAdapterContract } from './contracts';

const contractCache = new ContractCache(
  (address, providerOrAccount) => new DeferredBatchCallAdapterContract(address, providerOrAccount),
);

export const getDeferredBatchCallAdapterContract = (account?: AccountInterface): DeferredBatchCallAdapterContract => {
  const deferredBatchCallAdapterAddress = getConfigManager().getConfig().deferredBatchCallAdapter;
  return contractCache.get(deferredBatchCallAdapterAddress, account);
};
