import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  setActiveAccount,
  useWalletAccount,
  useAccountsHealthFactors,
  useAccountTotalAssets,
  useAccountTotalDebt,
} from '../../../../hooks';
import { DecimalUtils, getHealthFactorStatus } from '../../../../utils';
import { ButtonWrapper, colors, HealthFactorStatusDot, Icon, Typography } from '../../../shared';

import './AccountCard.scss';

interface AccountCardProps {
  accountName: string;
  accountId: number;
}

const AccountCard: FC<AccountCardProps> = props => {
  const { accountName, accountId } = props;

  const { t } = useTranslation();

  const walletAccount = useWalletAccount();
  const accountTotalAssets = useAccountTotalAssets();
  const accountTotalDebt = useAccountTotalDebt();

  const accountsHealthFactors = useAccountsHealthFactors();
  const healthFactor = accountsHealthFactors[`${walletAccount?.address}-${accountId}`]?.value;
  const healthFactorStatus = useMemo(() => getHealthFactorStatus(healthFactor), [healthFactor]);

  const paddedAccountId = useMemo(() => `${accountId}`.padStart(3, '0'), [accountId]);

  const totalAssetsFormatted = useMemo(() => {
    const dataKey = `${walletAccount?.address}-${accountId}`;

    if (!accountTotalAssets[dataKey]) {
      return null;
    }

    return DecimalUtils.format(accountTotalAssets[dataKey], {
      style: 'multiplier',
      fractionDigits: 2,
      currency: '$',
      lessThanFormat: true,
    });
  }, [walletAccount?.address, accountId, accountTotalAssets]);

  const totalDebtFormatted = useMemo(() => {
    const dataKey = `${walletAccount?.address}-${accountId}`;
    const totalDebt = accountTotalDebt[dataKey];

    if (!totalDebt) {
      return null;
    }

    return DecimalUtils.format(totalDebt, {
      style: 'multiplier',
      fractionDigits: 2,
      currency: '$',
      lessThanFormat: true,
    });
  }, [walletAccount?.address, accountId, accountTotalDebt]);

  const handleButtonClick = useCallback(() => {
    if (walletAccount) {
      setActiveAccount(walletAccount.address, accountId);
    }
  }, [walletAccount, accountId]);

  return (
    <ButtonWrapper className="nostra__accountCard" onClick={handleButtonClick}>
      {(healthFactorStatus === 'low' || healthFactorStatus === 'medium') && (
        <div
          className={`nostra__accountCard__health-factor-warning nostra__accountCard__${healthFactorStatus}-health-factor-warning`}
        >
          <Icon
            variant="exclamation-error"
            size="small"
            color={healthFactorStatus === 'low' ? colors.textError : colors.textWarning}
          />
          <Typography
            variant="body-tertiary"
            color={healthFactorStatus === 'low' ? 'text-error' : 'text-warning'}
            weight="bold"
          >
            {t('AccountCard.warningTitle')}
          </Typography>
          <Typography variant="body-tertiary" color={healthFactorStatus === 'low' ? 'text-error' : 'text-warning'}>
            {t('AccountCard.warningDescription', { healthFactor: healthFactor.toTruncated(2) })}
          </Typography>
        </div>
      )}
      <div className="nostra__accountCard__content">
        <div className="nostra__accountCard-label">
          <HealthFactorStatusDot status={healthFactorStatus} />
          <div className="nostra__accountCard-info">
            <Typography variant="body-tertiary" color="text-wallet">
              ID: {paddedAccountId}
            </Typography>
            <Typography variant="body-secondary" weight="bold" title={accountName}>
              {accountName}
            </Typography>
          </div>
        </div>
        <div className="nostra__accountCard-data-container">
          <div className="nostra__accountCard-data">
            <div className="nostra__accountCard-data-label">
              <Typography variant="body-tertiary" color="text-wallet">
                {t('Wallet.labelAssets')}
              </Typography>
            </div>
            <div className="nostra__accountCard-data-value">
              <Typography variant="body-tertiary">{totalAssetsFormatted || '---'}</Typography>
            </div>
          </div>
          <div className="nostra__accountCard-data">
            <div className="nostra__accountCard-data-label">
              <Typography variant="body-tertiary" color="text-wallet">
                {t('Wallet.labelDebt')}
              </Typography>
            </div>
            <div className="nostra__accountCard-data-value">
              <Typography variant="body-tertiary">{totalDebtFormatted || '---'}</Typography>
            </div>
          </div>
        </div>
      </div>
    </ButtonWrapper>
  );
};
export default AccountCard;
