import Typography, { TypographyVariant, TypographyColor } from '../components/shared/Typography';
import { truncateAddress } from './addressHelpers';

type HighlightTypographyOptions = {
  variant: TypographyVariant;
  color?: TypographyColor;
  highlightVariant?: TypographyVariant;
  highlightColor?: TypographyColor;
};

export const highlightText = (str: string, keywords: string, options: HighlightTypographyOptions) => {
  const { variant, color, highlightVariant, highlightColor } = options;

  if (!keywords) {
    return <Typography variant={variant}>{str}</Typography>;
  }

  const regexp = new RegExp(keywords, 'ig');
  const strings = str.split(regexp);
  const matches = [...str.matchAll(regexp)];

  if (!matches.length) {
    return (
      <Typography variant={variant} color={color}>
        {str}
      </Typography>
    );
  }

  const textComponents = strings.map((str, i) => (
    <>
      {i > 0 && (
        <Typography key={`keyword-${i}`} variant={highlightVariant ?? variant} color={highlightColor}>
          {matches[i - 1][0]}
        </Typography>
      )}
      <Typography key={`str-${i}`} variant={variant} color={color}>
        {str}
      </Typography>
    </>
  ));

  return <div style={{ display: 'flex' }}>{textComponents}</div>;
};

export const highlightTruncateAddress = (address: string, keywords: string, options: HighlightTypographyOptions) => {
  const { variant, color, highlightVariant, highlightColor } = options;
  const truncated = truncateAddress(address);

  if (!keywords) {
    return (
      <Typography variant={variant} color={color}>
        {truncated}
      </Typography>
    );
  }

  const regexp = new RegExp(keywords, 'ig');
  const strings = address.split(regexp);
  const matches = [...address.matchAll(regexp)];
  const highlightIndexes = [] as boolean[];
  const [prefix, suffix] = truncated.split('...');

  if (!matches.length) {
    return (
      <Typography variant={variant} color={color}>
        {truncated}
      </Typography>
    );
  }

  strings.forEach((str, i) => {
    if (i > 0) {
      const [match] = matches[i - 1];
      for (let j = 0; j < match.length; j++) {
        highlightIndexes.push(true);
      }
    }

    for (let j = 0; j < str.length; j++) {
      highlightIndexes.push(false);
    }
  });

  const prefixIndexes = highlightIndexes.slice(0, prefix.length);
  const suffixIndexes = highlightIndexes.slice(-suffix.length);
  const highlightEllipsis = highlightIndexes.slice(prefix.length, -suffix.length).some(highlight => highlight);

  const prefixComponent = prefixIndexes.map((highlight, i) => (
    <Typography
      key={`prefix-${i}`}
      variant={highlight ? highlightVariant ?? variant : variant}
      color={highlight ? highlightColor : color}
    >
      {prefix.charAt(i)}
    </Typography>
  ));
  const suffixComponent = suffixIndexes.map((highlight, i) => (
    <Typography
      key={`suffix-${i}`}
      variant={highlight ? highlightVariant ?? variant : variant}
      color={highlight ? highlightColor : color}
    >
      {suffix.charAt(i)}
    </Typography>
  ));

  return (
    <div style={{ display: 'flex' }}>
      {prefixComponent}
      <Typography
        key="ellipsis"
        variant={highlightEllipsis ? highlightVariant ?? variant : variant}
        color={highlightEllipsis ? highlightColor : color}
      >
        ...
      </Typography>
      {suffixComponent}
    </div>
  );
};
