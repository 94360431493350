import { FC, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, ButtonWrapper, Link } from '../../../shared';

import './AccountMenu.scss';

type AccountMenuProps = {
  accountId: number;
  disableClosing?: boolean;
  onRenameAccount: () => void;
  onCloseAccount: () => void;
};

export const AccountMenu: FC<AccountMenuProps> = memo(props => {
  const { accountId, disableClosing, onRenameAccount, onCloseAccount } = props;
  const { t } = useTranslation();

  const handleRenameAccount = useCallback(() => {
    onRenameAccount();
  }, [onRenameAccount]);

  const handleCloseAccount = useCallback(() => {
    onCloseAccount();
  }, [onCloseAccount]);

  return (
    <div className="nostra__account-menu-tooltip">
      <ul className="nostra__account-menu-tooltip__actions">
        <li>
          <Link className="nostra__account-menu-tooltip-button" href={`/accounts/${accountId}/transactions`}>
            <Typography variant="body-primary" weight="medium" color="text-secondary">
              {t('AccountManagementTooltip.btnTransactionHistory')}
            </Typography>
          </Link>
        </li>
        <li>
          <ButtonWrapper className="nostra__account-menu-tooltip-button" onClick={handleRenameAccount}>
            <Typography variant="body-primary" weight="medium" color="text-secondary">
              {t('AccountManagementTooltip.btnRenameAccount')}
            </Typography>
          </ButtonWrapper>
        </li>
        <li data-disabled={disableClosing}>
          <ButtonWrapper
            className="nostra__account-menu-tooltip-button nostra__account-menu-tooltip-button-highlight"
            onClick={handleCloseAccount}
            disabled={disableClosing}
          >
            <Typography variant="body-primary" weight="medium">
              {t('AccountManagementTooltip.btnCloseAccount')}
            </Typography>
          </ButtonWrapper>
        </li>
      </ul>
    </div>
  );
});
