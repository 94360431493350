import React, { FC, memo, ReactNode, useCallback } from 'react';
import ButtonWrapper from '../ButtonWrapper';
import Icon from '../Icon';
import ModalWrapper from '../ModalWrapper/ModalWrapper';
import Typography from '../Typography';

import './Modal.scss';

export interface ModalProps {
  open: boolean;
  title: string | ReactNode;
  children: ReactNode;
  onClose: () => void;
}

const Modal: FC<ModalProps> = props => {
  const { open, title = '', children, onClose } = props;

  const onCloseButtonClick = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <ModalWrapper onClose={onClose} open={open}>
      <div className="nostra__modal">
        <div className="nostra__modal-header">
          {typeof title === 'string' && (
            <Typography variant="title" weight="bold">
              {title}
            </Typography>
          )}
          {typeof title !== 'string' && title}

          <ButtonWrapper className="nostra__modal-close-wrapper" onClick={onCloseButtonClick}>
            <Icon variant="close" size="tiny" />
          </ButtonWrapper>
        </div>
        <div className="nostra__modal__body">{children}</div>
      </div>
    </ModalWrapper>
  );
};
export default memo(Modal);
