import { ChangeEvent, FC, ReactElement, useMemo } from 'react';
import Icon from '../Icon';
import Typography from '../Typography';
import './Checkbox.scss';

export interface CheckboxProps {
  checked: boolean;
  label?: string | ReactElement;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

let idCounter = 0;

const Checkbox: FC<CheckboxProps> = props => {
  const { checked, label, onChange } = props;
  const id = useMemo(() => {
    idCounter += 1;
    return `checkbox-${idCounter}`;
  }, []);

  return (
    <span className="nostra__checkbox">
      <span className="nostra__checkbox__box-container">
        <input id={id} checked={checked} type="checkbox" onChange={onChange} />
        {checked ? <Icon variant="checkmark" size="tiny" /> : null}
      </span>
      {label && (
        <label htmlFor={id} className="tc__checkbox__label">
          <Typography variant="body-secondary" weight={checked ? 'bold' : 'regular'}>
            {label}
          </Typography>
        </label>
      )}
    </span>
  );
};

export default Checkbox;
