import { state, useStateObservable } from '@react-rxjs/core';
import { BehaviorSubject } from 'rxjs';
import { Nullable, Token } from '../interfaces';

const selectedChartToken$ = new BehaviorSubject<Nullable<Token>>(null);
const stateSelectedChartToken$ = state(selectedChartToken$, null);
const setSelectedChartToken = (selectedChartToken: Nullable<Token>) => selectedChartToken$.next(selectedChartToken);

export function useSelectedChartToken(): [Nullable<Token>, (value: Nullable<Token>) => void] {
  const selectedChartToken = useStateObservable(stateSelectedChartToken$);
  return [selectedChartToken, setSelectedChartToken];
}

export { selectedChartToken$ };
