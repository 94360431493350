import { FC } from 'react';

import './MenuWrapper.scss';

const MenuWrapper: FC = props => {
  const { children } = props;

  return <div className="nostra__menu-wrapper">{children}</div>;
};
export default MenuWrapper;
