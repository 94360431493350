import { FC, useMemo } from 'react';
import { depositLimits } from '../../config';
import { AssetTier, Nullable, Token } from '../../interfaces';
import { DecimalUtils } from '../../utils';
import { AssetTierPill, LoadingPlaceholder, Logo, LogoType, Typography } from '../shared';

interface ActionModalTitleProps {
  token: Nullable<Token>;
  tokenPrecision: Nullable<number>;
  assetTier: Nullable<AssetTier>;
}

const ActionModalTitle: FC<ActionModalTitleProps> = props => {
  const { token, tokenPrecision, assetTier } = props;
  const depositLimit = token ? depositLimits[token] : null;

  const formattedDepositLimit = useMemo(
    () =>
      depositLimit && tokenPrecision && token
        ? DecimalUtils.format(depositLimit, { style: 'currency', fractionDigits: tokenPrecision, currency: token })
        : null,
    [depositLimit, token, tokenPrecision],
  );

  return (
    <div>
      <div className="nostra__action-modal__title">
        {(!token || !assetTier) && (
          <div className="nostra__action-modal__title-placeholder">
            <LoadingPlaceholder shape={{ circle: 'medium' }} />
            <div className="nostra__action-modal__title-placeholder-info">
              <LoadingPlaceholder shape={{ width: 'large', height: 'small' }} />
              <LoadingPlaceholder shape={{ width: 'small', height: 'small' }} />
            </div>
          </div>
        )}
        {token && assetTier && (
          <>
            <Typography variant="title" weight="bold">
              {token}
            </Typography>
            <Logo type={`token-${token}` as LogoType} size="small" />
            <AssetTierPill tier={assetTier} />
          </>
        )}
      </div>
      {formattedDepositLimit && (
        <Typography className="nostra__action-modal__title-limit" variant="body-secondary">
          Alpha deposit limit: {formattedDepositLimit}
        </Typography>
      )}
    </div>
  );
};

export default ActionModalTitle;
