import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  setTransactionHistoryAccountId,
  setTransactionKeywords,
  setFromTransactionDate,
  setToTransactionDate,
  setTransactionHistoryLimit,
  setTransactionHistorySkip,
  useTransactionHistory,
  useWalletAccount,
  useAccounts,
} from '../../hooks';
import { TRANSACTION_HISTORY_PAGE_SIZES } from '../../constants';
import { getAccountAddressFromId } from '../../utils';
import {
  ButtonWrapper,
  Icon,
  Table,
  TableBody,
  TableHead,
  TableHeadCell,
  TablePagination,
  TableRow,
  Typography,
  DateRangePicker,
  SearchInput,
  colors,
  ButtonSecondary,
} from '../shared';
import { Nullable } from '../../interfaces';
import TransactionHistoryTableRow from './TransactionHistoryTableRow';
import TransactionHistoryDropdown from './TransactionHistoryDropDown';
import TransactionHistoryDropdownItem from './TransactionHistoryDropDownItem';

import './TransactionHistory.scss';

const TransactionHistory: FC = () => {
  const { accountId } = useParams(); // TODO: check if account id is valid
  const { t } = useTranslation();
  const navigate = useNavigate();
  const transactionHistory = useTransactionHistory();
  const walletAccount = useWalletAccount();
  const accounts = useAccounts();

  const [keywords, setKeywords] = useState<string>('');
  const [searchFromDate, setSearchFromDate] = useState<Nullable<Date>>(null);
  const [searchToDate, setSearchToDate] = useState<Nullable<Date>>(null);
  const [rowsPerPage, setRowsPerPage] = useState<number>(TRANSACTION_HISTORY_PAGE_SIZES[0]);
  const [itemSkip, setItemSkip] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const onSearchDateChange = useCallback((from: Nullable<Date>, to: Nullable<Date>) => {
    setSearchFromDate(from);
    setSearchToDate(to);

    // When user uses filter by date we should send user back to first page
    setCurrentPage(1);
  }, []);

  const onKeywordsChanged = useCallback((value: string) => {
    setKeywords(value);

    // When user uses filter by text we should send user back to first page
    setCurrentPage(1);
  }, []);

  const onRowsPerPageChanged = useCallback((value: number) => {
    setRowsPerPage(value);

    // When user changes number of rows per page we should send user back to first page
    setCurrentPage(1);
  }, []);

  useEffect(() => {
    if (accountId !== undefined) {
      setTransactionHistoryAccountId(parseInt(accountId));
      setTransactionKeywords(keywords);
      setFromTransactionDate(searchFromDate);
      setToTransactionDate(searchToDate);
      setTransactionHistoryLimit(rowsPerPage);
      setTransactionHistorySkip(itemSkip);
    }
  }, [accountId, keywords, searchFromDate, searchToDate, rowsPerPage, itemSkip]);

  useEffect(() => {
    setItemSkip(rowsPerPage * (currentPage - 1));
  }, [currentPage, rowsPerPage]);

  const onBack = useCallback(() => navigate('/accounts'), [navigate]);

  const onPageChange = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  const goPrevPage = useCallback(() => setCurrentPage(prevState => --prevState), []);

  const goNextPage = useCallback(() => setCurrentPage(prevState => ++prevState), []);

  const isFirstPage = useMemo(
    () => !transactionHistory || itemSkip < rowsPerPage,
    [itemSkip, rowsPerPage, transactionHistory],
  );
  const isLastPage = useMemo(
    () => !transactionHistory || itemSkip + rowsPerPage > transactionHistory.transactionCount,
    [itemSkip, rowsPerPage, transactionHistory],
  );

  const accountAddress = useMemo(() => {
    if (!walletAccount || !accountId) {
      return null;
    }

    return getAccountAddressFromId(walletAccount.address, parseInt(accountId));
  }, [accountId, walletAccount]);

  const accountName = useMemo(() => {
    return accounts && accountId ? accounts[parseInt(accountId, 10)] : '';
  }, [accounts, accountId]);

  return (
    <div className="nostra__transaction-history">
      <div className="nostra__transaction-history__header">
        <div className="nostra__transaction-history__header-back">
          <ButtonWrapper onClick={onBack}>
            <Icon variant="back" size="large" />
            <Typography variant="body-primary" weight="medium">
              {t('TransactionHistory.goBack')}
            </Typography>
          </ButtonWrapper>
        </div>
        <div className="nostra__transaction-history__header-actions">
          {accountAddress && (
            <ButtonSecondary url={`${process.env.REACT_APP_GET_CSV_ENDPOINT_URL}/${accountAddress}`} download>
              <Icon variant="download" size="small" />
              <Typography variant="body-secondary" color="text-light">
                {t('TransactionHistory.exportCSV')}
              </Typography>
            </ButtonSecondary>
          )}
        </div>
      </div>
      <Typography variant="subheader" weight="medium">
        {t('TransactionHistory.title')}
      </Typography>
      <Typography variant="body-primary" weight="medium">
        {accountName}
      </Typography>
      <div className="nostra__transaction-history__search">
        <SearchInput value={keywords} onChange={onKeywordsChanged} />
        <div className="nostra__transaction-history__search-right">
          <DateRangePicker from={searchFromDate} to={searchToDate} onChange={onSearchDateChange} />
          <TransactionHistoryDropdown label={t('TransactionHistory.labelRowsPerPage')} value={rowsPerPage}>
            {TRANSACTION_HISTORY_PAGE_SIZES.map(size => (
              <TransactionHistoryDropdownItem
                key={`item-${size}`}
                label={`${size}`}
                value={size}
                onSelect={onRowsPerPageChanged}
              />
            ))}
          </TransactionHistoryDropdown>
          <Typography
            className="nostra__transaction-history__search__page-num"
            variant="body-secondary"
            color="text-light"
          >
            {t('TransactionHistory.textShowingXtoYofZ', {
              from: transactionHistory ? itemSkip + 1 : 0,
              to: transactionHistory ? Math.min(itemSkip + rowsPerPage, transactionHistory?.transactionCount) : 0,
              total: transactionHistory?.transactionCount ?? 0,
            })}
          </Typography>
          <div className="nostra__transaction-history__search__nav">
            <ButtonWrapper disabled={isFirstPage} onClick={goPrevPage}>
              <Icon variant="left-chevron" size="small" color={isFirstPage ? colors.textLight : undefined} />
            </ButtonWrapper>
            <ButtonWrapper disabled={isLastPage} onClick={goNextPage}>
              <Icon variant="right-chevron" size="small" color={isLastPage ? colors.textLight : undefined} />
            </ButtonWrapper>
          </div>
        </div>
      </div>
      {transactionHistory && (
        <div className="nostra__transaction-history__table">
          <Table>
            <TableHead>
              <TableRow id="header">
                <TableHeadCell label={t('TransactionHistory.headerLabelDate')} />
                <TableHeadCell label={t('TransactionHistory.headerLabelTransaction')} />
                <TableHeadCell label={t('TransactionHistory.headerLabelValue')} align="right" />
                <TableHeadCell label={t('TransactionHistory.headerLabelToken')} align="center" />
                <TableHeadCell label={t('TransactionHistory.headerLabelCollateral')} align="center" />
                <TableHeadCell label={t('TransactionHistory.headerLabelLending')} align="center" />
                <TableHeadCell label={t('TransactionHistory.headerLabelTransactionId')} align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {transactionHistory.transactions.map(transaction => (
                <TransactionHistoryTableRow key={transaction.hash} transaction={transaction} keywords={keywords} />
              ))}
            </TableBody>
          </Table>
          <TablePagination
            page={currentPage}
            pageSize={rowsPerPage}
            totalItems={transactionHistory.transactionCount}
            onPageChange={onPageChange}
          />
        </div>
      )}
    </div>
  );
};

export default TransactionHistory;
