import { ChangeEvent, FC, useCallback, useMemo, useState, useEffect, ReactNode, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import { Trans, useTranslation } from 'react-i18next';
import { Collapse } from 'react-collapse';
import { Asset, Nullable } from '../../../../interfaces';
import { Decimal, ZERO } from '../../../../datastructures';
import { depositLimits } from '../../../../config';
import { GAS_FEE_UI_DECIMALS } from '../../../../constants';
import {
  useWalletAccount,
  useActiveAccountId,
  useAssetVariant,
  useDeposit,
  useAccountsHealthFactors,
  useNewDepositHealthFactor,
  useTokenRates,
  useNostraTokenBalance,
  useTokenBalances,
  AssetVariant,
  usePerTokenAccountTotalSupply,
  usePerTokenAccountTotalIdle,
} from '../../../../hooks';
import { calculateNewLendingRate, DecimalUtils, getNostraTokenFromFlags } from '../../../../utils';
import {
  ActionButton,
  ActionButtonLabels,
  ActionButtonState,
  CurrencyInput,
  Link,
  LoadingPlaceholder,
  LoadingToggleSwitchPlaceholder,
  ShadowContainer,
  ToggleSwitch,
  TooltipWrapper,
  Typography,
} from '../../../shared';
import AssetTierTooltip from '../../../AssetTierTooltip';
import ActionModalHealthFactorData from '../../ActionModalHealthFactorData';
import ActionModalSuccessInfo, { ActionModalSuccessRow } from '../../ActionModalSuccess';
import Wallet from '../../../Wallet';
import DepositInfoTooltipContent from './DepositInfoTooltipContent';

import './Deposit.scss';

interface DepositProps {
  asset: Nullable<Asset>;
  onModalClose?: () => void;
}

const Deposit: FC<DepositProps> = props => {
  const { asset, onModalClose } = props;

  const { t } = useTranslation();

  const walletAccount = useWalletAccount();
  const activeAccountId = useActiveAccountId();
  const { depositStatus, deposit } = useDeposit();
  const assetBalance = useTokenBalances();
  const perTokenAccountTotalSupply = usePerTokenAccountTotalSupply();
  const perTokenAccountTotalIdle = usePerTokenAccountTotalIdle();
  const nostraTokenBalances = useNostraTokenBalance();
  const tokenRates = useTokenRates();
  const accountHealthFactor = useAccountsHealthFactors();
  const calculateNewDepositHealthFactor = useNewDepositHealthFactor();
  const assetVariantMap = useAssetVariant();

  const [amount, setAmount] = useState<string>('');
  const [state, setState] = useState<ActionButtonState>('default');

  // We want to store copy of balance when deposit is started,
  // we need this info to calculate outstanding balance and show it in success modal
  const activeNostraTokenBalanceStore = useRef<Nullable<Decimal>>(null);

  const healthFactor = useMemo(() => {
    const dataKey = `${walletAccount?.address}-${activeAccountId}`;

    if (!accountHealthFactor[dataKey]) {
      return null;
    }

    return accountHealthFactor[dataKey].value;
  }, [walletAccount?.address, activeAccountId, accountHealthFactor]);

  const pendingAmount = useMemo(
    () => (depositStatus?.pending ? depositStatus?.request.amount : undefined),
    [depositStatus],
  );
  const decimalAmount = useMemo(() => pendingAmount ?? Decimal.parse(amount, ZERO), [amount, pendingAmount]);

  const newHealthFactor = useMemo(() => {
    if (!walletAccount || activeAccountId === null || !asset) {
      return null;
    }

    return decimalAmount.isZero() || !calculateNewDepositHealthFactor
      ? healthFactor
      : calculateNewDepositHealthFactor(walletAccount.address, activeAccountId, decimalAmount, asset);
  }, [activeAccountId, decimalAmount, calculateNewDepositHealthFactor, walletAccount, healthFactor, asset]);

  const formattedApy = useMemo(() => {
    const lendingApy = asset ? calculateNewLendingRate(decimalAmount, asset) : null;
    return lendingApy ? DecimalUtils.format(lendingApy, { style: 'percentage', fractionDigits: 2, pad: true }) : null;
  }, [decimalAmount, asset]);

  const formattedUtilizationRate = useMemo(
    () =>
      asset?.utilizationRate
        ? DecimalUtils.format(asset.utilizationRate, { style: 'percentage', fractionDigits: 2, pad: true })
        : null,
    [asset?.utilizationRate],
  );

  const formattedCollateralFactor = useMemo(
    () =>
      asset?.collateralFactor
        ? DecimalUtils.format(asset.collateralFactor, { style: 'percentage', fractionDigits: 2, pad: true })
        : null,
    [asset?.collateralFactor],
  );

  const formattedAvailableSupply = useMemo(
    () =>
      asset?.availableForBorrowing
        ? DecimalUtils.format(asset.availableForBorrowing, {
            style: 'multiplier',
            fractionDigits: 2,
            noMultiplierFractionDigits: asset.uiTokenPrecision,
            currency: asset.token,
            lessThanFormat: true,
          })
        : null,
    [asset?.availableForBorrowing, asset?.token, asset?.uiTokenPrecision],
  );

  const nostraInterestBearingCollateralDataKey = useMemo(() => {
    return `${walletAccount?.address}-${activeAccountId}-${asset?.nostraInterestBearingCollateralTokenAddress}`;
  }, [walletAccount?.address, activeAccountId, asset?.nostraInterestBearingCollateralTokenAddress]);

  const nostraInterestBearingDataKey = useMemo(() => {
    return `${walletAccount?.address}-${activeAccountId}-${asset?.nostraInterestBearingTokenAddress}`;
  }, [walletAccount?.address, activeAccountId, asset?.nostraInterestBearingTokenAddress]);

  const nostraCollateralDataKey = useMemo(() => {
    return `${walletAccount?.address}-${activeAccountId}-${asset?.nostraCollateralTokenAddress}`;
  }, [walletAccount?.address, activeAccountId, asset?.nostraCollateralTokenAddress]);

  const nostraDataKey = useMemo(() => {
    return `${walletAccount?.address}-${activeAccountId}-${asset?.nostraTokenAddress}`;
  }, [walletAccount?.address, activeAccountId, asset?.nostraTokenAddress]);

  const tokenBalance = asset ? assetBalance[asset.address] : null;

  const nostraInterestBearingCollateralBalance = nostraTokenBalances[nostraInterestBearingCollateralDataKey];
  const nostraInterestBearingTokenBalance = nostraTokenBalances[nostraInterestBearingDataKey];
  const nostraCollateralTokenBalance = nostraTokenBalances[nostraCollateralDataKey];
  const nostraTokenBalance = nostraTokenBalances[nostraDataKey];

  const previouslyDeposited = useMemo(() => {
    if (
      !nostraInterestBearingTokenBalance ||
      !nostraInterestBearingCollateralBalance ||
      !nostraTokenBalance ||
      !nostraCollateralTokenBalance
    ) {
      return null;
    }

    return (
      nostraInterestBearingTokenBalance.gt(ZERO) ||
      nostraInterestBearingCollateralBalance.gt(ZERO) ||
      nostraTokenBalance.gt(ZERO) ||
      nostraCollateralTokenBalance.gt(ZERO)
    );
  }, [
    nostraCollateralTokenBalance,
    nostraTokenBalance,
    nostraInterestBearingCollateralBalance,
    nostraInterestBearingTokenBalance,
  ]);

  const assetVariant = useMemo((): AssetVariant | null => {
    return assetVariantMap[`${walletAccount?.address}-${activeAccountId}-${asset?.address}`] || null;
  }, [walletAccount?.address, activeAccountId, asset?.address, assetVariantMap]);

  const canBeUsedAsCollateral = asset ? asset.assetTier === 'shared' || asset.assetTier === 'nominal' : null;

  const [enableLending, setEnableLending] = useState<boolean | null>(() => {
    if (!asset || previouslyDeposited === null || assetVariant === null || assetVariant.isLending === null) {
      return null;
    }

    return previouslyDeposited ? assetVariant.isLending : true;
  });

  const [enableCollateral, setEnableCollateral] = useState<boolean | null>(() => {
    if (
      !asset ||
      previouslyDeposited === null ||
      assetVariant === null ||
      assetVariant.isCollateral === null ||
      canBeUsedAsCollateral === null
    ) {
      return null;
    }

    return previouslyDeposited ? assetVariant.isCollateral : canBeUsedAsCollateral;
  });

  const activeNostraToken = useMemo(() => {
    if (!asset || enableLending === null || enableCollateral === null) {
      return null;
    }

    if (depositStatus) {
      return getNostraTokenFromFlags(asset, {
        interestBearing: depositStatus.request.isInterestBearing,
        collateral: depositStatus.request.isCollateral,
      });
    }

    return getNostraTokenFromFlags(asset, { interestBearing: enableLending, collateral: enableCollateral });
  }, [asset, depositStatus, enableLending, enableCollateral]);

  /**
   * Each account can hold only one type of nostra token
   */
  const activeNostraTokenBalance = useMemo(() => {
    if (!nostraTokenBalances || !activeNostraToken?.address) {
      return null;
    }

    const activeNostraTokenDataKey = `${walletAccount?.address}-${activeAccountId}-${activeNostraToken.address}`;
    return nostraTokenBalances[activeNostraTokenDataKey];
  }, [nostraTokenBalances, activeNostraToken?.address, walletAccount?.address, activeAccountId]);

  const totalSupply = useMemo(() => {
    if (!asset || enableLending === null || enableCollateral === null) {
      return null;
    }

    if (enableLending) {
      return enableCollateral ? asset.nostraInterestBearingCollateralSupply : asset.nostraInterestBearingSupply;
    }

    return enableCollateral ? asset.nostraCollateralSupply : asset.nostraSupply;
  }, [asset, enableCollateral, enableLending]);

  const totalSupplyCap = useMemo(() => {
    if (!asset || enableLending === null || enableCollateral === null) {
      return null;
    }

    if (enableLending) {
      return enableCollateral ? asset.nostraInterestBearingCollateralSupplyCap : asset.nostraInterestBearingSupplyCap;
    }

    return enableCollateral ? asset.nostraCollateralSupplyCap : asset.nostraSupplyCap;
  }, [asset, enableCollateral, enableLending]);

  const maxAmountToDeposit = useMemo(() => {
    if (!walletAccount || !asset) {
      return null;
    }

    const accountTokenSupply = perTokenAccountTotalSupply?.[walletAccount.address]?.[asset.token];
    const accountTokenIdle = perTokenAccountTotalIdle?.[walletAccount.address]?.[asset.token];

    if (!totalSupply || !totalSupplyCap || !accountTokenSupply || !accountTokenIdle) {
      return null;
    }

    if (totalSupply.gte(totalSupplyCap)) {
      return ZERO;
    }

    const cappedMaxDeposit = Decimal.min(tokenBalance ?? ZERO, totalSupplyCap.sub(totalSupply));
    const depositedAmount = accountTokenSupply.add(accountTokenIdle);
    const tokenDepositLimit = depositLimits[asset.token];

    return Decimal.max(Decimal.min(cappedMaxDeposit, tokenDepositLimit.sub(depositedAmount)), ZERO);
  }, [
    walletAccount,
    asset,
    perTokenAccountTotalIdle,
    perTokenAccountTotalSupply,
    tokenBalance,
    totalSupply,
    totalSupplyCap,
  ]);

  useEffect(() => {
    if (depositStatus?.pending) {
      setEnableCollateral(depositStatus?.request.isCollateral);
      setEnableLending(depositStatus?.request.isInterestBearing);
    } else if (previouslyDeposited !== null && assetVariant !== null && canBeUsedAsCollateral !== null) {
      setEnableCollateral(!previouslyDeposited ? canBeUsedAsCollateral : assetVariant?.isCollateral ?? true);
      setEnableLending(!previouslyDeposited ? true : assetVariant?.isLending ?? true);
    }
  }, [assetVariant, canBeUsedAsCollateral, previouslyDeposited, depositStatus]);

  const amountDepositedFormatted = useMemo(() => {
    if (!depositStatus?.transactionData || !asset) {
      return '';
    }

    return DecimalUtils.format(depositStatus.transactionData?.depositedAmount, {
      style: 'multiplier',
      fractionDigits: 2,
      noMultiplierFractionDigits: asset.uiTokenPrecision,
      currency: asset.token,
      lessThanFormat: true,
    });
  }, [asset, depositStatus?.transactionData]);

  const depositedBalanceFormatted = useMemo(() => {
    if (!activeNostraTokenBalanceStore.current || !depositStatus?.transactionData || !asset) {
      return '';
    }

    return DecimalUtils.format(
      Decimal.max(activeNostraTokenBalanceStore.current.add(depositStatus.transactionData.depositedAmount), ZERO),
      {
        style: 'multiplier',
        fractionDigits: 2,
        noMultiplierFractionDigits: asset.uiTokenPrecision,
        currency: asset.token,
        lessThanFormat: true,
      },
    );
  }, [depositStatus?.transactionData, asset]);

  const gasFeeFormatted = useMemo(() => {
    if (!depositStatus?.transactionData) {
      return '';
    }

    return DecimalUtils.format(depositStatus.transactionData.gasFee, {
      style: 'currency',
      fractionDigits: GAS_FEE_UI_DECIMALS,
      currency: 'ETH',
      lessThanFormat: true,
    });
  }, [depositStatus?.transactionData]);

  /**
   * Update action button state based on current deposit request status
   */
  useEffect(() => {
    if (!depositStatus) {
      return;
    }

    if (depositStatus.pending) {
      setState('loading');
      setAmount(depositStatus.request.amount.toString());
    } else if (depositStatus.success) {
      setState('success');
    } else {
      setState('default');
    }
  }, [depositStatus]);

  /**
   * Called when user wants to deposit tokens
   */
  const handleDeposit = useCallback(() => {
    // Do not allow user to start deposit process before connecting wallet
    if (
      !walletAccount ||
      !activeNostraTokenBalance ||
      activeAccountId === null ||
      !asset ||
      enableLending === null ||
      enableCollateral === null
    ) {
      return;
    }

    activeNostraTokenBalanceStore.current = activeNostraTokenBalance;

    const depositTxnId = uuid();

    deposit({
      amount: decimalAmount,
      asset,
      onBehalfOf: walletAccount.address,
      accountId: activeAccountId,
      isInterestBearing: enableLending,
      isCollateral: enableCollateral,
      txnId: depositTxnId,
    });

    setState('loading');
  }, [
    walletAccount,
    activeNostraTokenBalance,
    deposit,
    decimalAmount,
    asset,
    activeAccountId,
    enableLending,
    enableCollateral,
  ]);

  const handleConnectWallet = useCallback(() => {
    onModalClose?.();
  }, [onModalClose]);

  const handleAmountChange = useCallback((newAmount: string) => {
    setAmount(newAmount);
  }, []);

  const actionButtonLabels: ActionButtonLabels = {
    default: t('Deposit.actionDeposit'),
    loading: t('Deposit.actionExecuting'),
    success: t('Deposit.actionSuccess'),
  };

  const handleLendingToggle = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setEnableLending(event.target.checked);
  }, []);

  const handleCollateralToggle = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setEnableCollateral(event.target.checked);
  }, []);

  const notEnoughBalance = decimalAmount.gt(maxAmountToDeposit ?? ZERO);

  const error = useMemo(() => {
    if (!walletAccount || !amount || !asset) {
      return undefined;
    }

    if (totalSupply && totalSupplyCap && totalSupply.add(decimalAmount).gte(totalSupplyCap)) {
      return (
        <Trans t={t} i18nKey="Deposit.errorSupplyCapReached">
          <Link href="https://docs.nostra.finance/extras/glossary" />
        </Trans>
      );
    }

    if (tokenBalance && decimalAmount.gt(tokenBalance)) {
      return t('Deposit.errorInsufficientBalance');
    }

    if (decimalAmount.gt(depositLimits[asset.token])) {
      return t('Deposit.errorDepositLimitReached');
    }

    return undefined;
  }, [walletAccount, amount, asset, decimalAmount, t, tokenBalance, totalSupply, totalSupplyCap]);

  const collateralToggleTooltip = useMemo((): ReactNode | null => {
    if (!asset || previouslyDeposited === null) {
      return null;
    }

    if (previouslyDeposited && canBeUsedAsCollateral) {
      return (
        <DepositInfoTooltipContent
          title={t('Deposit.tooltipCollateralLockedTitle')}
          message={t('Deposit.tooltipCollateralLockedMessage')}
        />
      );
    }

    if (!canBeUsedAsCollateral && (asset.assetTier === 'isolated' || asset.assetTier === 'cross')) {
      return <AssetTierTooltip assetTier={asset.assetTier} />;
    }

    return null;
  }, [asset, canBeUsedAsCollateral, previouslyDeposited, t]);

  if (asset && activeNostraToken && depositStatus?.success) {
    return (
      <ActionModalSuccessInfo
        onClose={onModalClose}
        tokenToAdd={activeNostraToken}
        txHash={depositStatus.getTransactionResponse?.transaction_hash}
      >
        <ActionModalSuccessRow
          label={t('ActionModalSuccessInfo.labelDepositAmount')}
          value={`${amountDepositedFormatted} (${activeNostraToken?.symbol})`}
        />
        <ActionModalSuccessRow
          label={t('ActionModalSuccessInfo.labelTotalDeposited')}
          value={`${depositedBalanceFormatted} (${activeNostraToken?.symbol})`}
        />
        <ActionModalSuccessRow
          label={t('ActionModalSuccessInfo.labelLending')}
          value={
            depositStatus.request.isInterestBearing
              ? t('ActionModalSuccessInfo.labelEnabled')
              : t('ActionModalSuccessInfo.labelDisabled')
          }
        />
        <ActionModalSuccessRow
          label={t('ActionModalSuccessInfo.labelCollateral')}
          value={
            depositStatus.request.isCollateral
              ? t('ActionModalSuccessInfo.labelEnabled')
              : t('ActionModalSuccessInfo.labelDisabled')
          }
        />
        <ActionModalSuccessRow label={t('ActionModalSuccessInfo.labelNetworkFee')} value={gasFeeFormatted} />
      </ActionModalSuccessInfo>
    );
  }

  return (
    <>
      {/* Input field */}
      <div className="nostra__deposit-modal__input">
        <CurrencyInput
          asset={asset}
          amount={pendingAmount ? pendingAmount.toString() : amount}
          showAmount={!!walletAccount}
          maxAmount={maxAmountToDeposit}
          maxAmountLabel={t('Deposit.labelBalance')}
          showWalletIcon={false}
          tokenRate={asset?.address ? tokenRates[asset.address] : null}
          disabled={state === 'loading'}
          error={error}
          autoFocus
          onUpdate={handleAmountChange}
        />
      </div>

      {/* Lending */}
      <div className="nostra__deposit-modal__lending">
        <div className="nostra__deposit-modal__lending__title">
          {enableLending === null && (
            <>
              <LoadingToggleSwitchPlaceholder />
              <Typography variant="body-tertiary">{t('Deposit.labelEnableLending')}</Typography>
            </>
          )}
          {enableLending !== null && (
            <TooltipWrapper
              tooltipContent={
                previouslyDeposited && (
                  <DepositInfoTooltipContent
                    title={t('Deposit.tooltipLendingLockedTitle')}
                    message={t('Deposit.tooltipLendingLockedMessage')}
                  />
                )
              }
              placement="left"
              openEvent="mouseenter"
            >
              <ToggleSwitch
                checked={enableLending}
                disabled={state === 'loading'}
                locked={previouslyDeposited ?? false}
                label={t('Deposit.labelEnableLending')}
                onChange={handleLendingToggle}
              />
            </TooltipWrapper>
          )}
        </div>
        <Collapse isOpened={enableLending ?? true}>
          <ShadowContainer className="nostra__action-modal__data-container">
            <div className="nostra__action-modal__data-row">
              <div className="nostra__action-modal__data-token-label">
                <Typography variant="body-tertiary">{t('Deposit.labelApy')}</Typography>
              </div>
              {formattedApy ? (
                <Typography variant="body-tertiary">{formattedApy}</Typography>
              ) : (
                <LoadingPlaceholder shape={{ width: 'large', height: 'small' }} />
              )}
            </div>
            <div className="nostra__action-modal__data-row">
              <Typography variant="body-tertiary">{t('Deposit.labelUtilizationRate')}</Typography>
              {formattedUtilizationRate ? (
                <Typography variant="body-tertiary">{formattedUtilizationRate}</Typography>
              ) : (
                <LoadingPlaceholder shape={{ width: 'small', height: 'small' }} />
              )}
            </div>
          </ShadowContainer>
        </Collapse>
      </div>

      {/* Collateral */}
      <div className="nostra__deposit-modal__collateral">
        <div className="nostra__deposit-modal__collateral__title">
          {(enableCollateral === null || previouslyDeposited === null) && (
            <>
              <LoadingToggleSwitchPlaceholder />
              <Typography variant="body-tertiary">{t('Deposit.labelEnableCollateral')}</Typography>
            </>
          )}
          {enableCollateral !== null && previouslyDeposited !== null && (
            <TooltipWrapper tooltipContent={collateralToggleTooltip} placement="left" openEvent="mouseenter">
              <ToggleSwitch
                checked={enableCollateral}
                disabled={state === 'loading'}
                locked={!canBeUsedAsCollateral || previouslyDeposited}
                label={t('Deposit.labelEnableCollateral')}
                onChange={handleCollateralToggle}
              />
            </TooltipWrapper>
          )}
        </div>
        <Collapse isOpened={enableCollateral ?? true}>
          <ShadowContainer className="nostra__action-modal__data-container">
            <div className="nostra__action-modal__data-row">
              <Typography variant="body-tertiary">{t('Deposit.labelCollateralFactor')}</Typography>
              {formattedCollateralFactor ? (
                <Typography variant="body-tertiary">{formattedCollateralFactor}</Typography>
              ) : (
                <LoadingPlaceholder shape={{ width: 'large', height: 'small' }} />
              )}
            </div>
            <div className="nostra__action-modal__data-row">
              <Typography variant="body-tertiary">{t('Deposit.labelAvailableSupply')}</Typography>
              {formattedAvailableSupply ? (
                <Typography variant="body-tertiary">{formattedAvailableSupply}</Typography>
              ) : (
                <LoadingPlaceholder shape={{ width: 'small', height: 'small' }} />
              )}
            </div>
            <ActionModalHealthFactorData
              amount={decimalAmount}
              healthFactor={healthFactor}
              newHealthFactor={newHealthFactor}
            />
          </ShadowContainer>
        </Collapse>
      </div>

      <div className="nostra__action-modal__info-message">
        <Typography variant="body-secondary" color="text-error">
          New deposits are disabled for the alpha version. Click <Link href="https://app.nostra.finance">here</Link> to
          deposit into the latest version of Nostra.
        </Typography>
      </div>

      {/* Action button */}
      {walletAccount || !asset ? (
        <ActionButton
          size="large"
          variant="primary"
          labels={actionButtonLabels}
          onClick={handleDeposit}
          state="disabled"
          fullWidth
        />
      ) : (
        <Wallet
          connectWalletButtonLabel={t('Wallet.btnConnectWalletLong')}
          connectWalletButtonVariant="primary"
          connectWalletButtonFullWidth
          redirectTo={`/asset/${asset.token}`}
          onConnectWalletClick={handleConnectWallet}
        />
      )}
    </>
  );
};
export default Deposit;
