import { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useTotalAssets,
  useTotalNostraCollateralSupply,
  useTotalNostraInterestBearingCollateralSupply,
  useTotalNostraInterestBearingSupply,
  useTotalNostraSupply,
} from '../../../hooks';
import { DecimalUtils } from '../../../utils';
import { colors, Icon, LoadingPlaceholder, Typography } from '../../shared';

const EVEN_RATIO = 100 / 4;

const SummaryMarketSupplyBreakdownTooltip: FC = () => {
  const { t } = useTranslation();

  const totalAssets = useTotalAssets();
  const totalNostraInterestBearingCollateralSupply = useTotalNostraInterestBearingCollateralSupply();
  const totalNostraInterestBearingSupply = useTotalNostraInterestBearingSupply();
  const totalNostraCollateralSupply = useTotalNostraCollateralSupply();
  const totalNostraSupply = useTotalNostraSupply();

  const portionNostraInterestBearingCollateralSupply = useMemo(() => {
    if (!totalNostraInterestBearingCollateralSupply || !totalAssets) {
      return null;
    }

    if (totalAssets.isZero()) {
      return EVEN_RATIO;
    }

    return totalNostraInterestBearingCollateralSupply.mul(100).div(totalAssets).toTruncated(2);
  }, [totalNostraInterestBearingCollateralSupply, totalAssets]);
  const portionNostraCollateralSupply = useMemo(() => {
    if (!totalNostraCollateralSupply || !totalAssets) {
      return null;
    }

    if (totalAssets.isZero()) {
      return EVEN_RATIO;
    }

    return totalNostraCollateralSupply.mul(100).div(totalAssets).toTruncated(2);
  }, [totalNostraCollateralSupply, totalAssets]);
  const portionNostraInterestBearingSupply = useMemo(() => {
    if (!totalNostraInterestBearingSupply || !totalAssets) {
      return null;
    }

    if (totalAssets.isZero()) {
      return EVEN_RATIO;
    }

    return totalNostraInterestBearingSupply.mul(100).div(totalAssets).toTruncated(2);
  }, [totalNostraInterestBearingSupply, totalAssets]);
  const portionNostraSupply = useMemo(() => {
    if (!totalNostraSupply || !totalAssets) {
      return null;
    }

    if (totalAssets.isZero()) {
      return EVEN_RATIO;
    }

    return totalNostraSupply.mul(100).div(totalAssets).toTruncated(2);
  }, [totalNostraSupply, totalAssets]);

  const formattedTotalNostraInterestBearingCollateralSupply = useMemo(
    () =>
      totalNostraInterestBearingCollateralSupply
        ? DecimalUtils.format(totalNostraInterestBearingCollateralSupply, {
            style: 'multiplier',
            fractionDigits: 2,
            currency: '$',
            lessThanFormat: true,
          })
        : null,
    [totalNostraInterestBearingCollateralSupply],
  );
  const formattedTotalNostraCollateralSupply = useMemo(
    () =>
      totalNostraCollateralSupply
        ? DecimalUtils.format(totalNostraCollateralSupply, {
            style: 'multiplier',
            fractionDigits: 2,
            currency: '$',
            lessThanFormat: true,
          })
        : null,
    [totalNostraCollateralSupply],
  );
  const formattedTotalNostraInterestBearingSupply = useMemo(
    () =>
      totalNostraInterestBearingSupply
        ? DecimalUtils.format(totalNostraInterestBearingSupply, {
            style: 'multiplier',
            fractionDigits: 2,
            currency: '$',
            lessThanFormat: true,
          })
        : null,
    [totalNostraInterestBearingSupply],
  );
  const formattedTotalNostraSupply = useMemo(
    () =>
      totalNostraSupply
        ? DecimalUtils.format(totalNostraSupply, {
            style: 'multiplier',
            fractionDigits: 2,
            currency: '$',
            lessThanFormat: true,
          })
        : null,
    [totalNostraSupply],
  );
  const formattedTotalAssets = useMemo(
    () =>
      totalAssets
        ? DecimalUtils.format(totalAssets, {
            style: 'multiplier',
            fractionDigits: 2,
            currency: '$',
            lessThanFormat: true,
          })
        : null,
    [totalAssets],
  );

  return (
    <div className="nostra__summary__market-supply-tooltip">
      <div className="nostra__summary__market-supply-tooltip__title">
        <Icon variant="info-bordered-rounded" color={colors.textHighlight} size={20} />
        <Typography variant="body-primary" weight="bold">
          {t('SummaryMarketSupplyBreakdownTooltip.title')}
        </Typography>
      </div>
      <div className="nostra__summary__market-supply-tooltip__description">
        <Typography variant="body-primary">{t('SummaryMarketSupplyBreakdownTooltip.desc')}</Typography>
      </div>
      <div className="nostra__summary__market-supply-tooltip__chart">
        {portionNostraInterestBearingCollateralSupply &&
        portionNostraCollateralSupply &&
        portionNostraInterestBearingSupply &&
        portionNostraSupply ? (
          <>
            <div
              className="nostra__summary__market-supply-tooltip__chart-section collateral-lending"
              style={{ width: `${portionNostraInterestBearingCollateralSupply}%` }}
            ></div>
            <div
              className="nostra__summary__market-supply-tooltip__chart-section collateral"
              style={{ width: `${portionNostraCollateralSupply}%` }}
            ></div>
            <div
              className="nostra__summary__market-supply-tooltip__chart-section lending"
              style={{ width: `${portionNostraInterestBearingSupply}%` }}
            ></div>
            <div
              className="nostra__summary__market-supply-tooltip__chart-section idle"
              style={{ width: `${portionNostraSupply}%` }}
            ></div>
          </>
        ) : (
          <LoadingPlaceholder shape={{ width: 'large', height: 'medium' }} />
        )}
      </div>
      <div className="nostra__summary__market-supply-tooltip__assets">
        <div className="nostra__summary__market-supply-tooltip__asset collateral-lending">
          <Typography variant="body-secondary">
            {t('SummaryMarketSupplyBreakdownTooltip.labelCollateralLending')}
          </Typography>
          {formattedTotalNostraInterestBearingCollateralSupply ? (
            <Typography variant="body-secondary" weight="medium">
              {formattedTotalNostraInterestBearingCollateralSupply}
            </Typography>
          ) : (
            <LoadingPlaceholder shape={{ width: 'small', height: 'small' }} />
          )}
        </div>
        <div className="nostra__summary__market-supply-tooltip__asset collateral">
          <Typography variant="body-secondary">{t('SummaryMarketSupplyBreakdownTooltip.labelCollateral')}</Typography>
          {formattedTotalNostraCollateralSupply ? (
            <Typography variant="body-secondary" weight="medium">
              {formattedTotalNostraCollateralSupply}
            </Typography>
          ) : (
            <LoadingPlaceholder shape={{ width: 'small', height: 'small' }} />
          )}
        </div>
        <div className="nostra__summary__market-supply-tooltip__asset lending">
          <Typography variant="body-secondary">{t('SummaryMarketSupplyBreakdownTooltip.labelLending')}</Typography>
          {formattedTotalNostraInterestBearingSupply ? (
            <Typography variant="body-secondary" weight="medium">
              {formattedTotalNostraInterestBearingSupply}
            </Typography>
          ) : (
            <LoadingPlaceholder shape={{ width: 'small', height: 'small' }} />
          )}
        </div>
        <div className="nostra__summary__market-supply-tooltip__asset idle">
          <Typography variant="body-secondary">{t('SummaryMarketSupplyBreakdownTooltip.labelIdle')}</Typography>
          {formattedTotalNostraSupply ? (
            <Typography variant="body-secondary" weight="medium">
              {formattedTotalNostraSupply}
            </Typography>
          ) : (
            <LoadingPlaceholder shape={{ width: 'small', height: 'small' }} />
          )}
        </div>
      </div>
      <div className="nostra__summary__market-supply-tooltip__total">
        <Typography variant="body-secondary">{t('SummaryMarketSupplyBreakdownTooltip.labelTotal')}</Typography>
        {formattedTotalAssets ? (
          <Typography variant="body-secondary" weight="medium">
            {formattedTotalAssets}
          </Typography>
        ) : (
          <LoadingPlaceholder shape={{ width: 'small', height: 'small' }} />
        )}
      </div>
    </div>
  );
};

export default memo(SummaryMarketSupplyBreakdownTooltip);
