import { FC } from 'react';
import { InnerIconProps } from './IconProps';
import withIcon from './withIcon';

const Medium: FC<InnerIconProps> = ({ size, color }) => (
  <svg
    className="nostra__icon nostra__icon-medium"
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.025 8C9.025 10.7611 7.005 13 4.512 13C3.92026 13.0009 3.33417 12.8722 2.78717 12.6214C2.24018 12.3706 1.743 12.0026 1.32402 11.5383C0.90504 11.074 0.572469 10.5226 0.345294 9.91546C0.118118 9.30836 0.000787407 8.65749 0 8C0 5.23778 2.02 3.00001 4.512 3.00001C5.10382 2.99898 5.69003 3.12751 6.23714 3.37824C6.78425 3.62898 7.28154 3.99701 7.70063 4.46131C8.11971 4.92562 8.45237 5.47711 8.67961 6.08428C8.90685 6.69146 9.02421 7.34242 9.025 8ZM13.975 8C13.975 10.6 12.965 12.7067 11.719 12.7067C10.473 12.7067 9.463 10.5989 9.463 8C9.463 5.4 10.473 3.29334 11.719 3.29334C12.965 3.29334 13.975 5.40111 13.975 8ZM16 8C16 10.3289 15.645 12.2167 15.206 12.2167C14.768 12.2167 14.413 10.3278 14.413 8C14.413 5.67111 14.768 3.78334 15.207 3.78334C15.645 3.78334 16 5.67111 16 8Z"
      fill={color}
    />
  </svg>
);

export default withIcon(Medium);
