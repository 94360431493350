import { AccountInterface } from 'starknet';
import { LentDebtTokenContract } from './contracts';
import { getConfigManager } from '../config/getConfigManager';
import { DEFAULT_DECIMAL_PRECISION } from '../datastructures';
import { ContractCache } from '../utils';

const contractCache = new ContractCache((address, providerOrAccount) => {
  const asset = getConfigManager().getAssetByAddress(address);
  return new LentDebtTokenContract(asset?.tokenPrecision ?? DEFAULT_DECIMAL_PRECISION, address, providerOrAccount);
});

export const getLentDebtTokenContract = (address: string, account?: AccountInterface) =>
  contractCache.get(address, account);
