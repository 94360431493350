import { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Decimal, ZERO } from '../../../datastructures';
import { useAssets, useStatisticsData, useTotalAssets } from '../../../hooks';
import { DecimalUtils, weightedAverage } from '../../../utils';
import { TooltipWrapper } from '../../shared';
import { SummaryBoxMainValue, SummaryBoxSideValue } from '../SummaryBox';
import SummaryBox from '../SummaryBox/SummaryBox';
import SummaryMarketSupplyBreakdownTooltip from './SummaryMarketSupplyBreakdownTooltip';

import './SummaryMarketSupply.scss';

const SummaryMarketSupply: FC = () => {
  const { t } = useTranslation();

  const assets = useAssets();
  const totalAssets = useTotalAssets();
  const statisticsData = useStatisticsData();

  const formattedWeighedAverageApy = useMemo(() => {
    const values = assets.map(asset => asset.supplyApy);
    const weights = assets.map(asset => {
      if (!asset.totalSupply || !totalAssets) {
        return null;
      }

      if (asset.totalSupply.equals(ZERO) || totalAssets.equals(ZERO)) {
        return ZERO;
      }

      return asset.totalSupply.div(totalAssets);
    });

    if (values.some(value => value === null) || weights.some(weight => weight === null)) {
      return null;
    }

    const nonNullValues = values.filter((value): value is Decimal => value !== null);
    const nonNullWeights = weights.filter((weight): weight is Decimal => weight !== null);

    return DecimalUtils.format(weightedAverage(nonNullValues, nonNullWeights), {
      style: 'percentage',
      fractionDigits: 2,
    });
  }, [assets, totalAssets]);

  const formattedTotalAssets = useMemo(
    () =>
      totalAssets !== null
        ? DecimalUtils.format(totalAssets, {
            style: 'multiplier',
            fractionDigits: 2,
            currency: '$',
            lessThanFormat: true,
          })
        : null,
    [totalAssets],
  );

  const formattedLenders = useMemo(
    () => (statisticsData !== null ? DecimalUtils.format(statisticsData.lendersCount, { style: 'decimal' }) : null),
    [statisticsData],
  );

  return (
    <SummaryBox title={t('SummaryMarketSupply.title')}>
      <div className="nostra__summary__market-supply">
        <SummaryBoxMainValue title={t('SummaryMarketSupply.labelAverageApy')} value={formattedWeighedAverageApy} />
        <div className="nostra__summary__market-supply-side-values">
          <TooltipWrapper tooltipContent={<SummaryMarketSupplyBreakdownTooltip />} placement="bottom-start">
            <SummaryBoxSideValue
              title={t('SummaryMarketSupply.labelTotalAggregateSupplyNumber')}
              value={formattedTotalAssets}
              placeholderWidth="large"
            />
          </TooltipWrapper>
          <div className="nostra__summary-box__horizontal-divider" />
          <SummaryBoxSideValue
            title={t('SummaryMarketSupply.labelLenders')}
            value={formattedLenders}
            placeholderWidth="small"
          />
        </div>
      </div>
    </SummaryBox>
  );
};

export default memo(SummaryMarketSupply);
