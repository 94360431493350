import { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelectedChartToken, useTokenList } from '../../../hooks';
import { Link, Icon, Logo, Typography, colors, ButtonWrapper } from '../../shared';

import './TokenSelector.scss';

const TokenSelector = () => {
  const { t } = useTranslation();
  const [selectedChartToken] = useSelectedChartToken();
  const tokenList = useTokenList();
  const [menuOpened, setMenuOpened] = useState<boolean>(false);

  const onSelect = useCallback(() => setMenuOpened(false), []);
  const onToggleMenu = useCallback(() => setMenuOpened(prev => !prev), []);

  return (
    <div className="nostra__analytics__token-selector">
      {menuOpened && (
        <>
          <div className="nostra__analytics__token-selector__backdrop" onClick={onToggleMenu} />
          <div className="nostra__analytics__token-selector__menu">
            <Link href="/analytics" onClick={onSelect}>
              <Typography className="nostra__analytics__token-selector__menu-overview" variant="subtitle" weight="bold">
                {t('TokenSelector.optionDefault')}
              </Typography>
            </Link>
            <div className="nostra__analytics__token-selector__menu-divider" />
            <Typography className="nostra__analytics__token-selector__menu-title" variant="body-secondary">
              {t('TokenSelector.titleMarkets')}
            </Typography>
            <ul>
              {tokenList.map(token => (
                <li key={`token-${token}`}>
                  <Link href={`/analytics/${token}`} onClick={onSelect}>
                    <Logo type={`token-${token}`} size="small" />
                    <Typography variant="subtitle" weight="bold">
                      {token}
                    </Typography>
                    <Icon variant="right-arrow" color={colors.breadcrumbText} size="small" />
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
      <ButtonWrapper className="nostra__analytics__token-selector__btn" onClick={onToggleMenu}>
        {selectedChartToken && <Logo type={`token-${selectedChartToken}`} />}
        <Typography variant="title" color="breadcrumb">
          {selectedChartToken ?? t('TokenSelector.optionDefault')}
        </Typography>
        <Icon variant={menuOpened ? 'up-chevron' : 'down-chevron'} color={colors.breadcrumbText} size="small" />
      </ButtonWrapper>
    </div>
  );
};

export default memo(TokenSelector);
