const Background = () => {
  return (
    <div className="nostra__background-container">
      <svg className="nostra__background" viewBox="0 0 1700 1964" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M857.566 986.363V977.632L850 973.271L842.434 977.632V986.363L850 990.725L857.566 986.363Z"
          stroke="#80211F"
          strokeWidth="22"
          strokeMiterlimit="10"
        />
        <path
          d="M888.358 1004.13V959.863L850.001 937.733L811.645 959.863V1004.13L850.001 1026.27L888.358 1004.13Z"
          stroke="#7C201F"
          strokeWidth="22"
          strokeMiterlimit="10"
        />
        <path
          d="M919.155 1021.9V942.095L850 902.189L780.846 942.095V1021.9L850 1061.81L919.155 1021.9Z"
          stroke="#772020"
          strokeWidth="22"
          strokeMiterlimit="10"
        />
        <path
          d="M949.945 1039.68V924.327L850 866.652L750.055 924.327V1039.68L850 1097.35L949.945 1039.68Z"
          stroke="#731F20"
          strokeWidth="22"
          strokeMiterlimit="10"
        />
        <path
          d="M980.737 1057.44V906.558L850.001 831.108L719.266 906.558V1057.44L850.001 1132.89L980.737 1057.44Z"
          stroke="#6F1F20"
          strokeWidth="22"
          strokeMiterlimit="10"
        />
        <path
          d="M1011.53 1075.21V888.782L850.001 795.571L688.467 888.782V1075.21L850.001 1168.43L1011.53 1075.21Z"
          stroke="#6A1E20"
          strokeWidth="22"
          strokeMiterlimit="10"
        />
        <path
          d="M1042.32 1092.98V871.014L850 760.027L657.676 871.014V1092.98L850 1203.97L1042.32 1092.98Z"
          stroke="#661E21"
          strokeWidth="22"
          strokeMiterlimit="10"
        />
        <path
          d="M1073.12 1110.76V853.246L849.999 724.49L626.877 853.246V1110.76L849.999 1239.51L1073.12 1110.76Z"
          stroke="#611D21"
          strokeWidth="22"
          strokeMiterlimit="10"
        />
        <path
          d="M1103.91 1128.53V835.477L850.001 688.954L596.088 835.477V1128.53L850.001 1275.05L1103.91 1128.53Z"
          stroke="#5D1D21"
          strokeWidth="22"
          strokeMiterlimit="10"
        />
        <path
          d="M1134.71 1146.29V817.701L850 653.409L565.289 817.701V1146.29L850 1310.59L1134.71 1146.29Z"
          stroke="#591C21"
          strokeWidth="22"
          strokeMiterlimit="10"
        />
        <path
          d="M1165.5 1164.06V799.933L850.001 617.873L534.5 799.933V1164.06L850.001 1346.13L1165.5 1164.06Z"
          stroke="#541C22"
          strokeWidth="22"
          strokeMiterlimit="10"
        />
        <path
          d="M1196.3 1181.83V782.165L850.001 582.328L503.701 782.165V1181.83L850.001 1381.67L1196.3 1181.83Z"
          stroke="#501B22"
          strokeWidth="22"
          strokeMiterlimit="10"
        />
        <path
          d="M1227.09 1199.61V764.397L850 546.792L472.91 764.397V1199.61L850 1417.21L1227.09 1199.61Z"
          stroke="#4C1B22"
          strokeWidth="22"
          strokeMiterlimit="10"
        />
        <path
          d="M1257.89 1217.37V746.628L850.001 511.255L442.113 746.628V1217.37L850.001 1452.75L1257.89 1217.37Z"
          stroke="#471A22"
          strokeWidth="22"
          strokeMiterlimit="10"
        />
        <path
          d="M1288.68 1235.14V728.852L850.001 475.71L411.322 728.852V1235.14L850.001 1488.29L1288.68 1235.14Z"
          stroke="#431A23"
          strokeWidth="22"
          strokeMiterlimit="10"
        />
        <path
          d="M1319.48 1252.91V711.084L850 440.174L380.523 711.084V1252.91L850 1523.83L1319.48 1252.91Z"
          stroke="#3E1923"
          strokeWidth="22"
          strokeMiterlimit="10"
        />
        <path
          d="M1350.27 1270.68V693.315L850 404.629L349.732 693.315V1270.68L850 1559.37L1350.27 1270.68Z"
          stroke="#3A1923"
          strokeWidth="22"
          strokeMiterlimit="10"
        />
        <path
          d="M1381.06 1288.46V675.547L850.001 369.093L318.943 675.547V1288.46L850.001 1594.91L1381.06 1288.46Z"
          stroke="#361823"
          strokeWidth="22"
          strokeMiterlimit="10"
        />
        <path
          d="M1411.86 1306.22V657.778L850 333.548L288.145 657.778V1306.22L850 1630.45L1411.86 1306.22Z"
          stroke="#311824"
          strokeWidth="22"
          strokeMiterlimit="10"
        />
        <path
          d="M1442.65 1323.99V640.003L850.002 298.012L257.355 640.003V1323.99L850.002 1665.99L1442.65 1323.99Z"
          stroke="#2D1724"
          strokeWidth="22"
          strokeMiterlimit="10"
        />
        <path
          d="M1473.45 1341.76V622.234L850.001 262.475L226.557 622.234V1341.76L850.001 1701.54L1473.45 1341.76Z"
          stroke="#291724"
          strokeWidth="22"
          strokeMiterlimit="10"
        />
        <path
          d="M1504.24 1359.53V604.466L850 226.93L195.766 604.466V1359.53L850 1737.07L1504.24 1359.53Z"
          stroke="#241624"
          strokeWidth="22"
          strokeMiterlimit="10"
        />
        <path
          d="M1535.03 1377.31V586.697L850 191.394L164.967 586.697V1377.31L850 1772.61L1535.03 1377.31Z"
          stroke="#201625"
          strokeWidth="22"
          strokeMiterlimit="10"
        />
        <path
          d="M1565.82 1395.07V568.929L850.001 155.849L134.178 568.929V1395.07L850.001 1808.15L1565.82 1395.07Z"
          stroke="#1B1525"
          strokeWidth="22"
          strokeMiterlimit="10"
        />
        <path
          d="M1596.62 1412.84V551.153L850 120.312L103.379 551.153V1412.84L850 1843.69L1596.62 1412.84Z"
          stroke="#171525"
          strokeWidth="22"
          strokeMiterlimit="10"
        />
        <path
          d="M1627.41 1430.61V533.385L850 84.7759L72.5879 533.385V1430.61L850 1879.23L1627.41 1430.61Z"
          stroke="#131425"
          strokeWidth="22"
          strokeMiterlimit="10"
        />
        <path
          d="M1658.21 1448.38V515.616L850.001 49.2314L41.791 515.616V1448.38L850.001 1914.77L1658.21 1448.38Z"
          stroke="#0E1426"
          strokeWidth="22"
          strokeMiterlimit="10"
        />
        <path
          d="M1689 1466.15V497.848L850.001 13.6948L11 497.848V1466.15L850.001 1950.32L1689 1466.15Z"
          stroke="#0A1326"
          strokeWidth="22"
          strokeMiterlimit="10"
        />
      </svg>
      <div className="nostra__background nostra__background-overlay" />
    </div>
  );
};
export default Background;
