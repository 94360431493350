import { from, map, Observable, of } from 'rxjs';
import { Abi, AccountInterface, ProviderInterface } from 'starknet';
import { Decimal, DEFAULT_DECIMAL_PRECISION } from '../../datastructures';
import { parseBnToHexString } from '../../helpers';
import { DEBT_TIERS, DebtTier } from '../../interfaces';
import CDPManager_ABI from './abi/CDPManager_abi.json';
import { ContractBase } from './base/ContractBase';
import { CDPManager } from './typechain';

export interface CDPManagerDebtData {
  id: Decimal;
  debtTier: DebtTier;
  debtToken: string;
  priceOracle: string;
  debtFactor: Decimal;
}

export interface CDPManagerCollateralData {
  id: Decimal;
  asset: string;
  priceOracle: string;
  collateralFactor: Decimal;
}

export interface UserAccountData {
  healthFactor: Decimal;
  totalCollateral: Decimal;
  totalDebt: Decimal;
}

export class CDPManagerContract extends ContractBase<CDPManager> {
  constructor(address: string, providerOrAccount: ProviderInterface | AccountInterface) {
    super(CDPManager_ABI as Abi, address, providerOrAccount);
  }

  getDebtData(debtToken: string): Observable<CDPManagerDebtData | null> {
    if (!this.contract) {
      return of(null);
    }

    return from(this.contract.getDebtData(debtToken)).pipe(
      map(([data]) => ({
        id: new Decimal(data.id, DEFAULT_DECIMAL_PRECISION),
        debtTier: DEBT_TIERS[data.debtTier.toNumber()],
        debtToken: parseBnToHexString(data.debtToken, 64),
        priceOracle: parseBnToHexString(data.priceOracle, 64),
        debtFactor: new Decimal(data.debtFactor, DEFAULT_DECIMAL_PRECISION),
      })),
    );
  }

  getCollateralData(asset: string): Observable<CDPManagerCollateralData | null> {
    if (!this.contract) {
      return of(null);
    }

    return from(this.contract.getCollateralData(asset)).pipe(
      map(([data]) => ({
        id: new Decimal(data.id, DEFAULT_DECIMAL_PRECISION),
        asset: parseBnToHexString(data.asset, 64),
        priceOracle: parseBnToHexString(data.priceOracle, 64),
        collateralFactor: new Decimal(data.collateralFactor, DEFAULT_DECIMAL_PRECISION),
      })),
    );
  }

  getUserAccountData(user: string): Observable<UserAccountData | null> {
    if (!this.contract) {
      return of(null);
    }

    return from(this.contract.getUserAccountData(user)).pipe(
      map(([totalCollateral, totalDebt, , healthFactor]) => ({
        healthFactor: new Decimal(healthFactor, DEFAULT_DECIMAL_PRECISION),
        totalCollateral: new Decimal(totalCollateral, DEFAULT_DECIMAL_PRECISION),
        totalDebt: new Decimal(totalDebt, DEFAULT_DECIMAL_PRECISION),
      })),
    );
  }
}
