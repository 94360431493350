import { state, useStateObservable } from '@react-rxjs/core';
import { createSignal } from '@react-rxjs/utils';
import { Asset } from '../interfaces';

export type AppEventType = 'deposit' | 'withdraw' | 'borrow' | 'repay' | 'burnAndMint' | 'setActiveAccount';

export interface AppEvent {
  eventType: AppEventType;
  asset?: Asset;
  accountId: number;
  txnHash?: string;
  timestamp: number;
}

const [appEvent$, setAppEvent] = createSignal<AppEvent>();
const stateAppEvent$ = state(appEvent$, null);

export const emitAppEvent = setAppEvent; // to remind to use for better naming only

export function useAppEvent(): [AppEvent | null, (value: AppEvent) => void] {
  const appEvent = useStateObservable(stateAppEvent$);
  return [appEvent, emitAppEvent];
}

export { appEvent$ };
