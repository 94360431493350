import { catchError, from, map, of } from 'rxjs';
import { Abi, AccountInterface, ProviderInterface } from 'starknet';
import { Decimal, Uint256 } from '../../datastructures';
import Erc20_ABI from './abi/Erc20_abi.json';
import { ContractBase } from './base/ContractBase';
import { ERC20 } from './typechain';

export class Erc20Contract extends ContractBase<ERC20> {
  private precision: number;

  constructor(precision: number, address: string, providerOrAccount: ProviderInterface | AccountInterface) {
    super(Erc20_ABI as Abi, address, providerOrAccount);
    this.precision = precision;
  }

  balanceOf(walletAddress: string) {
    if (!this.contract) {
      return of(null);
    }

    return from(this.contract.balanceOf(walletAddress)).pipe(
      map(([walletBalance]) => new Decimal(walletBalance as Uint256, this.precision)),
      catchError(() => of(null)),
    );
  }
}
