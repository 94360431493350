import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { DecimalUtils, getFlagsFromNostraToken } from '../../utils';
import { highlightText, highlightTruncateAddress } from '../../helpers';
import { ParsedTransaction } from '../../interfaces';
import { getConfigManager } from '../../config/getConfigManager';
import { ButtonWrapper, colors, Icon, Link, TableBodyCell, TableRow, Typography } from '../shared';

interface TransactionHistoryTableRowProps {
  transaction: ParsedTransaction;
  keywords?: string;
}

const TransactionHistoryTableRow: FC<TransactionHistoryTableRowProps> = ({ transaction, keywords }) => {
  const { t } = useTranslation();

  const dateFormatted = useMemo(() => {
    return format(transaction.date, 'd MMM y, HH:mm');
  }, [transaction.date]);

  // TODO - Store transaction type in MongoDB as 'Deposit' not 'DEPOSIT', so we don't have to this transform on frontend
  const typeFormatted = useMemo(() => {
    const type = transaction.type.charAt(0).toUpperCase() + transaction.type.slice(1).toLocaleLowerCase();

    return highlightText(type, keywords ?? '', { variant: 'body-primary', highlightColor: 'secondary-light' });
  }, [transaction.type, keywords]);

  const asset = useMemo(() => {
    const configManager = getConfigManager();

    let assetAddress: string | null = null;
    if (transaction.type !== 'ADJUST') {
      assetAddress = configManager.getNostraTokenAssetAddress(transaction.tokens.tokenAddress);
    } else {
      assetAddress = configManager.getNostraTokenAssetAddress(transaction.tokens.toTokenAddress);
    }

    if (assetAddress === null) {
      console.error(`Failed to find asset address for transaction ${transaction.hash}`);
      return null;
    }

    return configManager.getAssetByAddress(assetAddress);
  }, [transaction]);

  const valueFormatted = useMemo(() => {
    if (asset === null) {
      return null;
    }

    return DecimalUtils.format(transaction.amount, {
      style: 'decimal',
      fractionDigits: asset.uiTokenPrecision,
      lessThanFormat: true,
    });
  }, [asset, transaction.amount]);

  const tokenFormatted = useMemo(() => {
    if (asset === null) {
      return null;
    }

    return highlightText(asset.ticker, keywords ?? '', { variant: 'body-primary', highlightColor: 'secondary-light' });
  }, [asset, keywords]);

  const shortTransactionHash = useMemo(
    () =>
      highlightTruncateAddress(transaction.hash, keywords ?? '', {
        variant: 'body-primary',
        color: 'primary-main',
        highlightColor: 'secondary-light',
      }),
    [transaction.hash, keywords],
  );

  const flagsData = useMemo(() => {
    if (!asset) {
      return null;
    }

    const labelYes = t('TransactionHistory.labelYes');
    const labelNo = t('TransactionHistory.labelNo');

    if (transaction.type === 'DEPOSIT') {
      const flagsData = getFlagsFromNostraToken(asset, transaction.tokens.tokenAddress);

      return {
        collateral: <Typography variant="body-primary">{flagsData.collateral ? labelYes : labelNo}</Typography>,
        lend: <Typography variant="body-primary">{flagsData.lend ? labelYes : labelNo}</Typography>,
      };
    }

    if (transaction.type === 'REPAY' || transaction.type === 'BORROW' || transaction.type === 'WITHDRAW') {
      return {
        collateral: <Typography variant="body-primary">-</Typography>,
        lend: <Typography variant="body-primary">-</Typography>,
      };
    }

    if (transaction.type === 'ADJUST') {
      try {
        const fromFlagData = getFlagsFromNostraToken(asset, transaction.tokens.fromTokenAddress);
        const toFlagData = getFlagsFromNostraToken(asset, transaction.tokens.toTokenAddress);

        return {
          collateral: (
            <>
              <Typography variant="body-primary">{fromFlagData.collateral ? labelYes : labelNo}</Typography>
              <Icon variant="right-arrow-thin" size="tiny" />
              <Typography variant="body-primary">{toFlagData.collateral ? labelYes : labelNo}</Typography>
            </>
          ),
          lend: (
            <>
              <Typography variant="body-primary">{fromFlagData.lend ? labelYes : labelNo}</Typography>
              <Icon variant="right-arrow-thin" size="tiny" />
              <Typography variant="body-primary">{toFlagData.lend ? labelYes : labelNo}</Typography>
            </>
          ),
        };
      } catch (error) {
        console.error(error);
      }
    }

    return null;
  }, [asset, t, transaction.tokens, transaction.type]);

  const onCopyHash = useCallback(() => {
    navigator.clipboard.writeText(transaction.hash);
  }, [transaction.hash]);

  return (
    <TableRow id={`transaction-history-row-${transaction.hash}`}>
      <TableBodyCell>
        <Typography variant="body-primary">{dateFormatted}</Typography>
      </TableBodyCell>
      <TableBodyCell>
        <Typography variant="body-primary">{typeFormatted}</Typography>
      </TableBodyCell>
      <TableBodyCell align="right">
        <Typography variant="body-primary">{valueFormatted}</Typography>
      </TableBodyCell>
      <TableBodyCell align="center">
        <Typography variant="body-primary">{tokenFormatted}</Typography>
      </TableBodyCell>
      <TableBodyCell align="center">{flagsData && flagsData.collateral}</TableBodyCell>
      <TableBodyCell align="center">{flagsData && flagsData.lend}</TableBodyCell>
      <TableBodyCell align="right">
        <Typography variant="body-primary" color="primary-main">
          <Link href={`https://starkscan.co/tx/${transaction.hash}`}>{shortTransactionHash}</Link>
        </Typography>

        <ButtonWrapper onClick={onCopyHash}>
          <Icon variant="copy" size="small" color={colors.iconGray} />
        </ButtonWrapper>
      </TableBodyCell>
    </TableRow>
  );
};
export default TransactionHistoryTableRow;
