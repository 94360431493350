import { StorageService } from './StorageService';

interface WalletAddressAccounts {
  accounts: string[];
  active: number;
}

type AccountMap = {
  [walletAddress: string]: WalletAddressAccounts;
};

const ACCOUNT_KEY = 'accounts';

export class AccountService {
  private accountMap: AccountMap = {};

  constructor() {
    this.initStorage();
  }

  getAccountName(walletAddress: string, accountId: string | number): string {
    this.initWalletStorage(walletAddress);

    return this.accountMap[walletAddress].accounts[Number(accountId)];
  }

  setAccountName(walletAddress: string, accountId: string | number, name: string) {
    this.initWalletStorage(walletAddress);

    this.accountMap[walletAddress].accounts[Number(accountId)] = name;
    this.storeMap();
  }

  getAccounts(walletAddress: string): string[] {
    this.initWalletStorage(walletAddress);

    return this.accountMap[walletAddress].accounts;
  }

  addAccount(walletAddress: string): number | null {
    this.initWalletStorage(walletAddress);

    const index = this.accountMap[walletAddress].accounts.indexOf('');

    if (index > -1) {
      this.accountMap[walletAddress].accounts[index] = `Account ${index}`;
      this.storeMap();

      return index;
    }
    return null;
  }

  areAccountsAvailable(walletAddress: string): boolean {
    this.initWalletStorage(walletAddress);

    return this.accountMap[walletAddress].accounts.some(account => account === '');
  }

  closeAccount(walletAddress: string, accountId: string | number): string[] {
    this.initWalletStorage(walletAddress);

    this.accountMap[walletAddress].accounts[Number(accountId)] = '';
    this.storeMap();

    return this.accountMap[walletAddress].accounts;
  }

  closeAllAccounts(walletAddress: string) {
    this.setDefaultAccounts(walletAddress);
  }

  getActiveAccountId(walletAddress: string) {
    this.initWalletStorage(walletAddress);

    return this.accountMap[walletAddress].active ?? 0;
  }

  getActiveAccountName(walletAddress: string, activeAccountId: number): string {
    this.initWalletStorage(walletAddress);

    return this.accountMap[walletAddress].accounts[activeAccountId];
  }

  setActiveAccount(walletAddress: string, accountId: number) {
    this.initWalletStorage(walletAddress);

    if (accountId < 0 || accountId > 255 || this.accountMap[walletAddress].accounts[accountId] === '') {
      return;
    }

    this.accountMap[walletAddress].active = accountId;
    this.storeMap();
  }

  private initStorage() {
    try {
      const accounts = StorageService.get(ACCOUNT_KEY) as AccountMap;

      if (accounts) {
        this.accountMap = accounts;
      } else {
        this.storeMap();
      }
    } catch (error) {
      // fail to get from storage, most likely fail to decode from the storage
      this.storeMap();
    }
  }

  private initWalletStorage(walletAddress: string) {
    if (
      !this.accountMap ||
      !this.accountMap[walletAddress] ||
      !Array.isArray(this.accountMap[walletAddress].accounts) ||
      !this.accountMap[walletAddress].accounts.every(accounts => typeof accounts === 'string') ||
      !this.accountMap[walletAddress].accounts.length
    ) {
      this.setDefaultAccounts(walletAddress);
    }
    this.storeMap();
  }

  private setDefaultAccounts(walletAddress: string) {
    this.accountMap[walletAddress] = {
      accounts: Array<string>(256)
        .fill('')
        .map((_, index) => (index === 0 ? 'Main account' : '')),
      active: 0,
    };
  }

  private storeMap() {
    StorageService.set(ACCOUNT_KEY, this.accountMap);
  }
}
