import { bind } from '@react-rxjs/core';
import { BehaviorSubject, catchError, combineLatest, merge, mergeMap, of, Subscription, tap } from 'rxjs';
import { getConfigManager } from '../config/getConfigManager';
import { MULTICALL_CONTRACT_ADDRESS } from '../constants';
import { Decimal, DEFAULT_DECIMAL_PRECISION } from '../datastructures';
import { NostraToken } from '../interfaces';
import { getMultiCallContract } from '../services';
import { InterestRateConfig } from '../services/contracts/InterestRateModelContract';
import { logGlobalError } from './useGlobalError';
import { nostraTokenList$ } from './useNostraTokenList';

const DEFAULT_VALUE = null;

export type InterestRateConfigMap = { [debtTokenAddress: string]: InterestRateConfig };

export const interestRateConfig$ = new BehaviorSubject<InterestRateConfigMap | null>(DEFAULT_VALUE);

const RESPONSE_CHUNK_SIZE = 14;

const fetchData = (debtTokens: NostraToken[]) => {
  try {
    const config = getConfigManager().getConfig();

    const calldata = debtTokens.map(debtToken => {
      return {
        contractAddress: config.interestRateModel,
        entrypoint: 'getInterestRateConfig',
        calldata: [debtToken.address],
      };
    });

    const multiCallContract = getMultiCallContract(MULTICALL_CONTRACT_ADDRESS);

    return multiCallContract.aggregate(calldata).pipe(
      mergeMap(result => {
        if (!result) {
          return of(null);
        }

        const interestRateConfigMap: InterestRateConfigMap = {};

        for (let i = 0; i < result.length; i += RESPONSE_CHUNK_SIZE) {
          // Index 1,2 is optimalUtilizationRate
          // Index 3,4 is baseBorrowRate
          // Index 5,6 is rateSlope1
          // Index 7,8 is rateSlope2
          // Index 9,10 is generalProtocolFee
          const optimalUtilizationRate = new Decimal(
            { low: result[i + 1], high: result[i + 2] },
            DEFAULT_DECIMAL_PRECISION,
          );
          const baseBorrowRate = new Decimal({ low: result[i + 3], high: result[i + 4] }, DEFAULT_DECIMAL_PRECISION);
          const rateSlope1 = new Decimal({ low: result[i + 5], high: result[i + 6] }, DEFAULT_DECIMAL_PRECISION);
          const rateSlope2 = new Decimal({ low: result[i + 7], high: result[i + 8] }, DEFAULT_DECIMAL_PRECISION);
          const generalProtocolFee = new Decimal(
            { low: result[i + 9], high: result[i + 10] },
            DEFAULT_DECIMAL_PRECISION,
          );

          interestRateConfigMap[debtTokens[i / RESPONSE_CHUNK_SIZE].address] = {
            optimalUtilizationRate,
            baseBorrowRate,
            rateSlope1,
            rateSlope2,
            generalProtocolFee,
          };
        }

        return of(interestRateConfigMap);
      }),
      catchError(error => {
        console.error(`useInterestRateConfig - Failed to load interest rate config!`, error);
        logGlobalError(error);
        return of(null);
      }),
    );
  } catch (error) {
    console.error(`useInterestRateConfig - Failed to load interest rate config!`, error);
    logGlobalError(error);
    return of(null);
  }
};

/**
 * Interest rate config does not change often. We can load it only once on app load.
 */
const initialLoadStream$ = combineLatest([nostraTokenList$]).pipe(
  mergeMap(([nostraTokenList]) => {
    const debtTokens = nostraTokenList.filter(nostraToken => nostraToken.variant === 'debt');

    return fetchData(debtTokens);
  }),
);

const stream$ = merge(initialLoadStream$).pipe(
  tap(interestRateConfigMap => {
    if (interestRateConfigMap) {
      interestRateConfig$.next(interestRateConfigMap);
    }
  }),
);

export const [useInterestRateConfig] = bind(interestRateConfig$, DEFAULT_VALUE);

let subscription: Subscription | null = null;

export const subscribeInterestRateConfig = (): void => {
  unsubscribeInterestRateConfig();
  subscription = stream$.subscribe();
};
export const unsubscribeInterestRateConfig = (): void => subscription?.unsubscribe();
export const resetInterestRateConfig = (): void => interestRateConfig$.next(DEFAULT_VALUE);
