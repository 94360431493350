import { from, map, Observable, of } from 'rxjs';
import { Abi, AccountInterface, ProviderInterface } from 'starknet';
import { Decimal, DEFAULT_DECIMAL_PRECISION } from '../../datastructures';
import InterestRateModel_ABI from './abi/InterestRateModel_abi.json';
import { ContractBase } from './base';
import { InterestRateModel } from './typechain';

export interface InterestRateStateData {
  lendingRate: Decimal;
  borrowRate: Decimal;
}

export interface InterestRateConfig {
  optimalUtilizationRate: Decimal;
  baseBorrowRate: Decimal;
  rateSlope1: Decimal;
  rateSlope2: Decimal;
  generalProtocolFee: Decimal;
}

export class InterestRateModelContract extends ContractBase<InterestRateModel> {
  constructor(address: string, providerOrAccount: ProviderInterface | AccountInterface) {
    super(InterestRateModel_ABI as Abi, address, providerOrAccount);
  }

  getInterestState(nostraDebtToken: string): Observable<InterestRateStateData | null> {
    if (!this.contract) {
      return of(null);
    }

    return from(this.contract.getInterestState(nostraDebtToken)).pipe(
      map(([interestStateData]) => ({
        lendingRate: new Decimal(interestStateData.lendingRate, DEFAULT_DECIMAL_PRECISION),
        borrowRate: new Decimal(interestStateData.borrowRate, DEFAULT_DECIMAL_PRECISION),
      })),
    );
  }

  getInterestRateConfig(nostraDebtToken: string): Observable<InterestRateConfig | null> {
    if (!this.contract) {
      return of(null);
    }

    return from(this.contract.getInterestRateConfig(nostraDebtToken)).pipe(
      map(([interestRateConfig]) => ({
        optimalUtilizationRate: new Decimal(interestRateConfig.optimalUtilizationRate, DEFAULT_DECIMAL_PRECISION),
        baseBorrowRate: new Decimal(interestRateConfig.baseBorrowRate, DEFAULT_DECIMAL_PRECISION),
        rateSlope1: new Decimal(interestRateConfig.rateSlope1, DEFAULT_DECIMAL_PRECISION),
        rateSlope2: new Decimal(interestRateConfig.rateSlope2, DEFAULT_DECIMAL_PRECISION),
        generalProtocolFee: new Decimal(interestRateConfig.generalProtocolFee, DEFAULT_DECIMAL_PRECISION),
      })),
    );
  }
}
