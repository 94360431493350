import { ADDRESS_CHAR_LENGTH } from '../constants';
import { DEFAULT_DECIMAL_PRECISION } from '../datastructures';
import { AssetConfig, Config, NostraToken, Token } from '../interfaces';

class ConfigManager {
  private config: Config;

  private tokenList: Token[] = [];
  private tokenAddresses: string[] = [];
  private nostraTokenList: NostraToken[] = [];
  private nostraTokenToAssetMap: Map<string, string> = new Map();

  constructor(config: Config) {
    this.config = config;

    this.retrieveTokenList();
    this.retrieveTokenAddresses();
    this.retrieveNostraTokenList();
    this.retrieveNostraTokenToAssetMap();
  }

  getConfig(): Config {
    return this.config;
  }

  getTokenList(): Token[] {
    return this.tokenList;
  }

  getBaseAsset(): AssetConfig {
    return this.config.assets['ETH'];
  }

  getTokenAddresses(): string[] {
    return this.tokenAddresses;
  }

  getNostraTokenList(): NostraToken[] {
    return this.nostraTokenList;
  }

  getAssetByAddress(tokenAddress: string): AssetConfig | null {
    const entry = Object.entries(this.config.assets).find(
      ([, asset]) =>
        asset.address === tokenAddress ||
        asset.nostraInterestBearingTokenAddress === tokenAddress ||
        asset.nostraInterestBearingCollateralTokenAddress === tokenAddress ||
        asset.nostraTokenAddress === tokenAddress ||
        asset.nostraCollateralTokenAddress === tokenAddress ||
        asset.debtTokenAddress === tokenAddress,
    );
    return entry ? entry[1] : null;
  }

  getTokenByAddress(tokenAddress: string): Token | null {
    return this.getAssetByAddress(tokenAddress)?.ticker ?? null;
  }

  getTokenPrecision(tokenOrAddress: Token | string): number {
    const token =
      tokenOrAddress.length === ADDRESS_CHAR_LENGTH ? this.getTokenByAddress(tokenOrAddress) : tokenOrAddress;
    return token
      ? this.config.assets[token as Token]?.tokenPrecision ?? DEFAULT_DECIMAL_PRECISION
      : DEFAULT_DECIMAL_PRECISION;
  }

  getNostraTokenAssetAddress(nostraTokenAddress: string): string {
    const assetAddress = this.nostraTokenToAssetMap.get(nostraTokenAddress);

    if (!assetAddress) {
      throw new Error(`Failed to get asset address for nostra token ${nostraTokenAddress}!`);
    }

    return assetAddress;
  }

  private retrieveNostraTokenToAssetMap(): void {
    Object.entries(this.config.assets).forEach(([, asset]) => {
      this.nostraTokenToAssetMap.set(asset.nostraInterestBearingCollateralTokenAddress, asset.address);
      this.nostraTokenToAssetMap.set(asset.nostraInterestBearingTokenAddress, asset.address);
      this.nostraTokenToAssetMap.set(asset.nostraCollateralTokenAddress, asset.address);
      this.nostraTokenToAssetMap.set(asset.nostraTokenAddress, asset.address);
      this.nostraTokenToAssetMap.set(asset.debtTokenAddress, asset.address);
    });
  }

  private retrieveNostraTokenList(): void {
    this.nostraTokenList = Object.entries(this.config.assets).flatMap(
      ([, asset]) =>
        [
          {
            address: asset.nostraInterestBearingTokenAddress,
            variant: {
              interestBearing: true,
              collateral: false,
            },
            symbol: asset.nostraInterestBearingTokenSymbol,
            underlyingToken: asset.ticker,
            tokenPrecision: asset.tokenPrecision,
          },
          {
            address: asset.nostraInterestBearingCollateralTokenAddress,
            variant: {
              interestBearing: true,
              collateral: true,
            },
            symbol: asset.nostraInterestBearingCollateralTokenSymbol,
            underlyingToken: asset.ticker,
            tokenPrecision: asset.tokenPrecision,
          },
          {
            address: asset.nostraTokenAddress,
            variant: {
              interestBearing: false,
              collateral: false,
            },
            symbol: asset.nostraTokenSymbol,
            underlyingToken: asset.ticker,
            tokenPrecision: asset.tokenPrecision,
          },
          {
            address: asset.nostraCollateralTokenAddress,
            variant: {
              interestBearing: false,
              collateral: true,
            },
            symbol: asset.nostraCollateralTokenSymbol,
            underlyingToken: asset.ticker,
            tokenPrecision: asset.tokenPrecision,
          },
          {
            address: asset.debtTokenAddress,
            variant: 'debt',
            symbol: asset.debtTokenSymbol,
            underlyingToken: asset.ticker,
            tokenPrecision: asset.tokenPrecision,
          },
        ] as NostraToken[],
    );
  }

  private retrieveTokenList(): void {
    this.tokenList = Object.keys(this.config.assets)
      .map(token => token as Token)
      .sort();
  }

  private retrieveTokenAddresses(): void {
    this.tokenAddresses = Object.keys(this.config.assets).map(token => this.config.assets[token as Token].address);
  }
}

export default ConfigManager;
