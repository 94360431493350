import { FC, memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setFromTotalUniqueBorrowersChartDate, useTotalUniqueBorrowersChartData } from '../../../hooks';
import { Nullable } from '../../../interfaces';
import Graph from '../Graph';

const TotalUniqueBorrowers: FC = () => {
  const { t } = useTranslation();

  const totalUniqueBorrowersChartData = useTotalUniqueBorrowersChartData();

  const [fromTimestamp, setFromTimestamp] = useState<Nullable<number>>(-1);

  const handleDataFromChange = useCallback((fromTimestamp: Nullable<number>) => {
    setFromTimestamp(fromTimestamp);
  }, []);

  useEffect(() => {
    if (fromTimestamp === -1) {
      return;
    }

    setFromTotalUniqueBorrowersChartDate(fromTimestamp);
  }, [fromTimestamp]);

  return (
    <Graph
      title={t('Analytics.titleTotalUniqueBorrowers')}
      parsedChartData1={totalUniqueBorrowersChartData}
      onDateFromChange={handleDataFromChange}
    />
  );
};

export default memo(TotalUniqueBorrowers);
