import BN from 'bn.js';
import { utils } from 'ethers';
import type { BigNumberish } from 'starknet/utils/number';
import { uint256 } from 'starknet';
import { parseUint256ToBn } from '../helpers';

export const increasePrecision = (bigNum: BN, increment: number): BN => {
  const multiplicand = new BN(10).pow(new BN(increment));
  return bigNum.mul(multiplicand);
};

export const decreasePrecision = (bigNum: BN, decrement: number): BN => {
  const divisor = new BN(10).pow(new BN(decrement));
  return bigNum.div(divisor);
};

export type Uint256 = { low: BigNumberish; high: BigNumberish };
export type Numberish = Decimal | string | number;

export const DEFAULT_DECIMAL_PRECISION = 18;

function isUint256(value: Numberish | BN | Uint256): value is Uint256 {
  return (value as Uint256).low !== undefined && (value as Uint256).high !== undefined;
}

export default class Decimal {
  static MAX_DECIMAL = new Decimal(uint256.UINT_256_MAX, DEFAULT_DECIMAL_PRECISION);
  readonly value: BN;
  readonly precision = DEFAULT_DECIMAL_PRECISION;

  // In case user passes BN as value, we also need user to provide precision
  constructor(value: BN | Uint256, valuePrecision: number);
  // In case user passes (number | string | Decimal) as value, we don't need precision because
  // we can safely convert (number | string | Decimal) to BN with 18 decimal precision.
  constructor(value: Numberish);

  // Constructor implementation
  constructor(value: Numberish | BN | Uint256, valuePrecision: number = DEFAULT_DECIMAL_PRECISION) {
    // In case we pass in Decimal, underlying value inside the Decimal is already 18 decimal precision
    if (value instanceof Decimal) {
      this.value = new BN(value.value);
      // In case user passes number, attempt to create BN from passed value using parseUnits()
    } else if (typeof value === 'number') {
      try {
        // the decimal part may exceed the precision range, check and truncate
        const strValue = `${value}`;
        const needTruncate = strValue.indexOf('.') >= 0 || strValue.indexOf('e-') >= 0;
        const truncatedValue = needTruncate ? value.toFixed(valuePrecision) : strValue;
        this.value = new BN(utils.parseUnits(truncatedValue, valuePrecision).toString());
      } catch (e) {
        throw new Error(`Failed to parse ${value} when creating Decimal`);
      }
      // In case user passes string, attempt to create BN from passed value using parseUnits()
    } else if (typeof value === 'string') {
      try {
        // the decimal part may exceed the precision range, check and truncate
        const decimalPointIndex = value.indexOf('.');
        const truncatedValue =
          decimalPointIndex >= 0 ? value.substring(0, decimalPointIndex + valuePrecision + 1) : value;
        this.value = new BN(utils.parseUnits(truncatedValue, valuePrecision).toString());
      } catch (e) {
        throw new Error(`Failed to parse ${value} when creating Decimal`);
      }
      // In case we pass in Uint256 as value, need to convert it to 18 decimal
      // precision BN before storing it inside value
    } else if (isUint256(value)) {
      if (valuePrecision === DEFAULT_DECIMAL_PRECISION) {
        this.value = parseUint256ToBn(value);
      } else if (valuePrecision < DEFAULT_DECIMAL_PRECISION) {
        this.value = increasePrecision(parseUint256ToBn(value), DEFAULT_DECIMAL_PRECISION - valuePrecision);
      } else {
        this.value = decreasePrecision(parseUint256ToBn(value), valuePrecision - DEFAULT_DECIMAL_PRECISION);
      }
      // In case we pass in BN as value, need to convert it to 18 decimal
      // precision BN before storing it inside value
    } else {
      if (valuePrecision === DEFAULT_DECIMAL_PRECISION) {
        this.value = new BN(value);
      } else if (valuePrecision < DEFAULT_DECIMAL_PRECISION) {
        this.value = increasePrecision(new BN(value), DEFAULT_DECIMAL_PRECISION - valuePrecision);
      } else {
        this.value = decreasePrecision(new BN(value), valuePrecision - DEFAULT_DECIMAL_PRECISION);
      }
    }
  }

  static parse(value: BN, defaultValue: BN, valuePrecision: number): Decimal;
  static parse(value: Numberish, defaultValue: Numberish): Decimal;
  static parse(
    value: Numberish | BN,
    defaultValue: Numberish | BN,
    valuePrecision = DEFAULT_DECIMAL_PRECISION,
  ): Decimal {
    try {
      if (value instanceof BN) {
        return new Decimal(value, valuePrecision);
      }
      return new Decimal(value);
    } catch (e) {
      if (defaultValue instanceof BN) {
        return new Decimal(defaultValue, valuePrecision);
      }
      return new Decimal(defaultValue);
    }
  }

  static max(a: Numberish, b: Numberish) {
    const decimalA = new Decimal(a);
    const decimalB = new Decimal(b);
    return decimalA.gt(decimalB) ? decimalA : decimalB;
  }

  static min(a: Numberish, b: Numberish) {
    const decimalA = new Decimal(a);
    const decimalB = new Decimal(b);
    return decimalA.lt(decimalB) ? decimalA : decimalB;
  }

  add(addend: Numberish): Decimal {
    const decimal = new Decimal(addend);

    return new Decimal(this.value.add(decimal.value), DEFAULT_DECIMAL_PRECISION);
  }

  sub(subtrahend: Numberish): Decimal {
    const decimal = new Decimal(subtrahend);

    return new Decimal(this.value.sub(decimal.value), DEFAULT_DECIMAL_PRECISION);
  }

  mul(multiplicand: Numberish): Decimal {
    const decimal = new Decimal(multiplicand);
    const product = decreasePrecision(this.value.mul(decimal.value), DEFAULT_DECIMAL_PRECISION);

    return new Decimal(product, DEFAULT_DECIMAL_PRECISION);
  }

  div(divisor: Numberish): Decimal {
    const decimal = new Decimal(divisor);

    if (decimal.isZero()) {
      return Decimal.MAX_DECIMAL;
    }

    const quotient = increasePrecision(this.value, DEFAULT_DECIMAL_PRECISION).div(decimal.value);

    return new Decimal(quotient, DEFAULT_DECIMAL_PRECISION);
  }

  // TODO: it returns wrong result when exponent <= 0
  pow(exponent: number): Decimal {
    const result = this.value.pow(new BN(exponent));

    return new Decimal(
      decreasePrecision(result, exponent * DEFAULT_DECIMAL_PRECISION - DEFAULT_DECIMAL_PRECISION),
      DEFAULT_DECIMAL_PRECISION,
    );
  }

  abs(): Decimal {
    return new Decimal(this.value.abs(), DEFAULT_DECIMAL_PRECISION);
  }

  equals(comparable: Numberish): boolean {
    const decimal = new Decimal(comparable);

    return this.value.eq(decimal.value);
  }

  approximatelyEquals(comparable: Numberish, percentageOffset: Numberish): boolean {
    const decimal = new Decimal(comparable);
    const diff = new Decimal(this.value.sub(decimal.value).abs(), DEFAULT_DECIMAL_PRECISION);
    const offset = new Decimal(percentageOffset);

    return diff.div(this.abs()).lte(offset);
  }

  lt(another: Numberish): boolean {
    const decimal = new Decimal(another);

    return this.value.lt(decimal.value);
  }

  lte(another: Numberish): boolean {
    const decimal = new Decimal(another);

    return this.value.lte(decimal.value);
  }

  gt(another: Numberish): boolean {
    const decimal = new Decimal(another);

    return this.value.gt(decimal.value);
  }

  gte(another: Numberish): boolean {
    const decimal = new Decimal(another);

    return this.value.gte(decimal.value);
  }

  isZero(): boolean {
    return this.value.isZero();
  }

  toBN(precision = DEFAULT_DECIMAL_PRECISION): BN {
    if (precision === DEFAULT_DECIMAL_PRECISION) {
      return new BN(this.value);
    }
    if (DEFAULT_DECIMAL_PRECISION < precision) {
      return increasePrecision(this.value, precision - DEFAULT_DECIMAL_PRECISION);
    }
    return decreasePrecision(this.value, DEFAULT_DECIMAL_PRECISION - precision);
  }

  toUint256(precision: number) {
    return { type: 'struct' as const, ...uint256.bnToUint256(this.toBN(precision)) };
  }

  toString(): string {
    return utils.formatUnits(this.value.toString(), DEFAULT_DECIMAL_PRECISION).replace(/\.0*$/, '');
  }

  toRounded(fractionDigits = 0): string {
    const str = this.toString();
    const [integral, fraction = ''] = str.split('.');

    const lastDigit = fraction.charAt(fractionDigits);

    if (this.gte(0)) {
      // non-negative number
      if (Number(lastDigit) < 5) {
        // no need to be rounded
        return this.toTruncated(fractionDigits, true);
      }
      if (fractionDigits > 0) {
        // round to fractionDigits decimal places
        const bigNum = utils.parseUnits(`${integral}.${fraction.slice(0, fractionDigits)}`, fractionDigits);
        const strRounded = utils.formatUnits(bigNum.add(1), fractionDigits);
        const [outputIntegral, outputFraction] = strRounded.split('.');
        return `${outputIntegral}.${outputFraction.padEnd(fractionDigits, '0')}`;
      }
      // rounded as integer, simply +1
      return new BN(integral).add(new BN(1)).toString();
    }
    // negative number
    if (Number(lastDigit) <= 5) {
      // no need to be rounded
      return this.toTruncated(fractionDigits, true);
    }
    if (fractionDigits > 0) {
      // round to fractionDigits decimal places
      const bigNum = utils.parseUnits(`${integral}.${fraction.slice(0, fractionDigits)}`, fractionDigits);
      const strRounded = utils.formatUnits(bigNum.sub(1), fractionDigits);
      const [outputIntegral, outputFraction] = strRounded.split('.');
      return `${outputIntegral}.${outputFraction.padEnd(fractionDigits, '0')}`;
    }
    // rounded as negative integer, simply -1
    return new BN(integral).sub(new BN(1)).toString();
  }

  toTruncated(fractionDigits = 0, pad = false): string {
    const str = this.toString();
    const [integral, fraction = ''] = str.split('.');

    if (fractionDigits > 0) {
      if (pad) {
        return `${integral}.${fraction.slice(0, fractionDigits).padEnd(fractionDigits, '0')}`;
      }

      if (fraction.length > 0) {
        return `${integral}.${fraction.slice(0, fractionDigits)}`;
      }
      return integral;
    }
    return integral;
  }
}

export const ZERO = new Decimal(0);
export const ONE = new Decimal(1);
