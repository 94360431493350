import { bind } from '@react-rxjs/core';
import { createSignal } from '@react-rxjs/utils';
import { StarknetWindowObject } from 'get-starknet';
import { BehaviorSubject, Subscription, tap } from 'rxjs';
import { Nullable } from '../interfaces';
import { setWalletConnected } from './useWalletConnected';

const DEFAULT_VALUE = null;

const rawWallet$ = new BehaviorSubject<Nullable<StarknetWindowObject>>(DEFAULT_VALUE);
const wallet$ = rawWallet$.pipe(
  tap(async wallet => {
    await wallet?.enable();
    setWalletConnected(Boolean(wallet));
  }),
);

const [updatedWallet$, updateWallet] = createSignal<Nullable<StarknetWindowObject>>();

const stream$ = updatedWallet$.pipe(tap(wallet => rawWallet$.next(wallet)));

export const [useWallet] = bind(wallet$, DEFAULT_VALUE);

let subscription: Nullable<Subscription> = null;

export const subscribeWallet = (): void => {
  unsubscribeWallet();
  subscription = stream$.subscribe();
};
export const unsubscribeWallet = (): void => subscription?.unsubscribe?.();
export const resetWallet = (): void => rawWallet$.next(DEFAULT_VALUE);

export { wallet$, updateWallet };
