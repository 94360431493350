import { forwardRef, ReactNode } from 'react';

import './ShadowContainer.scss';

export interface ShadowContainerProps {
  className?: string;
  children: ReactNode;
}

const ShadowContainer = forwardRef<HTMLDivElement, ShadowContainerProps>((props, ref) => {
  const { children, className = '' } = props;

  return (
    <div ref={ref} className={`nostra__shadow-container ${className}`}>
      {children}
    </div>
  );
});

export default ShadowContainer;
