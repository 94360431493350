import { AccountInterface } from 'starknet';
import { ContractCache } from '../utils';
import { MultiCallContract } from './contracts';

const contractCache = new ContractCache(
  (address, providerOrAccount) => new MultiCallContract(address, providerOrAccount),
);

export const getMultiCallContract = (address: string, account?: AccountInterface) =>
  contractCache.get(address, account);
