import { HEALTH_FACTOR_DANGER, HEALTH_FACTOR_WARNING } from '../constants';
import { Decimal } from '../datastructures';
import { Nullable } from '../interfaces';

export type HealthFactorStatus = 'low' | 'medium' | 'high' | 'loading';

export function getHealthFactorStatus(healthFactor: Nullable<Decimal>): HealthFactorStatus {
  let healthFactorStatus: HealthFactorStatus = 'loading';

  if (healthFactor?.lte(HEALTH_FACTOR_DANGER)) {
    healthFactorStatus = 'low';
  } else if (healthFactor?.lte(HEALTH_FACTOR_WARNING)) {
    healthFactorStatus = 'medium';
  } else if (healthFactor) {
    healthFactorStatus = 'high';
  }

  return healthFactorStatus;
}
